import React, {useCallback, useContext, useRef, useState} from "react";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {Button, Checkbox, Form, message, Space} from "antd";
import ModalContainer from "../../../components/Modal";
import AddOrEditUser from "./AddOrEditUser";
import DeleteItem from "../../../components/DeleteItem";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import '../index.less';
import {
    createOrUpdateUser,
    deleteUser,
    getUser,
    getUserList,
    getUserRole,
    invokeDisableUser,
    resetPassword,
    roleUser
} from "../../../service";
import {filterData, getFormData, indexType, sexes, showError, statuses} from "../../../utils";
import {UserContext} from "../../../contexts/userContext";
import {ColumnsType} from "antd/es/table";

enum ModalTitle {
    ADD = '新增用户',
    EDIT = '编辑用户',
    DELETE = '系统确认',
    EMPOWER = '用户授权',
    RESET = '重置密码'
}

interface EmpowerProps {
    empowerList: EmpowerList[];
    defaultSelectedEmpower: number[];
}

interface EmpowerList {
    label: string,
    value: string
}


const User = (): React.ReactElement => {
    const {useInfo} = useContext(UserContext);
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectEmpowerList, setSelectEmpowerList] = useState<CheckboxValueType[]>([]);
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const formList: QueryListItem[] = [
        {
            key: 'name',
            name: '姓名',
            placeholder: '请输入姓名'
        }
    ]
    const getTextByCode = (code: number, dict: indexType): React.ReactElement => {
        return <span style={code ? {color: '#009669'} : {color: '#EB4420'}}>{dict[code]}</span>
    }
    const columns: ColumnsType<any> = [
        {
            title: '账号',
            dataIndex: 'account',
            key: 'account',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            align: 'center',
            ellipsis: true,
            width: 150,
            render: (text: number) => <span>{sexes[text]}</span>
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '工号',
            dataIndex: 'work_number',
            key: 'work_number',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '组织机构',
            dataIndex: 'organization',
            key: 'organization',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '岗位',
            dataIndex: 'station',
            key: 'station',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (code: number) => getTextByCode(code, statuses)
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'left',
            render: (_: any, record: any) => {
                if (record.object_id === useInfo.object_id) {
                    return null
                } else {
                    if (record.status === 0) {
                        return <Space size={'large'} className={'mes-user-options'}
                                      onClick={() => invokeOrDisable(record)}>启用</Space>
                    } else if (record.status === 1) {
                        return (
                            <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                            <span className={'mes-user-options'} onClick={() => {
                                openModal(ModalTitle.EDIT, record)
                            }}>编辑</span>
                                <span className={'mes-user-options'} onClick={() => {
                                    openModal(ModalTitle.DELETE, record)
                                }}>删除</span>
                                <span className={'mes-user-options'} onClick={() => invokeOrDisable(record)}>禁用</span>
                                <span className={'mes-user-options'} onClick={() => {
                                    openModal(ModalTitle.EMPOWER, record)
                                }}>授权</span>
                                <span className={'mes-user-options'} style={{marginRight: 0}} onClick={() => {
                                    openModal(ModalTitle.RESET, record)
                                }}>密码重置</span>
                            </Space>
                        )
                    }
                }
            }
        },
    ]

    const ExtraComponent = React.memo((): React.ReactElement => (
        <div>
            <Button className={'mes-user-add'} onClick={() => {
                openModal(ModalTitle.ADD, null);
            }}>新增</Button>
        </div>
    ))

    const Empower = React.memo((props: EmpowerProps): React.ReactElement => {
        const {empowerList, defaultSelectedEmpower} = props;
        const onChange = (checkedValue: Array<CheckboxValueType>) => {
            setSelectEmpowerList(checkedValue);
        }
        if (empowerList.length) {
            return (
                <div className={'mes-empower'}>
                    <Checkbox.Group options={empowerList} onChange={onChange} defaultValue={defaultSelectedEmpower}/>
                </div>
            )
        } else {
            return <div className={'mes-empower area-noData'}>暂无数据</div>
        }
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditUser form={form}/>)
        } else if (title === ModalTitle.EDIT) {
            getUser({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        form.setFieldsValue({
                            account: res.data.account,
                            name: res.data.name,
                            work_number: res.data.work_number,
                            sex: res.data.sex,
                            organization_id: res.data.organization_id,
                            phone: res.data.phone,
                            station_id: res.data.station_id,
                            email: res.data.email,
                        })
                        setInnerComponent(<AddOrEditUser form={form}/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.RESET) {
            setInnerComponent(<DeleteItem text={'确认要重置该用户密码吗？'}/>)
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.EMPOWER) {
            getUserRole({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        let empowerList: EmpowerList[] = [];
                        let defaultSelectedEmpower: number[] = [];
                        res.data.map(e => {
                            if (e.is_have) {
                                defaultSelectedEmpower.push(e.object_id)
                            }
                            setSelectEmpowerList(defaultSelectedEmpower)
                            empowerList.push({
                                label: e.role_name,
                                value: e.object_id
                            })
                        })
                        setInnerComponent(<Empower empowerList={empowerList}
                                                   defaultSelectedEmpower={defaultSelectedEmpower}/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createOrUpdateUser(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                createOrUpdateUser(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteUser(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.RESET) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            resetPassword(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('密码重置成功！');
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.EMPOWER) {
            const formData = getFormData(filterData({
                object_id: selectedItem.object_id,
                roles: selectEmpowerList.toString()
            }))
            roleUser(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('授权成功！');
                } else {
                    showError(res.message);
                }
            })
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        } else if (title === ModalTitle.EMPOWER) {
            setSelectEmpowerList([]);
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(<div/>);
        setOpen(false);
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getUserList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const invokeOrDisable = (item: any) => {
        const formData = getFormData(filterData({object_id: item.object_id}))
        invokeDisableUser(formData).then(res => {
            if (res.code === 200) {
                refreshData();
                message.success('操作成功！');
            } else {
                showError(res.message);
            }
        })
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setSelectedItem(null);
        refreshData();
        setOpen(false);
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const setWidth = () => {
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            return "50%"
        } else if (modalTitle === ModalTitle.EMPOWER) {
            return '40%'
        }
    }


    return (
        <div className={'mes-user'}>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} scrollValue={{x:1700}}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                width={setWidth()}
                                okText={(modalTitle === ModalTitle.RESET || modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}
export default User

// 打印外协，返厂转序单
import React,{forwardRef} from "react";
import PrintExternalTable from "./PrintExternalTable";
import "../index.less";
interface IProps {
  setCols?: Function,
  printData?:any,
  is_show_print?:boolean
}
const CreateExternal = forwardRef((props:IProps,ref:any) => {
  const {setCols,printData,is_show_print} = props;

  return (
    <div>
      <div style={{
        height:"43px",
        backgroundColor:"#F8F8F8",
        borderRadius:"0px 0px 6px 6px",
        marginBottom:"24px"
      }}></div>
     <div ref={ref}>
     <PrintExternalTable
        setCols={setCols}
        is_return={false}
        printData={printData}
        is_show_print={is_show_print}
      ></PrintExternalTable>
      <div 
      style={{ 
        padding: "24px",
        paddingBottom:"0px", 
        paddingTop:"0px",
        color:"#333333", 
        fontSize:"16px",
        fontWeight:"bold",
        marginRight:"200px"
        }}>
        <div style={{
            display:"flex",
            justifyContent:"space-between",
            marginBottom:"24px"
        }}>
          <span>外协单位人员签字：</span>
          <span>日期：</span>
        </div>
        <div style={{
            display:"flex",
            justifyContent:"space-between",
            marginBottom:"24px"
        }}>
          <span>外协配套科人员签字：</span>
          <span>日期：</span>
        </div>
        <div style={{
            display:"flex",
            justifyContent:"space-between",
            marginBottom:"24px"
        }}>
          <span>特种工艺车间人员签字：</span>
          <span>日期：</span>
        </div>
      </div>
     </div>
    </div>
  );
});
export default CreateExternal;

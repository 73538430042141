import React, {useState, useEffect} from 'react';
import {Button, Form, Input} from 'antd';
import './index.less'
import {getCaptcha, login} from "../../service";
import {getFormData, showError} from "../../utils";

interface LoginForm {
    account: string,
    password: string,
    code: string,
}

interface LoginProps {
    getUserInfo: () => void
}

const Login = (props: LoginProps): React.ReactElement => {
    const {getUserInfo} = props
    const [loginForm] = Form.useForm();
    const [captcha, setCaptcha] = useState<string>("");

    const onFinish = () => {
        loginForm.validateFields().then((values: LoginForm) => {
            const formData = getFormData(values)
            login(formData).then((response) => {
                if (response.code === 200) {
                    getUserInfo()
                }else{
                    showError(response.message)
                }
            })
        })
    }

    const changeCaptcha = () => {
        getCaptcha().then(res => {
            setCaptcha(res)
        })
    }

    useEffect(() => {
        changeCaptcha()
    }, [])

    return (
        <div className={'mes-login'}>
            <div className={'mes-login-title'}>生产管理平台</div>
            <div className={'mes-login-content'}>
                <Form
                    form={loginForm}
                    onFinish={() => onFinish()}
                    name="login"
                    requiredMark={true}
                    style={{margin: '90px 60px'}}
                >
                    <Form.Item
                        required
                        name="account"
                        rules={[{required: true, message: '请输入用户名!'}]}

                    >
                        <Input placeholder={'请输入用户名'} className={'mes-login-content-input'}/>
                    </Form.Item>

                    <Form.Item
                        required
                        name="password"
                        rules={[{required: true, message: '请输入密码!'}]}

                    >
                        <Input.Password placeholder={'请输入密码'} className={'mes-login-content-input'} visibilityToggle={false}/>
                    </Form.Item>
                    <Form.Item style={{marginBottom: 0}}>
                        <Form.Item
                            style={{width: '55%', float: "left", marginRight: 20}}
                            required
                            name="code"
                            rules={[{required: true, message: '请输入验证码!'}, {}]}
                        >
                            <Input placeholder={'请输入验证码'} className={'mes-login-content-input'}/>
                        </Form.Item>
                        <div style={{float: "left", height: 48, lineHeight: '48px'}}>
                            <img src={captcha} alt="" onClick={changeCaptcha}/>
                        </div>
                    </Form.Item>
                    <Form.Item style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit" className={'mes-login-content-submit'}>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login;

// 封装打印转序单的表格
import React, { useEffect} from "react";
import { Table } from "antd";
import moment from "moment";
import {formatNumber,getSystemInfoItem} from "../../../utils";
import "../index.less";
interface IProps {
  setCols?: Function;
  is_return?: boolean;
  printData?:any;
  // 当前是查看
  is_show?:boolean;
  // 当前是从查看页面进来的打印
  is_show_print?:boolean
}

const PrintExternalTable = (props: IProps) => {
  const {
    setCols,
    is_return,
    printData,
    is_show,
    is_show_print
  } = props;
  const [dataSource, setDataSource] = React.useState<any[]>(printData.work && printData.work);
  const columns: any = [
    {
      title: () => (
        <div className="external_title">
          {is_return ? "返厂零件转序清单" : "外协零件转序清单"}
        </div>
      ),
      key: "root",
      dataIndex:"root",
      children: [
        {
          title: <div style={{ fontWeight: "700" }}>生产制号</div>,
          key: "1",
          align: "center",
          dataIndex:"shengchan",
          children: [
            {
              title: () => <div style={{ fontWeight: "700" }}>转序日期</div>,
              dataIndex: "zhuanxu_date",
              key: "zhuanxu_date",
              align: "center",
              children:[
                {
                  title: "No",
                  width:100,
                  dataIndex: "index",
                  key: "index",
                  align: "center",
                  // render:(_:any,__:any,index:number)=><div>{index+1}</div>
                },
              ]
            },
          ],
        },
        {
          title: printData.batch_production && printData.batch_production,
          key: "2",
          align: "center",
          className: "bg",
          dataIndex:"batch_production",
          children: [
            {
              title: moment(printData.record_date) &&  moment(printData.record_date).format("YYYY-MM-DD"),
              className: "bg",
              dataIndex:"record_date",
              align: "center",
              children:[
                {
                  title: "产品名称",
                  width:120,
                  dataIndex: is_show_print? "name":"partName",
                  key: "name",
                  align: "center",
                },
              ]
            },
          ],
        },
        {
          title: () => <div style={{ fontWeight: "700" }}>任务名称</div>,
          key: "3",
          align: "center",
          dataIndex:"task_name",
          children: [
            {
              title: () => (
                <div style={{ fontWeight: "700" }}>要求完成日期</div>
              ),
              align: "center",
              dataIndex:"end_date",
              children:[
                {
                  title: "图号",
                  width:120,
                  dataIndex: is_show_print ?"number": "partNumber",
                  key: "number",
                  align: "center",
                },
              ]
            },
          ],
        },
        {
          title: printData.name &&  printData.name,
          key: "4",
          align: "center",
          className: "bg",
          dataIndex:"part_name",
          children: [
            {
              // printData.finish_date可能位undefiend
              title: printData.finish_date ? moment(printData.finish_date).format("YYYY-MM-DD") : null,
              className: "bg",
              align: "center",
              dataIndex:"finish",
              children: [
                {
                  title: "数量",
                  width:120,
                  dataIndex: is_show_print ? "quantity":"number",
                  key: "number",
                  align: "center",
                  render:(text:any)=>{
                    return <div>{text && formatNumber(text.toString())}</div>
                  }
                },
              ]
            },
          ],
        },
        {
          title: () => <div style={{ fontWeight: "700" }}>配套项目</div>,
          key: "5",
          align: "center",
          dataIndex:"pro",
          children: [
            {
              title: () => <div style={{ fontWeight: "700" }}>承接单位</div>,
              align: "center",
              dataIndex:"danwei",
              children:[
                {
                  title: "工序及加工内容",
                  dataIndex:  "description",
                  key:  "description",
                  align: "center",
                  render:(text:any) =><span style={{
                    wordWrap:"break-word",
                    wordBreak:"break-word"
                  }}>
                    {text}
                  </span>
                },
              ]
            },
          ],
        },
        {
          title: printData.project && printData.project,
          key: "6",
          align: "center",
          dataIndex:"project",
          className: "bg",
          children: [
            {
              title:printData.trader && printData.trader,
              dataIndex:"trader",
              className: "bg",
              align: "center",
              children:[
                {
                  title: "备注",
                  dataIndex: "remark",
                  width:100,
                  key: "remark",
                  align: "center",
                  render:(text:any) =><span style={{
                    wordWrap:"break-word",
                    wordBreak:"break-word"
                  }}>
                    {text}
                  </span>
                },
              ]
            },
          ],
        },
      ],
    },
  ];
  useEffect(() => {
    // 作为打印时传入的columns
    let newColumns:any[] = [];
    columns.map((item:any)=>{
      item.children && item.children.map((item1:any)=>{
        item1.children && item1.children.map((item2:any)=>{
          item2.children && item2.children.map((item3:any)=>{
            newColumns.push(item3)
          })
        })
      })
    })
    newColumns[0].title = "序号";
    newColumns[1].title = "产品名称";
    setCols && setCols(newColumns);
    // 查看的时候不加空行
     if(!is_show){
      getSystemInfoItem("转序单最大行数",(selectedItem:any[])=>{
        let max_row:number;
        max_row= Number(selectedItem[0].value);
        const emptyLine = max_row - dataSource.length;
        let newDataSource: any[] = dataSource;
        if (emptyLine > 0) {
          for (let i = 1; i <= emptyLine; i++) {
            newDataSource.push({ index: (dataSource.length + 1).toString() });
          }
        }
        // 解构是为了解决dataSource改变页面不更新
        setDataSource([...newDataSource]);
      })
     }
  }, []);

  return (
    <div className="print-external_modal_table">
        <Table
          columns={columns}
          className="firstTable"
          dataSource={dataSource}
          bordered
          pagination={false}
        />
    </div>
  );
};
export default React.memo(PrintExternalTable);

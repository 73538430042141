import React, { useState,useEffect } from "react";
import {formatTime,formatNumber,getErrorList} from "../../../../utils/index";
import "../../index.less";
import ModalForm,{FormItem} from "../../../../components/ModalForm";
import moment from "moment";
import { Form, Input,  Select, Row } from "antd";
interface IProps {
  selectedItem: any;
  modalForm: any;
  modalForm1: any;
  is_repair:boolean
}
export interface ISelectOption {
  label:string,
  value:number
}
const EditOrShowCraft: React.FC<IProps> = (props) => {
  const [errorList, setErrorList] = useState<ISelectOption[]>([]);
  const { selectedItem,modalForm,modalForm1,is_repair} = props;
  const [checkStatusDisabled, setCheckStatusDisabled] = useState<boolean>(!(is_repair && selectedItem.defective > 0));
  const formItem:FormItem[]=[
    {
      label: "任务单号",
      name: "number",
      is_disable: true,
    },
    {
      label: "订单号",
      name: "code",
      is_disable: true,
    },
    {
      label: "零件名称",
      name: "name",
      is_disable: true,
    },
    {
      label: "零件图号",
      name: "part_number",
      is_disable: true,
    },
    {
      label: "投产数量",
      name: "put_number",
      is_disable: true,
    },
    {
      label: "最小完成数量",
      name: "min_number",
      is_disable: true,
    },
    {
      label: "工序",
      name: "work",
      is_disable: true,
    },
    {
      label: "加工设备",
      name: "device",
      is_disable: true,
    },
    {
      label: "操作人",
      name: "user",
      is_disable: true,
    },
    {
      label: "报工类别",
      name: "category",
      is_disable: true,
    },
    {
      label: "开始时间",
      name: "start_time",
      is_disable: true,
    },
    {
      label: "结束时间",
      name: "end_time",
      is_require:true,
      is_datePicker:true,
    }
  ];
 

  useEffect(()=>{

    modalForm.setFieldsValue({
      number:selectedItem.report.task.number,
      code:selectedItem.report.task.order_part.order.code,
      name:selectedItem.report.task.order_part.part.name,
      part_number:selectedItem.report.task.order_part.part.number,
      put_number:selectedItem.report.task.quantity && formatNumber(selectedItem.report.task.quantity.toString()),
      min_number:selectedItem.report.task.min_quantity && formatNumber(selectedItem.report.task.min_quantity.toString()),
      work:selectedItem.work.process.work_process_name,
      device:selectedItem.device,
      user:selectedItem.report.user,
      // 10调试 20加工 30返修 40外协 50返厂
      category:selectedItem.report.category === 10 ? "调试":selectedItem.report.category===20?"加工":selectedItem.report.category===30?
      "返修":selectedItem.report.category===40?"外协":"返厂",
      start_time:selectedItem.report.start_time && formatTime(selectedItem.report.start_time,true),
      end_time:is_repair ? selectedItem.report.end_time && moment(selectedItem.report.end_time) : moment(),
    });
    modalForm1.resetFields(["qualified", "defective", "defect_reason_id"]);
    is_repair && modalForm1.setFieldsValue({
      qualified:selectedItem.qualified && formatNumber(selectedItem.qualified.toString()),
      defective:selectedItem.defective && formatNumber(selectedItem.defective.toString()),
      defect_reason_id:selectedItem.defect_reason_id ? selectedItem.defect_reason_id : undefined
    })
  },[])
  useEffect(()=>{
    getErrorList((errors: ISelectOption[]) => {
     
      setErrorList(errors);
    });
  },[])
  const handleInputChange = (e: any) => {
    if (parseInt(e.target.value) > 0) {
      setCheckStatusDisabled(false);
      modalForm1
        .validateFields(["defect_reason_id"])
        .then((res: any) => {
          return Promise.resolve();
        })
        .catch((err: any) => {
          return Promise.reject("不良原因校验错误");
        });
    }
    if (parseInt(e.target.value) === 0) {
      modalForm1.setFieldsValue({ defect_reason_id: undefined });
      setCheckStatusDisabled(true);
      modalForm1
        .validateFields(["defect_reason_id"])
        .then((res: any) => {
          return Promise.resolve();
        })
        .catch((err: any) => {
          return Promise.reject("不良原因校验错误");
        });
    }
  };
  return (
    <div className="work_report_or_modify">
        <ModalForm form={modalForm} formItem={formItem} selectedItem={selectedItem} dateFormat={"YYYY/MM/DD HH:mm"}
        form_name="work_report_or_modify_form" showTime={true}/>
        <Form
        name="first_check_form1"
        form={modalForm1}
        className="form1"
        autoComplete="off"
      >
        <Row>
          <Form.Item label="合格数量" name="qualified" className="wrap"
           rules={[
            { required: true, message: "请输入合格数量" },
            () => {
              let reg = /^[0-9]+(\.[0-9]{1})?$/;
              return {
                validator(_: any, value: any) {
                  
                  // Number(0) = true
                  // 只有空字符串和数字0使用Boolean转化时返回false
                  if (value && value.trim().length > 0) {
                    if (!reg.test(value.replace(',','')) && Number(value.replace(',','')) !== 0) {
                      return Promise.reject(new Error("只允许输入整数或者一位小数"));
                    }
                  }
                  return Promise.resolve();
                },
              };
            },
          ]}>
            <Input placeholder="请输入合格数量"/>
          </Form.Item>
          <Form.Item
            label="不良数量"
            name="defective"
            className="wrap"
            rules={[
              { required: true, message: "请输入不良数量" },
              () => {
                let reg = /^[0-9]*[1-9][0-9]*$/;
                return {
                  validator(_: any, value: any) {
                
                    // Number(0) = true
                    // 只有空字符串和数字0使用Boolean转化时返回false
                    if (value && value.trim().length > 0) {
                      if (!reg.test(value) && Number(value) !== 0) {
                        return Promise.reject(new Error("请输入整数"));
                      }
                      if (Number(value) > selectedItem.send_quantity) {
                        return Promise.reject(
                          new Error("不良数量应该小于等于送检数量")
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                };
              },
            ]}
          >
            <Input placeholder="请输入不良数量" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            className="wrap"
            label="不良原因"
            name="defect_reason_id"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (Number(getFieldValue("defective").toString().replace(',','')) > 0 && value) {
                    return Promise.resolve();
                  }
                  if (Number(getFieldValue("defective").toString().replace(',','')) === 0 && !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("请选择不良原因");
                },
              }),
            ]}
          >
            <Select
              mode="multiple"
              disabled={checkStatusDisabled}
              placeholder="请选择不良原因"
            >
              {errorList.map((oe: any, oi: number) => {
                return (
                  <Select.Option key={oi} value={oe.value}>
                    {oe.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default EditOrShowCraft;

import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../components/GeneralManagement";
import { Form, message, Space, Tooltip} from "antd";
import ModalContainer from "../../components/Modal";
import "./index.less";
import { moneyFormat, formatTime, formatNumber,part_status } from "../../utils/index";
import MButtom from "../../components/MyButton";
import ExportExcel from "../../components/ExportTable/exportTable";
import moment from "moment";
import EditInvoice from "./components/editInvoice";
import {ColumnsType} from "antd/es/table";
import {getInvoiceList,editInvoice} from "../../service";
import distributed from "../../assets/status/distributed.png";
import closed from "../../assets/status/closed.png";
import edit from "../../assets/edit.png";
import delivered from "../../assets/status/delivered.png";
import MyImage from "../../components/MyImage";

enum ModalTitle {
  EDIT = "编辑发票信息",
}

const CraftManagement = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] =  useState<React.ReactElement | null>(null);
  const [objectId,setObjectId] = useState<number>();
  const gRef = useRef<GRef>(null);
  const [form] = Form.useForm();
  const formList: QueryListItem[] = [
    {
      key: "trader_name",
      name: "客户名称",
      placeholder: "请输入客户名称",
    },
    {
      key: "order_number",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "order_status",
      name: "零件状态",
      placeholder: "请选择零件状态",
      multiple: true,
      options:part_status,
      defaultSelect:[30],
      // onClear:onClear,
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      key: "invoice_number",
      name: "发票号",
      placeholder: "请输入发票号",
    },
  ];
  const columns:ColumnsType<any>= [
    {
      title: "订单信息",
      className:"orderInfo",
      key: "1",
      align: "center",
      children: [
        {
          title: "No",
          className: "orderInfo",
          dataIndex: "index",
          key: "index",
          align: "center",
          width:60,
          ellipsis: true,
          fixed:true,
        },
        {
          title: "状态",
          className: "orderInfo",
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 90,
          fixed:true,
          render: (text: number) => (
            <div style={{ color: "#3E7BFA" }}>
              {text === 20 ? <MyImage src={distributed} width={60}></MyImage> :
               text === 30 ? <MyImage src={delivered} width={60}></MyImage> : <MyImage src={closed} width={60}></MyImage>}
            </div>
          ),
        },
        {
          title: "订单号",
          className:"orderInfo",
          dataIndex: ["order","code"],
          key: "code",
          align: "center",
          width: 140,
          ellipsis: true,
          fixed: "left",
        },
        {
          title: "零件名称",
          className:"orderInfo",
          dataIndex: ["part","name"],
          key: "name",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },
        {
          title: "零件图号",
          className:"orderInfo",
          dataIndex: ["part","number"],
          key: "part_num",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },
      ],
    },
    {
    title: "订单信息",
    className:"orderInfo",
    key: "9",
    align: "center",
    children: [
      {
        title: "客户",
        className:"orderInfo",
        dataIndex: ["order","trader","name"],
        key: "trader_name",
        align: "center",
        width: 140,
        ellipsis: true,
      },
      {
        title: "订单数量",
        className:"orderInfo",
        dataIndex:"number",
        key: "number",
        align: "right",
        ellipsis: true,
        width: 90,
        render: (item: Number) =>  <div> {item && formatNumber(item.toString())}</div>
      },
      {
        title: "加工内容",
        className:"orderInfo",
        dataIndex: "content",
        key: "content",
        align: "center",
        ellipsis: true,
        width: 140,
      },
      {
        title: "交货日期",
        className:"orderInfo",
        dataIndex: "finish_date",
        key: "finish_date",
        align: "center",
        ellipsis: true,
        width: 110,
        render: (item: String) => (
          <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
        ),
      },
    ]
    },
    {
      title: "发货信息",
      className:  "deliverInfo",
      align: "center",
      key: "2",
      children: [
        {
          title: "已发货数量",
          className:  "deliverInfo",
          dataIndex: "quantity",
          key: "quantity",
          align: "right",
          ellipsis: true,
          width: 110,
          render: (item: Number) =>  <div> {item && formatNumber(item.toString())}</div>
        },
      ],
    },
    {
      title: "合同信息",
      className:  "contractInfo",
      key: "3",
      children: [
        {
          title: "报价单价",
          className:  "contractInfo",
          dataIndex: "quot_single_price",
          key: "quot_single_price",
          align: "right",
          ellipsis: true,
          width: 90,
          render: (item: Number) => (
            <Tooltip
              placement="topLeft"
              title={moneyFormat(item)}
              className="tooltip"
            >
              {item && moneyFormat(item)}
            </Tooltip>
          ),
        },
        {
          title: "合同单价",
          className:  "contractInfo",
          dataIndex: "contract_single_price",
          key: "contract_single_price",
          align: "right",
          ellipsis: true,
          width: 90,
          render: (item: Number) => (
            <Tooltip
              placement="topLeft"
              title={moneyFormat(item)}
              className="tooltip"
            >
              {item && moneyFormat(item)}
            </Tooltip>
          ),
        },
        {
          title: "合同数量",
          className:  "contractInfo",
          dataIndex: "contract_number",
          key: "contract_number",
          align: "right",
          ellipsis: true,
          width: 90,
          onCell:(record, _) => {
            return {
              className:record.number !== record.contract_number? "bgColor":"bgWhite"
            };
          },
          render: (item: Number) =>  <div> {item && formatNumber(item.toString())}</div>

        },
        {
          title: "合同号",
          className:  "contractInfo",
          dataIndex: "contract_code",
          key: "contract_code",
          align: "center",
          ellipsis: true,
          width: 160,
        },
        {
          title: "合同日期",
          className:  "contractInfo",
          dataIndex: "contract_date",
          key: "contract_date",
          align: "center",
          ellipsis: true,
          width: 160,
          render: (item: String) => (
            <Tooltip placement="topLeft">{item}</Tooltip>
          ),
        },
      ],
    },

    {
      title: "发票信息",
      className:  "invoiceInfo",
      key: "4",
      align: "center",
      children: [
        {
          title: "发票日期",
          className:  "invoiceInfo",
          dataIndex: ["invoice","invoice_date"],
          key: "invoice_date",
          align: "center",
          ellipsis: true,
          width: 110,
          render: (item: String) => (
            <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
          ),
        },
        {
          title: "发票号",
          className:  "invoiceInfo",
          dataIndex: ["invoice","number"],
          key: "invoice_number",
          align: "center",
          ellipsis: true,
          width: 200,
        },
        {
          title: "结款日期",
          className:  "invoiceInfo",
          dataIndex: ["invoice","settlement_date"],
          key: "invoice_settlement_date",
          align: "center",
          ellipsis: true,
          width: 110,
          render: (item: String) => (
            <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
          ),
        },
        {
          title: "结款备注",
          className:  "invoiceInfo",
          dataIndex: ["invoice","remark"],
          key: "invoice_remark",
          align: "center",
          ellipsis: true,
          width: 200,
        },
      ],
    },
    {
      title: "订单信息",
      className: "orderInfo",
      key: "5",
      align: "center",
      children: [
        {
          title: "操作",
          className: "orderInfo",
          dataIndex: "options",
          key: "options",
          align: "center",
          // width: 90,
          fixed:"right",
          render: (_: any, record: any) => {
            return (
              <Space
                size={"large"}
                style={{ color: "#3E7BFA", cursor: "pointer" }}
              >
                <MyImage src={edit} title="编辑" onClick={
                   () => {
                    setObjectId(record.object_id)
                    openModal(ModalTitle.EDIT, record);
                  }
                }></MyImage>
              </Space>
            );
          },
        },
      ],
    },
  ]
  const ExtraComponent = React.memo((): React.ReactElement => {
    const exportExcel = async(callback:any) => {
      if (gRef.current) {
          if (gRef.current.exportData)
          {
              gRef.current.exportData().then(res => {

                 res.map((item:any) =>{
                  if(item.status) {
                    item.status === 20  ? item.status ="已下发" :item.status=== 30 ?item.status ="已交货" : item.status ="已关闭"
                  }
                })
                  let length  = res.length + 2
                  callback(res,length)
              }).catch(err => {
                console.log("导出全部数据遇到错误",err)
              })
          }
      }
  }
    const tabName =
      "发票管理" + moment(new Date()).format("YYMMDDHHmmss");
    return (
      <div className="Invoice_div">
        <ExportExcel
          onClick={exportExcel}
          columns={columns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={true}
        />
      </div>
    );
  });
  const openModal = (title: ModalTitle, item: any) => {
    setModalTitle(title);
    setTimeout(() => {
      setOpen(true);
      setInnerComponent( <EditInvoice modalForm={form} selectedItem={item}/>);
    }, 0);
  };

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getInvoiceList(Object.assign({order_status:JSON.stringify([30])}, queryCondition))
        .then((res) => {
          if (res.code === 200) {
            if(res.data.data) {
              res.data.data.map((item,index) => {
                item.index = index + 1
              })
            }
            resolve(res.data);

          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);

  const filterQueryData = (queryData: any) => {
    if(queryData.order_status){

      if(JSON.stringify(queryData.order_status) === "[]"){
        queryData.order_status = JSON.stringify([20,30,40])
      }else {
        queryData.order_status = JSON.stringify(queryData.order_status)
      }
    }
    return queryData;
  };
  const refreshData = () => {
    if (gRef.current) {
        gRef.current.onFinish();
    }
  }
  return (
    <div className="invoice_management">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ExtraComponent={<ExtraComponent />}
        ref={gRef}
        scroll={true}
        scrollValue={{x:2500}}
        filterQueryData={filterQueryData}
      />
      {modalTitle && (
        <ModalContainer
          title={modalTitle}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={
            <div className="modal_btn">
              <MButtom
                title="关闭"
                onClick={() => {
                  setOpen(false)
                }}
                buttonStyle={{
                  backgroundColor: "#B8B8B8",
                }}
              ></MButtom>
              <MButtom
                title="保存"
                onClick={() => {

                  let obj:any ={
                    order_part_id : objectId,
                    invoice_date: form.getFieldsValue().invoice_date ? moment(form.getFieldsValue().invoice_date).format("YYYY-MM-DD") : "Invalid date",
                    settlement_date : form.getFieldsValue().settlement_date ? moment(form.getFieldsValue().settlement_date).format("YYYY-MM-DD") : "Invalid date",
                    number : form.getFieldsValue().number,
                    remark : form.getFieldsValue().remark
                  };
                  if(obj.invoice_date === "Invalid date"){
                    delete obj.invoice_date
                  }
                  if(obj.settlement_date === "Invalid date"){
                    delete obj.settlement_date
                  }
                  if(obj.number === "" ||obj.number === null ){
                    delete obj.number
                  }
                  if(obj.remark === "" || obj.remark === null){
                    delete obj.remark
                  }

                  editInvoice(obj).then(res=>{

                    if(res.code === 200){
                      refreshData()
                      setOpen(false);
                    }
                  }).catch(err=>{
                    message.error(err)
                  })
                }}
                buttonStyle={{
                  backgroundColor: "#3E7BFA",
                }}
              ></MButtom>
            </div>
          }
          width={"50%"}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default CraftManagement;

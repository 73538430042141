import React from 'react';
import { Image} from "antd";
interface IProps {
    width?:number;
    src:string;
    title?:string;
    onClick?:()=>void;
}
const MyImage = (props:IProps) => {
    const {width,src,title,onClick} = props;
    return (
        <Image
        width={width?width:18}
        src={src}
        preview={false}
        title={ title ? title : undefined }
        onClick={onClick ? onClick : undefined}/>
    )
}
export default MyImage;


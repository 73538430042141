import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { Form, Tooltip, Button, message } from "antd";
import ModalContainer from "../../../components/Modal";
import "../index.less";
import {
  formatTime,
  getWorkprocesss,
  getUsersForSelect,
  getDeviceList,
  filterDateParam,
  formatNumber,
  getFormData,
  filterData,
  shiftTimeStamp,
  indexType 
} from "../../../utils/index";
import ExportExcel from "../../../components/ExportTable/exportTable";
import moment from "moment";
import WorkReportOrModify from "./components/WorkReportOrModify";
import MButton from "../../../components/MyButton";
import { ColumnsType } from "antd/es/table";
import {
  getReportList,
  forceModify,
  forceReport,
} from "../../../service";
import notStart from "../../../assets/status/notStart.png";
import processing from "../../../assets/status/processing.png";
import completed from "../../../assets/status/completed.png";
import MyImage from "../../../components/MyImage";
import compulsoryCompletion from "../../../assets/compulsory-completion.png";
import forceModification from "../../../assets/force-modification.png";

enum ModalTitle {
  WORKREPORT = "强制报工",
  MODIFY = "强制修改",
}
export interface IOptions {
  id: number;
  name: string;
}

const DeliveryDocManagement = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selectData, setSelectData] = useState<{ [key: string]: IOptions[] }>({});
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  const [objectId, setObjectId] = useState<number>();
  const gRef = useRef<GRef>(null);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [formList, setFormList] = useState<QueryListItem[]>([
    {
      key: "order_number",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      key: "report_status",
      name: "报工状态",
      placeholder: "请选择报工状态",
      multiple: true,
      options: [
        { id: 20, name: "处理中" },
        { id: 30, name: "已完成" },
      ],
      defaultSelect: [20],
    },
    {
      key: "task_number",
      name: "任务单号",
      placeholder: "请输入任务单号",
    },
    {
      key: "report_category",
      name: "报工类别",
      placeholder: "请选择报工类别",
      multiple: true,
      options: [
        { id: 10, name: "调试" },
        { id: 20, name: "加工" },
      ],
    },
    {
      key: "process_id",
      name: "工序",
      placeholder: "请选择工序",
      options: selectData.processes,
    },
    {
      key: "device_id",
      name: "加工设备",
      placeholder: "请选择加工设备",
      options: selectData.devices,
    },
    {
      key: "user_id",
      name: "操作员",
      placeholder: "请选择操作员",
      options: selectData.users,
    },
    {
      key: "date",
      name: "报工日期",
      placeholder: "请选择报工日期",
      rangePick: true,
    },
  ]);

  useEffect(() => {
    getWorkprocesss((processes: IOptions[]) => {
     
      setSelectData({
        ...selectData,
        processes: processes,
      });
      let newFormList = formList;
      newFormList[5].options = processes;
      setFormList(newFormList);
    });
    getUsersForSelect((users: IOptions[]) => {
     
      setSelectData({
        ...selectData,
        users: users,
      });
      let newFormList = formList;
      newFormList[7].options = users;
      setFormList(newFormList);
    });
    getDeviceList((devices: IOptions[]) => {
   
      setSelectData({
        ...selectData,
        devices: devices,
      });
      let newFormList = formList;
      newFormList[6].options = devices;
      setFormList(newFormList);
    });
  }, []);
  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      ellipsis: true,
      fixed:true,
    },
    {
      title: "报工状态",
      dataIndex: ["report", "status"],
      key: "status",
      align: "center",
      fixed:"left",
      width:90,
      render: (status: 10 | 20 | 30, __: any, index: any) => {
        return (
          <div>
            {status === 20 ? <MyImage src={processing} width={60}></MyImage> : 
            status === 10 ? <MyImage src={notStart} width={60}></MyImage> :
            <MyImage src={completed} width={60}></MyImage>}
          </div>
        );
      },
    },
    {
      title: "订单号",
      dataIndex: ["report", "task", "order_part", "order", "code"],
      key: "code",
      align: "center",
      width: 130,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "零件名称",
      dataIndex: ["report", "task", "order_part", "part", "name"],
      key: "name",
      align: "center",
      ellipsis: true,
      width: 130,
      fixed: "left",
    },
    {
      title: "零件图号",
      dataIndex: ["report", "task", "order_part", "part", "number"],
      key: "number",
      align: "center",
      ellipsis: true,
      width: 130,
      fixed: "left",
    },
    {
      title: "订单数量",
      dataIndex: ["report", "task", "order_part", "number"],
      key: "order_number",
      align: "right",
      ellipsis: true,
      width: 90,
      render: (text) => {
        return <div>{text && formatNumber(text.toString())}</div>;
      },
    },
    {
      title: "操作员",
      dataIndex: ["report", "user"],
      key: "user",
      align: "center",
      width:120,
      ellipsis: true,
    },
    // {
    //   title: "任务单号",
    //   dataIndex: ["report", "task", "number"],
    //   key: "task_number",
    //   align: "center",
    //   ellipsis: true,
    //   width: 160,
    // },
    // {
    //   title: "投产数量",
    //   dataIndex: ["report", "task", "quantity"],
    //   key: "quantity",
    //   align: "right",
    //   ellipsis: true,
    //   width: 120,
    //   render: (text) => {
    //     return <div>{text && formatNumber(text.toString())}</div>;
    //   },
    // },
    // {
    //   title: "最小完成数量",
    //   dataIndex: ["report", "task", "min_quantity"],
    //   key: "min_quantity",
    //   align: "right",
    //   ellipsis: true,
    //   width: 120,
    //   render: (text) => {
    //     return <div>{text && formatNumber(text.toString())}</div>;
    //   },
    // },
    {
      title: "工序",
      dataIndex: ["work", "process", "work_process_name"],
      key: "work_process_name",
      align: "center",
      ellipsis: true,
      width: 120,
    },
    {
      title: "加工设备",
      dataIndex: "device",
      key: "device",
      align: "center",
      width:120,
      ellipsis: true,
    },
    {
      title: "开始时间",
      dataIndex: ["report", "start_time"],
      key: "start_time",
      align: "center",
      ellipsis: true,
      width:150,
      render: (item: String) => (
        <Tooltip placement="topLeft" color="#fff">
          {item && formatTime(item, true)}
        </Tooltip>
      ),
    },
    {
      title: "结束时间",
      dataIndex: ["report", "end_time"],
      key: "end_time",
      align: "center",
      ellipsis: true,
      width:150,
      render: (item: String) => (
        <Tooltip placement="topLeft" color="#fff">
          {item && formatTime(item, true)}
        </Tooltip>
      ),
    },
    {
      title: "合格数量",
      dataIndex: "qualified",
      key: "qualified",
      align: "right",
      ellipsis: true,
      width:90,
      render: (text,record) => {
        return <div>{record.report.status === 20 ? "" : text && formatNumber(text)}</div>;
      },
    },
    {
      title: "不良数量",
      dataIndex: "defective",
      key: "defective",
      align: "right",
      ellipsis: true,
      width:90,
      render: (text,record) => {
        return <div>{record.report.status === 20 ? "" : text && formatNumber(text.toString())}</div>;
      },
    },
    
    {
      title: "不良原因",
      dataIndex: "defect_reason",
      key: "defect_reason_id",
      align: "center",
      ellipsis: true,
      width:350,
      render: (text) => {
        return (
          <Tooltip
            color="#fff"
            showArrow={false}
            overlayInnerStyle={{
              backgroundColor: "#fff",
              border: "1px solid black",
              color: "black",
            }}
            placement="bottomRight"
            title={
              text &&
              text.length > 0 &&
              text.map((item: any,index:number,arr:any) => {
                return <span>{item.reason && item.reason}{(index+1) === arr.length ? "" :","}</span>;
              })
            }
          >
            {text &&
              text.length > 0 &&
              text.map((item: any,index:number,arr:any) => {
                return <span>{item.reason && item.reason}{(index+1) === arr.length ? "" :","}</span>;
              })}
          </Tooltip>
        );
      },
    },
    {
      title: "报工类别",
      dataIndex: ["report", "category"],
      key: "category",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (text) => {
        return (
          <div>
            {/* 报工的种类 10调试 20加工 30返修 40外协 50返厂 */}
            {text === 10
              ? "调试"
              : text === 20
              ? "加工"
              : text === 30
              ? "返修"
              : text === 40
              ? "外协"
              : "返厂"}
          </div>
        );
      },
    },
    {
      title: "调试工时",
      dataIndex: ["work", "production_precesses", "duration"],
      key: "duration",
      align: "right",
      ellipsis: true,
      width:100,
      render:(t)=><span>{t?`${t}秒`:""}</span>
    },
    {
      title: "加工工时",
      dataIndex: ["work", "production_precesses", "work_duration"],
      key: "work_duration",
      align: "right",
      ellipsis: true,
      width:100,
      render:(t)=><span>{t?`${t}秒`:""}</span>
    },
   
   
   
    
    {
      title: "预计工时",
      dataIndex: ["report", "estimated_time"],
      key: "estimated_time",
      align: "right",
      ellipsis: true,
      width:120,
      render: (time) => <span>{time && shiftTimeStamp(time)}</span>,
    },
    {
      title: "实际工时",
      dataIndex: ["report", "real_time"],
      key: "real_time",
      align: "right",
      ellipsis: true,
      width:120,
      render: (time) => <span>{time && shiftTimeStamp(time)}</span>,
    },
    {
      title: "缺工时",
      dataIndex: ["report", "overtime"],
      key: "overtime",
      align: "right",
      ellipsis: true,
      width:120,
      onCell: (record, _) => {
        return {
          className: record.report.overtime > 0 ? "bgColor" : "bgWhite",
        };
      },
      render: (text) => <div>{text > 0 ? shiftTimeStamp(text) : "-"}</div>,
    },
    {
      title: "超工时",
      dataIndex: ["report", "lack_of_time"],
      key: "lack_of_time",
      align: "right",
      ellipsis: true,
      width:120,
      onCell: (record, _) => {
        return {
          className: record.report.lack_of_time > 0 ? "bgColor" : "bgWhite",
        };
      },
      render: (text) => <div>{text > 0 ? shiftTimeStamp(text) : "-"}</div>,
    },
    {
      title: "更新者",
      dataIndex: "user",
      key: "user",
      align: "center",
      width:120,
      ellipsis: true,
    },
    {
      title: "更新日期",
      dataIndex: "update_time",
      key: "update_time",
      align: "center",
      ellipsis: true,
      width:150,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
      ),
    },

    {
      title: "操作",
      dataIndex: "options",
      key: "options",
      align: "center",
      fixed:"right",
      // width:60,
      render: (_: any, record: any) => {
        return record.report.status === 20 ? (
          <div style={{
            cursor:"pointer"
          }}>
            <MyImage src={compulsoryCompletion} title="强制完工" onClick={
            () => {
              setObjectId(record.object_id);
              openModal(ModalTitle.WORKREPORT, record);
            }
          }></MyImage>
          </div>
        ) : (
           <div style={{
            cursor:"pointer"
           }}>
            <MyImage src={forceModification} title="强制修改" onClick={
            () => {
              setObjectId(record.object_id);
              openModal(ModalTitle.MODIFY, record);
            }
          }></MyImage>
           </div>
        );
      },
    },
  ];

  const ExtraComponent = React.memo((): React.ReactElement => {
    const exportExcel = async(callback:any) => {
      if (gRef.current) {
          if (gRef.current.exportData) 
          {
              gRef.current.exportData().then(res => {
             
                  const statusObj: indexType = {
                    10: "未开始",
                    20: "处理中",
                    30: "已完成",
                  }
                  const obj: indexType = {
                    10: "调试",
                    20: "加工",
                    30: "返修",
                    40: "外协",
                    50: "返厂",
                  };
                  res?.map((item:any) => {
                    if(item.work.production_precesses && item.work.production_precesses.duration) {
                      item.work.production_precesses.duration = item.work.production_precesses.duration + '秒';
                    }
                    if(item.work.production_precesses && item.work.production_precesses.work_duration) {
                      item.work.production_precesses.work_duration = item.work.production_precesses.work_duration + '秒';
                    }
                    if(item.report.estimated_time) {
                      item.report.estimated_time = shiftTimeStamp(item.report.estimated_time);
                    }
                    if(item.report.real_time) {
                      item.report.real_time = shiftTimeStamp(item.report.real_time);
                    }
                    item.report.overtime ? item.report.overtime = shiftTimeStamp(item.report.overtime) : item.report.overtime = '-'; 
                    
                    item.report.lack_of_time ? item.report.lack_of_time = shiftTimeStamp(item.report.lack_of_time) : item.report.lack_of_time = '-';
                    if (item.report.status) {
                      item.report.status = statusObj[item.report.status]
                    }
                    if (item.report.category) {
                      item.report.category = obj[item.report.category];
                    }
                    if(item.defect_reason && Array.isArray(item.defect_reason)) {
                      let arr = item.defect_reason.map((item1: any,index:number,arr:any) => {
                        return item1.reason
                      })
                      item.defect_reason =  arr.join(',')
                    }
                    
                  });
                  let length  = res.length + 1
                  callback(res,length)
              }).catch(err => {
                console.log("导出全部数据遇到错误",err)
              })
          }
      }
  }
    const tabName = "报工监控" + moment(new Date()).format("YYMMDDhhmmss");
    return (
      <div className="btn_box">
        <ExportExcel
          onClick={exportExcel}
          columns={columns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={false}
        ></ExportExcel>
      </div>
    );
  });
  const openModal = (title: ModalTitle, item: any) => {
    setModalTitle(title);
    setTimeout(() => {
      setOpen(true);
      if (title === ModalTitle.WORKREPORT) {
        setInnerComponent(
          <WorkReportOrModify modalForm1={form1} selectedItem={item} modalForm={form} is_repair={false}/>
        );
      } else {
        setInnerComponent(
          <WorkReportOrModify modalForm1={form1} selectedItem={item} modalForm={form} is_repair={true}/>
        );
      }
    }, 0);
  };

  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    if(queryData.report_category){
      queryData.report_category = JSON.stringify(queryData.report_category);
    }
    
    if(queryData.report_status){
      
      // 点击标签删除的情况会剩下[]
      if(JSON.stringify(queryData.report_status) === "[]"){
        queryData.report_status = JSON.stringify([20,30]);
      }else {
        queryData.report_status = JSON.stringify(queryData.report_status)
      }
    }
    return queryData;
  };
  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getReportList(Object.assign({report_status:JSON.stringify([20])},queryCondition))
        .then((res) => {
          if (res.code === 200) {
            if(res.data.data) {
              res.data.data.map((item,index) => {
                item.index = index + 1
              })
            }
            resolve(res.data);
           
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    });
  },[]);

  return (
    <div className="report_monitor">
      <GeneralManagement 
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ExtraComponent={<ExtraComponent />}
        ref={gRef}
        scrollValue={{x:3000}}
        filterQueryData={filterQueryData}
      />
      {modalTitle && (
        <ModalContainer
          footer={
            modalTitle === "强制报工" ? (
              <div className="modal_btn">
                <Button onClick={()=>{setOpen(false)}}>关闭</Button>
                <MButton
                  title="强制报工"
                  onClick={() => {
                   form.validateFields(["end_time"]).then(res => {
                    form1.validateFields(["qualified", "defective","defect_reason_id"])
                    .then((res) => {
                      let obj: any = {
                        process_id: objectId,
                        end_time:
                          form.getFieldsValue(true).end_time && moment(form.getFieldsValue(true).end_time).format("YYYY-MM-DD HH:mm:ss"),
                        qualified: Number(form1.getFieldsValue(true).qualified),
                        defective: Number(form1.getFieldsValue(true).defective),
                        defect_reason_id:
                          JSON.stringify(form1.getFieldsValue(true).defect_reason_id),
                      };
                      // 去掉undefined和null的项
                      let obj1 = filterData(obj);
                      const formData = getFormData(obj1);
                   
                      forceReport(formData)
                        .then((res) => {
                     
                          if(res.code === 200){
                            message.success("操作成功");
                            if(gRef.current){
                              gRef.current.onFinish()
                            }
                            setOpen(false);
                          }else{
                            message.error(res.message);
                          }
                        })
                        .catch((err) => {
                          message.error(err);
                        });
                    })
                    .catch((err) => {
                      console.log("校验失败", err);
                    });
                   }).catch(err => {

                   })
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            ) : (
              <div className="modal_btn">
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  关闭
                </Button>
                <MButton
                  title="强制修改"
                  onClick={() => {
                 
                   form.validateFields(["end_time"]).then(res => {
                    form1.validateFields(["qualified", "defective","defect_reason_id"])
                    .then((res) => {
                      let end = moment(form.getFieldsValue(true).end_time).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                       let obj: any = {
                        process_id: objectId,
                        end_time:end,
                        qualified: Number(form1.getFieldsValue(true).qualified.toString().replace(',','')),
                        defective: Number(form1.getFieldsValue(true).defective),
                        defect_reason_id:
                          JSON.stringify(form1.getFieldsValue(true).defect_reason_id),
                      };
                      // 去掉undefined和null的项
                      let obj1 = filterData(obj);
                      const formData = getFormData(obj1);
                  
                      forceModify(formData)
                        .then((res) => {
                         if(res.code === 200){
                          message.success("操作成功");
                          if(gRef.current){
                            gRef.current.onFinish()
                          };
                          setOpen(false);
                         } else {
                          message.error(res.message)
                         }
                        })
                        .catch((err) => {
                          message.error(err);
                        });
                    })
                    .catch((err) => {
                      console.log("校验失败", err);
                    });
                   }).catch(err => {

                   })
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            )
          }
          title={modalTitle}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          width={"50%"}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default DeliveryDocManagement;

import React, { useCallback, useEffect, useRef,useState } from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { Tooltip, message } from "antd";
import "../index.less";
import "../index.less";
import { formatTime,getUsersForSelect,filterDateParam,formatNumber } from "../../../utils/index";
import { ColumnsType } from "antd/es/table";
import ExportExcel from "../../../components/ExportTable/exportTable";
import moment from "moment";
import {getStorageList} from "../../../service";

const WarehousingQuery = (): React.ReactElement => {
  const [optionMans, setOptionMans] = useState<{ id: number; name: string }[]>([]);
  const gRef = useRef<GRef>(null);
  const [formList,setFormList]= useState<QueryListItem[]>([
    {
      key: "code",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      // 任务单号无
      key: "task_number",
      name: "任务单号",
      placeholder: "请输入任务单号",
    },
    {
      key: "user_id",
      name: "入库员",
      placeholder: "请选择入库员",
      options: optionMans,
    },
    {
      key: "date",
      name: "入库日期",
      placeholder: "请选择入库日期",
      rangePick: true,
    },
  ]);
  useEffect(() => {
    getUsersForSelect((users:{ id: number; name: string }[])=>{
      setOptionMans(users);
      let newFormList = formList;
      newFormList[3].options = users
      setFormList(newFormList);
    });
  },[]);
  const columns: ColumnsType<any> = [
    {
      title: "",
      key: "100",
      className:"orderInfo",
      children: [
        {
          title: "No",
          className:"orderInfo",
          dataIndex: "index",
          key: "index",
          align: "center",
          width:60,
          ellipsis: true,
          fixed:true,
        },
    ]},
    {
      title: "订单信息",
      key: "1",
      className:"orderInfo",
      children: [
        {
          title: "订单号",
          className:"orderInfo",
          dataIndex: "order_code",
          key: "order_code",
          align: "center",
          ellipsis: true,
          width: 140,
        },
        {
          title: "零件名称",
          className:"orderInfo",
          dataIndex: "part_name",
          key: "part_name",
          align: "center",
          ellipsis: true,
          width: 140,
        },

        {
          title: "零件图号",
          className:"orderInfo",
          dataIndex: "part_number",
          key: "part_number",
          align: "center",
          ellipsis: true,
          width: 140,
        },

        {
          title: "订单数量",
          className:"orderInfo",
          dataIndex: "number",
          key: "number",
          align: "right",
          ellipsis: true,
          width: 90,
          render:(item)=><div>{item && formatNumber(item.toString())}</div>
          
        },
        {
          title: "任务单号",
          className:"orderInfo",
          dataIndex: ["task","number"], 
          key: "task_number",
          align: "center",
          ellipsis: true,
          width: 160,
        },
        {
          title: "投产日期",
          className:"orderInfo",
          dataIndex: ["task","start_date"],
          key: "start_date",
          align: "center",
          ellipsis: true,
          width: 120,
          render: (item: String) => (
            <Tooltip placement="topLeft">{formatTime(item, false)}</Tooltip>
          ),
        },
        {
          title: "投产数量",
          className:"orderInfo",
          dataIndex: ["task","quantity"],
          key: "quantity",
          align: "right",
          ellipsis: true,
          width: 90,
          render:(item)=><div>{item && formatNumber(item.toString())}</div>
        },
        {
          title: "最小完成数量",
          className:"orderInfo",
          dataIndex: ["task","min_quantity"],
          key: "min_quantity",
          align: "right",
          ellipsis: true,
          width: 120,
          render:(item)=><div>{item && formatNumber(item.toString())}</div>
        },
      ],
    },
    {
      title: "入库信息",
      key: "2",
      className:"warehousingInfo",
      children: [
        {
          title: "入库数量",
          className:"warehousingInfo",
          dataIndex: "quantity", 
          key: "quantity",
          align: "right",
          ellipsis: true,
          width: 90,
          render:(item)=><div>{item && formatNumber(item.toString())}</div>
        },
        {
          title: "备注",
          className:"warehousingInfo",
          dataIndex: "remark",
          key: "remark",
          align: "center",
          ellipsis: true,
          width: 160,
        },

        {
          title: "入库人员",
          className:"warehousingInfo",
          dataIndex: "user",
          key: "user",
          align: "center",
          ellipsis: true,
          width: 100,
        },
        {
          title: "入库时间",
          className:"warehousingInfo",
          dataIndex: "create_time",
          key: "create_time",
          align: "center",
          ellipsis: true,
          // width: 110,
          render: (item: String) => (
            <Tooltip placement="topLeft">{formatTime(item, true)}</Tooltip>
          ),
        },
      ],
    },
  ];
  const ExtraComponent = React.memo((): React.ReactElement => {
    const tabName =
      "入库记录" + moment(new Date()).format("YYMMDDhhmmss");
      const exportExcel = async(callback:any) => {
        if (gRef.current) {
            if (gRef.current.exportData) 
            {
                gRef.current.exportData().then(res => {
              
                    res.map((a:any,i:any) =>{
                      a.index = i+1;
                      if(a.create_time) {
                        a.create_time = formatTime(a.create_time,true)
                      }
                    })
                    let length  = res.length + 2
                    callback(res,length)
                }).catch(err => {
                  console.log("导出全部数据遇到错误",err)
                })
            }
        }
    }
    return (
      <div className="btn_box">
        <ExportExcel
          onClick={exportExcel}
          columns={columns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={true}
        ></ExportExcel>
      </div>
    );
  });

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getStorageList(queryCondition)
        .then((res) => {
          if (res.code === 200) {
            if(res.data.data) {
              res.data.data.map((item,index) => {
                item.index = index + 1;
              })
            }
            resolve(res.data);
           
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);
  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    return queryData;
  };

  return (
    <div className="ware_housing_query">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        scroll={true}
        scrollValue={{x:1600}}
        ExtraComponent = {<ExtraComponent/>}
        filterQueryData={filterQueryData}
      />
    </div>
  );
};
export default WarehousingQuery;

import React, { useState} from "react";
import { Tooltip, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatTime } from "../../../utils/index";
import released from "../../../assets/status/released.png";
import processing from "../../../assets/status/processing.png";
import completed from "../../../assets/status/completed.png";
import MyImage from "../../../components/MyImage";
import "../index.less";
interface IProps {
  selectedItem: any;
  setSelectedRowKeys:Function;
  selectedRowKeys:any;
}

const Synchronous = (props: IProps) => {
  const {selectedItem,setSelectedRowKeys,selectedRowKeys} = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 60,
      ellipsis: true,
      render: (_,__,i) => <span>{i + 1}</span>,
    },
    {
      title: "任务单号",
      dataIndex: "number",
      key: "number",
      width: 130,
      ellipsis: true,
    },
    {
      title: "投产数量",
      dataIndex: "quantity",
      key: "quantity",
      width: 130,
      ellipsis: true,
    },
    {
      title: "任务单状态",
      dataIndex: "status",
      key: "status",
      width: 130,
      ellipsis: true,
      render:(text) => {
        // 10未下达 20已下达 30处理中 40已完成
        if(text === 20) return <MyImage width={60} src={released}></MyImage>
        if(text === 30) return <MyImage width={60} src={processing}></MyImage>
        if(text === 40) return <MyImage width={60} src={completed}></MyImage>
      } 
    },
    {
      title: "投产日期",
      dataIndex: "start_date",
      key: "start_date",
      width:130,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
      ),
    },
  ];
  const getTasks = () => {
    if(selectedItem.task) {
      return selectedItem.task.filter((i:any)=>i.status !== 10 && i.processing_category === 10);
    }
    return []
  }
 

  const onChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const showTotal = () => {
      return (
        <div className={"pagination-total"}>
          <span style={{ marginRight: 12 }}>{`共${getTasks().length}条记录`}</span>
          {`第${currentPage}/${Math.ceil(getTasks().length/10)}页`}
        </div>
      );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };


  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 60,
    getCheckboxProps: (record:any) => ({
      // 只是不渲染复选框全选时仍会被选中，需要同时禁用复选框
      disabled: record.status === 40
    }),
    renderCell: (_:any, record:any, ___:any, originNode:any) => {
        if (record.status === 40) {
          return null;
        }
        return originNode;
      },
  };
 

  return (
    <div className="synchronous_craft">
      <div className="title">任务单列表</div>
      <Table 
      columns={columns}
      dataSource={getTasks()}
      pagination={{
        position: ["bottomRight"],
        showTotal:showTotal,
        total:getTasks().length,
        current:currentPage,
        onChange:onChange,
        pageSize:pageSize,
        defaultCurrent: 1,
        defaultPageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      rowSelection={rowSelection}
      rowKey={record=>record.object_id}
      >
      </Table>
    </div>
  );
};
export default Synchronous;

import React, {useState} from 'react';
import {Layout} from 'antd';
import HeaderBar from "./headerBar";
import SiderBar from './siderBar';
import BreadcrumbBar from "./breadcrumbBar";
import Router from '../../router';
import {useNavigate} from "react-router-dom";
import './index.less';

/**
 * 整体布局
 */
const LayoutContainer = () => {
    const {Header, Sider, Content} = Layout;
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <Layout className={'mes-layout'}>
            <Sider className={'mes-sider'} width={260} collapsible collapsed={collapsed} collapsedWidth={98}
                   trigger={null}>
                <div className={collapsed ? 'logoSmall' : 'logo'} onClick={() => {
                    navigate('/')
                }}>
                    {
                        collapsed ? <img src={require('../../assets/logo.png')} alt=""/> : <div className={'mes-sider-title'}>生产管理平台</div>
                    }
                </div>
                <SiderBar collapsed={collapsed}/>
            </Sider>
            <Layout>
                <Header>
                    <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed}/>
                </Header>
                <BreadcrumbBar/>
                <Content className={'mes-content'}>
                    <Router/>
                </Content>
            </Layout>
        </Layout>
    )
}

export default React.memo(LayoutContainer);

import React from "react";
import './index.less'

interface DeleteOrResetProps {
    text: string
}

/**
 * 通用删除组件
 */
const DeleteItem = (props: DeleteOrResetProps): React.ReactElement => {
    const {text} = props;

    return (
        <div className={'mes-modal-delete'}>
            <div>
                <img src={require('../../assets/confirm-delete.png')} alt={''} style={{width: 60}}/>
            </div>
            <div className={'confirm-text'}>{text}</div>
        </div>
    )
}
export default React.memo(DeleteItem);

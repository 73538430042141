// 带分页的表格
import { useState, useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import "../index.less";
interface IProps {
  columns: ColumnsType<any>;
  getListAsync: Function;
  scrollValue?:{x:number}
}
const PaganationTable = (props: IProps) => {
  const { columns, getListAsync, scrollValue } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState();
  useEffect(() => {
    getList({
      page_num: currentPage.toString(),
      page_size: pageSize.toString(),
    });
  }, []);
  const getList = (values: any) => {
    setLoading(true);
    getListAsync(values).then((res: any) => {
      
      setLoading(false);
      res.data.map((e: any) => (e.key = e.key ? e.key : e.object_id));
      setDataSource(res.data);
      setTotal(res.total);
      setTotalPage(res.pages);
    });
  };
  const showTotal = () => {
    return (
      <div className={"pagination-total"}>
        <span style={{ marginRight: 12 }}>{`共${total}条记录`}</span>
        {`第${currentPage}/${totalPage}页`}
      </div>
    );
  };
  const onChange = (page: number, pageSize: number) => {

    setCurrentPage(page);
    setPageSize(pageSize);
    getList({
      page_num: page.toString(),
      page_size: pageSize.toString(),
    });
  };
  return (
    <div className="paganation_table">
      <Table
        columns={columns}
        dataSource={dataSource}
        size="middle"
        loading={loading}
        scroll={scrollValue ? scrollValue : { x: "calc(700px + 50%)" }}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: 1,
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: onChange,
          showTotal: showTotal,
        }}
      />
    </div>
  );
};
export default PaganationTable;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {Button, message, Space, Modal, Form, InputNumber} from "antd";
import {
    filterData, filterQueryDataOrder, fontColor,
    formatNumber, shiftTimeStamp,
    showError, statusList,
    wayList
} from "../../../utils";
import moment from "moment";
import {ColumnsType} from "antd/es/table";
import {changePartNumber, orderCloseOrder, orderGetCompositeList} from "../../../service";
import ExportExcel from "../../../components/ExportTable/exportTable";
import ModalContainer from "../../../components/Modal";
import '../index.less'
import MyImage from "../../../components/MyImage";


const IntegratedManagement = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'trader_name',
            name: '客户名称',
            width: 60,
            placeholder: '请输入客户名称'
        },
        {
            key: 'code',
            name: '订单号',
            width: 60,
            placeholder: '请输入订单号'
        },
        {
            key: 'part_number',
            name: '零件图号',
            width: 60,
            placeholder: '请输入零件图号'
        },
        // {
        //     key: 'part_status',
        //     name: '零件状态',
        //     width: 60,
        //     placeholder: '请选择零件状态',
        //     options: statusList,
        //     defaultValue: [20]
        // },
        {
            key: 'material_type',
            name: '供料方式',
            width: 60,
            placeholder: '请选择供料方式',
            options: wayList
        },
        {
            key: 'deliveryDate',
            name: '交货日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'contract_code',
            name: '合同编号',
            width: 60,
            placeholder: '请输入合同编号'
        },
        {
            key: 'invoice_number',
            name: '发票号',
            width: 60,
            placeholder: '请输入发票号'
        },
        {
            key: 'createDate',
            name: '创建日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'issueDate',
            name: '下发日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'is_quot',
            name: '无报价',
            placeholder: '',
            check: true
        },
        {
            key: 'is_contract',
            name: '无合同',
            placeholder: '',
            check: true
        },
        {
            key: 'is_invoice',
            name: '无发票',
            placeholder: '',
            check: true
        },
        {
            key: 'is_settlement',
            name: '未结款',
            placeholder: '',
            check: true
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            className: 'orderInfo',
            key: 'orderInfo',
            align: 'center',
            width: 200,
            children: [
                {
                    title: "No",
                    dataIndex: "index",
                    className: 'orderInfo',
                    key: "index",
                    align: "center",
                    width: 60,
                    ellipsis: true,
                    fixed: "left",
                    render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
                },
                {
                    title: '零件状态',
                    dataIndex: 'status',
                    className: 'orderInfo',
                    key: 'status',
                    width: 90,
                    align: 'center',
                    fixed: "left",
                    render: (value: number) => {
                        return statusList.map(e => {
                            if (e.id === value) {
                                return <img key={e.id} src={e.src} alt={''} style={{width: 60}}/>
                            }
                        })
                    }
                },
                {
                    title: '订单号',
                    dataIndex: ['order', 'code'],
                    className: 'orderInfo',
                    key: 'orderNumber',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                },
                {
                    title: '零件名称',
                    dataIndex: ['part', 'name'],
                    className: 'orderInfo',
                    key: 'partName',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                },
                {
                    title: '零件图号',
                    dataIndex: ['part', 'number'],
                    className: 'orderInfo',
                    key: 'partNumber',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            className: 'orderInfo',
            key: 'orderInfo',
            align: 'center',
            width: 200,
            children: [
                {
                    title: '客户',
                    dataIndex: ['order', 'trader', 'name'],
                    className: 'orderInfo',
                    key: 'customer',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                },
                {
                    title: '供料方式',
                    dataIndex: ['part', 'material_type'],
                    className: 'orderInfo',
                    key: 'way',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    render: (value: number) => {
                        return wayList.map(e => {
                            if (e.id === value) {
                                return <span key={e.id}>{e.name}</span>
                            }
                        })
                    }
                },
                {
                    title: '材料',
                    dataIndex: ['part', 'material'],
                    className: 'orderInfo',
                    key: 'material',
                    align: 'center',
                    width: 120,
                    ellipsis: true,
                },
                {
                    title: '订单数量',
                    dataIndex: 'number',
                    className: 'orderInfo',
                    key: 'orderAccount',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '加工内容',
                    dataIndex: 'content',
                    className: 'orderInfo',
                    key: 'content',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '交货日期',
                    dataIndex: 'finish_date',
                    className: 'orderInfo',
                    key: 'deliveryDate',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD')}</span>
                        }
                    }
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    className: 'orderInfo',
                    key: 'remarks',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '合同信息',
            dataIndex: 'contractInfo',
            className: 'contractInfo',
            key: 'contractInfo',
            align: "center",
            width: 200,
            children: [
                {
                    title: '报价单价',
                    dataIndex: 'quot_single_price',
                    className: 'contractInfo',
                    key: 'quotedPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同单价',
                    dataIndex: 'contract_single_price',
                    className: 'contractInfo',
                    key: 'contractInfoPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同数量',
                    dataIndex: 'contract_number',
                    className: 'contractInfo',
                    key: 'contractQuantity',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    onCell: (record, _) => {
                        return {
                            className: record.contractQuantity !== record.orderAccount ? "cell-yellow" : ""
                        };
                    },
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同编号',
                    dataIndex: 'contract_code',
                    className: 'contractInfo',
                    key: 'contractNumber',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '合同日期',
                    dataIndex: 'contract_date',
                    className: 'contractInfo',
                    key: 'contract_date',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '工艺信息',
            dataIndex: 'processInfo',
            className: 'processInfo',
            key: 'processInfo',
            align: "center",
            width: 200,
            children: [
                {
                    title: '总投产数量',
                    dataIndex: ['production', 'number'],
                    className: 'processInfo',
                    key: 'quantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '工艺完成日期',
                    dataIndex: 'production_time',
                    className: 'processInfo',
                    key: 'completeDate',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD')}</span>
                        }
                    }
                },
                {
                    title: '工艺员',
                    dataIndex: 'operator',
                    className: 'processInfo',
                    key: 'person',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '投产信息',
            dataIndex: 'productionInfo',
            className: 'productionInfo',
            key: 'productionInfo',
            align: "center",
            width: 200,
            children: [
                {
                    title: '累计投产数量',
                    dataIndex: 'total_quantity',
                    className: 'productionInfo',
                    key: 'productionQuantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '累计入库数量',
                    dataIndex: ['task', 'ware_quantity'],
                    className: 'productionInfo',
                    key: 'receiptQuantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合格率',
                    dataIndex: ['task', 'pass_percent'],
                    className: 'productionInfo',
                    key: 'rate',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div style={{textAlign: 'right'}}>{value}</div>
                },
                {
                    title: '已发货数量',
                    dataIndex: ['task', 'deliver_quantity'],
                    className: 'productionInfo',
                    key: 'shippedQuantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div style={{textAlign: 'right'}}>{value}</div>
                },
            ]
        },
        {
            title: '外协信息',
            dataIndex: 'externalAssistanceInfo',
            className: 'externalAssistanceInfo',
            key: 'externalAssistanceInfo',
            align: "center",
            width: 200,
            children: [
                {
                    title: '外协单价',
                    dataIndex: 'out_single_price',
                    className: 'externalAssistanceInfo',
                    key: 'externalAssistancePrice',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    onCell: (record, _) => {
                        return {
                            className: record.contractInfoPrice ? "cell-yellow" : ""
                        };
                    },
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '外协说明',
                    dataIndex: 'out_remark',
                    className: 'externalAssistanceInfo',
                    key: 'externalAssistanceExplain',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '发票信息',
            dataIndex: 'invoiceInfo',
            className: 'invoiceInfo',
            key: 'invoiceInfo',
            align: "center",
            width: 200,
            children: [
                {
                    title: '发票日期',
                    dataIndex: ['invoice', 'invoice_date'],
                    className: 'invoiceInfo',
                    key: 'invoiceDate',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div>{value ? formatNumber(value.toString()) : null}</div>
                },
                {
                    title: '发票号',
                    dataIndex: ['invoice', 'number'],
                    className: 'invoiceInfo',
                    key: 'invoiceNumber',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '结款日期',
                    dataIndex: ['invoice', 'settlement_date'],
                    className: 'invoiceInfo',
                    key: 'settlementDate',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div>{value ? formatNumber(value.toString()) : null}</div>
                },
                {
                    title: '结款备注',
                    dataIndex: ['invoice', 'remark'],
                    className: 'invoiceInfo',
                    key: 'settlementRemark',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo2',
            className: 'orderInfo',
            key: 'orderInfo2',
            align: "center",
            width: 200,
            children: [

                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    className: 'orderInfo',
                    key: 'createTime',
                    width: 200,
                    align: 'center',
                    ellipsis: true,
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                        }
                    }
                },
                {
                    title: '下发时间',
                    dataIndex: ['order', 'issue_time'],
                    className: 'orderInfo',
                    key: 'issueTime',
                    width: 200,
                    align: 'center',
                    ellipsis: true,
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                        }
                    }
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo2',
            className: 'orderInfo',
            key: 'orderInfo2',
            align: "center",
            width: 200,
            children: [
                {
                    title: '操作',
                    align: 'center',
                    className: 'orderInfo',
                    width: 150,
                    fixed: "right",
                    render: (_: any, item: any) => {
                        if (item.status === 30) {
                            return <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                <MyImage title={'修改数量'} src={require('../../../assets/quantity-adjustment.png')}
                                         onClick={() => {
                                             handleUpdate(item)
                                         }}></MyImage>
                                <MyImage title={'关闭订单'} src={require('../../../assets/close.png')} onClick={() => {
                                    handleClose(item)
                                }}></MyImage>
                            </Space>
                        } else if (item.status === 20) {
                            return <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                <MyImage title={'修改数量'} src={require('../../../assets/quantity-adjustment.png')}
                                         onClick={() => {
                                             handleUpdate(item)
                                         }}></MyImage>
                            </Space>
                        }
                    }
                },
            ]
        },
    ]
    const closeOrder = (params: Object) => {
        const formData = filterData(params)
        orderCloseOrder(formData).then(res => {
            if (res.code === 200) {
                message.success('操作成功！');
                setOpen(false)
                setFlag(false)
                setSelectedItem(null)
                refreshData();
            } else {
                setOpen(false)
                setFlag(false)
                setSelectedItem(null)
                showError(res.message);
            }
        })
    }
    const [open, setOpen] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();

    const handleUpdate = (item: any) => {
        setSelectedItem(item)
        form.setFieldValue('origin', item.number)
        setIsUpdate(true)
    }

    const onOk = () => {
        form.validateFields().then(value => {
            changePartNumber({
                object_id: selectedItem.object_id,
                number: value.update
            }).then(res => {
                if (res.code === 200) {
                    message.success('操作成功！');
                    form.resetFields();
                    setIsUpdate(false);
                    setSelectedItem(null)
                    refreshData();
                } else {
                    showError(res.message)
                }
            })
        })
    }


    const handleClose = (item: any) => {
        setSelectedItem(item)
        if (item.status === 30) {
            if (item.contract_date) {
                if (item.invoice && item.invoice.invoice_date) {
                    closeOrder({
                        object_id: item.object_id,
                    })
                } else {
                    setFlag(false)
                    setOpen(true)
                }
            } else {
                setFlag(true)
                setOpen(true)
            }
        } else {
            message.warning('只能对已交货状态的零件执行关闭操作!')
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => {
        const exportExcel = async (callback: any) => {
            if (gRef.current) {
                if (gRef.current.exportData) {
                    gRef.current.exportData(true).then(res => {
                        res.map((item: any, index: number) => {
                            if (item.part.material_type) {
                                item.part.material_type === 10 ? item.part.material_type = "带料" : item.part.material_type = "购料"
                            }
                            if (item.status) {
                                item.status === 20 ? item.status = "已下发" : item.status === 30 ? item.status = "已交货" : item.status = "已关闭"
                            }
                            item.index = index + 1
                        })
                        let length = res.length + 2
                        callback(res, length)
                    }).catch(err => {
                        console.log("导出全部数据遇到错误", err)
                    })
                }
            }
        }
        const tabName =
            "综合管理" + moment(new Date()).format('YYYYMMDD');

        return (
            <div className="Invoice_div" style={{marginRight: 20}}>
                <ExportExcel
                    onClick={exportExcel}
                    columns={columns}
                    tableName={tabName}
                    btnName="导出"
                    isTwoHeader={true}
                />
            </div>
        );
    });

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const filterQueryData = (queryData: any) => {
        let tempQueryData = {
            ...queryData,
            is_quot: queryData.is_quot ? 0 : 1,
            is_contract: queryData.is_contract ? 0 : 1,
            is_invoice: queryData.is_invoice ? 0 : 1,
            is_settlement: queryData.is_settlement ? 0 : 1,
        };
        return filterQueryDataOrder(tempQueryData)
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise((resolve) => {
            orderGetCompositeList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        });
    }, []);

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               filterQueryData={filterQueryData}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} scroll={true}
            />
            <ModalContainer title={'系统确认'} open={open} width={'30%'}
                            onOk={() => {
                                closeOrder({
                                    object_id: selectedItem.object_id,
                                    contract_date_status: flag ? 1 : 0,
                                    invoice_date_status: !flag ? 1 : 0,
                                })
                            }}
                            onCancel={() => {
                                setOpen(false)
                            }}
                            innerComponent={
                                <div className={'mes-modal-delete'}>
                                    <div>
                                        <img src={require('../../../assets/mes-confirm.png')} alt={''}
                                             style={{width: 60}}/>
                                    </div>
                                    <div
                                        className={'confirm-text'}>{flag ? '该零件没有合同信息，确定要关闭？' : '该零件没有发票信息，确定要关闭？'}</div>
                                </div>
                            }/>
            <ModalContainer title={'修改数量'} open={isUpdate}
                            width={'600px'}
                            onOk={onOk}
                            okText={'保存'}
                            onCancel={() => {
                                form.resetFields();
                                setIsUpdate(false)
                            }}
                            innerComponent={
                                <div className={'form-margin-update'}>
                                    <Form form={form} colon={false}>
                                        <Form.Item name={'origin'} label={'原数量'}>
                                            <InputNumber disabled
                                                         formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                                        </Form.Item>
                                        <Form.Item name={'update'} label={'修改后数量'} rules={[
                                            {required: true, message: '请输入订单数量!'},
                                            ((form) => ({
                                                validator(_, value) {
                                                    if (selectedItem.production.number) {
                                                        if (value < selectedItem.production.number) {
                                                            return Promise.reject(new Error('修改后的订单数量必须大于等于该订单零件的投产总数量！'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    } else {
                                                        if (value < selectedItem.number) {
                                                            return Promise.reject(new Error('修改后的订单数量必须大于等于原数量！'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                }
                                            }))
                                        ]}>
                                            <InputNumber placeholder={'请输入订单数量'} min={0}
                                                         formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                                        </Form.Item>
                                    </Form>
                                </div>
                            }
            />
        </div>
    )
}
export default IntegratedManagement;

import React from "react";
import * as echarts from "echarts/core";
// 按需导入
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import ReactEchartsCore from "echarts-for-react/lib/core";
echarts.use([GridComponent, BarChart, CanvasRenderer]);
interface IProps {
    chartData:{dataAxis:string[],dataYAxis:any[]}
    onEvents:{click:(params:any)=>void}
}
const Charts = (props:IProps) => {
    const {chartData,onEvents} = props;
    const setOptions = (row:number) => {
        let data =  chartData?.dataAxis.slice((row-1)*7,row*7);
        let dataY= chartData?.dataYAxis.slice((row-1)*7,row*7);
        for(let i=1;i<=7-data.length;) {
          // 补空格让柱子往左移
          data.push("")
        }
        return {
          xAxis: {
            position: "left",
            // 每行7个
            data: data,
            // data:chartData?.dataAxis,
            axisLabel: {
              fontSize: "16px",
              fontWeight: 700,
              color: "black",
              interval: 0,
              formatter: function (value:any) {
                let rowMAx = 9;
                let overValue = "";
                for (let i = 0; i < value.length; i++) {
                  if (i % rowMAx == 0 && i != 0) {
                    overValue += "\n";
                    overValue += value[i];
                  } else {
                    overValue += value[i];
                  }
                }
                return overValue;
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            z: 10,
          },
          yAxis: {
            min: 0,
            max: 100,
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          grid: {
            top: "10%",
            left: "3%",
            right: "3%",
            bottom: "6%",
            containLabel: true,
          },
          series: [
            {
              info: { some: 123 },
              data: dataY,
              // data:chartData?.dataYAxis,
              type: "bar",
              showBackground: true,
              // barWidth:'30%',
              // barMaxWidth:"40%",
              barWidth: 150,
              backgroundStyle: {
                color: "rgba(139, 197, 255, 0.15)",
              },
              tooltip: {
                trigger: "axis", // 设置触发类型为坐标轴
                axisPointer: {
                  // 添加axisPointer属性
                  type: "shadow", // 设置类型为阴影
                },
              },
              label: {
                show: true,
                // position: 'bottom',
                position: "insideBottom", // 标签位置为柱状图内部的下方
                color: "black",
                formatter:function(params:any){ //标签内容
                  return  params.value + '%'
                },
                // distance:-20,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#3E7BFA" },
                  { offset: 1, color: "#99AFFF " },
                ]),
              },
              emphasis: {
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#2378f7" },
                    { offset: 1, color: "#83bff6" },
                  ]),
                },
              },
            },
          ],

        };
      }
  return (
    <div key={new Date().getTime()}>
          {
          chartData.dataAxis.map((i,index,arr) =>{
            if(index+1 <= Math.ceil(chartData?.dataAxis.length/7)) {
              return (
                <ReactEchartsCore
                echarts={echarts}
                option={setOptions(index + 1)}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                // showLoading={loading}
                opts={{ renderer: "svg" }}
                onEvents={onEvents}
              />
              );
            }
          })
        }
     
    </div>
  );
};
export default React.memo(Charts);
// 封装查看和打印的表格
import React,{useEffect} from "react";
import { Table} from "antd";
import {getSystemInfoItem,formatNumber} from "../../../utils/index"
import "../index.less";


interface IProps {
  setCols?:Function;
  printData?:any;
  is_show?:boolean;
  showDetailDelivery?:any
}
const PrintDeliveryTable = (props: IProps) => {
  const {showDetailDelivery,is_show,setCols,printData } = props;
  const [dataSource, setDataSource] = React.useState<any[]>(
    is_show ? showDetailDelivery.deliver_part : printData.work && printData.work
  );
  useEffect(()=>{
    let newColumns:any[] =[];
    columns.map((item:any)=>{
      item.children && item.children.map((item1:any)=>{
        newColumns.push(item1)
      })
    })
    newColumns[0].title = "序号";
    setCols && setCols(newColumns);
    
    let max_row:number = 0;
    getSystemInfoItem("发货单最大行数",(selectItem)=>{
      max_row = Number(selectItem[0].value);
      const emptyLine = max_row - dataSource.length;
      let newDataSource:any[] = dataSource;
      if(emptyLine > 0) {
        for(let i =1; i<=emptyLine; i++) {
          newDataSource.push({
            index:(dataSource.length + 1).toString()
          })
        }
      }
      setDataSource([...newDataSource])
    })
  },[])
  const columns:any[]=[
    {
      title: "加工项目",
      key: "1",
      align: "center",
      children: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          align: "center",
          width:100,
        },
      ],
    },
    {
      title: <div>{is_show ? showDetailDelivery.content : printData.content && printData.content}</div>,
      key: "2",
      align: "center",
      className: "title_style",
      children: [
        {
          title: "零件名称",
          // 无 在part模型里
          dataIndex: is_show ?["part","name"] : "name",
          key: "name",
          align: "center",
          width:100,
        },
        {
          title: "零件图号",
          // 无 在part模型里
          dataIndex: is_show ?["part","number"] : "part_number",
          key: "number1",
          align: "center",
          width:100,
        },
      ],
    },
    {
      title: "任务制号",
      key: "3",
      align: "center",
      children: [
        {
          title: "数量",
          // 初始值是主画面的库存数量
          dataIndex: "number",
          key: "number",
          align: "center",
          width:100,
          render:(i:any) => <span>{
            i && formatNumber(i.toString())
            }</span>
        },
      ],
    },
    {
      title: () => (
        <div>{is_show ? showDetailDelivery.order_code : printData.order_code && printData.order_code}</div>
      ),
      className: "title_style",
      key: "4",
      align: "center",
      children: [
        {
          title: "工序",
          // dataIndex是决定下方render的时候text拿到的是哪个值的
          dataIndex: "precesses",
          key: "precesses",
          align: "center",
          width:180,
          // ellipsis: true,
          render:(text:any) =><span style={{
            wordWrap:"break-word",
            wordBreak:"break-word"
          }}>
            {text}
          </span>
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          align: "center",
          // ellipsis: true,
          render:(text:any) =><span style={{
            wordWrap:"break-word",
            wordBreak:"break-word"
          }}>
            {text}
          </span>
        },
      ],
    },
  ];
  return (
    <div className="print_delivery_table">
        <Table
          columns={columns}
          dataSource={dataSource}
          className="table"
          bordered
          pagination={false}
          onHeaderRow={(columns, index) => {
            if(index === 0) {
              return {
                className: "row1"
              }
            }else if ( index === 1){
              return {
                className: "row2"
              }
            }else {
              return {
                className:"otherRows"
              }
            }
          }}
        />
    </div>
  );
};
export default React.memo(PrintDeliveryTable);


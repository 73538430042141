// 封装外协，返厂转序单的表格
import React, { useEffect, forwardRef } from "react";
import { Input, Form, Table, DatePicker } from "antd";
import moment from "moment";
import "../index.less";
import {formatNumber} from "../../../utils/index";
interface IProps {
  // 是外协零件转序清单么
  is_return?: boolean;
  selectedRows?: any[];
  form?: any;
}
const ExternalTable = forwardRef((props: IProps) => {
  const {
    selectedRows,
    is_return,
    form,
  } = props;

  const columns: any = [
    {
      title: () => (
        <div className="external_title">
          {is_return ? "返厂零件转序清单" : "外协零件转序清单"}
        </div>
      ),
      key: "root",
      children: [
        {
          title: <div style={{ fontWeight: "700" }}>生产制号</div>,
          key: "1",
          align: "center",
          children: [
            {
              title: () => <div style={{ fontWeight: "700" }}>转序日期</div>,
              dataIndex: "zhuanxu_date",
              key: "zhuanxu_date",
              align: "center",
              children: [
                {
                  title: () => <div style={{ fontWeight: "700" }}>No</div>,
                  width:120,
                  dataIndex: "xuhao",
                  key: "xuhao",
                  align: "center",
                  render: (_: any, __: any, index: number) => (
                    <span>{index + 1}</span>
                  ),
                },
              ],
            },
          ],
        },
        {
          title: () => (
            <Form.Item
              name="batch_production"
              rules={[
                { required: true, message: "请输入生产制号!" },
                { max: 200, message: "最多输入200个字符" },
              ]}
            >
              <Input/>
            </Form.Item>
          ),
          key: "2",
          align: "center",
          className: "bg",
          children: [
            {
              title: () => (
                <Form.Item
                  name="record_date"
                  rules={[{ required: true, message: "请选择转序日期!" }]}
                >
                  <DatePicker format={"YYYY/MM/DD"}/>
                </Form.Item>
              ),
              className: "bg",
              align: "center",
              children: [
                {
                  title: () => <div style={{ fontWeight: "700" }}>产品名称</div>,
                  dataIndex: "part_name",
                  key: "part_name",
                  align: "center",
                  ellipsis: true,
                },
              ],
            },
          ],
        },
        {
          title: () => <div style={{ fontWeight: "700" }}>任务名称</div>,
          key: "3",
          align: "center",
          children: [
            {
              title: () => (
                <div style={{ fontWeight: "700" }}>要求完成日期</div>
              ),
              align: "center",
              
              children: [
                {
                  title: () => <div style={{ fontWeight: "700" }}>图号</div>,
                  dataIndex:"part_number",
                  key: "part_number",
                  align:"center",
                  width:140,
                  ellipsis: true,
                },
              ],
            },
          ],
        },
        {
          title: () =>
           (
              <Form.Item
                name="name"
                rules={[
                  // { required: true, message: "请输入任务名称!" },
                  { max: 200, message: "最多输入200个字符" },
                ]}
              >
                <Input placeholder="请输入任务名称"/>
              </Form.Item>
            ),
          key: "4",
          align: "center",
          className: "bg",
          children: [
            {
              title: () =>
               (
                  <Form.Item
                    name="finish_date"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "请选择要求完成日期!",
                    //   },
                    // ]}
                  >
                    <DatePicker format={"YYYY/MM/DD"}/>
                  </Form.Item>
                ),
              className: "bg",
              align: "center",
              children: [
                {
                  title:  () => <div style={{ fontWeight: "700" }}>数量</div>,
                  dataIndex:"quantity",
                  key: "quantity",
                  align: "center",
                  ellipsis: true,
                  render:(quantity:number)=><span>{quantity && formatNumber(quantity.toString())}</span>
                },
              ],
            },
          ],
        },
        {
          title: () => <div style={{ fontWeight: "700" }}>配套项目</div>,
          key: "5",
          align: "center",
          children: [
            {
              title: () => <div style={{ fontWeight: "700" }}>承接单位</div>,
              align: "center",
              children: [
                {
                  title: () => (
                    <div style={{ fontWeight: "700" }}>工序及加工内容</div>
                  ),
                  dataIndex: "description",
                  key: "description",
                  align: "center",
                  width:140,
                  render: (text: any, record: any) => (
                      <Form.Item
                        name={`${record.object_id}-description`}
                        initialValue={`${record.record_process ? record.record_process : '' }:${record.process_explain ? record.process_explain : ''}`}
                        rules={[
                          {
                            required: true,
                            message: "请输入工序及工序内容",
                          },
                          { max: 200, message: "最多输入200个字符" },
                        ]}
                      >
                        <Input placeholder="请输入工序及加工内容" />
                      </Form.Item>
                    )   
                },
              ],
            },
          ],
        },
        {
          title: () =>(
              <Form.Item
                name="project"
                rules={[
                  // { required: true, message: "请输入配套项目" },
                  { max: 200, message: "最多输入200个字符" },
                ]}
              >
                <Input placeholder="请输入配套项目"/>
              </Form.Item>
            ),
          key: "6",
          align: "center",
          className: "bg",
          children: [
            {
              title: ()=>(
                <Form.Item
                  name="trader"
                  rules={[
                    { required: true, message: "请输入承接单位" },
                    { max: 200, message: "最多输入200个字符" },
                  ]}
                >
                  <Input/>
                </Form.Item>
              ) ,
              className: "bg",
              align: "center",
              children: [
                {
                  title: () => <div style={{ fontWeight: "700" }}>备注</div>,
                  dataIndex: "remark",
                  key: "remark",
                  align: "center",
                  render: (_: any, record: any) =>
                      <Form.Item name={`${record.object_id}-remark`}   
                      rules={[
                        { max: 200, message: "最多输入200个字符" },
                      ]}>
                        <Input placeholder={"请输入备注"} />
                      </Form.Item>,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  useEffect(()=>{
    if(selectedRows) {
      form.setFieldsValue({
        batch_production:selectedRows[0].order_number,
        record_date: moment(),
        name:undefined,
        finish_date:undefined,
        project:undefined,
        trader: selectedRows[0].trader,
      });
    }
  },[])

  return (
    <div className="external_modal_table">
      <Form form={form}>
        <Table
          className="external_table"
          columns={columns}
          dataSource={selectedRows}
          bordered
          pagination={false}
        />
      </Form>
    </div>
  );
});
export default React.memo(ExternalTable);

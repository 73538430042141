import React, {useEffect } from "react";
import ModalForm from "../../../components/ModalForm";
import "../index.less";
import {formatTime,formatNumber} from "../../../utils/index";
import moment from "moment";
interface IProps {
  selectedItem: any;
  modalForm:any
}

const EditInvoice = (props:IProps) => {
  const formItem=[
    { label: "客户", name: "customer",is_disable:true },
    {
      label: "订单号",
      name: "code",
      is_disable:true
    },
    {
      label: "零件名称",
      name: "name",
      is_disable:true
    },
    {
      label: "零件图号",
      name: "part_number",
      is_disable:true
    },
    {
      label: "供料方式",
      name: "material_type",
      is_disable:true
    },
    {
      label: "材料",
      name: "material",
      is_disable:true
    },
    {
      label: "订单数量",
      name: "order_number",
      is_disable:true
    },
    {
      label: "交货日期",
      name: "finish_date",
      is_disable:true
    },
    {
      label: "加工内容",
      name: "content",
      is_row: true,
      // is_textArea:true,
      is_disable:true
    },
    {
      label: "备注",
      name: "remark1",
      is_row: true,
      // is_textArea:true,
      is_disable:true
    },
    {
      label: "发票日期",
      name: "invoice_date",
      is_datePicker:true,
      is_require:false
    },
    {
      label: "发票号",
      name: "number",
      is_edit:true,
      // is_textArea:true,
      is_row:true,
      rules: [
        () => {
          return {
            validator(_: any, value: any) {
              if (value && value.length >200) {
                return Promise.reject(
                  new Error("内容长度不能超过200")
                );
              }
              return Promise.resolve();
            },
          };
        },
      ],
    },
    {
      label: "结款日期",
      name: "settlement_date",
      is_datePicker:true,
      
    },
    {
      label: "结款备注",
      name: "remark",
      is_edit:true,
      is_row:true,
      // is_textArea:true,
      rules: [
        () => {
          return {
            validator(_: any, value: any) {
              if (value.length !==0  && value.length >200) {
                return Promise.reject(
                  new Error("内容长度不能超过200")
                );
              }
              return Promise.resolve();
            },
          };
        },
      ],
    },
  ];
  const {selectedItem, modalForm} = props;
  useEffect(()=>{

    // modalForm.resetFields(["invoice_date","number","settlement_date","remark"]);
    modalForm.setFieldsValue({
      customer:selectedItem.order.trader.name,
      code:selectedItem.order.code,
      name:selectedItem.part.name,
      part_number:selectedItem.part.number,
      material_type:selectedItem.part.material_type === 10 ? "带料":"购料",
      material:selectedItem.part.material,
      order_number:selectedItem.number && formatNumber((selectedItem.number).toString()),
      finish_date:selectedItem.finish_date && formatTime(selectedItem.finish_date,false),
      content:selectedItem.content,
      remark1:selectedItem.remark,
      invoice_date: selectedItem.invoice.invoice_date && moment(selectedItem.invoice.invoice_date),
      number:selectedItem.invoice.number,
      settlement_date:selectedItem.invoice.settlement_date && moment(selectedItem.invoice.settlement_date),
      remark:selectedItem.invoice.remark
    });
  },[])

  return (
    <div className="edit_invoice">
        <ModalForm 
        form={modalForm}
        form_name="edit_invoice_form"
        formItem={formItem} 
        selectedItem={selectedItem}
        ></ModalForm>
    </div>
  );
};
export default EditInvoice;

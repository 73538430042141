import React, {useContext, useEffect, useState} from "react";
import {AutoComplete, Col, DatePicker, Form, FormInstance, Input, Row, Select} from "antd";
import {formatNumber, showError, wayList} from "../../../utils";
import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import {ModalTitle} from "./index";
import {InputNumber} from "antd/es";
import {partInfo, traderInfo} from "../../../service";
import {UserContext} from "../../../contexts/userContext";
import {useNavigate} from "react-router-dom";
import {useWatch} from "antd/es/form/Form";

interface AddOrEditProps {
    //弹窗标题
    title: ModalTitle,
    //form FormInstance
    form: FormInstance,
    //form disabled
    disabled?: boolean
    //tableForm FormInstance
    tableForm: FormInstance,
    //tableForm FormInstance
    tableDisabled?: boolean
    //显示的表格数据
    initialDataSource?: any[]
    //零件图号列表
    partNumberDefault: PartNumberOptions[],
}

interface QuotationAmountProps {
    index: number
}

interface PartNumberProps {
    index: number,
    partNumberOptions: PartNumberOptions[],
}

export interface PartNumberOptions {
    key: number,
    value: string,
    name: string,
    material: string,
    material_type: number,
}

const AddOrEdit = React.memo((props: AddOrEditProps): React.ReactElement => {
    const {form, title, tableForm, tableDisabled, disabled, initialDataSource, partNumberDefault} = props;
    const {useInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const [editOrDragTableLoading,setEditOrDragTableLoading] = useState<boolean>(true);


    const defaultColumns: any[] = [
        {
            title: "No",
            dataIndex: "No",
            key: "No",
            width: 60,
            ellipsis: true,
            fixed: "left",
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>零件名称</span>,
            dataIndex: 'part_name',
            key: 'part_name',
            width: 150,
            ellipsis: true,
            fixed: "left",
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'part_name']}
                           style={{textAlign: 'left'}}
                           rules={[
                               {required: true, message: '请输入零件名称！'},
                           ]}>
                    <Input placeholder={'请输入零件名称'} disabled={title === ModalTitle.EDIT2}/>
                </Form.Item>
        },
        //新增和编辑都不显示该项
        {
            title: '零件ID',
            dataIndex: 'part_id',
            key: 'part_id',
            ellipsis: true,
            fixed: "left",
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'part_id']}>
                    <Input placeholder={'请输入零件ID'} disabled/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>零件图号</span>,
            dataIndex: 'part_number',
            key: 'part_number',
            width: 150,
            ellipsis: true,
            fixed: "left",
            align: 'center',
            render: (value: any, item: any, index: number) => <PartNumber index={index}/>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>订单数量</span>,
            dataIndex: 'number',
            key: 'number',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'number']} style={{textAlign: 'left'}}
                           rules={[{required: true, message: '请输入订单数量！'}]}>
                    <InputNumber min={0} placeholder={'请输入订单数量'} disabled={title === ModalTitle.EDIT2}/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>供料方式</span>,
            dataIndex: 'part_material_type',
            key: 'part_material_type',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'part_material_type']} style={{textAlign: 'left'}}
                           rules={[{required: true, message: '请选择供料方式！'}]}
                >
                    <Select placeholder={'请选择供料方式'}>
                        {wayList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)}
                    </Select>
                </Form.Item>
        },
        {
            title: <span>材料</span>,
            dataIndex: ['part', 'material'],
            key: 'part_material',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'part_material']} style={{textAlign: 'left'}}
                           rules={[{max: 32, message: '材料不得大于32位!'}]}>
                    <Input placeholder={'请输入材料'}/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>加工内容</span>,
            dataIndex: 'content',
            key: 'content',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) => {
                return <Form.Item name={[`${index}`, 'content']}
                                  rules={[{max: 32, message: '加工内容不得大于32位!'},
                                      {required: true, message: '请输入加工内容！'}]}
                                  style={{textAlign: 'left'}}
                >
                    <Input placeholder={'请输入加工内容'}/>
                </Form.Item>
            }
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>交货日期</span>,
            dataIndex: 'finish_date',
            key: 'finish_date',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) => {
                return <Form.Item name={[`${index}`, 'finish_date']}
                                  rules={[{required: true, message: '请选择交货日期！'}]}
                                  style={{textAlign: 'left'}}>
                    <DatePicker/>
                </Form.Item>
            }
        },
        {
            title: '报价单价',
            dataIndex: 'quot_single_price',
            key: 'quot_single_price',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'quot_single_price']} style={{textAlign: 'left'}} rules={[
                    {
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '最多只能输入两位小数!'
                    }
                ]}>
                    <InputNumber min={0} placeholder={'请输入报价单价'} step={0.01}
                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}
                    />
                </Form.Item>
        },
        {
            title: '报价金额',
            dataIndex: 'quotationAmount',
            key: 'quotationAmount',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) => <QuotationAmount index={index}/>
        },
        {
            title: '合同数量',
            dataIndex: 'contract_number',
            key: 'contract_number',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'contract_number']} style={{textAlign: 'left'}}>
                    <InputNumber min={0} placeholder={'请输入合同数量'}/>
                </Form.Item>
        },
        {
            title: '合同单价',
            dataIndex: 'contract_single_price',
            key: 'contract_single_price',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'contract_single_price']} style={{textAlign: 'left'}} rules={[
                    {
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '最多只能输入两位小数!'
                    }
                ]}>
                    <InputNumber min={0} placeholder={'请输入合同单价'} step={0.01}
                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                </Form.Item>
        },
        {
            title: '合同金额',
            dataIndex: 'contractAmount',
            key: 'contractAmount',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) => <ContractAmount index={index}/>
        },
        {
            title: '合同号',
            dataIndex: 'contract_code',
            key: 'contract_code',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'contract_code']}
                           rules={[{max: 32, message: '合同号不得大于32位!'}]}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入合同号'}/>
                </Form.Item>
        },
        {
            title: '合同日期',
            dataIndex: 'contract_date',
            key: 'contract_date',
            ellipsis: true,
            width: 150,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'contract_date']}
                           rules={[{max: 32, message: '合同日期不得大于32位!'}]}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入合同日期'}/>
                </Form.Item>
        },
        {
            title: '外协单价',
            dataIndex: 'out_single_price',
            key: 'out_single_price',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'out_single_price']} style={{textAlign: 'left'}}
                           rules={[
                               {
                                   pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                                   message: '最多只能输入两位小数!'
                               }
                           ]}
                >
                    <InputNumber min={0} placeholder={'请输入外协单价'} step={0.01}
                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                </Form.Item>
        },
        {
            title: '外协说明',
            dataIndex: 'out_remark',
            key: 'out_remark',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item
                    name={[`${index}`, 'out_remark']} style={{textAlign: 'left'}}
                    rules={[{max: 200, message: '外协说明不得大于200位!'}]}
                >
                    <Input placeholder={'请输入外协说明'}/>
                </Form.Item>
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 400,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'remark']}
                           rules={[{max: 200, message: '备注不得大于200位!'}]}
                           style={{textAlign: 'left'}}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
        },
    ]
    const defaultNewItem: any = {
        part_name: undefined,
        part_number: undefined,
        part_material_type: undefined,
        part_material: undefined,
        number: undefined,
        content: undefined,
        finish_date: undefined,
        quot_single_price: undefined,
        quotationAmount: undefined,
        contract_number: undefined,
        contract_single_price: undefined,
        contractAmount: undefined,
        contract_code: undefined,
        contract_date: undefined,
        out_single_price: undefined,
        out_remark: undefined,
        remark: undefined,
    }
    const tRef = React.useRef<TRef>(null);
    const [newItem, setNewItem] = useState(defaultNewItem)
    const [detailColumns, setDetailColumns] = useState<any[]>(defaultColumns);
    const [customerList, setCustomerList] = useState<any[]>([]);
    const [detailDataSource, setDetailDataSource] = useState<any[]>([]);
    const skipPage = (router: string) => {
        if (useInfo.authRoutes?.includes(router)) {
            navigate(router)
        }
    }
    const finish_date = useWatch('finish_date', form)

    const changeForm = (index: number) => {
        tableForm.setFieldValue([`${index}`, 'finish_date'], finish_date)
    }

    //零件图号
    const PartNumber = React.memo((props: QuotationAmountProps) => {
        const [partNumberOptions, setPartNumberOptions] = useState<PartNumberOptions[]>(partNumberDefault);

        //获取订单零件表
        const getPartInfo = (values?: Object) => {
            partInfo(values).then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        const temp: PartNumberOptions[] = []
                        res.data.data.map((e: any) => {
                            temp.push({
                                key: e.object_id,
                                value: e.number,
                                name: e.name,
                                material: e.material,
                                material_type: e.material_type,
                            })
                        })
                        setPartNumberOptions(temp)
                    }
                } else {
                    showError(res.message)
                }
            })
        }

        const onSelect = (text: string, option: PartNumberOptions) => {
            //text对应value
            tableForm.resetFields([[`${props.index}`, 'part_id'], [`${props.index}`, 'part_name'], [`${props.index}`, 'part_material_type'], [`${props.index}`, 'part_material']])
            tableForm.setFieldValue([`${props.index}`, 'part_id'], option.key)
            tableForm.setFieldValue([`${props.index}`, 'part_name'], option.name)
            tableForm.setFieldValue([`${props.index}`, 'part_material_type'], option.material_type)
            tableForm.setFieldValue([`${props.index}`, 'part_material'], option.material)
        }

        const onSearch = (text: string) => {
            //text对应输入值
            getPartInfo({number: text})
        }

        return <Form.Item name={[`${props.index}`, 'part_number']}
                          rules={[{required: true, message: '请输入零件图号！'}]}
                          style={{textAlign: 'left'}}>
            <AutoComplete
                placeholder={'请输入零件图号'}
                options={partNumberOptions}
                onSelect={onSelect}
                onSearch={onSearch}
                disabled={title === ModalTitle.EDIT2}
            >
                {
                    partNumberOptions.map(e => <AutoComplete.Option key={e.key}
                                                                    value={e.value}>{e.value}</AutoComplete.Option>)
                }
            </AutoComplete>
        </Form.Item>
    })

    //报价金额
    const QuotationAmount = React.memo((props: QuotationAmountProps) => {
        const quot_single_price = useWatch([`${props.index}`, 'quot_single_price'], tableForm);
        const number = useWatch([`${props.index}`, 'number'], tableForm);

        useEffect(() => {
            const price = (quot_single_price ? quot_single_price : 0) * (number ? number : 0)
            tableForm.setFieldValue([`${props.index}`, 'quotationAmount'], price.toFixed(2))
            let allPrice = 0;
            Object.values(tableForm.getFieldsValue()).map((e: any) => {
                allPrice += Number(e.quotationAmount ? e.quotationAmount : '0')
            })
            form.setFieldValue('offer_price', allPrice.toFixed(2))
        }, [quot_single_price, number])

        return <Form.Item name={[`${props.index}`, 'quotationAmount']} style={{textAlign: 'left'}} initialValue={0}>
            <InputNumber disabled step={0.01} formatter={value => value ? formatNumber(value.toString()) : ''}/>
        </Form.Item>
    })

    //合同金额
    const ContractAmount = React.memo((props: QuotationAmountProps) => {
        const contract_number = useWatch([`${props.index}`, 'contract_number'], tableForm);
        const contract_single_price = useWatch([`${props.index}`, 'contract_single_price'], tableForm);

        useEffect(() => {
            const price = (contract_number ? contract_number : 0) * (contract_single_price ? contract_single_price : 0)
            tableForm.setFieldValue([`${props.index}`, 'contractAmount'], price.toFixed(2))
            let allPrice = 0;
            Object.values(tableForm.getFieldsValue()).map((e: any) => {
                allPrice += Number(e.contractAmount ? e.contractAmount : '0')
            })
            form.setFieldValue('contract_price', allPrice.toFixed(2))
        }, [contract_number, contract_single_price])
        return <Form.Item name={[`${props.index}`, 'contractAmount']} style={{textAlign: 'left'}}>
            <InputNumber disabled step={0.01} formatter={value => value ? formatNumber(value.toString()) : ''}/>
        </Form.Item>
    })

    useEffect(() => {
        if (finish_date) {
            setNewItem(
                {
                    part_name: undefined,
                    part_number: undefined,
                    part_material_type: undefined,
                    part_material: undefined,
                    number: undefined,
                    content: undefined,
                    finish_date: form.getFieldValue('finish_date'),
                    quot_single_price: undefined,
                    quotationAmount: undefined,
                    contract_number: undefined,
                    contract_single_price: undefined,
                    contractAmount: undefined,
                    contract_code: undefined,
                    contract_date: undefined,
                    out_single_price: undefined,
                    out_remark: undefined,
                    remark: undefined,
                }
            )
        } else {
            setNewItem(defaultNewItem)
        }
    }, [finish_date])

    useEffect(() => {
        //获取客商信息中类别为客户、客商的客户信息
        traderInfo().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    setCustomerList(res.data.data)
                }
            } else {
                showError(res.message);
            }
        })
    }, [])

    useEffect(() => {
        //detailColumns的设置
        //新增和编辑有操作按钮，查看和编辑已下发没有
        const editDefaultColumns = [
            {
                title: 'object_id',
                dataIndex: 'object_id',
                key: 'object_id',
                ellipsis: true,
                fixed: "left",
                align: 'center',
                render: (value: any, item: any, index: number) =>
                    <Form.Item name={[`${index}`, 'object_id']}>
                        <Input placeholder={'请输入object_id'} disabled/>
                    </Form.Item>
            }, ...defaultColumns]
        if (title === ModalTitle.ADD) {
            setDetailColumns([...defaultColumns, {
                title: '操作',
                dataIndex: 'options',
                key: 'options',
                width: 200,
                align: 'center',
                render: (_: any, item: any, index: number) => (
                    <span className={'mes-user-options'} onClick={() => {
                        if (tRef.current) {
                            tRef.current.handleDelete(index)
                        }
                    }}>删除</span>
                )
            },])
        } else if (title === ModalTitle.EDIT) {
            setDetailColumns([...editDefaultColumns, {
                title: '操作',
                dataIndex: 'options',
                key: 'options',
                width: 200,
                align: 'center',
                render: (_: any, item: any, index: number) => (
                    <span className={'mes-user-options'} onClick={() => {
                        if (tRef.current) {
                            tRef.current.handleDelete(index)
                        }
                    }}>删除</span>
                )
            },])
        } else if (title === ModalTitle.EDIT2) {
            setDetailColumns(editDefaultColumns)
        }
        //detailDataSource的设置
        //新增没有，编辑、编辑已下发、查看从接口获取
        if (title === ModalTitle.ADD) {
            setDetailDataSource([])
            setEditOrDragTableLoading(false)
        } else if (title === ModalTitle.EDIT || title === ModalTitle.EDIT2) {
            if (initialDataSource) {
                setDetailDataSource(initialDataSource)
                setEditOrDragTableLoading(false)
            }
        }
    }, [title])

    return (
        <div className={'form-margin'}>
            <Form form={form} colon={false} disabled={disabled}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'订单号'} key={'code'} name={'code'}
                                   rules={[{required: true, message: '请输入订单号!'}, {max: 32, message: '订单号不得大于32位!'}]}>
                            <Input placeholder={'请输入订单号'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={useInfo.authRoutes?.includes('/basic/businessman') ?
                            <span className={'mes-user-options'}
                                  onClick={() => {
                                      skipPage('/basic/businessman')
                                  }}
                            >客户</span> : <span>客户</span>
                        } key={'trader_id'} name={'trader_id'} rules={[{required: true, message: '请选择客户!'}]}>
                            <Select placeholder={'请选择客户'} showSearch optionFilterProp={'children'}>
                                {
                                    customerList.map((oe: any, oi: number) =>
                                        <Select.Option key={oi} value={oe.object_id}>{oe.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'交货日期'} key={'finish_date'} name={'finish_date'}>
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'报价金额'} key={'offer_price'} name={'offer_price'}>
                            <InputNumber placeholder={'自动计算'} disabled step={0.01}
                                         formatter={value => value ? formatNumber(value.toString()) : ''}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'合同金额'} key={'contract_price'} name={'contract_price'}>
                            <InputNumber placeholder={'自动计算'} disabled step={0.01}
                                         formatter={value => value ? formatNumber(value.toString()) : ''}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'备注'} key={'remark'} name={'remark'}
                                   rules={[{max: 200, message: '备注不得大于200位！'}]}>
                            <Input.TextArea placeholder={'请输入备注'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <EditOrDragTable editOrDragTableLoading={editOrDragTableLoading} tableForm={tableForm} detailColumns={detailColumns} detailDataSource={detailDataSource}
                             title={'订单零件信息'} tableDisabled={tableDisabled} changeForm={changeForm}
                             newItem={newItem} ref={tRef} scroll={true}
                             isNoneAddBtn={title === ModalTitle.EDIT2}/>
        </div>
    )
})

export default AddOrEdit;

import React, {useContext, useEffect, useState} from "react";
import {Form, Input, Select, Upload, message} from 'antd';
import type {RcFile} from 'antd/es/upload/interface';
import {LoadingOutlined} from '@ant-design/icons';
import {getFormData, sexes, showError} from "../../utils";
import {getCurrentUser, getUploadUrl, request, updateProfilePhoto} from "../../service";
import './index.less';
import {UserContext} from "../../contexts/userContext";

const Settings = (): React.ReactElement => {
    const {Option} = Select;
    const layout = {
        labelCol: {span: 10},
        wrapperCol: {span: 12},
    };
    const [form] = Form.useForm();
    const {useInfo, setUserInfo} = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            account: useInfo.account,
            work_number: useInfo.work_number,
            organization: useInfo.organization,
            station: useInfo.station,
            name: useInfo.name,
            sex: useInfo.sex,
            phone: useInfo.phone,
            email: useInfo.email,
        })
    }, [useInfo.account])

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传jpg/png格式的图片!');
        }
        return isJpgOrPng;
    };

    const customRequest = (options: any) => {
        const formData = getFormData({name: options.file.name})
        setLoading(true)
        getUploadUrl(formData).then(res => {
            if (res.code === 200) {
                request.put(res.data.upload_url, {
                    data: options.file
                }).then(res1 => {
                    if (res1) {
                        setLoading(false)
                        message.error('上传失败！')
                    } else {
                        const formData1 = getFormData({file_id: res.data.file.object_id})
                        updateProfilePhoto(formData1).then(res2 => {
                            if (res2.code === 200) {
                                getCurrentUser().then((res3) => {
                                    if (res3.code === 200) {
                                        setUserInfo({
                                            ...useInfo,
                                            avatar: res3.data.file ? res.data.file.url : undefined,
                                        })
                                        message.success('上传成功！')
                                    } else {
                                        showError(res3.message)
                                    }
                                })
                            } else {
                                showError(res2.message)
                            }
                        })
                    }
                })
            } else {
                showError(res.message)
            }
        })
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> :
                <img src={require('../../assets/upload-avatar.png')} alt={''} style={{width: '100%'}}/>}
        </div>
    );

    return (
        <div className={'mes-setting'}>
            <div className={'mes-setting-content'}>
                <Form {...layout} style={{flex: 1}} colon={false} disabled form={form}>
                    <Form.Item label={'账号'} name={'account'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'工号'} name={'work_number'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'组织机构'} name={'organization'}>
                        <Select>
                        </Select>
                    </Form.Item>
                    <Form.Item label={'岗位'} name={'station'}>
                        <Select>
                        </Select>
                    </Form.Item>
                    <Form.Item label={'姓名'} name={'name'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'性别'} name={'sex'}>
                        <Select>
                            {
                                Object.entries(sexes).map(e =>
                                    <Option key={e[0]} value={Number(e[0])}>{e[1]}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={'手机号'} name={'phone'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'邮箱'} name={'email'}>
                        <Input/>
                    </Form.Item>
                </Form>
                <div style={{flex: 1}}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={customRequest}
                    >
                        {useInfo.avatar ? <img src={useInfo.avatar} alt="" style={useInfo.avatar ? {
                            width: 350,
                            height: 350,
                            borderRadius: '50%'
                        } : {width: '100%'}}/> : uploadButton}
                    </Upload>
                </div>
            </div>
        </div>
    )
}
export default Settings

import React, {useCallback, useRef, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Space, Row, Col} from "antd";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {filterData, formatNumber, getFormData, showError, taskList, toastMessage} from "../../../utils";
import DeleteItem from "../../../components/DeleteItem";
import ModalContainer from "../../../components/Modal";
import '../index.less';
import {ColumnsType} from "antd/es/table";
import {
    defectReason, defectReasonCreate, defectReasonDelete, defectReasonDetail, defectReasonUpdate,
} from "../../../service";
import moment from "moment";

enum ModalTitle {
    ADD = '新增不良信息',
    EDIT = '编辑不良信息',
    DELETE = '系统确认',
}


const Cause = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement>(<div/>);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const formList: QueryListItem[] = [
        {
            key: 'reason',
            name: '不良原因',
            placeholder: '请输入不良原因',
            width: 60
        },
    ]

    const columns: ColumnsType<any> = [
        {
            title: '不良代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '不良原因',
            dataIndex: 'reason',
            key: 'reason',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 400,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            ellipsis: true,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, item)
                        }}>编辑</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, item)
                        }}>删除</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEditCategory = React.memo((): React.ReactElement => {
        return (
            <Form colon={false} form={form} className={'basic-form form-margin'}>
                <Form.Item label={'不良代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '不良代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'不良原因'} name={'reason'} rules={[
                    {required: true, message: '请输入不良原因!'},
                    {
                        type: "string",
                        max: 32,
                        message: '不良原因为不得大于32位！'
                    }
                ]}>
                    <Input placeholder={'请输入名称'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remark'} rules={[
                    {
                        max: 200,
                        message: '备注不得大于200位！'
                    }
                ]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditCategory/>)
        } else if (title === ModalTitle.EDIT) {
            defectReasonDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        reason: res.data.reason,
                        remark: res.data.remark
                    })
                    setInnerComponent(<AddOrEditCategory/>);
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                defectReasonCreate(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                defectReasonUpdate(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            if (selectedRowKeys.length) {
                //批量删除
                const formData = getFormData(filterData({ids: `[${selectedRowKeys}]`}))
                defectReasonDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                        setSelectedRowKeys([])
                    } else {
                        showError(res.message);
                    }
                })
            } else {
                //单个删除
                const formData = getFormData(filterData({ids: `[${selectedItem.object_id}]`}))
                defectReasonDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                    } else {
                        showError(res.message);
                    }
                })
            }
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        //去除勾选的数据
        if (title === ModalTitle.DELETE && selectedRowKeys.length > 0) {
            setSelectedRowKeys([])
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(<div/>);
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => {
        const ExportJsonExcel = require("js-export-excel");

        const exportExcel = () => {
            if (gRef.current) {
                if (gRef.current.exportData) {
                    gRef.current.exportData().then(res => {
                        const option: any = {};
                        option.fileName = '不良原因' + moment(new Date()).format('YYYYMMDD');
                        option.datas = [
                            {
                                sheetData: res,
                                sheetName: "sheet",
                                sheetFilter: ['code', 'reason', 'remark'],
                                sheetHeader: ['不良代码', '不良原因', '备注'],
                                columnWidths: [20, 20, 20],
                            }
                        ];
                        const toExcel = new ExportJsonExcel(option);
                        toExcel.saveExcel();
                    })
                }
            }
        }

        return (
            <div>
                <Button className={'mes-user-add'} style={{marginRight: 20}} onClick={() => {
                    openModal(ModalTitle.ADD, null)
                }}>新增</Button>
                <Button className={'mes-batch-deletion'} style={{marginRight: 20}} onClick={() => {
                    if (selectedRowKeys.length) {
                        openModal(ModalTitle.DELETE, null)
                    } else {
                        toastMessage('请选择要删除的不良数据')
                    }
                }}>批量删除</Button>
                <Button className={'mes-export'} style={{marginRight: 20}} onClick={exportExcel}>导出</Button>
            </div>
        )
    })

    const getDataAsync = useCallback((queryCondition: API.defectReasonParams & API.pageInfo): Promise<any> => {
        return new Promise(resolve => {
            defectReason(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const setWidth = () => {
        if (modalTitle !== ModalTitle.DELETE) {
            return '40%'
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<ExtraComponent/>}
                               getDataAsync={getDataAsync} ref={gRef}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open} width={setWidth()}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default Cause;

import React, {
    useCallback,
    useRef,
    useEffect,
    useState
  } from "react";
  import GeneralManagement, {
    GRef,
    QueryListItem,
  } from "../../../components/GeneralManagement";
  import {  Tooltip,message } from "antd";
  import "../index.less";
  import { formatTime, formatNumber,moneyFormat } from "../../../utils/index";
  import {ColumnsType} from "antd/es/table";
  import {getUsersForSelect,filterDateParam,uuid} from "../../../utils/index";
  import {getDeliverList} from "../../../service";
  import ExportExcel from "../../../components/ExportTable/exportTable";
  import moment from "moment";
  const DeliveryQuery = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const [users, setUsers] = useState<{ id: number; name: string }[]>([]);
    const [formList,setFormList] = useState<QueryListItem[]>( [
      {
        key: "trader_name",
        name: "客户名称",
        placeholder: "请输入客户名称",
      },
      {
        key: "code",
        name: "订单号",
        placeholder: "请输入订单号",
      },
      {
        key: "part_number",
        name: "零件图号",
        placeholder: "请输入零件图号",
      },
      {
        key: "user_id",
        name: "发货人",
        options:users,
        placeholder: "请选择发货人",
      },
      {
        key: "date",
        name: "发货日期",
        placeholder: "请选择发货日期",
        rangePick:true
      },
      {
        key: "remark",
        name: "发货备注",
        placeholder: "请输入发货备注",
      }
    ])

    const columns: ColumnsType<any> = [
      {
        title: "No",
        dataIndex: "index",
        key: "index",
        align: "center",
        width:60,
        ellipsis: true,
        fixed:true,
      },
      {
        title: "订单号",
        dataIndex: ["order_part", "order", "code"],
        key: "code",
        align: "center",
        ellipsis: true,
        width: 140,
        fixed:"left"
      },

      {
        title: "零件名称",
        dataIndex: ["order_part", "part", "name"],
        key:"part_name",
        align: "center",
        ellipsis: true,
        width: 140,
        fixed:"left"
      },
      {
        title: "零件图号",
        dataIndex: ["order_part", "part", "number"],
        key:"part_num",
        align: "center",
        ellipsis: true,
        width: 140,
        fixed:"left"
      },
      {
        title: "客户",
        dataIndex: ["order_part", "order", "trader", "name"],
        key: "name",
        align: "center",
        ellipsis: true,
        width: 140,
      },
      {
        title: "订单数量",
        dataIndex: ["order_part", "number"],
        key: "number",
        align: "right",
        ellipsis: true,
        width: 90,
        render: (item: Number) => <div>{item && formatNumber(item.toString())}</div>
      },
      {
        title: "合同数量",
        dataIndex: ["order_part", "contract_number"],
        key: "contract_number",
        align: "right",
        ellipsis: true,
        width: 90,
        render: (item: Number) => <div>{item && formatNumber(item.toString())}</div>
      },
      {
        title: "交货日期",
        dataIndex: ["order_part", "finish_date"],
        key: "finish_date",
        align: "center",
        ellipsis: true,
        width: 120,
        render: (item: String) => (
            <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
          ),
      },
      {
        title: "发货单号",
        dataIndex: "code",
        key: "code",
        align: "center",
        ellipsis: true,
        width: 150,
      },
      {
        title: "发货数量",
        dataIndex: "fahuo_num",
        key: "fahuo_num",
        align: "right",
        ellipsis: true,
        width: 90,
        render: (item: Number) => <div>{item && formatNumber(item.toString())}</div>
      },
      {
        title: "报价单价",
        dataIndex: ["order_part","quot_single_price"],
        key: "quot_single_price",
        align: "right",
        ellipsis: true,
        width: 90,
      },
      {
        title: "报价金额",
        dataIndex: ["order_part","quot_price"],
        key: "quot_price",
        align: "right",
        ellipsis: true,
        width: 90,
      },
      {
        title: "合同单价",
        dataIndex: ["order_part","contract_single_price"],
        key: "contract_single_price",
        align: "right",
        ellipsis: true,
        width: 90,
      },
      {
        title: "合同金额",
        dataIndex: ["order_part","contract_price"],
        key: "contract_price",
        align: "right",
        ellipsis: true,
        width: 90,
      },
      {
        title: "发货人",
        dataIndex: "user_name",
        key: "user_name",
        align: "center",
        ellipsis: true,
        width: 100,
      },
      {
        title: "发货日期",
        dataIndex: "create_time", 
        key: "create_time",
        align: "center",
        ellipsis: true,
        width: 120,
        render: (item: String) => (
          <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
        ),
      },
      {
        title: "发货备注",
        dataIndex: "remark",
        key: "remark",
        align: "center",
        ellipsis: true,
        // width: 160,
        // render:(_,record)=>(
        //   <Tooltip
        //   color="#fff"
        //   showArrow={false}
        //   overlayInnerStyle={{
        //     backgroundColor: "#fff",
        //     border: "1px solid black",
        //     color: "black",
        //   }}
        //   placement="bottomRight"
        //   title={record.deliver_part && record.deliver_part.map((r:any,i:any,arr:any[]) => {
        //     return <span>{r.remark && r.remark}{(i+1) === arr.length ? "" :","}</span>;
        //   })}
        //   >{record.deliver_part.map((item:any) => {
        //     return <span>{item.remark}</span>
        //   })}</Tooltip>
        // )
      }
    ];
    useEffect(() => {
      getUsersForSelect((users:{ id: number; name: string }[])=>{
        setUsers(users);
        let newFormList = formList;
        newFormList[3].options = users
        setFormList(newFormList);
      });
    },[]);
    const ExtraComponent = React.memo((): React.ReactElement => {
      const exportExcel = async(callback:any) => {
        if (gRef.current) {
            if (gRef.current.exportData) 
            {
                gRef.current.exportData()
                  .then((res) => {
                    res.map((item: any) => {
                      if (item.create_time) {
                        item.create_time = formatTime(item.create_time, false);
                      }
                      if(item.order_part && item.order_part.finish_date) {
                        item.order_part.finish_date =  formatTime(item.order_part.finish_date,false)
                      }
                      // if(item.deliver_part) {
                      //   item.deliver_part.map( (i:any) => {
                      //     item.remark = i.remark;
                      //   })
                      // }
                    });
                    let length = res.length + 1;
                    callback(res, length);
                  })
                  .catch((err) => {
                    console.log("导出全部数据遇到错误", err);
                  });
            }
        }
    }
      const tabName =
        "发货查询" + moment(new Date()).format("YYMMDDHHmmss");
      return (
        <div className="delivery_div">
          <ExportExcel
            onClick={exportExcel}
            columns={columns}
            tableName={tabName}
            btnName="导出"
            isTwoHeader={false}
          />
        </div>
      );
    });
    const filterQueryData = (queryData: any) => {
      if (queryData.date) {
        queryData = filterDateParam(queryData, "date", "start_date", "end_date");
      }
      return queryData;
    };
    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
      return new Promise((resolve) => {
        getDeliverList(queryCondition)
          .then((res) => {
            if (res.code === 200) {
              if(res.data.data) {
                res.data.data.map((item, index) => {
                  item.key = uuid();
                  item.index = index + 1;
                  item.order_part.quot_price = moneyFormat(parseFloat(item.order_part.quot_single_price) * item.fahuo_num);
                  item.order_part.contract_price = moneyFormat(parseFloat(item.order_part.contract_single_price) * item.fahuo_num)
                })
              }
              resolve(res.data);
            } else {
              message.error(res.message);
            }
          })
          .catch((err) => {
           message.error(err)
          });
      });
    }, []);

    return (
      <div className="delivery_doc_management">
        <GeneralManagement
          formList={formList}
          columns={columns}
          getDataAsync={getDataAsync}
          ref={gRef}
          scroll={true}
          scrollValue={{x:1940}}
          filterQueryData={filterQueryData}
          ExtraComponent={<ExtraComponent/>}
        />
      </div>
    );
  };
  export default DeliveryQuery;

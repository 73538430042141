import {useEffect, useCallback } from "react";
import { message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getWorkReportDetail } from "../../../../service";
import PaganationTable from "../../components/PaganationTable";
import { formatTime, formatNumber } from "../../../../utils/index";
import {uuid} from "../../../../utils/index";
import "../../index.less";
interface IProps {
  record: any;
  setExportColumns:(columns:ColumnsType<any>)=>void;
  setModalDataSource:(dataSource:any[])=>void
}
const columns: ColumnsType<any> = [
  {
    title: "订单信息",
    className: "orderInfo",
    key: "1",
    align: "center",
    children: [
      {
        title: "订单号",
        className: "orderInfo",
        dataIndex: "order_number",
        key: "order_number",
        align: "center",
        width: 140,
        ellipsis: true,
        fixed: "left",
      },
      {
        title: "零件名称",
        className: "orderInfo",
        dataIndex: "part_name",
        key: "part_name",
        align: "center",
        ellipsis: true,
        width: 140,
        fixed: "left",
      },
      {
        title: "零件图号",
        className: "orderInfo",
        dataIndex: "part_number",
        key: "part_number",
        align: "center",
        ellipsis: true,
        width: 140,
        fixed: "left",
      },
      {
        title: "订单数量",
        className: "orderInfo",
        dataIndex: "order_part_number",
        key: "order_part_number",
        align: "right",
        ellipsis: true,
        width: 90,
        fixed: "left",
        render: (item: Number) => (
          <div>{item && formatNumber(item.toString())}</div>
        ),
      },
    ],
  },

  {
    title: "报工信息",
    className: "reportInfo",
    align: "center",
    key: "2",
    children: [
      {
        title: "任务单号",
        className: "reportInfo",
        dataIndex: "task_number",
        key: "task_number",
        align: "center",
        ellipsis: true,
        width: 140,
      },
      {
        title: "投产数量",
        className: "reportInfo",
        dataIndex: "task_quantity",
        key: "task_quantity",
        align: "right",
        ellipsis: true,
        width: 90,
        render: (item: Number) => (
          <div>{item && formatNumber(item.toString())}</div>
        ),
      },
      {
        title: "报工类型",
        className: "reportInfo",
        dataIndex: "category",
        key: "category",
        align: "center",
        ellipsis: true,
        width: 90,
        render: (item: String) => (
          <div>
            {item && item === "10"
              ? "调试"
              : item === "20"
              ? "加工"
              : item === "30"
              ? "返修"
              : item === "40"
              ? "外协"
              : "返厂"}
          </div>
        ),
      },
      {
        title: "加工设备",
        className: "reportInfo",
        dataIndex: "device_info_name",
        key: "device_info_name",
        align: "center",
        ellipsis: true,
        width: 140,
      },
      {
        title: "设备加工费用(元/小时)",
        className: "reportInfo",
        dataIndex: "device_hour_single_price",
        key: "device_hour_single_price",
        align: "right",
        ellipsis: true,
        width: 200,
      },
      {
        title: "操作员",
        className: "reportInfo",
        dataIndex: "user_name",
        key: "user_name",
        align: "center",
        ellipsis: true,
        width: 140,
      },
      {
        title: "开始时间",
        className: "reportInfo",
        dataIndex: "start_time",
        key: "start_time",
        align: "center",
        ellipsis: true,
        width: 150,
        render: (item: string) => <div>{item && formatTime(item, true)}</div>,
      },
      {
        title: "完成时间",
        className: "reportInfo",
        dataIndex: "end_time",
        key: "end_time",
        align: "center",
        ellipsis: true,
        width: 150,
        render: (item: string) => <div>{item && formatTime(item, true)}</div>,
      },
      {
        title: "返修数量",
        className: "reportInfo",
        dataIndex: "qualified",
        key: "qualified",
        align: "right",
        ellipsis: true,
        width: 90,
        render:(i)=><span>{i && formatNumber(i)}</span>
      },
    ],
  },
  {
    title: "统计信息",
    align:"center",
    className: "statisticsInfo",
    key: "3",
    children: [
      {
        title: "实际费用(元)",
        className: "statisticsInfo",
        dataIndex: "real_price",
        key: "real_price",
        align: "right",
        ellipsis: true,
        width: 130,
        // render: (item: string) => <div>{item && formatNumber(item)}</div>,
      },
      {
        title: "实际单价(元)",
        className: "statisticsInfo",
        dataIndex: "real_unit_price",
        key: "real_unit_price",
        align: "right",
        ellipsis: true,
        // width: 130,
        // render: (item: string) => <div>{item && formatNumber(item)}</div>,
      },
    ],
  },
];
const Repair = (props: IProps) => {
  const { record,setExportColumns,setModalDataSource } = props;
  useEffect(()=>{
    getListAsync({
      object_id:record.object_id,
      work_process_name:record.work_process_name,
      page_num:"1",
      page_size:"1000000000",
    }).then(res => {
      res.data && setModalDataSource(res.data)
    }).catch(err => {
      message.error(err)
    })
    setExportColumns(columns);
  },[])
  const getListAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getWorkReportDetail(
        Object.assign(queryCondition, { object_id: record.object_id.toString(),work_process_name:record.work_process_name })
      ).then((res) => {
          if (res.code === 200) {
            if(res.data.data && Array.isArray(res.data.data)) {
              res.data.data.map( i => {
                i.key = uuid()
              })
            }
            resolve(res.data);
        
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);

  return (
    <div className="repair_modal">
      <PaganationTable
        columns={columns}
        getListAsync={getListAsync}
        scrollValue={{x:1950}}
      ></PaganationTable>
    </div>
  );
};
export default Repair;

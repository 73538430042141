// 打印或者查看发货单
import React,{forwardRef,useEffect} from "react";
import PrintDeliveryTable from "./printDeliveryTable";
import moment from "moment";
import "../index.less";
interface IProps {
  // 保存发货单打印的columns
  setCols?:Function,
  // 创建后打印时传
  printData?:any,
  // 当前是查看
  is_show?:boolean,
  // 查看传
  showDetailDelivery?:any,
}
// forwardRef进行ref的透传,ref必须作为函数式组件的第二个参数
const PrintDelivery = forwardRef((props:IProps,ref:any) => {
  const {setCols,printData,is_show,showDetailDelivery} = props;

 
  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          height: "44px",
          backgroundColor: "#F8F8F8",
          opacity: 1,
        }}
      ></div>
      <div
      ref={ref}
        style={{
          color: "#333333",
        }}
      >
        {/* 实际上被打印的部分 */}
        <div 
          style={{
            height: "70px",
            fontSize: "20px",
            lineHeight: "70px",
            textAlign: "center",
            fontWeight: "bold",
            pageBreakAfter:"avoid"
          }}
        >
          {is_show?showDetailDelivery.title: printData.title && printData.title}
        </div>
        <div
          style={{
            padding: "0 24px",
            height: "70px",
            fontSize: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>合同日期：</span> 
            {is_show ?showDetailDelivery.contract_date: printData.contract_date && printData.contract_date}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pageBreakAfter:"avoid"
            }}
          >
            <div>
              <span style={{ fontWeight: "bold" }}>合同编号：</span>
               {is_show ?showDetailDelivery.contract_code:printData.contract_code && printData.contract_code}
            </div>
            <div>
              <span style={{ fontWeight: "bold"}}>日期：</span> 
              {is_show ?moment(showDetailDelivery.create_time).format("YYYY/MM/DD"):printData.fahuo_date && printData.fahuo_date}
            </div>
          </div>
        </div>
        <div style={{ padding: "0 24px"}}>
        <PrintDeliveryTable
          setCols={setCols}
          printData={printData}
          is_show={is_show}
          showDetailDelivery={showDetailDelivery}
        ></PrintDeliveryTable>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontWeight: "bold",
            fontSize: "16px",
            padding: "24px",
            paddingRight: "250px",
          }}
        >
          <span>交货人:</span>
          <span>收货人:</span>
        </div>
      </div>
    </div>
  );
});
export default PrintDelivery;


import React, { useState, useEffect } from "react";
import { Button, FormInstance, Select } from "antd";
import "../index.less";

interface IProps {
  tableForm: FormInstance;
  index: number;
  name: "work_duration" | "duration";
}
interface IPickerProps {
  setSelectDropdownOpen: Function;
  selectDropdownOpen: boolean; 
  setValue: Function
}
export interface ISelectValue {
    day: string;
    hour: string;
    minute: string;
    seconds: string;
}



function selectValueToStr(selectValue: ISelectValue ) {
  return `${selectValue.day}天${selectValue.hour}时${selectValue.minute}分${selectValue.seconds}秒`;
}
const MyTimePicker = React.memo((props: IProps)  => {
  const [selectDropdownOpen, setSelectDropdownOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);
  const { tableForm, index, name } = props;
  useEffect(()=>{
    // 父组件改变引起子组件刷新，组件刷新时把值回填
    const value = tableForm.getFieldValue([`${index}`, name]);
    value && setValue(selectValueToStr(value));
  },[])
  return (
    <Select
      value={value}
      placeholder={`请选择${name === 'duration' ? '调试工时' : '加工工时'}`}
      options={[{ value: "gold" }]}
      dropdownRender={(originNode) => {
        return (
          <Picker
            tableForm={tableForm}
            index={index}
            setSelectDropdownOpen={setSelectDropdownOpen}
            selectDropdownOpen={selectDropdownOpen}
            setValue={setValue}
            name={name}
          ></Picker>
        );
      }}
      getPopupContainer={(triggerNode) => {return triggerNode.parentNode}}
      open={selectDropdownOpen}
      onDropdownVisibleChange={(open) => {
        const obj = tableForm.getFieldValue([`${index}`, name]);
        if(open === false) {
          // 如果是点击空白处关闭的，会触发onDropDornVisibleChange,此时重置选项，如果是设置selectDropdownOpen关闭的，不会触发该函数
          obj ? setValue(selectValueToStr(obj)) : setValue(null);
        }
        setSelectDropdownOpen(open);
      }}
    ></Select>
  );
});
export default MyTimePicker;


function Picker(props: IProps & IPickerProps) {
  const { tableForm, index, setSelectDropdownOpen, selectDropdownOpen, setValue, name } = props;
  const [selectValue, setSelectValue] = useState<ISelectValue>({ day: "00", hour: "00", minute: "00", seconds: "00" });
  useEffect(()=>{
    const obj = tableForm.getFieldValue([`${index}`, name]);
    const dayParentNode = document.getElementById(`${name}${index}day`);
    const hourParentNode = document.getElementById(`${name}${index}hour`);
    const minuteParentNode = document.getElementById(`${name}${index}minute`);
    const secondsParentNode = document.getElementById(`${name}${index}seconds`);
     if(obj) {
        // 编辑原来的数据
        setSelectValue(obj)
       // 使用scrollTop 代替 scrollIntoView 防止滚动时外部的body一并滚动的问题
       const dayNode = document.getElementById(`${name}${index}day${obj.day}`);
       const hourNode = document.getElementById(`${name}${index}hour${obj.hour}`);
       const minuteNode = document.getElementById(`${name}${index}minute${obj.minute}`);
       const secondsNode = document.getElementById(`${name}${index}seconds${obj.seconds}`);
       if(dayParentNode && dayNode) {
         dayParentNode.scrollTop = dayNode.offsetTop - dayParentNode.offsetTop;
       }
       if(hourParentNode && hourNode) {
         hourParentNode.scrollTop = hourNode.offsetTop - hourParentNode.offsetTop;
       }
       if(minuteNode && minuteParentNode) {
         minuteParentNode.scrollTop = minuteNode.offsetTop - minuteParentNode.offsetTop;
       }
       if(secondsNode && secondsParentNode) {
         secondsParentNode.scrollTop = secondsNode.offsetTop - secondsParentNode.offsetTop;
       }
     } else {
      // 新增一条数据，点击空白处关闭未保存的情况
      setSelectValue({ day: "00", hour: "00", minute: "00", seconds: "00" })
      if (dayParentNode) {
        dayParentNode.scrollTop = 0;
      }
      if (hourParentNode) {
        hourParentNode.scrollTop = 0;
      }
      if (minuteParentNode) {
        minuteParentNode.scrollTop = 0;
      }
      if (secondsParentNode) {
        secondsParentNode.scrollTop = 0;
      }
     }
  },[tableForm, index, name, selectDropdownOpen])

  function generateArr(num: number) {
    const arr: string[] = [];
    for (let i = 0; i < num; i++) {
      i < 10 ? arr.push("0" + i) : arr.push(i + "");
    }
    return arr;
  }
  return (
    <div className="my-time-picker">
      <div className="container">
        {
          ["day", "hour", "minute", "seconds"].map(i => {
            return (
            <div key={i} className="container-ul-wrapper">
             <ul
              id={name + index + i}
              className="container-ul"
              onClick={(event: any) => {
                const value = event.target.dataset.value;
                if(value) {
                  const obj = {...selectValue,[i]:value}
                  setSelectValue(obj);
                  setValue(`${obj.day}天${obj.hour}时${obj.minute}分${obj.seconds}秒`);
                  const parentEle = document.getElementById(`${name}${index}${i}`);
                  const childEle = document.getElementById(`${name}${index}${i}${value}`);
                  if(parentEle && childEle) {
                     parentEle.scrollTop = childEle.offsetTop - parentEle.offsetTop;
                  }
                }
              }}
            >
              {generateArr(i === 'hour' ? 24 : 60).map((item) => {
                return (
                  <li
                    // 不能使用 i + item 作为id,document.getELementById置灰获取当前文档中第一个为id的元素，使用i+item永远会获取到第一行第一个元素
                    id={name + index + i+item}
                    key={item}
                    data-value={item}
                    className={item === (selectValue as any)[i] ? "container-ul-li container-ul-li-bg" : "container-ul-li"}>
                    {item}
                  </li>
                );
              })}
            </ul>
            </div>
            )
          })
        }
      </div>
      <div className="btn">
        <Button
          type="primary"
          size="small"
          onClick={() => {
            tableForm.setFieldValue([`${index}`, name],selectValue);
            setValue(`${selectValue.day}天${selectValue.hour}时${selectValue.minute}分${selectValue.seconds}秒`);
            tableForm.validateFields([[`${index}`, name]]);
            setSelectDropdownOpen(false);
          }}
        >
          确认
        </Button>
      </div>
    </div>
  );
}

import React, { useCallback,  useState,useEffect,useRef } from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import {  Tooltip,message } from "antd";
import "../index.less";
import {
  formatTime,
  getUsersForSelect,
  getDeviceList,
  filterDateParam,
  indexType,
  formatNumber} from "../../../utils/index";
import ExportExcel from "../../../components/ExportTable/exportTable";
import moment from "moment";
import {ColumnsType} from "antd/es/table";
import { IOptions } from "../ReportMonitor";
import {getRepairList} from "../../../service";


const RepairMonitor = (): React.ReactElement => {
  const [selectData, setSelectData] = useState<{ [key: string]: IOptions[] }>({});
  const gRef = useRef<GRef>(null);
  const [formList,setFormList]=useState<QueryListItem[]>([
    {
      key: "order_number",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      key: "report_status",
      name: "报工状态",
      placeholder: "请选择报工状态",
      multiple: true,
      defaultSelect:[20],
      options: [
        { id: 20, name: "处理中" },
        { id: 30, name: "已完成" },
      ],
      // onClear:()=>{
      //   setCurrentPageStatus(true);
      //   setTimeout(()=>{
      //   if (gRef.current) {
      //     gRef.current.onFinish();
      //   }
      //   },0)
      // }
    },
    {
      key: "task_number",
      name: "任务单号",
      placeholder: "请输入任务单号",
    },
    
    {
      key: "device_id",
      name: "加工设备",
      placeholder: "请选择加工设备",
      options:selectData.devices,
    },
    {
      key: "user_id",
      name: "操作员",
      placeholder: "请选择操作员",
      options: selectData.users,
    },
    {
      key: "date",
      name: "报工日期",
      placeholder: "请选择报工日期",
      rangePick: true,
    },
  ]);
  useEffect(() => {
    getUsersForSelect((users: IOptions[]) => {

      setSelectData({
        ...selectData,
        users: users,
      });
      let newFormList = formList;
      newFormList[5].options = users;
      setFormList(newFormList);
    });
    getDeviceList((devices: IOptions[]) => {
      
      setSelectData({
        ...selectData,
        devices: devices,
      });
      let newFormList = formList;
      newFormList[4].options = devices;
      setFormList(newFormList);
    });
  }, []);
  const ExtraComponent = React.memo((): React.ReactElement => {
    const exportExcel = async(callback:any) => {
      if (gRef.current) {
          if (gRef.current.exportData) 
          {
              gRef.current.exportData().then(res => {
              
                  const statusObj: indexType = {
                    10: "未开始",
                    20: "处理中",
                    30: "已完成",
                  }
                  res.map( (a:any) => {
                    if(a.status) {
                      a.status = statusObj[a.status]
                    }
                    if(a.start_time) {
                      a.start_time = formatTime(a.start_time,true)
                    }
                    if(a.end_time) {
                      a.end_time = formatTime(a.end_time,true)
                    }else {
                      // 完工时显示返修数量
                      a.quantity = ""
                    }
                  })
                  let length  = res.length + 1
                  callback(res,length)
              }).catch(err => {
                console.log("导出全部数据遇到错误",err)
              })
          }
      }
  }
    const tabName =
      "返修监控" + moment(new Date()).format("YYMMDDhhmmss"); 
    return (
      <div className="btn_box">
        <ExportExcel
          onClick={exportExcel}
          columns={columns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={false}
        ></ExportExcel>
      </div>
    );
  });
  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      ellipsis: true,
      fixed:true,
    },
    {
      title: "订单号",
      dataIndex: "order_number",
      key: "order_number",
      align: "center",
      width: 140,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "零件名称",
      dataIndex: "part_name",
      key: "part_name",
      align: "center",
      ellipsis: true,
      width: 140,
      fixed: "left",
    },
    {
      title: "零件图号",
      dataIndex: "part_number",
      key: "part_number",
      align: "center",
      ellipsis: true,
      width: 140,
      fixed: "left",
    },
    {
      title: "订单数量",
      dataIndex: "order_quantity",
      key: "order_quantity",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(text)=> <div>{text && formatNumber(text.toString())}</div>
    },
    {
      title: "任务单号", 
      dataIndex: "task_number",
      key: "task_number",
      align: "center",
      ellipsis: true,
      width: 160,
    },
    {
      title: "投产数量", 
      dataIndex: "task_quantity",
      key: "task_quantity",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(text)=> <div>{text && formatNumber(text.toString())}</div>
    },
    {
      title: "最小完成数量",
      dataIndex: "min_quantity",
      key: "min_quantity",
      align: "right",
      ellipsis: true,
      width: 130,
      render:(text)=> <div>{text && formatNumber(text.toString())}</div>
    },
    {
      title: "加工设备",
      dataIndex: "device",
      key: "device",
      align: "center",
      ellipsis: true,
      width: 160,
    },
    {
      title: "返修数量",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(text,record)=> <div>
        {/* 完工的时候显示返修数量，开工不显示 */}
        {record.end_time && text && formatNumber(text.toString())}
        </div>
    },
    {
      title: "备注",
      dataIndex:"remark",
      key: "remark",
      align: "center",
      ellipsis: true,
      width: 250,
    },
    {
      title: "操作员",
      dataIndex:"user",
      key: "user",
      align: "center",
      ellipsis: true,
      width: 140,
    },
    {
        title: "开始时间",
        dataIndex:"start_time",
        key: "start_time",
        align: "center",
        ellipsis: true,
        width: 150,
        render: (item: String) => (
          <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
        ),
      },
      {
        title: "结束时间",
        dataIndex: "end_time",
        key: "end_time",
        align: "center",
        ellipsis: true,
        width: 150,
        render: (item: String) => (
          <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
        ),
      },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      align: "center",
      ellipsis: true,
      // width: 90,
      render: (status: 10| 20 | 30, __: any, index: any) => {
        return <div className={ status === 20 ? "progress":"finish"}>{status === 20 ? "处理中": status === 10? "未开始": "已完成"}</div>;
      },
    },
  ];
  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    if(queryData.report_status){
    
      if(JSON.stringify(queryData.report_status) === "[]"){
        queryData.report_status = JSON.stringify([20,30])
      }else {
        queryData.report_status = JSON.stringify(queryData.report_status)
      }
    }
    return queryData;
  };
  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getRepairList(Object.assign({report_status:JSON.stringify([20])},queryCondition))
        .then((res) => {
          if (res.code === 200) {
            if(res.data.data) {
              res.data.data.map((item,index) => {
                item.index = index + 1
              })
            }
            resolve(res.data);
           
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          message.error(err)
        });
    });
  }, []);
  return (
    <div className="repair_monitor">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ExtraComponent={<ExtraComponent />}
        ref={gRef}
        scroll={true}
        scrollValue={{x:2000}}
        filterQueryData={filterQueryData}
      />
    </div>
  );
};
export default RepairMonitor;

import React, {useState, useEffect, useContext } from "react";
import {
  Input,
  message,
  Select,
  Form,
  Checkbox,
  Table,
} from "antd";
import ModalForm, { FormItem } from "../../../components/ModalForm";
import Button from "../../../components/MyButton";
import Modal from "../../../components/Modal";
import CopyCraft from "./CopyCraft";
import { getCraftInfo, workProcess } from "../../../service";
import { formatTime,showError,shiftTimeStampWithDay} from "../../../utils";
import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import { UserContext } from "../../../contexts/userContext";
import "../index.less";
import {useNavigate} from "react-router-dom";
import  MyTimePicker from "./MyTimePicker";

interface IProps {
  object_id: number;
  modalForm: any;
  is_show?:boolean,
  selectedItem?: any;
  tableForm?:any;
  is_disable:boolean
}

// 把秒转化为可用于进行MyPicker初始化的数据
function initMyPicker(curValue: number) {
  const day = Math.floor(curValue/86400);
  const hour =  Math.floor((curValue - day*86400)/3600);
  const minute = Math.floor((curValue - day*86400 - hour*3600)/60);
  const seconds = curValue - day*86400 - hour*3600 - minute*60;
  return {
    day: (day + '').length === 1 ? ('0' + day)  : ('' + day),
    hour: (hour + '').length === 1 ? ('0' + hour)  : ('' + hour),
    minute: (minute + '').length === 1 ? ('0' + minute) : ('' + minute),
    seconds: (seconds + '').length === 1 ? ('0' + seconds) : ('' + seconds)
  }
}

const EditOrShowCraft: React.FC<IProps> = (props) => {
  const { useInfo } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [sRows, setSRows] = useState<any[]>([]);
  const [workProcessList, setWorkProcess] = useState<any[]>([]);
  const [detailDataSource, setDetailDataSource] = useState<any[]>([]);
  const [showTableData,setShowTableData] = useState<any[]>([]);
  const [editOrDragTableLoading,setEditOrDragTableLoading] = useState<boolean>(true);
  const tRef = React.useRef<TRef>(null);
  const { object_id, is_disable, modalForm, selectedItem,tableForm,is_show } = props;
  const navigate = useNavigate();
  const dateFormat = "YYYY/MM/DD";
  const newItem = {
    object_id:false, // 新增的数据object_id为false
    new:true, // 判断当前工艺流程是否是新增
    work_processinfo_id:undefined,
    duration:undefined,
    work_duration:undefined,
    out_status:false,
    repair_status:false,
    remark:""}
  useEffect(() => {
    //获取工序列表
    workProcess().then(res => {
      if (res.code === 200) {
          if (res.data.data) {
              setWorkProcess(res.data.data)
          }
      } else {
          showError(res.message);
      }
  })
  // 请求工艺详情
    getCraftInfo({ object_id })
      .then((res) => {
        if (res.code === 200 && res.data) {

          // 此处使用modalForm.setFieldsValue是每次进来的时候都可变。不用defaultValue,defaultValue只在初始化和重置时生效
          if (res.data.production) {
            modalForm.setFieldsValue({
              blank_size: res.data.production.blank_size,
              part_size: res.data.production.part_size,
              number: res.data.production.number,
              blank_number: res.data.production.blank_number,
            });
          }
          // 设置table中表单项的初始值
          let initialDataSource: any = [];
          if (res.data.production.precesses) {
            let arr = JSON.parse(JSON.stringify(res.data.production.precesses))
            setShowTableData(res.data.production.precesses)
            // 初始化为数据添加index,index从0开始
            arr.map((e: any, i: number) => {
              if(e.duration || e.duration === 0){
                e.duration = initMyPicker(e.duration);
              }
              if(e.work_duration || e.work_duration === 0) {
                e.work_duration = initMyPicker(e.work_duration);
              }
              e.new = false; // 增加标记 oldData
              initialDataSource.push({
                index: i + 1,
                ...e,
              });
            });
          }
          tableForm.setFieldsValue({ ...initialDataSource });

          setDetailDataSource(initialDataSource);
          setEditOrDragTableLoading(false)
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }, []);
  useEffect(()=>{
   modalForm.setFieldsValue({
    trader_id:selectedItem.order.trader.name,
    code:selectedItem.order.code,
    name:selectedItem.part.name,
    part_number:selectedItem.part.number,
    material_type:selectedItem.part.material_type && selectedItem.part.material_type === 10 ? "带料" : "购料",
    material:selectedItem.part.material,
    order_number:selectedItem.number,
    finish_date:selectedItem.finish_date && formatTime(selectedItem.finish_date, false),
    content:selectedItem.content,
    remark:selectedItem.remark
    });
   tableForm.setFieldsValue({ ...detailDataSource });
  },[detailDataSource])


  const formItem:FormItem[]=[
    {
      label: "客户",
      name: "trader_id", // 客户id对应的是不是客商信息表里面的object_id
      is_disable: true,
    },
    {
      label: "订单号",
      name: "code",
      is_disable: true,
    },
    {
      label: "零件名称",
      name: "name",
      is_disable: true,
    },
    {
      label: "零件图号",
      name: "part_number",
      is_disable: true,
    },
    {
      label: "供料方式",
      name: "material_type",
      is_disable: true,
    },
    {
      label: "材料",
      name: "material",
      is_disable: true,
    },
    {
      label: "订单数量",
      name: "order_number",
      is_disable: true,
    },
    {
      label: "交货日期",
      name: "finish_date",
      is_disable: true,
    },
    {
      label: "加工内容",
      name: "content",
      is_row: true,
      is_disable: true,
    },
    {
      label: "备注",
      name: "remark",
      is_row: true,
      is_disable: true,
    },
    {
      label: "毛坯外形尺寸",
      name: "blank_size",
      is_edit: true,
      is_disable:is_disable,
    },
    {
      label: "零件净尺寸",
      name: "part_size",
      is_edit: true,
      is_disable:is_disable,
    },
    {
      label: "总投产数量",
      name: "number",
      is_edit: true,
      is_disable:is_disable,
      rules: is_show ? [] : [
        { required: true, message: "请输入总投产数量" },
        ({ getFieldValue }: any) => {
          let reg = /^[0-9]*[1-9][0-9]*$/;
          return {
            validator(_: any, value: any) {

              if(value && typeof value === "number") {
              value = value.toString();
            }
              if(value && value.trim().length > 0) {
              if (!reg.test(value)&& Number(value) !== 0 ) {
                return Promise.reject(new Error("请输入整数"));
              } else if (
                getFieldValue("order_number") && Number(value) < getFieldValue("order_number")
              ) {
                return Promise.reject(
                  new Error("投产数量应该大于或者等于订单数量")
                );
              }
            }

              return Promise.resolve();
            },
          };
        },
      ],
    },
    {
      label: "毛坯数量",
      name: "blank_number",
      is_edit: true,
      is_disable:is_disable,
      rules: is_show ? [] : [
        { required: true, message: "请输入毛坯数量" },
        ({ getFieldValue }: any) => {
          let reg = /^[0-9]*[1-9][0-9]*$/;
          return {
            validator(_: any, value: any) {
              if(value && typeof value === "number") {
                value = value.toString();
              }
              if(value && value.trim().length > 0){
              if (!reg.test(value)&& Number(value) !== 0 ) {
                return Promise.reject(new Error("请输入大于0的整数"));
              }
            }
              return Promise.resolve();
            },
          };
        },
      ],
    },
  ];

  const WordProcess = (props:any) => {
   return  (
   <Form.Item name={[`${props.index}`, "work_processinfo_id"]} style={{ textAlign: "left" }} rules={[{ required: true, message: "请选择工序！" }]}>
      <Select getPopupContainer={() => {return document.getElementById("craft_table") as any}} placeholder={"请选择工序"}
                onSelect={(_: any, option: any) => { tableForm.setFieldValue([`${props.index}`, 'out_status'], option.title1.out_help)}}>
         {workProcessList.map((e) => (
          <Select.Option key={e.object_id} value={e.object_id} title1={e}>
            {e.work_process_name}
          </Select.Option>
     ))}
   </Select>
 </Form.Item>
 )}

  const setSelectedRows = (selectRows:any[]) => {
    setSRows(selectRows)
  }

  const defaultColumns: any[] = [
    {
      title: "",
      dataIndex: "object_id",
      key: "object_id",
      width: 0,
      render: (_: any, record: any, index: number) => (
        <Form.Item name={[`${index}`, "object_id"]}>
          <div></div>
        </Form.Item>
      ),
    },
    {
      title: "",
      dataIndex: "new",
      key: "new",
      width: 0,
      render: (_: any, record: any, index: number) => (
        <Form.Item name={[`${index}`, "new"]}>
          <div></div>
        </Form.Item>
      ),
    },
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 80,
      align: "center",
      inputType: "text",
      ellipsis: true,
      render: (_: any, record: any, index: number) => <div>{index + 1}</div>,
    },
    {
      title: (
        <div>
          {is_show ? (
            <span>工序</span>
          ) : (
            <div>
              {useInfo.authRoutes?.includes("/basic/procedure") ? (
                <div>
                  <span style={{ color: "red" }}>*</span>
                  <span
                    style={{
                      color: "#3E7BFA",
                      paddingBottom: "5px",
                      borderBottom: "1px solid #3E7BFA",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/basic/procedure");
                    }}
                  >
                    工序
                  </span>
                </div>
              ) : (
                <span>工序</span>
              )}
            </div>
          )}
        </div>
      ),
      dataIndex: "work_processinfo_id",
      key: "work_processinfo_id1",
      render: (value: any, item: any, index: number) => (
        <WordProcess index={index} />
      ),
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>调试工时
        </div>
      ),
      dataIndex: "duration",
      key: "duration1",
      width: 250,
      render: (_: any, record: any, index: number) => (
        <Form.Item
          name={[`${index}`, "duration"]}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error("请选择调试工时!"));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <MyTimePicker name="duration" tableForm={tableForm} index={index}></MyTimePicker>
        </Form.Item>
      ),
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>加工工时
        </div>
      ),
      dataIndex: "work_duration",
      key: "work_duration1",
      width: 250,
      render: (_: any, record: any, index: number) => (
        <Form.Item
          name={[`${index}`, "work_duration"]}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error("请选择加工工时!"));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <MyTimePicker name="work_duration" tableForm={tableForm} index={index}></MyTimePicker>
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          <span className={"requiredIcon"}>*</span>是否外协
        </span>
      ),
      dataIndex: "out_status",
      key: "out_status1",
      width: 100,
      render: (value: any, item: any, index: number) => (
        <Form.Item
          name={[`${index}`, "out_status"]}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          <span className={"requiredIcon"}>*</span>是否返厂
        </span>
      ),
      dataIndex: "repair_status",
      key: "repair_status1",
      width: 100,
      render: (value: any, item: any, index: number) => (
        <Form.Item
          name={[`${index}`, "repair_status"]}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: "工艺说明",
      dataIndex: "remark",
      key: "remark1",
      align: "center",
      inputType: "text",
      ellipsis: true,
      width: 200,
      render: (value: any, item: any, index: number) => (
        <Form.Item
          name={[`${index}`, "remark"]}
          rules={[{ max: 200, message: "工艺说明不得大于200位!" }]}
          style={{ textAlign: "left" }}
        >
          <Input.TextArea placeholder={"请输入工艺说明"} />
        </Form.Item>
      ),
    },
    {
      title: "操作",
      dataIndex: "options",
      key: "options1",
      width: 100,
      align: "center",
      render: (_: any, item: any, index: number) => (
        <span
          className={"mes-user-options"}
          onClick={() => {
            if (tRef.current) {
              tRef.current.handleDelete(index);
            }
          }}
        >
          删除
        </span>
      ),
    },
  ];

  const showColumns: any[] = [
    {
      title: "NO",
      dataIndex: "index",
      key: "index",
      width:80,
      align: "center",
      inputType: "text",
      ellipsis: true,
      render: (_: any, record: any, index: number) => <div>{index+1}</div>,
    },
    {
      title: <div>工序</div>,
      dataIndex: "work_processinfo_id",
      key: "work_processinfo_id",
      align: "center",
      inputType: "text",
      ellipsis: true,
      render: (id: any) => {
        return (
          <div> {workProcessList.map((e) => {
            if(id === e.object_id){
              return <span key={e.object_id}>{e.work_process_name}</span>
            }
          }
          )}</div>
        );
      },
    },
    {
      title:"调试工时",
      dataIndex: "duration",
      key: "duration",
      ellipsis: true,
      render:(text:any) => {
        return <span>{text && shiftTimeStampWithDay(text.toString())}</span>
      }
    },
    {
      title: "加工工时",
      dataIndex: "work_duration",
      key: "work_duration",
      ellipsis: true,
      render:(text:any) => {
        return <span>{text && shiftTimeStampWithDay(text.toString())}</span>
      }
    },
    {
      title: "是否外协",
      dataIndex: "out_status",
      key: "out_status",
      align: "center",
      width:100,
      render:(v:number)=><span>{v===1?"是":"否"}</span>
    },
    {
      title:"是否返厂",
      dataIndex: "repair_status",
      key: "repair_status",
      align: "center",
      width:100,
      render:(v:number)=><span>{v===1?"是":"否"}</span>
    },
    {
      title: "工艺说明",
      dataIndex: "remark",
      key: "remark",
      align: "center",
      ellipsis: true,
      width:350
    }
  ];
  const otherBtn = () => {
    return (
      <Button
        title="复制工艺流程"
        onClick={() => {
          setOpen(true);
        }}
        buttonStyle={{
          backgroundColor: "#3B9FFD",
          width: "128px",
          height: "28px",
          marginRight: "20px",
          borderRadius: "5px 5px 5px 5px",
          opacity: 1
        }}
        titleStyle={{ color: "#fff" }}
      ></Button>
    );
  };

  const handleCopy = () => {
      if(sRows.length === 0){
        message.warning("请选工艺流程数据");
        return;
      }
      sRows[0].precesses && sRows[0].precesses.map((item:any,i:number) => {
        item.index = detailDataSource.length + i + 1;
        if(item.duration || item.duration === 0){
          item.duration = initMyPicker(item.duration);
        }
        if(item.work_duration || item.work_duration === 0) {
          item.work_duration = initMyPicker(item.work_duration);
        }
        item.new = true; // 增加标记 新的工艺流程
      })
      setDetailDataSource([
              ...Object.values(tableForm.getFieldsValue()),
        ...(sRows[0].precesses && sRows[0].precesses),
      ]);
     setSRows([]);
     setOpen(false);

  };
  return (
    <div className="craft">
      <div className="craft_form">
        <div className="form_title">订单信息</div>
        <ModalForm
          form={modalForm}
          formItem={formItem}
          dateFormat={dateFormat}
          form_name="craft_form"
        />
      </div>
      <div className="craft_table" id="craft_table">
        {
          is_show?(
            <Table
            dataSource={showTableData}
            columns={showColumns}
            loading={JSON.stringify(showTableData) === '[]' ? true : false}
            pagination={false}>
          </Table>
          ):(
            <EditOrDragTable editOrDragTableLoading={editOrDragTableLoading} isNoneAddBtn={is_show?true:false} tableForm={tableForm} detailColumns={defaultColumns} detailDataSource={detailDataSource} scroll={{}}
          otherBtn={is_disable? ()=><span></span>: otherBtn} title={'工艺流程'} newItem={newItem} ref={tRef} tableDisabled={is_disable?is_disable:false} isDrag={is_show?false:true}/>
          )
        }
      </div>
      {open && (
        <Modal
          title="复制工艺流程"
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <div className="modal_btn">
              <Button
                title="复制"
                onClick={handleCopy}
                buttonStyle={{
                  backgroundColor: "#3E7BFA",
                }}
              ></Button>
            </div>,
          ]}
          width={"65%"}
          innerComponent={
            <CopyCraft setSelectedRows={setSelectedRows}/>
          }
        />
      )}
    </div>
  );
};
export default EditOrShowCraft;

import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {ExpandedRowRender} from "rc-table/lib/interface";
import {filterData, indexType} from "../../utils";
import {ColumnsType} from "antd/es/table";
import QueryBar from "./QueryBar";
import TableBar from "./TableBar";
import {Form} from "antd";
import './index.less';

export interface GRef {
    onFinish: (jumpCurrentPage?: boolean) => void;
    exportData?: (downloadCurrentPage?:boolean) => Promise<any>;
}

export interface Options {
    id: number,
    name: string
}

export interface Checkbox {
    label: string,
    value: number
}

export interface QueryListItem {
    key: string,
    name: string,
    placeholder: string,
    width?: number,
    options?: Options[],
    multiple?: boolean,
    date?: boolean,
    rangePick?: boolean,
    checkbox?: Checkbox[],
    check?: boolean,
    defaultValue?: number[] | number,
    defaultDate?: any[],
    defaultSelect?: any,
    onClear?: () => void,// form表单的select的清除事件
}

interface GeneralManagementProps {
    //表单查询条件
    formList: QueryListItem[],
    //表格表头
    columns: ColumnsType<any>,
    //功能按钮组件
    ExtraComponent?: JSX.Element,
    //调用接口方法
    getDataAsync: (queryCondition: any) => Promise<any>,
    //组织机构上级机构treeID
    treeID?: number[],
    //修改勾选的数组
    setSelectedRowKeys?: (selectedRowKeys: any[]) => void,
    //勾选的数组
    selectedRowKeys?: any[],
    // 修改勾选的行的数组
    setSelectedRows?: (selectedRows: any[]) => void,
    //是否显示表格内滚动条
    scroll?: boolean,
    // 表格内滑动的长度
    scrollValue?: { x: number },
    //嵌套子表格
    expandedRowRender?: ExpandedRowRender<any>,
    // 表格行属性
    rowClassName?: (record: any, index: number) => string,
    // 过滤queryData
    filterQueryData?: (queryData: any) => any,
    // form表单按钮的样式
    btnStyle?: React.CSSProperties;
    // 选择框
    type?: "checkbox" | "radio";
    // 更改rowSelections的复选框的属性
    getCheckboxProps?:any;
    // 去除Table自带分页
    paginationFalse?:boolean
}

const GeneralManagement = forwardRef<GRef, GeneralManagementProps>((props, ref) => {
    useImperativeHandle(ref, () => ({
        onFinish,
        exportData,
    }));

    const {
        formList,
        columns,
        ExtraComponent,
        getDataAsync,
        treeID,
        setSelectedRowKeys,
        setSelectedRows,
        selectedRowKeys,
        scroll,
        expandedRowRender,
        rowClassName,
        filterQueryData,
        btnStyle,
        type,
        scrollValue,
        getCheckboxProps,
        paginationFalse
    } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [queryForm] = Form.useForm();

    //表单查询
    const onFinish = (jumpCurrentPage?: boolean) => {
        setCurrentPage(jumpCurrentPage ? currentPage : 1);
        getList({
            ...queryForm.getFieldsValue(),
            object_id: treeID?.join(),
            page_num: jumpCurrentPage ? currentPage : 1,
            page_size: pageSize
        });
        setSelectedRowKeys && setSelectedRowKeys([]);
        setSelectedRows && setSelectedRows([]);
    }

    //导出数据
    const exportData = (downloadCurrentPage?:boolean): Promise<any> => {
        //需求修改（20240412）：生产管理-任务管理导出当前页的数据，不导出所有数据
        if(downloadCurrentPage){
            let realParams = filterQueryData ? filterQueryData(filterData(Object.assign(queryForm.getFieldsValue(),{page_num:currentPage,page_size:pageSize}))) : filterData(Object.assign(queryForm.getFieldsValue(),{page_num:currentPage,page_size:pageSize}));
            return new Promise((rescolve, reject) => {
                getDataAsync(realParams).then((res: any) => {
                    rescolve(res.data);
                }).catch(err => {
                    reject(err)
                })
            })
        }else{
            let realParams = filterQueryData ? filterQueryData(filterData(Object.assign(queryForm.getFieldsValue(),{page_num:1,page_size:10000}))) : filterData(Object.assign(queryForm.getFieldsValue(),{page_num:1,page_size:10000}));
            return new Promise((rescolve, reject) => {
                getDataAsync(realParams).then((res: any) => {
                    rescolve(res.data);
                }).catch(err => {
                    reject(err)
                })
            })
        }

    }

    //分页查询
    const onChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        getList({...queryForm.getFieldsValue(), object_id: treeID?.join(), page_num: page, page_size: pageSize});
        setSelectedRowKeys && setSelectedRowKeys([]);
        setSelectedRows && setSelectedRows([]);
    }

    //获取数据
    const getList = (values: any) => {
        setLoading(true);
        let realParams = filterQueryData ? filterQueryData(filterData(values)) : filterData(values)
        getDataAsync(realParams).then((res: any) => {
            setLoading(false);
            res.data.map((e: any) => e.key = e.key ? e.key : e.object_id)
            setDataSource(res.data);
            setTotal(res.total);
            setTotalPage(res.pages)
        })
    }

    //查询表单设置初始值
    const initialValues = (): Object => {
        let object: indexType = {}
        formList.map((e: any) => {
            if (e.defaultValue) {
                return object[e.key] = e.defaultValue
            } else if(e.defaultDate) {
                return object[e.key] = e.defaultDate
            } else if(e.defaultSelect) {
                return object[e.key] = e.defaultSelect
            }
        })
        return object
    }

    //页面初始调用接口
    useEffect(() => {
        getList({page_num: currentPage, page_size: pageSize, ...initialValues()});
    }, [])

    return (
        <div className={'mes-generalManagement'}>
            <QueryBar queryForm={queryForm} formList={formList} onFinish={onFinish} ExtraComponent={ExtraComponent}
                      btnStyle={btnStyle} initialValues={initialValues}/>
            <TableBar columns={columns} dataSource={dataSource} onChange={onChange} total={total}
                      scroll={scroll} scrollValue={scrollValue} expandedRowRender={expandedRowRender}
                      setSelectedRowKeys={setSelectedRowKeys}
                      selectedRowKeys={selectedRowKeys} setSelectedRows={setSelectedRows}
                      currentPage={currentPage} pageSize={pageSize} loading={loading} totalPage={totalPage}
                      rowClassName={rowClassName} type={type} getCheckboxProps={getCheckboxProps}
                      paginationFalse={paginationFalse}/>
        </div>
    )
})

export default React.memo(GeneralManagement);

import React, { useCallback,  useRef,useState,useEffect } from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { message } from "antd";
import "../index.less";
import "../index.less";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { getOperatorList } from "../../../service";
import {getUsersForSelect,getStations,filterDateParam,shiftTimeStamp} from "../../../utils/index";
import Button from "../../../components/MyButton";
import ExportExcel from "../../../components/ExportTable/exportTable";
import ModalContainer from "../../../components/Modal";
import ReportInfo from "./components/ReportInfo";
import workApplicationDetails from "../../../assets/work-application-details.png";
import MyImage from "../../../components/MyImage";

const start = moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD");
const end = moment().subtract(0, 'day').endOf('day').format("YYYY-MM-DD");
const OperatorStatistics = (): React.ReactElement => {
  const [exportColumns, setExportColumns] = useState<any[]>([]);
  const [modalDataSource, setModalDataSource] = useState<any[]>([]);
  const [optionMans, setOptionMans] = useState<{ id: number; name: string }[]>([]);
  const [stations,setStations] = useState<{ id: number; name: string }[]>([]);
  const [open,setOpen] = useState<boolean>(false);
  const [objectId,setObjectId] = useState<number>();
  const [optionName,setOptionName] = useState<string>();
  // 报工详情页面需要的日期
  const [selectDate,setSelectDate] = useState<{start_date:string,end_date:string}>({
    start_date:start,
    end_date:end
  });
  const gRef = useRef<GRef>(null);
  const dateFormat = 'YYYY/MM/DD';
  let [formList,setFormList] = useState<QueryListItem[]>([
    {
      key: "station_id",
      name: "岗位",
      placeholder: "请选择岗位",
      options:stations

    },
    {
      key: "user_id",
      name: "操作员",
      placeholder: "请选择操作员",
      options:optionMans

    },
    {
      key: "date",
      name: "报工时间",
      placeholder: "请选择报工时间",
      rangePick:true,
      defaultDate:[moment(start, dateFormat), moment(end, dateFormat)],
    }
  ])
  useEffect(() => {
    getUsersForSelect((users:{ id: number; name: string }[])=>{
      setOptionMans(users);
      let newFormList = formList;
      newFormList[1].options = users
      setFormList(newFormList);
    });
    getStations((stations:{ id: number; name: string }[]) => {
      setStations(stations);
      let newFormList = formList;
      newFormList[0].options = stations
      setFormList(newFormList);
    })
  },[]);

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      ellipsis: true,
      fixed:true,
    },
    {
        title:"操作员",
        key:"user_name",
        dataIndex:"user_name",
        align:"center",
        width:100,
        ellipsis: true,
         fixed:true,
    },
    {
      title: "调试工时",
      key: "1",
      align:"center",
      children: [
        {
          title: "预计调试工时",
          dataIndex: "debug_estimated_time",
          key: "debug_estimated_time",
          align: "right",
          ellipsis: true,
          width: 160,
          render:(text) => <div>{text && shiftTimeStamp(text)}</div>
        },
        {
          title: "实际调试工时",
          dataIndex: "debug_real_time",
          key: "debug_real_time",
          align: "right",
          ellipsis: true,
          width: 160,
          render:(text) => <div>{text && shiftTimeStamp(text)}</div>
        },

        {
          title: "缺工时",
          dataIndex: "debug_overtime",
          key: "debug_overtime",
          align: "right",
          ellipsis: true,
          width: 160,
          onCell:(record, _) => {
            return {
              className:record.debug_overtime > 0 ? "bgColor":"bgWhite"
            };
          },
          render:(text) => <div>{text>0?shiftTimeStamp(text):"-"}</div>
        },

        {
          title: "超工时",
          dataIndex: "debug_lack_of_time",
          key: "debug_lack_of_time",
          align: "right",
          ellipsis: true,
          width: 160,
          onCell:(record, _) => {
            return {
              className:record.debug_lack_of_time > 0 ? "bgColor":"bgWhite"
            };
          },
          render:(text) => <div>{text>0?shiftTimeStamp(text):"-"}</div>
        },

      ],
    },
    {
        title:"加工工时",
        key:"2",
        align:"center",
        children:[
            {
                title: "预计加工工时",
                dataIndex: "process_estimated_time",
                key: "process_estimated_time",
                align: "right",
                ellipsis: true,
                width: 160,
                render:(text) => <div>{text>0?shiftTimeStamp(text):text}</div>
              },
              {
                title: "实际加工工时",
                dataIndex: "process_real_time",
                key: "process_real_time",
                align: "right",
                ellipsis: true,
                width: 160,
                render:(text) => <div>{text>0?shiftTimeStamp(text):text}</div>
              },
              {
                title: "缺工时",
                dataIndex: "process_overtime",
                key: "process_overtime",
                align: "right",
                ellipsis: true,
                width: 160,
                onCell:(record, _) => {
                  return {
                    className:record.process_overtime > 0 ? "bgColor":"bgWhite"
                  };
                },
                render:(text) => <div>{text>0?shiftTimeStamp(text):"-"}</div>
              },
              {
                title: "超工时",
                dataIndex: "process_lack_of_time",
                key: "process_lack_of_time",
                align: "right",
                ellipsis: true,
                width: 160,
                onCell:(record, _) => {
                  return {
                    className:record.process_lack_of_time > 0 ? "bgColor":"bgWhite"
                  };
                },
                render:(text) => <div>{text>0?shiftTimeStamp(text):"-"}</div>
              },

        ]
    }, {
        title: "返修工时",
        dataIndex: "repair_total_duration",
        key: "repair_total_duration",
        align: "right",
        ellipsis: true,
        width: 160,
        render:(text) => <div>{text>0?shiftTimeStamp(text):"-"}</div>
      },
      {
        title: "总工时",
        dataIndex: "total_duration",
        key: "total_duration",
        align: "right",
        ellipsis: true,
        width: 160,
        render:(text) => <div>{text>0?shiftTimeStamp(text):"-"}</div>
      },
      {
        title: "操作",
        dataIndex: "options",
        key: "options",
        align: "center",
        // width: 150,
        fixed:"right",
        render: (_: any,record:any) =>  (
            <span
              className="table_btn_type"
            >
              <MyImage src={workApplicationDetails} title="报工详情" onClick={
                () => {
                  setOpen(true);
                  setObjectId(record.user_id);
                  setOptionName(record.user_name);
                }
              }></MyImage>
            </span>
        )
      }
  ];


  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getOperatorList(
        Object.assign(queryCondition,{
          start_date:queryCondition.start_date ? queryCondition.start_date : start,
          end_date:queryCondition.end_date?queryCondition.end_date:end,
        })
      ).then((res) => {
          if (res.code === 200) {
            res.data.data.map((i:any,index:number)=>{
              i.key = i.user_id;
              i.index = index + 1;
            })
            resolve(res.data);
           // 实际的数据得加工使用user_id作为object_id,否则没有key
         
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);

  const filterQueryData = (queryData: any) => {
    if(queryData.user_id) {
      queryData.user_id = Number(queryData.user_id);
    }
    if(queryData.station_id) {
      queryData.station_id = Number(queryData.station_id);
    }
    if(queryData.date) {
      queryData = filterDateParam(queryData,"date","start_date","end_date");
      setSelectDate({
        start_date:queryData.start_date,
        end_date:queryData.end_date
      })
    }
    return queryData
  }
  return (
    <div className="operator_statistics">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        scroll={true}
        scrollValue={{x:1860}}
        filterQueryData={filterQueryData}
      />
      {(
        <ModalContainer
          title={"报工详情"}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <div className="modal_btn">
              <Button
                title="关闭"
                onClick={() => {
                  setOpen(false)
                }}
                buttonStyle={{
                  backgroundColor: "#B8B8B8",
                }}
              ></Button>
              <ExportExcel
                dataSource={modalDataSource}
                columns={exportColumns}
                length={modalDataSource.length+1}
                tableName={
                  optionName + moment(new Date()).format("YYMMDDHHmmss")
                }
                btnName="导出"
                btnStyle={{
                  width: "130px",
                  height: "48px",
                  background: "#3E7BFA",
                  borderRadius: "5px 5px 5px 5px",
                }}
                isTwoHeader={false}
              ></ExportExcel>
            </div>,
          ]}
          width={"70%"}
          innerComponent={
          <ReportInfo
          object_id={objectId as number}
          setExportColumns={setExportColumns}
          setModalDataSource={setModalDataSource}
          selectDate={selectDate}/>}
        />
      )}
    </div>
  );
};
export default OperatorStatistics;

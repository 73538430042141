import React from "react";

// 用户信息的存储与修改
export interface UserInfo {
    //账号
    account?: string,
    //工号
    work_number?: number,
    //用户ID
    object_id?: number,
    //组织机构ID
    organization_id?: number,
    //组织机构
    organization?: string,
    //岗位ID
    station_id?: number,
    //岗位
    station?: string,
    //姓名
    name?: string,
    //性别
    sex?: number,
    //手机号
    phone?: string,
    //邮箱
    email?: string,
    //头像
    avatar?: string,
    //当前用户所拥有的角色
    role_ids?: any[],
    //所有路由
    allRoutes?: any[],
    //当前用户所拥有的路由
    authRoutes?: any[],
    //当前用户所拥有的菜单
    menus?: any[],
}

interface UserContextType {
    useInfo: UserInfo,
    setUserInfo: (useInfo: any) => void
}

export const UserContext = React.createContext<UserContextType>({
    useInfo: {},
    setUserInfo: () => {
    }
});
UserContext.displayName = 'UserContext'
export const Provider = UserContext.Provider
export const Consumer = UserContext.Consumer

// 创建外协，返厂转序单
import React from "react";
import ExternalTable from "./ExternalTable";
import "../index.less";
interface IProps {
  selectedRows?:any[],
  form?:any
}
const CreateExternal = (props: IProps) => {
  const {selectedRows,form} = props;
  return (
    <div className="create_external">
      <div className="header"></div>
      <div className="transfer_number">转序单号:自动生成</div>
      <ExternalTable
        selectedRows={selectedRows as any[]}
        form={form}
        is_return={false}
      ></ExternalTable>
    </div>
  );
};
export default CreateExternal;

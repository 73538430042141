import React, {
  useCallback,
  useRef,
  useState,
  useEffect
} from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { Form, message, Space, Modal } from "antd";
import ModalContainer from "../../../components/Modal";
import PrintDelivery from "../components/printDelivery";
import "../index.less";
import {
  formatTime,
  formatNumber,
  filterDateParam,
  getSystemInfoItem,
  transformObjectDelivery,
  transformObjectPrintDelivery,
  getFormData,
  filterData,
} from "../../../utils/index";
import MButton from "../../../components/MyButton";
import type { ColumnsType } from "antd/es/table";
import CreateOrEditDelivery from "../components/createOrEditDelivery";
import MyButton from "../../../components/MyButton";
import {
  submitDeliver,
  getOrderPartInfo,
  createOrEditDeliver,
  getOrderPartList
} from "../../../service";
import moment from "moment";
import ExportExcel from "../../../components/ExportTable/exportTable"
import Print from "react-print-html";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import MyImage from "../../../components/MyImage";
import delivered from "../../../assets/status/delivered.png";
import closed from "../../../assets/status/closed.png";
import distributed from "../../../assets/status/distributed.png";
import delivered1 from "../../../assets/delivered.png";

enum ModalTitle {
  CREATE = "创建发货单",
  PRINT = "打印发货单",
}
const DeliveryManagement = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // 保存创建发货单的数据
  const [createDeliverData, setCreateDeliverData] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  // 保存创建后被用来打印的数据
  const [printDeliverDetail,setPrintDeliveryDetail] = useState<any>({});
  // 保存发货单导出时的columns
  const [cols,setCols] = useState<any[]>([]);
  const [maxRow,setMaxRow] = useState<number>(0);
  const gRef = useRef<GRef>(null);
  const [modalForm] = Form.useForm();
  const [tableForm] = Form.useForm();
  const createDeliverRef = useRef();
  createDeliverRef.current = createDeliverData;
  const printDeliverDetailRef = useRef();
  printDeliverDetailRef.current = printDeliverDetail;
  const printRef = useRef();
  useEffect(()=>{
    getSystemInfoItem("发货单最大行数",(selectItem:any[])=>{
      
      setMaxRow(Number(selectItem[0].value))
    })
  },[])
  const formItem: QueryListItem[] = [
    {
      name: "客户名称",
      key: "trader_name",
      placeholder: "请输入客户名称",
    },
    {
      name: "订单号",
      key: "code",
      placeholder: "请输入订单号",
    },
    {
      name: "零件图号",
      key: "part_number",
      placeholder: "请输入零件图号",
    },
    {
      name: "交货日期",
      key: "date",
      rangePick: true,
      placeholder: "请选择交货日期",
    },
  ];
  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      ellipsis: true,
      fixed:true,
      render: (_: any, __: any, index: any) => <span>{index+1}</span>,
    },
    {
      title: "零件状态",
      dataIndex: "status",
      key: "status",
      align: "right",
      fixed: "left",
      width: 90,
      render: (text: number) => (
        <div>
        {text === 20 ? 
        <MyImage src={distributed} width={60}></MyImage> : text === 30 ? 
        <MyImage src={delivered} width={60}></MyImage> : 
        <MyImage src={closed} width={60}></MyImage>}
      </div>
      ),
    },  
    {
      title: "订单号",
      dataIndex: ["order", "code"],
      key: "code",
      align: "center",
      width: 130,
      ellipsis: true,
      fixed: true,
    },
    {
      title: "零件名称",
      dataIndex: [ "part", "name"],
      key: "part_name",
      align: "center",
      ellipsis: true,
      width: 130,
      fixed: true,
    },
    {
      title: "零件图号",
      dataIndex: ["part", "number"],
      key: "part_num",
      align: "center",
      ellipsis: true,
      width: 130,
      fixed: true,
    },
    {
      title: "客户",
      dataIndex: "tarder_name",
      key: "tarder_name",
      align: "center",
      width: 130,
      ellipsis: true,
    },
    {
      title: "订单数量",
      dataIndex: "number",
      key: "number",
      align: "right",
      ellipsis: true,
      width: 90,
      render: (item: Number) => (
        <div>{item && formatNumber(item.toString())}</div>
      ),
    },
    {
      title: "合同数量",
      dataIndex: ["contract_number"],
      key: "contract_number",
      align: "right",
      ellipsis: true,
      width: 90,
      render: (item: Number) => (
        <div>{item && formatNumber(item.toString())}</div>
      ),
    },
    {
      title: "加工内容",
      dataIndex: ["content"],
      key: "content",
      align: "center",
      ellipsis: true,
      width: 110,
    },
    {
      title: "交货日期",
      dataIndex: "finish_date",
      key: "finish_date",
      align: "center",
      ellipsis: true,
      width: 90,
      render: (item: String) => <div>{item && formatTime(item, false)}</div>,
    },
    {
      title: "入库数量",
      dataIndex: "ruku_num",
      key: "ruku_num",
      align: "right",
      ellipsis: true,
      width: 90,
      render: (item: Number) => (
        <div>{item && formatNumber(item.toString())}</div>
      ),
    },
    {
      title: "发货数量",
      dataIndex: "fahuo_num",
      key: "fahuo_num",
      align: "right",
      ellipsis: true,
      width: 90,
      render: (item: Number) => <div>{item && formatNumber(item.toString())}</div>,
    },
    {
      title: "库存",
      dataIndex: "kucun_num",
      key: "kucun_num",
      align: "right",
      ellipsis: true,
      width: 90,
      render: (item: Number) => <div>{item && formatNumber(item.toString())}</div>,
    },
    {
      title: "操作",
      dataIndex: "options",
      key: "options",
      align: "center",
      // width: 60,
      fixed:"right",
      render: (_: any, record: any) => {
        return (
          record.status === 20 && (
            <Space
              size={"large"}
              style={{ 
                cursor: "pointer",
                padding:"10px",
              }}
            >
              <MyImage src={delivered1} title="已交货" onClick={
                () => {
                  Modal.confirm({
                    title: "确定要将零件状态修改为已交货？",
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      let obj = {
                        object_id: record.object_id,
                      };
                      submitDeliver(obj)
                        .then((res) => {
                          if (res.code === 200) {
                            if (gRef.current) {
                              gRef.current.onFinish(true);
                            }
                            message.success("操作成功");
                          } else {
                            message.error(res.message);
                          }
                        })
                        .catch((err) => {
                          message.error(err);
                        });
                    },
                    onCancel() {},
                  });
                }
              }></MyImage>
            </Space>
          )
        );
      },
    },
  ];
  const ExtraComponent = React.memo((): React.ReactElement => {
    return (
        <MyButton
          title="发货"
          onClick={() => {
            if (selectedRowKeys.length === 0) {
              message.warning("请选择要发货的订单零件");
              return;
            }
            //  ② 校验选中行的库存数量大于0 ，否则提示：只能对库存数量大于0的订单零件进行发货。
            let arr = selectedRows.filter(i => {
              return i.kucun_num > 0
            });
            if(arr.length !== selectedRows.length) {
              message.warning("只能对库存数量大于0的订单零件进行发货");
                return;
            }

            // 如果存在多个客户的订单零件，提示：只能对相同客户的订单零件进行发货。
            if (selectedRows.length > 1) {
              let arr = selectedRows.map((item) => {
                return (
                  item.order.trader_id && item.order.trader_id
                );
              });
              if (Array.from(new Set(arr)).length !== 1) {
                message.warning("只能对相同客户的订单零件进行发货");
                return;
              }
            }
              if (selectedRowKeys.length > maxRow) {
                message.warning(
                  `发货单最大行数为${maxRow}条,所选零件行数不能超过该值`
                );
                return;
              }
            let orderpart_ids1 = selectedRows.map((item) => {
              return item.object_id;
            });
            getOrderPartInfo({
              orderpart_ids: JSON.stringify(orderpart_ids1),
            })
              .then((res) => {
            
                if (res.code === 200) {
                  if(res.data){
                    res.data.part_data && res.data.part_data.map((item:any,index:number) => {
                      item.index = index + 1
                    })
                    setCreateDeliverData(res.data);
                  }
                  openModal(ModalTitle.CREATE);
                }
              })
              .catch((err) => {
                message.error(err);
              });
          }}
          buttonStyle={{
            width: "48px",
            height: "28px",
            background: "#3E7BFA",
            marginRight:0,
          }}
        ></MyButton>
    );
  });

  const openModal = (modalName: ModalTitle,printData?:any) => {
    setOpen(true);
    setModalTitle(modalName);
    setTimeout(() => {
      if (modalName === "创建发货单") {
        setInnerComponent(
          <CreateOrEditDelivery
            isEdit={false}
            tableForm={tableForm}
            modalForm={modalForm}
            createDeliverData={createDeliverRef.current}
            setCreateDeliverData={setCreateDeliverData}
          />
        );
      } else if (modalName === "打印发货单") {
        setInnerComponent(<PrintDelivery ref={printRef} printData={printData} setCols={setCols}/>);
      }
    }, 0);
  };

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getOrderPartList(Object.assign({part_status:50},queryCondition))
        .then((res) => {
          if (res.code === 200) {
            resolve(res.data); 
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
    });
  }, []);

  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    return queryData;
  };
  const onCancel1 = (title: any) => {
    //去除勾选的数据
    if (title === ModalTitle.PRINT && selectedRowKeys.length > 0) {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }
    setModalTitle(ModalTitle.CREATE);
    setInnerComponent(<div/>);
    setOpen(false);
}
  const rowClassName = (record: any) => {
  if (record.status === 30 && record.fahuo_num === 0) {
    return "color"
  }
  return "white";
  };
  return (
    <div className="delivery_management">
      <GeneralManagement
        formList={formItem}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        ExtraComponent={<ExtraComponent />}
        scroll={true}
        scrollValue={{x:1450}}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedRows={setSelectedRows}
        filterQueryData={filterQueryData}
        rowClassName={rowClassName}
      />
      {modalTitle && (
        <ModalContainer
          width={modalTitle === "创建发货单" ? "60%" : "794px"}
          title={modalTitle === "创建发货单" ? "创建发货单" : ""}
          open={open}
          onCancel={()=>{
            setOpen(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
          }}
          footer={[
            modalTitle === "创建发货单" ? (
              <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false);
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="创建"
                  onClick={() => {
                    modalForm.validateFields().then((res) => {
                        tableForm.validateFields().then((res) => {
                       
                            let obj: any = transformObjectDelivery(
                              tableForm.getFieldsValue()
                            );
                          
                            obj.trader_id = createDeliverData.trader_id;
                            obj.contract_code =modalForm.getFieldsValue().contract_code;
                            obj.contract_date = modalForm.getFieldsValue().contract_date;
                            obj.title = modalForm.getFieldsValue().title;
                            obj.content = tableForm.getFieldsValue().content;
                            obj.order_code = tableForm.getFieldsValue().order_code;
                            obj.part_data = JSON.stringify(obj.work);
                            delete obj.work;
                            if(JSON.parse(obj.part_data).length === 0){
                              message.warning("必须添加一条订单零件")
                              return;
                            }
                            let formData = getFormData(filterData(obj));
                            
                           
                            let obj1: any = transformObjectPrintDelivery(
                              tableForm.getFieldsValue()
                            );
                           
                            obj1.contract_code =modalForm.getFieldsValue().contract_code;
                            obj1.contract_date = modalForm.getFieldsValue().contract_date;
                            obj1.title = modalForm.getFieldsValue().title;
                            obj1.fahuo_date = moment(modalForm.getFieldsValue().fahuo_date).format("YYYY/MM/DD");
                            createOrEditDeliver(formData).then(res=>{
                            
                              if(res.code === 200){
                          
                                // 在此处调用发货单详情有误，应在发货单管理页面调用,此处直接将form收集的数据传给打印的组件
                                createDeliverData.part_data.map((item:any) => {
                                  obj1.work &&
                                    obj1.work.map((w: any, index:number) => {
                                      w.index = index + 1
                                      if (item.object_id === w.id) {
                                        w.name= item.part.name;
                                        w.part_number = item.part.number;
                                      }
                                    });
                                 
                                });
                                if(gRef.current){
                                  gRef.current.onFinish(true)
                                }
                                
                                setPrintDeliveryDetail(obj1);
                                setSelectedRowKeys([]);
                                setSelectedRows([]);
                                openModal(ModalTitle.PRINT,obj1);  
                              } else {
                                message.error(res.message)
                              }
                              
                            }).then((err:any)=>{
                              console.log("err",err)
                            }) 
                          }).catch((err) => {
                            console.log("发货表格校验失败", err);
                          });
                      }).catch((err) => {
                        console.log("发货表单校验失败", err);
                      });
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            ) : (
              <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false)
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="打印"
                  onClick={() => {
                    if(printRef.current){
                      Print(printRef.current);
                      onCancel1(modalTitle)
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
                  <ExportExcel
                    columns={cols as any}
                    dataSource={printDeliverDetail.work}
                    btnName="导出"
                    tableName="发货单"
                    isTwoHeader={false}
                    length={maxRow}
                    lastRowLength={maxRow+5}
                    is_bg_null={true}
                    btnStyle={{
                      width: "130px",
                      height: "48px",
                      backgroundColor: "#3E7BFA",
                    }}
                    is_delivery_table={true}
                    otherData={[
                      {
                        rows1: ["合同日期",printDeliverDetail.contract_date],
                        rows2: ["合同编号",printDeliverDetail.contract_code,"日期",printDeliverDetail.fahuo_date],
                        rows3: ["加工项目",printDeliverDetail.content,"任务制号",printDeliverDetail.order_code],
                        lastRow: [
                          "交货人：",
                          "收货人：",
                        ],
                      },
                      { title: [printDeliverDetail.title] },
                    ]}
                  ></ExportExcel>
              </div>
            ),
          ]}
          innerComponent={innerComponent}
        ></ModalContainer>
      )}
    </div>
  );
};
export default DeliveryManagement;

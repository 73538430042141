import React, {useCallback, useRef, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Space, Row, Col, Select} from "antd";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {filterData, formatNumber, getFormData, showError, taskList, toastMessage} from "../../../utils";
import DeleteItem from "../../../components/DeleteItem";
import ModalContainer from "../../../components/Modal";
import '../index.less';
import {ColumnsType} from "antd/es/table";
import moment from "moment";
import {
    traderInfo, traderInfoCreate, traderInfoDelete, traderInfoDetail, traderInfoUpdate
} from "../../../service";

enum ModalTitle {
    ADD = '新增客商信息',
    VIEW = '查看客商信息',
    EDIT = '编辑客商信息',
    DELETE = '系统确认',
}


interface AddOrEditCategoryProps {
    formDisabled?: boolean
}

const Businessman = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement>(<div/>);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const typeList = [
        {id: 10, name: '客户'},
        {id: 20, name: '供应商'},
        {id: 30, name: '客商'},
    ]
    const formList: QueryListItem[] = [
        {
            key: 'name',
            name: '名称',
            placeholder: '请输入客商名称'
        },
        {
            key: 'type',
            name: '类别',
            placeholder: '供应商',
            options: typeList
        },
    ]

    const columns: ColumnsType<any> = [
        {
            title: '客商代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '客商名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '类别',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (value: number) => {
                return typeList.map(e => {
                    if (e.id === value) {
                        return <span key={e.id}>{e.name}</span>
                    }
                })
            }
        },
        {
            title: '联系人',
            dataIndex: 'link_name',
            key: 'person',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '联系方式',
            dataIndex: 'contact',
            key: 'contact',
            align: 'center',
            width: 200,
            ellipsis: true
        },

        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 300,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            ellipsis: true,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.VIEW, item)
                        }}>查看</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, item)
                        }}>编辑</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, item)
                        }}>删除</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEditCategory = React.memo((props: AddOrEditCategoryProps): React.ReactElement => {
        const {formDisabled} = props
        return (
            <Form colon={false} form={form} className={'basic-form form-margin'} disabled={formDisabled}>
                <Form.Item label={'客商代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '客商代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'客商名称'} name={'name'} rules={formDisabled ? [] : [
                    {required: true, message: '请输入客商名称!'},
                    {
                        type: "string",
                        max: 32,
                        message: '客商名称为不得大于32位！'
                    }
                ]}>
                    <Input placeholder={'请输入名称'}/>
                </Form.Item>
                <Form.Item label={'客商类别'} name={'type'} rules={formDisabled ? [] : [
                    {required: true, message: '请选择客商类别!'},
                ]}>
                    <Select placeholder={'请选择客商类别'}>
                        {
                            typeList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'联系人'} name={'link_name'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                        message: '联系人由不大于32位的汉字、字母和数字组成!'
                    }
                ]}>
                    <Input placeholder={'请输入名称'}/>
                </Form.Item>
                <Form.Item label={'联系方式'} name={'contact'} rules={[
                    {
                        type: "string",
                        max: 32,
                        message: '联系方式为不得大于32位！'
                    }
                ]}>
                    <Input placeholder={'请输入联系人方式，邮箱或电话'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remark'} rules={[
                    {
                        max: 200,
                        message: '备注不得大于200位！'
                    }
                ]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditCategory/>)
        } else if (title === ModalTitle.EDIT) {
            traderInfoDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        name: res.data.name,
                        type: res.data.type,
                        link_name: res.data.link_name,
                        contact: res.data.contact,
                        remark: res.data.remark
                    })
                    setInnerComponent(<AddOrEditCategory/>);
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.VIEW) {
            traderInfoDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        name: res.data.name,
                        type: res.data.type,
                        link_name: res.data.link_name,
                        contact: res.data.contact,
                        remark: res.data.remark
                    })
                    setInnerComponent(<AddOrEditCategory formDisabled={true}/>);
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                traderInfoCreate(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                traderInfoUpdate(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            if (selectedRowKeys.length) {
                //批量删除
                const formData = getFormData(filterData({ids: `[${selectedRowKeys}]`}))
                traderInfoDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                        setSelectedRowKeys([])
                    } else {
                        showError(res.message);
                    }
                })
            } else {
                //单个删除
                const formData = getFormData(filterData({ids: `[${selectedItem.object_id}]`}))
                traderInfoDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                    } else {
                        showError(res.message);
                    }
                })
            }
        }
    }

    const onCancel = () => {
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            form.resetFields();
        }
        //去除勾选的数据
        if (modalTitle === ModalTitle.DELETE && selectedRowKeys.length > 0) {
            setSelectedRowKeys([])
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(<div/>);
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => {
        const ExportJsonExcel = require("js-export-excel");

        const exportExcel = () => {
            if (gRef.current) {
                if (gRef.current.exportData) {
                    gRef.current.exportData().then(res => {
                        let exportData: any[] = [];
                        res.map((e: any) => {
                            const item = typeList.find(t => t.id === e.type)
                            exportData.push({
                                code: e.code,
                                name: e.name,
                                type: item ? item.name : '',
                                link_name: e.link_name,
                                contact: e.contact,
                                remark: e.remark,
                            })
                        })
                        const option: any = {};
                        option.fileName = '客商信息' + moment(new Date()).format('YYYYMMDD');
                        option.datas = [
                            {
                                sheetData: exportData,
                                sheetName: "sheet",
                                sheetFilter: ['code', 'name', 'type', 'link_name', 'contact', 'remark'],
                                sheetHeader: ['客商代码', '客商名称', '类别', '联系人', '联系方式', '备注'],
                                columnWidths: [20, 20, 20, 20, 20, 20],
                            }
                        ];
                        const toExcel = new ExportJsonExcel(option);
                        toExcel.saveExcel();
                    })
                }
            }
        }

        return (
            <div>
                <Button className={'mes-user-add'} style={{marginRight: 20}} onClick={() => {
                    openModal(ModalTitle.ADD, null)
                }}>新增</Button>
                <Button className={'mes-batch-deletion'} style={{marginRight: 20}} onClick={() => {
                    if (selectedRowKeys.length) {
                        openModal(ModalTitle.DELETE, null)
                    } else {
                        toastMessage('请选择要删除的客商数据')
                    }
                }}>批量删除</Button>
                <Button className={'mes-export'} style={{marginRight: 20}} onClick={exportExcel}>导出</Button>
            </div>
        )
    })

    const filterQueryData = (queryData: any) => {
        if (queryData.type) {
            queryData.type = `[${queryData.type}]`
            return queryData
        } else {
            return queryData
        }
    }

    const getDataAsync = useCallback((queryCondition: API.traderInfo & API.pageInfo): Promise<any> => {
        return new Promise(resolve => {
            traderInfo(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.VIEW) {
            return (<div>
                <Button onClick={() => onCancel()}>关闭</Button>
            </div>)
        }
    }

    const setWidth = () => {
        if (modalTitle !== ModalTitle.DELETE) {
            return '40%'
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<ExtraComponent/>}
                               getDataAsync={getDataAsync} ref={gRef} filterQueryData={filterQueryData}
                               scrollValue={{x: 1600}}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open} width={setWidth()}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel()}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default Businessman;

import { message } from "antd";
import {systemInfo,getUserList,getUserListForSelect,getStationList,workProcess,deviceInfo,defectReason} from "../service";
import moment,{Moment} from "moment";


//删除空元素
export const filterData = (data: object) => {
  const params: any = data;
  // Object.entries() 方法返回一个给定对象自身可枚举属性的键值对数组。
  for (let [key, value] of Object.entries(data)) {
    if (value === null || value === undefined || value === "") {
      delete params[key];
    }
  }
  return params;
};

//过滤时间参数
/**
 *
 * @param values 表单元素对象
 * @param param 需要过滤的参数名称日期
 * @param startParams 过滤好的参数开始日期
 * @param endParam 过滤好的参数结束日期
 */
export const filterDateParam = (values:any,param:string,startParams:string,endParam:string):Object => {
  if (Object.keys(values).includes(param)) {
    let temp = {
      ...values,
      [startParams]: moment(values[param][0]).format('YYYY-MM-DD'),
      [endParam]: moment(values[param][1]).format('YYYY-MM-DD'),
    }
    delete temp[param];
    return temp
  } else {
    return {...values}
  }
}

//过滤订单部分的时间参数
export const filterQueryDataOrder = (queryData: any) => {
  if (queryData.deliveryDate) {
    queryData = filterDateParam(queryData, 'deliveryDate', 'finish_start_time', 'finish_end_time')
  }
  if (queryData.createDate) {
    queryData = filterDateParam(queryData, 'createDate', 'create_start_time', 'create_end_time')
  }
  if (queryData.issueDate) {
    queryData = filterDateParam(queryData, 'issueDate', 'send_start_time', 'send_end_time')
  }
  return queryData;
}

export const toastMessage = (msg: string) => {
  message.warn(msg)
}

//将参数封装成FormData格式
export const getFormData = (values: object) => {
  const formData = new FormData();
  Object.entries(values).map((e) => {
    formData.append(e[0], e[1]);
  });
  return formData;
};

//封装当前用户所拥有的菜单
export const getMenus = (values: any) => {
  const menus: any[] = [];
  values.map((e: any) => {
    if (!e.parent_id) {
      e.children = [];
    }
  });
  values.map((e: any) => {
    if (e.is_have) {
      if (e.parent_id) {
        const parent = values.find(
          (item: any) => item.object_id === e.parent_id
        );
        parent.children = parent.children || [];
        parent.children.push({
          ...e,
          is_valid: e.is_valid.toString(),
        });
      } else {
        menus.push({
          ...e,
          is_valid: e.is_valid.toString(),
        });
      }
    }
  });
  const getSort = (array: any) => {
    array.sort((a: any, b: any) => {
      if (a.object_id < b.object_id) {
        return -1;
      } else {
        return 1;
      }
    });
  };
  getSort(menus);
  menus.map((e) => {
    if (e.children) {
      getSort(e.children);
    }
  });
  return menus;
};

//封装所有的菜单
export const getAllMenus = (values: any) => {
  const menus: any[] = [];
  values.map((e: any) => {
    if (!e.parent_id) {
      e.children = [];
    }
  });
  values.map((e: any) => {
    if (e.parent_id) {
      const parent = values.find((item: any) => item.object_id === e.parent_id);
      parent.children = parent.children || [];
      parent.children.push({
        ...e,
        key: e.object_id.toString(),
        is_valid: e.is_valid.toString(),
      });
    } else {
      menus.push({
        ...e,
        key: e.object_id.toString(),
        is_valid: e.is_valid.toString(),
      });
    }
  });
  const getSort = (array: any) => {
    array.sort((a: any, b: any) => {
      if (a.object_id < b.object_id) {
        return -1;
      } else {
        return 1;
      }
    });
  };
  getSort(menus);
  menus.map((e) => {
    if (e.children) {
      getSort(e.children);
    }
  });
  return menus;
};

//封装组织机构树结构
export const getTreeData = (values: any[]) => {
  const getArray = (array: any[]) => {
    let temp: any[] = [];
    array.map((e) => {
      if (e.children) {
        temp.push({
          title: e.organization_name,
          value: e.object_id,
          key: e.object_id,
          children: getArray(e.children),
        });
      } else {
        temp.push({
          title: e.organization_name,
          value: e.object_id,
          key: e.object_id,
        });
      }
    });
    return temp;
  };
  return getArray(values);
};

export interface indexType {
  [key: number]: string;
}

export const sexes: indexType = {
  0: "女",
  1: "男",
};

export const statuses: indexType = {
  0: "已禁用",
  1: "已启用",
};

export const reportStatus: indexType = {
  30: "已派工",
  40: "处理中",
  50: "已完成",
};

export const showError = (text: string) => {
  message.error(text);
};

//数量、金额等分位显示
export const formatNumber = (value: string) =>
  value.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");

// 保留两位小数的千分位展示
export const moneyFormat = (num: any) => {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
// 不保留小数的千分位展示
export const numberFormat = (num: Number) => {
  return Number(num)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};

// 格式化时间 YYYY/MM/DD或者YYYY/MM/DD HH:MM
export const formatTime = (date: String, is_save_time: boolean) => {
  let str:String = '';
  if (is_save_time) {
    // "2023-04-07 10:55:32"
    str = date.split("-").join("/").substring(0, 16);
  } else {
    str = date.split("-").join("/").substring(0, 10);
  }
  return str;
};
// 传入的时间格式为YYYY-MM-DD时，时间格式化
export const formatTimeShort = (date: String) => {
  let str = date.split("-").join("/")
  return str;
};

export const booleanType: indexType = {
    0: '否',
    1: '是'
}

//订单状态
export const statusList = [
  {id: 20, name: '已下发', src: require('../assets/status/distributed.png')},
  {id: 30, name: '已交货', src: require('../assets/status/delivered.png')},
  {id: 40, name: '已关闭', src: require('../assets/status/closed.png')},
]

export const orderStatusList = [
  {id: 10, name: '未下发', src: require('../assets/status/notIssued.png')},
  {id: 20, name: '已下发', src: require('../assets/status/distributed.png')},
]

//任务状态
export const taskList = [
  {id: 10, name: '未下达', src: require('../assets/status/notReleased.png')},
  {id: 20, name: '已下达', src: require('../assets/status/released.png')},
  {id: 30, name: '处理中', src: require('../assets/status/processing.png')},
  {id: 40, name: '已完成', src: require('../assets/status/completed.png')},
]

//供料方式
export const wayList = [
  {id: 10, name: '带料'},
  {id: 20, name: '购料'},
]

//加工分类
export const classificationList = [
  {id: 10, name: '量产加工'},
  {id: 20, name: '整件外协'},
  {id: 30, name: '小批量加工'},
]

// 检验类别
export const InspectionCategory = [
  { id: 10, name: "首件自检" },
  { id: 20, name: "定时自检" },
  { id: 30, name: "首件检验" },
  { id: 40, name: "外协检验" },
  { id: 50, name: "终检" },
  { id: 60, name: "巡检" },
]

// 检验状态
export const InspectionStatus =
[
{ id: 10, name: "未开始" },
{ id: 20, name: "处理中" },
{ id: 30, name: "已完成" },
]

// 零件状态
export const part_status =[
  // { name: "未下发", id: 10 },
  { name: "已交货", id: 30 },
  { name: "已下发", id: 20 },
  { name: "已关闭", id: 40 },
  // { name: "请选择零件状态", id: 50 },
]

// 获取系统参数的某一项
export const getSystemInfoItem = (params_name:string, callback:(selectedItem:any[])=>void) => {
  systemInfo({
    page_num:1,
    page_size:10000000
  }).then((res:any) =>{
    const selectedItem = res.data.data?.filter( (item:any)=> {
      return item.param_name === params_name
    })
    callback(selectedItem)
  }).catch((err:any) => {
    message.error(err)
  })
}
// 获取未删除的用户列表
export const getUsers = (callback:(users:{ id: number; name: string }[])=>void) => {
  getUserList()
      .then((res) => {
        if (res.code === 200) {
         
          const newArr =
            // res.data.data.length !== 0 &&
            res.data.data.map((item: any) => {
              return { id: item.object_id, name: item.name };
            });
           
            callback(newArr)
        }
      }).catch((err) => {
        message.error(err)
      });
}
// 获取下拉框专用用户列表
export const getUsersForSelect = (callback:(users:{ id: number; name: string }[])=>void) => {
  getUserListForSelect()
      .then((res) => {
        if (res.code === 200) {
          
          const newArr =
            // res.data.length !== 0 &&
            res.data.map((item: any) => {
              return { id: item.object_id, name: item.name };
            });
          
            callback(newArr)
        }
      }).catch((err) => {
        message.error(err)
      });
}
// 获取岗位信息
export const getStations = (callback:(stations:{ id: number; name: string }[])=>void) => {
  getStationList({
    page_num:1,
    page_size:10000000
  }).then((res) => {
        if (res.code === 200) {
         
          const newArr =
            // res.data?.data.length !== 0 &&
            res.data?.data.map((item: any) => {
              return { id: item.object_id, name: item.station_name };
            });
            
            callback(newArr as { id: number; name: string }[])
        }
      }).catch((err) => {
        message.error(err)
      });
}
// 获取未删除的工序信息
export const getWorkprocesss = (callback:(works:{ id: number; name: string }[])=>void) => {
  workProcess().then((res) => {
        if (res.code === 200) {
        
          const newArr =
            // res.data?.data?.length !== 0 &&
            res.data?.data?.map((item: any) => {
              return { id: item.object_id, name: item.work_process_name };
            });
          
            callback(newArr as { id: number; name: string }[])
        }
      }).catch((err) => {
        message.error(err)
      });
}
// 获取未删除的工序信息------label/value
export const getWorkprocesss1 = (callback:(errors:{ label:string,value:number }[])=>void) => {
  workProcess().then((res) => {
        if (res.code === 200) {
         
          const newArr =
            // res.data?.data?.length !== 0 &&
            res.data?.data?.map((item: any) => {
              return { label: item.work_process_name, value:item.object_id  };
            });
           
            callback(newArr as { label: string; value: number }[])
        }
      }).catch((err) => {
        message.error(err)
      });
}
// 获取设备信息
export const getDeviceList = (callback:(devices:{ id: number; name: string }[])=>void) => {
  deviceInfo().then((res) => {
        if (res.code === 200) {
        
          const newArr =
            // res.data?.data?.length !== 0 &&
            res.data?.data?.map((item: any) => {
              return { id: item.object_id, name: item.name };
            });
           
            callback(newArr as { id: number; name: string }[])
        }
      }).catch((err) => {
        message.error(err)
      });
}
// 获取不良原因列表
export const getErrorList = (callback: (errors: { label: string, value: number }[]) => void) => {
  defectReason().then((res) => {
    if (res.code === 200) {

      let newArr: { label: any; value: any }[] = []
      if (res.data.data?.length) {
        newArr =
            res.data?.data?.map((item: any) => {
              return {label: item.reason, value: item.object_id};
            });
      }


      // label: string;
      // value: number;
      callback(newArr as { label: string; value: number }[])
    }
  }).catch((err) => {
    message.error(err)
  });
}
// 转化form.getFieldsValues获得的参数
export function transformObject(obj: { [key: string]: any }): { [key: string]: any } {
  const work = Object.keys(obj)
    .filter((key) => /^\d+-description$/.test(key))
    .map((key) => {
      const id = Number(key.split("-")[0]);
      const description = obj[`${id}-description`];
      const remark = obj[`${id}-remark`];
      return { id, description, ...(remark && { remark }) };
    });
  const {
    batch_production,
    finish_date,
    name,
    project,
    record_date,
    trader,
    ...rest
  } = obj;
  return {
    work,
    batch_production,
    finish_date,
    name,
    project,
    record_date,
    trader,
    ...rest,
  };
}
// 发货单页面转换params的函数
// 转化form.getFieldsValues获得的参数
export function transformObjectDelivery(obj: { [key: string]: any }): { [key: string]: any } {
  const work = Object.keys(obj)
    .filter((key) => /^\d+-precesses$/.test(key))
    .map((key) => {
      const object_id = Number(key.split("-")[0]);
      const precesses = obj[`${object_id}-precesses`];
      const number = obj[`${object_id}-number`];
      let remark = obj[`${object_id}-remark`];
      if(!remark){
        remark = ""
      }
      return { object_id, precesses,number, remark };
    });
  const { contract_code, contract_date, title, order_code,content, ...rest } = obj;
  return {
    work,
    contract_code,
    contract_date,
    title,
    order_code,
    ...rest,
    content
  };
}

export function transform(obj: { [key: string]: any }): { [key: string]: any } {
  const work = Object.keys(obj)
    .filter((key) => /^\d+-+\d+-precesses$/.test(key))
    .map((key) => {
      const object_id = Number(key.split("-")[0]);
      const deliver_id = Number(key.split("-")[1]);
      const precesses = obj[`${object_id}-${deliver_id}-precesses`];
      const number = obj[`${object_id}-${deliver_id}-number`];
      let remark = obj[`${object_id}-${deliver_id}-remark`];
      if(!remark){
        remark = ""
      }
      return { object_id, precesses,number, remark };
    });
  const { contract_code, contract_date, title, order_code,content, ...rest } = obj;
  return {
    work,
    contract_code,
    contract_date,
    title,
    order_code,
    ...rest,
    content
  };
}

// 发货单页面取得整个打印发货单表格的数据
export function transformObjectPrintDelivery(obj: { [key: string]: any }): { [key: string]: any } {
  const work = Object.keys(obj)
    .filter((key) => /^\d+-precesses$/.test(key))
    .map((key) => {
      const id = Number(key.split("-")[0]);
      const name = obj[`${id}-name`];
      const part_number = obj[`${id}-partNumber`];
      const precesses = obj[`${id}-precesses`];
      const number = obj[`${id}-number`];
      let remark = obj[`${id}-remark`];
      if(!remark){
        remark = ""
      }
      return { id, precesses,name,part_number,number, remark };
    });
  const { contract_code, contract_date, title, order_code,content, ...rest } = obj;
  return {
    work,
    contract_code,
    contract_date,
    title,
    order_code,
    content,
    ...rest,
  };
}
// 转序单页面获得整个表格打印转序单数据的函数
export function transformPrint(obj: { [key: string]: any }): { [key: string]: any } {
  const work = Object.keys(obj)
    .filter((key) => /^\d+-description$/.test(key))
    .map((key) => {
      const id = Number(key.split("-")[0]);
      const partName = obj[`${id}-partName`];
      const partNumber = obj[`${id}-partNumber`];
      const number = obj[`${id}-number`];
      const description = obj[`${id}-description`];
      const remark = obj[`${id}-remark`];
      return { id, description,partName,partNumber,number, ...(remark && { remark }) };
    });
  const {
    batch_production,
    finish_date,
    name,
    project,
    record_date,
    trader,
    partName,
    partNumber,
    number,
    ...rest
  } = obj;
  return {
    work,
    batch_production,
    finish_date,
    name,
    project,
    record_date,
    trader,
    partName,
    partNumber,
    number,
    ...rest,
  };
}

/**
 * 将秒转换为时分秒的格式
 * @param sec 秒
 */
export const convertSecToHHmmss = (sec: number) => {
  let currentTime = moment.duration(sec, "seconds");

  // 将获取到的下轮间隔秒数转换成时分秒
  return moment({
    h: currentTime.hours(),
    m: currentTime.minutes(),
    s: currentTime.seconds(),
  }).format("HH小时mm分ss秒");
}

export const shiftTimeStamp = (time: number) => {
  function f_m_dispose(min: number, sec: number) { // 分秒处理函数
    if (min < 10 && sec < 10) {
      // "0" +
      return min + "分" + "0" + sec + "秒"; // 如果分和秒都小于10，则前面都加入0
    } else if (min < 10 && sec >= 10) {
      // "0" +
      return min + "分" + sec + "秒"; // 如果分小于10，秒大于10，则给分前面加0
    } else if (min >= 10 && sec < 10) {
      return min + "分" + "0" + sec + "秒"; // 如果分大于10，秒小于10，则给秒前面加0
    } else {
      return min + "分" + sec + "秒"; // 如果分秒都大于10，则直接return
    }
  }

  let hour = Number.parseInt((time / 3600).toString());
  let min = Number.parseInt(((time - hour * 3600) / 60).toString());
  let sec = time - (hour * 3600) - (min * 60);
  if (!hour) {
    if (!min) {
      //小时为0、分钟为0
      return sec + "秒";
    } else {
      //小时为0，分钟不为0
      return f_m_dispose(min, sec);
    }
  } else { // 小时大于0的处理
    return hour + "小时" + f_m_dispose(min, sec);
  }
}
export const shiftTimeStampWithDay = (time: number) => {
  function f_m_dispose(min: number, sec: number) { // 分秒处理函数
    if (min < 10 && sec < 10) {
      // "0" +
      return min + "分" + "0" + sec + "秒"; // 如果分和秒都小于10，则前面都加入0
    } else if (min < 10 && sec >= 10) {
      // "0" +
      return min + "分" + sec + "秒"; // 如果分小于10，秒大于10，则给分前面加0
    } else if (min >= 10 && sec < 10) {
      return min + "分" + "0" + sec + "秒"; // 如果分大于10，秒小于10，则给秒前面加0
    } else {
      return min + "分" + sec + "秒"; // 如果分秒都大于10，则直接return
    }
  }

  let day = Number.parseInt((time/86400).toString());
  let hour = Number.parseInt(((time - day*86400) / 3600).toString());
  let min = Number.parseInt(((time - day*86400 - hour * 3600) / 60).toString());
  let sec = time - (day*86400) - (hour * 3600) - (min * 60);
  if(!day){
    if (!hour) {
      if (!min) {
        //小时为0、分钟为0
        return sec + "秒";
      } else {
        //小时为0，分钟不为0
        return f_m_dispose(min, sec);
      }
    } else { // 小时大于0的处理
      return hour + "小时" + f_m_dispose(min, sec);
    }
  } else {
    const hourStr = hour < 10 ? '0' + hour : hour+'';
    const minStr = min < 10 ? '0' + min : min + '';
    const secStr = sec < 10 ? '0' + sec : sec + '';
    return day + '天' + hourStr + '小时' + minStr + '分' + secStr + '秒';
  }
}

// 把秒数转化成HH:mm:ss
export function formatSeconds(value:number) {
  let secondTime:any = parseInt(value.toString());
  let minuteTime:any = 0;
  let hourTime:any = 0;
  if (secondTime >= 60) {
    minuteTime = parseInt((secondTime / 60).toString());
    secondTime = parseInt((secondTime % 60).toString());
    if (minuteTime >= 60) {
      hourTime = parseInt((minuteTime / 60).toString());
      minuteTime = parseInt((minuteTime % 60).toString());
    }
  }
  // 补0
  hourTime = hourTime < 10 ? "0" + hourTime : hourTime;
  minuteTime = minuteTime < 10 ? "0" + minuteTime : minuteTime;
  secondTime = secondTime < 10 ? "0" + secondTime : secondTime;
  let res = hourTime + ":" + minuteTime + ":" + secondTime;
  return res;
}
// 把HH:mm:ss转化回秒数
export function toSeconds(value:Moment) {
  let h = value.hours(); 
  let m = value.minutes();
  let s = value.seconds();
  return h*3600 + m *60 + s;
}




//taskList
export const fontColor = (value:number) => {
  if(value === 10){
    return {
      color:'#EB4420'
    }
  }else if(value === 20){
    return {
      color:'#3E7BFA'
    }
  }else if(value === 30){
    return {
      color:'#FBCE9D'
    }
  }else if(value === 40){
    return {
      color:'#06C270'
    }
  }
}
export function uuid() {
  var s = [];
  var hexDigits:any = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}





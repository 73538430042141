import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { getUsersForSelect,getSystemInfoItem } from "../../../utils/index";
import { Tooltip, message } from "antd";
import ModalContainer from "../../../components/Modal";
import "../index.less";
import {
  formatTime,
  filterDateParam,
} from "../../../utils/index";
import { ColumnsType } from "antd/es/table";
import MButton from "../../../components/MyButton";
import ShowExternal from "../components/ShowExternal";
import { getRecordList,getRecordDetail } from "../../../service";
import Print from "react-print-html";
import moment from "moment";
import PrintExternal from "../components/PrintExternal";
import ExportExcel from "../../../components/ExportTable/exportTable";
import show from "../../../assets/show.png";
import MyImage from "../../../components/MyImage";

enum ModalTitle {
  SHOW = "查看",
  Print = "打印"
}

const ExternalAssistanceTransfer = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  const [optionMans, setOptionMans] = useState<{ id: number; name: string }[]>([]);
  const [printData,setPrintData] = useState<any>({});
  const [cols,setCols] = useState<any[]>();
  const [max_row,setMaxRow] = useState<number>(0);
  const gRef = useRef<GRef>(null);
  const printRef = useRef<any>();
  printRef.current = printData;
  const printTable = useRef<any>();
  const [formList, setFormList] = useState<QueryListItem[]>([
    {
      key: "number",
      name: "转序单号",
      placeholder: "请输入转序单号",
    },
    {
      key: "batch_production",
      name: "生产制号",
      placeholder: "请输入生产制号",
    },
    {
      key: "trader",
      name: "承制单位",
      placeholder: "请输入承制单位",
    },
    {
      key: "user_id",
      name: "外协员",
      placeholder: "请选择外协员",
      options: optionMans,
    },
    {
      key: "date",
      name: "转序日期",
      placeholder: "请选择转序日期",
      rangePick: true,
    },
  ]);
  useEffect(() => {
    getUsersForSelect((users: { id: number; name: string }[]) => {
      setOptionMans(users);
      let newFormList = formList;
      newFormList[3].options = users;
      setFormList(newFormList);
    });
    getSystemInfoItem("转序单最大行数", (selectedItem) => {
      let max_num = Number(selectedItem[0].value);

      setMaxRow(max_num);
    });
  }, []);
  const openModal = (modalTitle: ModalTitle | null) => {
    setModalTitle(modalTitle);
    setTimeout(()=>{
      setOpen(true);
      if (modalTitle === "查看") {
      setInnerComponent(<ShowExternal ref={printTable} is_show_print={true} is_return={false} is_show={true} printData={printRef.current}/>);
      }
      if(modalTitle === "打印"){
        setInnerComponent(<PrintExternal setCols={setCols} is_show_print={true} printData={printRef.current} ref={printTable}/>);
      }
    },0)
  };

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      ellipsis: true,
      fixed:true,
    },
    {
      title: "外协转序单",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 160,
      ellipsis: true,
    },
    {
      title: "生产制号",
      dataIndex: "batch_production",
      key: "batch_production",
      align: "center",
      ellipsis: true,
      width: 160,
    },
    {
      title: "任务名称",
      dataIndex: "name",
      key: "name",
      align: "center",
      ellipsis: true,
      width: 160,
    },
    {
      title: "转序日期",
      dataIndex: "record_date",
      key: "record_date",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
      ),
    },
    {
      title: "要求完成日期",
      dataIndex: "finish_date",
      key: "finish_date",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, false) }</Tooltip>
      ),
    },
    {
      title: "承制单位",
      dataIndex: "trader",
      key: "trader",
      align: "center",
      ellipsis: true,
      width: 160,
    },
    {
      title: "外协员",
      dataIndex: "user",
      key: "user",
      align: "center",
      ellipsis: true,
      width: 100,
    },
    {
      title: "转序时间",
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (item: any) => (
        <Tooltip placement="topLeft">{moment(item).format('YYYY/MM/DD HH:mm')}</Tooltip>
      ),
    },
    {
      title: "操作",
      dataIndex: "options",
      key: "options",
      align: "center",
      // width: 60,
      fixed:"right",
      render: (_: any, record: any) => (
        <div
          style={{
            color: "#3E7BFA",
            cursor: "pointer",
          }}
        >
          
          <MyImage src={show} title="查看" onClick={
            () => {
              getRecordDetail({
                record_id:record.object_id as number
              }).then(response=>{
                // 给每一项加上index,否则最后到处没有index
                  response.data.work &&  response.data.work.map((item:any,index:number)=> {
                    item.index = index + 1
                  });
                  setPrintData(response.data)
                  openModal(ModalTitle.SHOW)
              }).catch(err=>{
                message.error(err)
              })
            }
          }></MyImage>
        </div>
      ),
    },
  ];

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getRecordList(queryCondition)
        .then((res) => {
          if (res.code === 200) {
            if(res.data.data) {
              res.data.data.map((item,index) => {
                item.index = index + 1
              })
            }
            res.data.data && resolve(res.data);
            // 实际的数据得加工使用user_id作为object_id,否则没有key
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);

  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    return queryData;
  };
  const getTotalNumber = (data:any) => {
    let totalNumber:number = 0;
    data.forEach((item:any) => {
      if(item.quantity) {
        totalNumber+=item.quantity
      }
    })
    return totalNumber;
  }
  return (
    <div className="external_assistance_transfer">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        scroll={true}
        scrollValue={{x:1300}}
        filterQueryData={filterQueryData}
      />
      {modalTitle && (
        <ModalContainer
            width={modalTitle === "打印" ? "794px" : "50%"}
            footer={[
              modalTitle === "查看"? (
                <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false);
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="打印"
                  onClick={() => {
                    openModal(ModalTitle.Print)
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
              ):(
                <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false);
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <ExportExcel
                  columns={cols as any}
                  dataSource={printData.work}
                  btnName="导出"
                  tableName="外协转序单"
                  isTwoHeader={false}
                  length={max_row+1}
                  lastRowLength={max_row+5}
                  is_bg_null={true}
                  btnStyle={{
                    width: "130px",
                    height: "48px",
                    backgroundColor: "#3E7BFA",
                  }}
                  is_external_table={true}
                  totalNumber={getTotalNumber(printData.work)}
                  otherData={[
                    {
                      rows1: ["生产制号",printData.batch_production, "任务名称",printData.name, "配套项目",printData.project],
                      rows2: ["转序日期", moment(printData.record_date).format("YYYY-MM-DD"),"要求完成日期",printData.finish_date ? moment(printData.finish_date).format("YYYY-MM-DD") : '', "承制单位",printData.trader],
                      lastRow: [
                        "外协单位人员签字：",
                        "外协配套科人员签字：",
                        "特种工艺车间人员签字：",
                        "日期：",
                        "日期：",
                        "日期",
                        "合计"
                      ],
                    },
                    { title: ["外协零件转序清单"] },
                  ]}
                ></ExportExcel>
                <MButton
                  title="打印"
                  onClick={() => {
                    if(printTable.current){
                     
                      Print(printTable.current)
                    } 
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
              )
          ]}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default ExternalAssistanceTransfer;

import React, { useEffect, useState } from "react";
import { Form, DatePicker, Input, message, Image } from "antd";
import moment from "moment";
import {
  deviceInfoDetail,
  getCapacityUtilizationDetail,
  getExportCapacityUtilization
} from "../../../../service";
import * as echarts from "echarts/core";
// 按需导入
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
// import ReactEcharts from 'echarts-for-react';
import ReactEchartsCore from "echarts-for-react/lib/core";
import left from "../../../../assets/left.png";
import right from "../../../../assets/right.png";
interface IProps {
  objectId: number;
  selectDate: { start_date: string; end_date: string };
  setExportDataSource:(exportData:any[]) => void;
  setDeviceName:(deviceName:string) => void
}
echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  BarChart,
  CanvasRenderer,
]);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const DeviceRate = (props: IProps) => {
  const {objectId, selectDate,setExportDataSource,setDeviceName} = props;
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<{ start_date: string; end_date: string }>(selectDate);
  const [sDate,setSDate] = useState([moment(selectDate.start_date, dateFormat),moment(selectDate.end_date, dateFormat),])
  const [chartData, setChartData] = useState<{
    dataAxis: string[];
    dataYAxis: any[];
  }>({ dataAxis: [], dataYAxis: [] });
  const [form] = Form.useForm();
  useEffect(() => {
    deviceInfoDetail({
      object_id: objectId,
    })
      .then((res) => {
       
        if (res.code === 200) {
         
          let obj = {
            code: res.data.code,
            name: res.data.name,
            model: res.data.model,
            date:sDate
          };
          res.data.name && setDeviceName(res.data.name)
          form.setFieldsValue(obj);
        }
      })
      .catch((err) => {
        message.error("err", err);
      });
  }, [sDate]);
  useEffect(() => {
    setLoading(true);
    getList();
    getExportCapacityUtilization({
      object_id:objectId,
      start_date:date.start_date,
      end_date:date.end_date,
    }).then(res=>{
      if(res.code === 200){
        const obj:any = {
          10:"调试",
          20:"加工",
          30:"返修",
          40:"外协",
          50:"返厂"
        }
        if(res.data) {
          res.data.map( (i:any) => {
             if(i.report_category) {
              i.report_category = obj[i.report_category.toString()]
             }
             if(i.start_time) {
              // const str1 = Date.parse(i.start_time)-8*60*60*1000;
              // i.start_time = moment(str1).format("YYYY-MM-DD HH:mm:ss");
              let strWithoutGMT = i.start_time.slice(0, -4);
              let ms = Date.parse(strWithoutGMT);
              let date = new Date(ms);
              i.start_time = moment(date).format("YYYY-MM-DD HH:mm:ss");
             }
             if(i.end_time) {
              let strWithoutGMT = i.end_time.slice(0, -4);
              let ms = Date.parse(strWithoutGMT);
              let date = new Date(ms);
              i.end_time = moment(date).format("YYYY-MM-DD HH:mm:ss");
             }
          })
        }
       
        res.data && setExportDataSource(res.data)
      }
    }).catch(err =>{
      message.error(err)
    })
  }, [date]);
  const getList = () => {
    getCapacityUtilizationDetail(Object.assign({object_id: objectId,}, date))
      .then((res) => {
    
        if (res.code === 200) {
          setLoading(false);
          let newDataAxis =
            res.data &&
            res.data.map((i) => {
              return i.date;
            });
          let newDataY =
            res.data &&
            res.data.map((i) => {
              return i.total;
            });
          setChartData({
            dataAxis: newDataAxis,
            dataYAxis: newDataY,
          });
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  let options = {
    xAxis: [
      {
        type: "category",
        position: "left",
        data: chartData.dataAxis,
        axisLabel: {
          color: "black",
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
      formatter:function(datas:any){ //标签内容
        
        let res = ''
        for(let i =0, length = datas.length; i < length; i++){
          res = datas[i].name +'<br/>'+ datas[i].data + "%"
        }
        return res
      },
    },
    calculable: true,

    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    dataZoom: [
      {
        show: true,
        start: 1,
        end: 60,
      },
    ],
    grid: {
      top: "10%",
      left: "3%",
      right: "3%",
      bottom: "6%",
      containLabel: true,
    },

    series: [
      {
        data: chartData.dataYAxis,
        type: "bar",
        showBackground: true,
        // barWidth: 30,
        backgroundStyle: {
          color: "rgba(178, 202, 253, 0.16)",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(153, 175, 255, 1)" },
            { offset: 1, color: "rgba(153, 175, 255, 1)" },
          ]),
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#2378f7" },
              { offset: 1, color: "#83bff6" },
            ]),
          },
        },
      },
    ],
  };
  const handleLeft = () => {
    setDate({
      start_date: moment(date.start_date).add(-7, "d").format("YYYY-MM-DD"),
      end_date: moment(date.end_date).add(-7, "d").format("YYYY-MM-DD"),
    });
    setSDate([
        moment(date.start_date, dateFormat).add(-7, "d"),
        moment(date.end_date, dateFormat).add(-7, "d"),
    ])
  };
  const handleRight = () => {
    setDate({
      start_date: moment(date.start_date).add(7, "d").format("YYYY-MM-DD"),
      end_date: moment(date.end_date).add(7, "d").format("YYYY-MM-DD"),
    });
    setSDate([
        moment(date.start_date, dateFormat).add(7, "d"),
        moment(date.end_date, dateFormat).add(7, "d"),
    ])
  };
  return (
    <div className="device_rate">
      <Form
        form={form}
        className={"device_rate_form"}
        name="device_rate_form"
        disabled={true}
      >
        <Form.Item label="设备代码" name="code" className="precent" >
          <Input></Input>
        </Form.Item>
        <Form.Item label="设备名称" name="name" className="precent">
          <Input></Input>
        </Form.Item>
        <Form.Item label="设备型号" name="model" className="precent">
          <Input></Input>
        </Form.Item>
        <Form.Item label="统计日期" name="date" className="precent">
          <RangePicker format={dateFormat} />
        </Form.Item>
      </Form>
      <div className="main">
        <div onClick={handleLeft}>
          <Image width={30} src={left} />
        </div>
        <div className="chart">
          <ReactEchartsCore
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            showLoading={loading}
            opts={{ renderer: "svg" }}
            className="echarts-for-echarts"
            style={{ width: 900, height: 350 }}
          />
        </div>
        <div onClick={handleRight}>
          <Image width={30} src={right} />
        </div>
      </div>
    </div>
  );
};
export default DeviceRate;

import React, {useEffect, useState} from "react";
import {Table, Pagination, Space} from "antd";
import {ColumnsType} from "antd/es/table";
import {ExpandedRowRender} from "rc-table/lib/interface";
import ResizableTitle from "./ResizableTitle";
import "./index.less";
import moment from "moment";
import {formatNumber, orderStatusList} from "../../utils";
import MyImage from "../MyImage";
import {ModalTitle} from "../../pages/Order/OrderManagement";

interface TableBarProps {
    columns: ColumnsType<any>;
    dataSource: any[];
    onChange: any;
    total: number;
    currentPage: number;
    pageSize: number;
    loading: boolean;
    totalPage: number;
    setSelectedRowKeys?: (selectedRowKeys: any[]) => void;
    selectedRowKeys?: any[];
    setSelectedRows?: (selectedRows: any[]) => void;// 修改勾选的行的数组
    scroll?: boolean;
    scrollValue?: { x: number };
    expandedRowRender?: ExpandedRowRender<any>;
    rowClassName?: (record: any, index: number) => string;// 表格行属性
    type?: "checkbox" | "radio";// 选择框的类型
    getCheckboxProps?: any;
    paginationFalse?: boolean;
}

/**
 * 表格组件
 */
const TableBar = (props: TableBarProps) => {
    const {
        columns,
        dataSource,
        onChange,
        total,
        currentPage,
        pageSize,
        loading,
        totalPage,
        setSelectedRowKeys,
        selectedRowKeys,
        setSelectedRows,
        scroll,
        expandedRowRender,
        rowClassName,
        type,
        scrollValue,
        getCheckboxProps,
        paginationFalse,
    } = props;

    const showTotal = () => {
        return (
            <div className={"mes-table-pagination-total"}>
                <span style={{marginRight: 12}}>{`共${total}条记录`}</span>
                {`第${currentPage}/${totalPage}页`}
            </div>
        );
    };

    const rowOnChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        if (selectedRowKeys && setSelectedRowKeys) {
            setSelectedRowKeys(selectedRowKeys);
        }
        if (selectedRows && setSelectedRows) {
            setSelectedRows(selectedRows);
        }
    };

    const [realColumns, setRealColumns] = useState<ColumnsType<any>>(columns);

    const handleResize: Function =
        (index: number, childIndex?: number) =>
            (_: React.SyntheticEvent<Element>, {size}: any) => {
                const newColumns = [...realColumns];
                if (childIndex !== undefined) {
                    let children = [...(newColumns[index] as any).children]
                    children[childIndex] = {
                        ...children[childIndex],
                        width: size.width,
                    }
                    newColumns[index] = {
                        ...newColumns[index],
                        children: children
                    }
                } else {
                    newColumns[index] = {
                        ...newColumns[index],
                        width: size.width,
                    };
                }
                setRealColumns(newColumns);
            };

    const mergeColumns = (array: any) => {
        let temp = [...array]
        return temp.map((e: any, i: number) => {
            if (e.title) {
                if (e.children) {
                    let children = e.children.map((c: any, ci: number) => {
                        return ({
                            ...c,
                            onHeaderCell: () => ({
                                width: c.width,
                                onResize: handleResize(i, ci),
                            })
                        })
                    })
                    return ({
                        ...e,
                        children: children,
                        onHeaderCell: () => ({
                            width: e.width,
                            onResize: handleResize(i),
                        })
                    })
                } else {
                    return ({
                        ...e,
                        onHeaderCell: () => ({
                            width: e.width,
                            onResize: handleResize(i),
                        })
                    })
                }
            } else {
                return e
            }
        })
    }

    const tempColumns = mergeColumns(realColumns)

    return (
        <div className={"mes-tableBar"}>
            <Table
                columns={tempColumns}
                dataSource={dataSource}
                loading={loading}
                rowClassName={rowClassName}
                components={{
                    header: {
                        cell: ResizableTitle,
                    },
                }}
                pagination={
                    paginationFalse
                        ? false
                        : {
                            position: ["bottomRight"],
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: onChange,
                            showTotal: showTotal,
                        }
                }
                rowSelection={
                    setSelectedRowKeys
                        ? {
                            type: type ? type : "checkbox",
                            selectedRowKeys,
                            onChange: rowOnChange,
                            columnWidth: 50,
                            getCheckboxProps: getCheckboxProps
                                ? getCheckboxProps
                                : undefined,
                        }
                        : undefined
                }
                scroll={scrollValue ? {...scrollValue, y: "calc(100vh - 380px)"} : scroll ? {
                    x: 2000,
                    y: "calc(100vh - 380px)"
                } : {y: "calc(100vh - 380px)"}}
                expandable={
                    expandedRowRender
                        ? {
                            expandedRowRender,
                            expandIcon: ({expanded, onExpand, record}) =>
                                expanded ? (
                                    <img
                                        style={{width: 30, height: 30, cursor: "pointer"}}
                                        src={require("../../assets/icon_expanded.png")}
                                        alt=""
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ) : (
                                    <img
                                        style={{width: 30, height: 30, cursor: "pointer"}}
                                        src={require("../../assets/icon_unexpanded.png")}
                                        alt=""
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ),
                        }
                        : undefined
                }
            />
            {paginationFalse && (
                <div
                    className="my_paganation"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "16px 0"
                    }}>
                    <div style={{color: "#999"}}>
                        <span style={{marginRight: 12}}>{`共${total}条记录`}</span>
                        {`第${currentPage}/${totalPage}页`}
                    </div>
                    <Pagination
                        className="paganation_false"
                        showSizeChanger={true}
                        showQuickJumper={true}
                        total={total}
                        current={currentPage}
                        onChange={onChange}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(TableBar);

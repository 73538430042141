import React from 'react';
import Dashboard from "./Dashboard";
import Login from "./Login";
import User from "./System/User";
import Role from "./System/Role";
import Settings from "./Settings";
import OrderManagement from "./Order/OrderManagement";
import OrderView from "./Order/OrderView";
import Businessman from "./Basic/Businessman";
import Cause from "./Basic/Cause";
import Procedure from "./Basic/Procedure";
import Workmanship from "./Basic/Workmanship";
import Organization from "./System/Organization";
import Job from "./System/Job";
import CraftManagement from "./CraftManagement/index";
import Param from "./System/Param";
import Log from "./System/Log";
import InvoiceManagement from './Invoice';
import DeliveryDocManagement from './Delivery/DeliveryDocManagement';
import DeliveryManagement from './Delivery/DeliveryManagement';
import DeliveryQuery from './Delivery/DeliveryQuery';
import Device from "./Basic/Device";
import ProductionOrderManagement from "./Production/ProductionOrderManagement";
import TaskManagement from "./Production/TaskManagement";
import IntegratedManagement from "./Order/IntegratedManagement";
import ReportMonitor from "./ComprehensiveQuery/ReportMonitor";
import RepairMonitor from "./ComprehensiveQuery/RepairMonitor";
import ExternalAssistanceMonitor from "./ComprehensiveQuery/ExternalAssistanceMonitor";
import ExternalAssistanceTransfer from "./ComprehensiveQuery/ExternalAssistanceTransfer";
import ReturnMonitor from "./ComprehensiveQuery/ReturnMonitor";
import ReturnTransfer from "./ComprehensiveQuery/ReturnTransfer";
import Cost from './ComprehensiveQuery/Cost';
import WarehousingQuery from './ComprehensiveQuery/WarehousingQuery';
import UtilizationRate from './ComprehensiveQuery/UtilizationRate';
import OperatorStatistics from './ComprehensiveQuery/OperatorStatistics';
import QualityMonitor from './ComprehensiveQuery/QualityMonitor';
export interface MesComponent {
    [key: string]: (props: any) => React.ReactElement;
}

export default {
    Login,
    Dashboard,
    Settings,
    User,
    Role,
    Organization,
    Job,
    Param,
    Log,
    Procedure,
    Workmanship,
    Businessman,
    Device,
    Cause,
    OrderManagement,
    OrderView,
    IntegratedManagement,
    ProductionOrderManagement,
    TaskManagement,
    CraftManagement,
    DeliveryDocManagement,
    DeliveryManagement,
    DeliveryQuery,
    InvoiceManagement,
    ReportMonitor,
    RepairMonitor,
    ExternalAssistanceMonitor,
    ExternalAssistanceTransfer,
    ReturnMonitor,
    ReturnTransfer,
    WarehousingQuery,
    Cost,
    UtilizationRate,
    OperatorStatistics,
    QualityMonitor
} as MesComponent

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Space, Row, Col} from "antd";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {filterData, formatNumber, getFormData, showError, toastMessage} from "../../../utils";
import DeleteItem from "../../../components/DeleteItem";
import ModalContainer from "../../../components/Modal";
import QRCode from 'qrcode.react';
import '../index.less';
import {ColumnsType} from "antd/es/table";
import {
    deviceInfo,
    deviceInfoCreate,
    deviceInfoDelete,
    deviceInfoDetail,
    deviceInfoUpdate
} from "../../../service";
import moment from "moment";
import Print from 'react-print-html'

enum ModalTitle {
    ADD = '新增设备信息',
    EDIT = '编辑设备信息',
    DELETE = '系统确认',
    LABEL = '设备标签'
}


interface DeviceLabelProps {
    code: string,
    name: string,
    model: string,
    remark: string,
    id: number
}


const Device = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const printRef = useRef(null);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement>(<div/>);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '代码',
            placeholder: '请输入设备代码'
        },
        {
            key: 'name',
            name: '名称',
            placeholder: '请输入设备名称'
        },
    ]

    const columns: ColumnsType<any> = [
        {
            title: '设备代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '设备型号',
            dataIndex: 'model',
            key: 'type',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '加工费用(元/小时)',
            dataIndex: 'hour_single_price',
            key: 'cost',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (value: string) => <div
                style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 300,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            ellipsis: true,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.LABEL, item)
                        }}>设备标签</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, item)
                        }}>编辑</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, item)
                        }}>删除</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEditCategory = React.memo((): React.ReactElement => {
        return (
            <Form colon={false} form={form} className={'form-margin basic-form'}>
                <Form.Item label={'设备代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '设备代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'设备名称'} name={'name'} rules={[
                    {required: true, message: '请输入设备名称!'},
                    {
                        type: "string",
                        max: 32,
                        message: '设备名称为不得大于32位！'
                    }
                ]}>
                    <Input placeholder={'请输入设备名称'}/>
                </Form.Item>
                <Form.Item label={'设备型号'} name={'model'} rules={[
                    {
                        type: "string",
                        max: 32,
                        message: '设备型号为不得大于32位！'
                    }
                ]}>
                    <Input placeholder={'请输入设备型号'}/>
                </Form.Item>
                <Form.Item label={<div><span className={'requiredIcon'}>*</span><span>加工费用</span></div>}>
                    <Form.Item noStyle name={'hour_single_price'} rules={[
                        {required: true, message: '请输入加工费用!'},
                    ]}>
                        <InputNumber step={0.01} min={0} placeholder={'请输入加工费用'}/>
                    </Form.Item>
                    <span>元/小时</span>
                </Form.Item>

                <Form.Item label={'备注'} name={'remark'} rules={[
                    {max: 200, message: '备注不得大于200位！'}
                ]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditCategory/>)
        } else if (title === ModalTitle.EDIT) {
            deviceInfoDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        name: res.data.name,
                        model: res.data.model,
                        hour_single_price: res.data.hour_single_price,
                        remark: res.data.remark
                    })
                    setInnerComponent(<AddOrEditCategory/>);
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.LABEL) {
            deviceInfoDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<DeviceLabel code={res.data.code} name={res.data.name} model={res.data.model}
                                                   remark={res.data.remark} id={res.data.object_id}/>);
                } else {
                    showError(res.message);
                }
            })
        }
        setOpen(true);
    }

    const DeviceLabel = React.memo((props: DeviceLabelProps): React.ReactElement => {
        const {code, name, model, remark, id} = props;

        useEffect(() => {
            const canvasToImage = (canvas: HTMLCanvasElement) => {
                const image = new Image();
                image.src = canvas.toDataURL("image/png");
                image.width = 120;
                return image;
            }
            const canvasContainer = document.getElementsByTagName('canvas')[0];
            const img = canvasToImage(canvasContainer);
            const ewmImg: HTMLElement | null = document.getElementById('ewm');
            if (ewmImg) {
                ewmImg.append(img);
            }
        }, [])

        return (
            <div className={'form-margin'} style={{padding: '68px auto'}}>
                <div className={'device-label'} ref={printRef}>
                    <Row style={{alignItems: 'center'}}>
                        <Col span={18}>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>设备代码</div>
                                <div className={'label-value'}>{code}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>设备名称</div>
                                <div className={'label-value'}>{name}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>设备型号</div>
                                <div className={'label-value'}>{model}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>备注</div>
                                <div className={'label-value'}>{remark}</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <QRCode
                                id="qrCode"
                                value={`EQ_${id}_${name}`}
                                size={80}
                                fgColor="#000000"
                                style={{float: 'right', display: 'none'}}
                            />
                            <div className="codeImg" id="ewm"></div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    })

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                deviceInfoCreate(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                deviceInfoUpdate(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            if (selectedRowKeys.length) {
                //批量删除
                const formData = getFormData(filterData({ids: `[${selectedRowKeys}]`}))
                deviceInfoDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                        setSelectedRowKeys([])
                    } else {
                        showError(res.message);
                    }
                })
            } else {
                //单个删除
                const formData = getFormData(filterData({ids: `[${selectedItem.object_id}]`}))
                deviceInfoDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                    } else {
                        showError(res.message);
                    }
                })
            }
        } else if (title === ModalTitle.LABEL) {
            if (printRef.current) {
                Print(printRef.current)
                onCancel(title)
            }
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        //去除勾选的数据
        if (title === ModalTitle.DELETE && selectedRowKeys.length > 0) {
            setSelectedRowKeys([])
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(<div/>);
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => {
        const ExportJsonExcel = require("js-export-excel");

        const exportExcel = () => {
            if (gRef.current) {
                if (gRef.current.exportData) {
                    gRef.current.exportData().then(res => {
                        const option: any = {};
                        option.fileName = '设备列表' + moment(new Date()).format('YYYYMMDD');
                        option.datas = [
                            {
                                sheetData: res,
                                sheetName: "sheet",
                                sheetFilter: ['code', 'name', 'model', 'hour_single_price', 'remark'],
                                sheetHeader: ["设备代码", "设备名称", "设备型号", "加工费用(元/小时)", "备注"],
                                columnWidths: [20, 20, 20, 20, 20],
                            }
                        ];
                        const toExcel = new ExportJsonExcel(option);
                        toExcel.saveExcel();
                    })
                }
            }
        }

        return (
            <div>
                <Button className={'mes-user-add'} style={{marginRight: 20}} onClick={() => {
                    openModal(ModalTitle.ADD, null)
                }}>新增</Button>
                <Button className={'mes-batch-deletion'} style={{marginRight: 20}} onClick={() => {
                    if (selectedRowKeys.length) {
                        openModal(ModalTitle.DELETE, null)
                    } else {
                        toastMessage('请选择要删除的设备数据')
                    }
                }}>批量删除</Button>
                <Button className={'mes-export'} style={{marginRight: 20}} onClick={exportExcel}>导出</Button>
            </div>
        )
    })

    const getDataAsync = useCallback((queryCondition: API.deviceinfo & API.pageInfo): Promise<any> => {
        return new Promise(resolve => {
            deviceInfo(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const onText = () => {
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            return '保存'
        } else if (modalTitle === ModalTitle.LABEL) {
            return '打印'
        }
    }

    enum ModalTitle {
        ADD = '新增设备信息',
        EDIT = '编辑设备信息',
        DELETE = '系统确认',
        LABEL = '设备标签'
    }

    const setWidth = () => {
        if (modalTitle !== ModalTitle.DELETE) {
            return '40%'
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<ExtraComponent/>}
                               getDataAsync={getDataAsync} ref={gRef} scrollValue={{x: 1500}}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open} width={setWidth()}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={onText()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default Device;

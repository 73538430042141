import React,{useEffect,useState,useRef} from "react";
import { Form, DatePicker,message,Spin } from "antd";
import MyButton from "../../../components/MyButton";
import {getCapacityUtilizationList} from "../../../service";
import moment from "moment";
import ModalContainer from "../../../components/Modal";
import Button from "../../../components/MyButton";
import ExportExcel from "../../../components/ExportTable/exportTable";
import DeviceRate from "./components/DeviceRate";
import Charts from "./components/Charts";

// 设备使用率导出的columns 
const exportColumns = [
  {
    title: "订单号",
    dataIndex: "order_number",
    key: "order_number",
  },
  {
    title: "零件名称",
    dataIndex: "part_name",
    key: "part_name",
  },
  {
    title: "零件图号",
    dataIndex: "part_number",
    key: "part_number",
  },
  {
    title: "任务单号",
    dataIndex: "task_number",
    key: "task_number",
  },
  {
    title: "工序",
    dataIndex: "work_process_name",
    key: "work_process_name",
  },
  {
    title: "报工类别",
    dataIndex: "report_category",
    key: "report_category",
  },
  {
    title: "加工设备代码",
    dataIndex: "device_code",
    key: "device_code",
  },
  {
    title: "加工设备名称",
    dataIndex: "device_name",
    key: "device_name",
  },
  {
    title: "加工设备型号",
    dataIndex: "device_model",
    key: "device_model",
  },
  {
    title: "操作员",
    dataIndex:"user_name",
    key: "user_name",
  },
  {
    title: "合格数量",
    dataIndex: "process_qualified", 
    key: "process_qualified",
  },
  {
    title: "不良数量",
    dataIndex: "process_defective", 
    key: "process_defective",
  },
  {
    title: "开始时间",
    dataIndex: "start_time",
    key: "start_time",
  },
  {
    title: "结束时间",
    dataIndex: "end_time",
    key: "end_time",
  },
  {
    title: "实际工时(秒)",
    dataIndex: "real_time",
    key: "real_time",
  }
];
const start = moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD");
const end = moment().subtract(0, 'day').endOf('day').format("YYYY-MM-DD");
const UtilizationRate = () => {
  // 设备使用率数据导出
  const [exportDataSource,setExportDataSource] = useState<any[]>([]);
  const [loading,setLoading] = useState(false);
  const [chartData,setChartData] = useState<{dataAxis:string[],dataYAxis:any[]}>({dataAxis:[],dataYAxis:[]});
  const chartDataRef = useRef<{dataAxis:string[],dataYAxis:any[]}>({dataAxis:[],dataYAxis:[]});
  chartDataRef.current = chartData;
  const [open,setOpen] =useState<boolean>(false);
  const [objectId,setObjectId] =useState<number>();
  const [selectDate,setSelectDate] = useState<{start_date:string,end_date:string}>({start_date:start,end_date:end})
  const [form] = Form.useForm();
  const [deviceName,setDeviceName] = useState<string>();
  useEffect(()=>{
    getList(start,end);
  },[])
  const getList = ( start_date:string,end_date:string) => {
    setLoading(true);
    getCapacityUtilizationList({
      start_date,
      end_date,
    }).then(res => {
      setLoading(false);
      if(res.code === 200) {
       
        let newDataAxis = res.data && res.data.map(i => {
          return i.device_name;
        })
        let newDataY = res.data && res.data.map(i=> {
          return {value:i.percent,object_id:i.device_id}
        })
        setChartData({
          dataAxis:newDataAxis,
          dataYAxis:newDataY
        })
      }else {
        message.error(res.message)
      }
    }).catch(err => {
      console.log("err",err)
    })
  }
 
  const { RangePicker } = DatePicker;
  const handleSelect = () => {

    setSelectDate({
      start_date: moment(form.getFieldsValue().date[0]).format("YYYY-MM-DD"),
      end_date:moment(form.getFieldsValue().date[1]).format("YYYY-MM-DD")
    })
    getList(
      moment(form.getFieldsValue().date[0]).format("YYYY-MM-DD"),
      moment(form.getFieldsValue().date[1]).format("YYYY-MM-DD")
    )
  };
  const handleReset = () => {
    form.resetFields();
  };

  const dateFormat = 'YYYY/MM/DD';
  let onEvents = {
    click: (params:any) => {
      setObjectId(params.data.object_id)
      setOpen(true);
    }
  }
  return (
    <div className="utilization_rate">
      <Form
        form={form}
        className={"utilization_rate_form"}
        name="utilization_rate_form"
        initialValues={{
          date: [moment(start, dateFormat), moment(end, dateFormat)],
        }}
      >
        <Form.Item label="统计日期" name="date">
          <RangePicker format={dateFormat} />
        </Form.Item>
        <Form.Item className="form_item">
          <MyButton
            title="查询"
            onClick={handleSelect}
            buttonStyle={{
              width: "48px",
              height: "28px",
              marginLeft: "20px",
              marginRight: "0px",
            }}
          ></MyButton>
        </Form.Item>
        <Form.Item>
          <MyButton
            title="重置"
            onClick={handleReset}
            buttonStyle={{
              width: "48px",
              height: "28px",
              backgroundColor: "#3B9FFD",
              marginLeft: "20px",
              marginRight: "0px",
            }}
          ></MyButton>
        </Form.Item>
      </Form>
      <div className="react_echarts">
        {loading ? (
          <div className="example">
            <Spin/>
          </div>
        ) : (
          <Charts chartData={chartData} onEvents={onEvents}></Charts>
        )}
      </div>
      {
        <ModalContainer
          title={"设备使用率查看"}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <div className="modal_btn">
              <Button
                title="关闭"
                onClick={() => {
                  setOpen(false);
                }}
                buttonStyle={{
                  backgroundColor: "#B8B8B8",
                }}
              ></Button>
              <ExportExcel
                dataSource={exportDataSource}
                columns={exportColumns}
                tableName={
                  deviceName + moment(new Date()).format("YYMMDDHHmmss")
                }
                length={exportDataSource.length + 1}
                btnName="导出"
                btnStyle={{
                  width: "130px",
                  height: "48px",
                  background: "#3E7BFA",
                  borderRadius: "5px 5px 5px 5px",
                }}
                isTwoHeader={false}
              ></ExportExcel>
            </div>,
          ]}
          width={"58%"}
          innerComponent={
            <DeviceRate
              setDeviceName={setDeviceName}
              objectId={objectId as number}
              selectDate={selectDate}
              setExportDataSource={setExportDataSource}
            />
          }
        />
      }
    </div>
  );
};
export default UtilizationRate;

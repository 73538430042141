import React, { useState, useEffect, useCallback } from "react";
import { message,Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getWorkReportDetailForOperator } from "../../../../service";
import PaganationTable from "../../components/PaganationTable";
import { formatTime, formatNumber,shiftTimeStamp } from "../../../../utils/index";
import {uuid} from "../.././../../utils/index";
import "../../index.less";
interface IProps {
  object_id: number;
  setExportColumns:(columns:ColumnsType<any>)=>void;
  setModalDataSource:(dataSource:any[])=>void;
  selectDate:{start_date:string,end_date:string}
}
const columns: ColumnsType<any> = [
  {
    title: "No",
    dataIndex: "index",
    key: "index",
    align: "center",
    width:60,
    ellipsis: true,
    fixed:true,
  },
  // 新增日期列
  {
    title: "日期",
    dataIndex: "date",
    key: "date",
    align: "center",
    width: 140,
    ellipsis: true,
    fixed: "left",

  },
  {
    title: "操作员",
    dataIndex: "user_name",
    key: "user_name",
    align: "center",
    ellipsis: true,
    width: 140,
    fixed:"left",
  },
  {
    title: "零件图号",
    dataIndex: "part_number",
    key: "part_number",
    align: "center",
    ellipsis: true,
    width: 140,
    fixed: "left",
  },
  {
    title: "工序",
    dataIndex: "work_process_name",
    key: "work_process_name",
    align: "center",
    ellipsis: true,
    width: 140,
    fixed:true,
    render:(text,record) => <div>
        {record.category === 30? "-": text}
    </div>

  },
  {
    title: "合格数量",
    dataIndex: "qualified",
    key: "qualified",
    align: "right",
    ellipsis: true,
    width: 90,
    render:(i)=><span>{i && formatNumber(i)}</span>
  },
  {
    title: "加工设备",
    dataIndex: "device_info_name",
    key: "device_info_name",
    align: "center",
    ellipsis: true,
    width: 140,
  },
  {
    title: "预计工时",
    dataIndex: "estimated_time",
    key: "estimated_time",
    align: "right",
    ellipsis: true,
    width: 160,
    render:(text,record) => {
      if(record.category === 30 || record.processing_category === 20 || record.processing_category === 30)  {
        return <div>-</div>
      }else {
        return <div>
        {text>0?shiftTimeStamp(text):"-"}
       </div>
      }
    }
  },
  {
    title: "实际工时",
    dataIndex: "real_time",
    key: "real_time",
    align: "right",
    ellipsis: true,
    width: 160,
    render:(text) => <div>
    {text>0?shiftTimeStamp(text): "-"}
   </div>
  },
  {
    title: "缺工时",
    dataIndex: "overtime",
    key: "overtime",
    align: "right",
    ellipsis: true,
    width: 160,
    onCell:(record, _) => {
        return {
          className:record.overtime > 0 ? "bgColor":"bgWhite"
        };
      },
    render:(text,record) => {
      if(record.category === 30 || record.processing_category === 20 || record.processing_category === 30)  {
        return <div>-</div>
      }else {
        return <div>
        {text>0?shiftTimeStamp(text):"-"}
       </div>
      }
    }
  },
  {
    title: "超工时",
    dataIndex: "lack_of_time",
    key: "lack_of_time",
    align: "right",
    ellipsis: true,
    width: 160,
    onCell:(record, _) => {
        return {
          className:record.lack_of_time > 0 ? "bgColor":"bgWhite"
        };
      },
      render:(text,record) => {
        if(record.category === 30 || record.processing_category === 20 || record.processing_category === 30)  {
          return <div>-</div>
        }else {
          return <div>
          {text>0?shiftTimeStamp(text):"-"}
         </div>
        }
      }
  },
  {
    title: "报工类型",
    dataIndex: "category",
    key: "category",
    align: "center",
    ellipsis: true,
    width: 90,
    render: (item: Number) => (
      <div>
        {item && item === 10
          ? "调试"
          : item === 20
          ? "加工"
          : item === 30
          ? "返修"
          : item === 40
          ? "外协"
          : "返厂"}
      </div>
    ),
  },

  {
    title: "调试工时",
    dataIndex: "duration",
    key: "duration",
    align: "right",
    ellipsis: true,
    width: 100,
    render:(text,record) =>
      {
        if(record.category === 30 || record.processing_category === 20 || record.processing_category === 30) {
          return <div>-</div>
        }else {
          return <div>{text && text + "秒"}</div>
        }
      }
  },
  {
    title: "加工工时",
    dataIndex: "work_duration",
    key: "work_duration",
    align: "right",
    ellipsis: true,
    width: 100,
    render:(text,record) =>   {
      if(record.category === 30 || record.processing_category === 20 || record.processing_category === 30) {
        return <div>-</div>
      }else {
        return <div>{text && text + "秒"}</div>
      }
    }
  },
  {
    title: "订单号",
    dataIndex: "order_number",
    key: "order_number",
    align: "center",
    width: 140,
    ellipsis: true,
  },
  {
    title: "零件名称",
    dataIndex: "part_name",
    key: "part_name",
    align: "center",
    ellipsis: true,
    width: 140,
  },

  {
    title: "订单数量",
    dataIndex: "order_part_number",
    key: "order_part_number",
    align: "right",
    ellipsis: true,
    width: 90,
    render: (item: Number) => (
      <div>{item && formatNumber(item.toString())}</div>
    ),
  },
  {
    title: "任务单号",
    dataIndex: "task_number",
    key: "task_number",
    align: "center",
    ellipsis: true,
    width: 160,
  },
  {
    title: "投产数量",
    dataIndex: "task_quantity",
    key: "task_quantity",
    align: "right",
    ellipsis: true,
    width: 90,
    render: (item: Number) => (
      <div>{item && formatNumber(item.toString())}</div>
    ),
  },
  
  {
    title: "开始时间",
    dataIndex: "start_time",
    key: "start_time",
    align: "center",
    ellipsis: true,
    width: 150,
    render: (item: string) => <div>{item && formatTime(item, true)}</div>,
  },
  {
    title: "完成时间",
    dataIndex: "end_time",
    key: "end_time",
    align: "center",
    ellipsis: true,
    width: 150,
    render: (item: string) => <div>{item && formatTime(item, true)}</div>,
  },
  {
    title: "不良数量",
    dataIndex: "defective",
    key: "defective",
    align: "right",
    ellipsis: true,
    width: 90,
    render:(text,record) => <div>
    {record.category === 30? "-": text && formatNumber(text.toString())}
   </div>
  },
  {
    title: "不良原因",
    dataIndex: "defect_reason",
    key: "defect_reason",
    align: "center",
    ellipsis: true,
    // width: 180,
    render:(reasons:string[])=>
      <Tooltip
      color="#fff"
      showArrow={false}
      overlayInnerStyle={{
        backgroundColor:"#fff",
        border:"1px solid black",
        color:"black",
      }}
      placement="bottomRight"
      title={ reasons.map( (r,i,arr) => {
        return <span>{r}{(i+1 === arr.length)? "": ","}</span>
        })}>
       {
         reasons.map((r,i,arr)=> {
          return <span>{r}{(i+1 === arr.length)? "": ","}</span>
          })
       }
      </Tooltip>
  },
  
];
const ReportInfo = (props: IProps) => {
  const { object_id,setExportColumns,setModalDataSource,selectDate } = props;
  useEffect(()=>{
    getWorkReportDetailForOperator({
      object_id:object_id.toString(),
      start_date:selectDate.start_date,
      end_date:selectDate.end_date,
      page_num:"1",
      page_size:"1000000000",
    }).then(res => {
      if(res.code === 200){
        const obj:any = {
          10:"调试",
          20:"加工",
          30:"返修",
          40:"外协",
          50:"返厂"
        }
        if (res.data.data) {
          // 报工类型 报工的种类 10调试 20加工 30返修 40外协 50返厂
          res.data.data.map((i,index) => {
            i.index = index + 1;
            if (i.defect_reason) {
              i.defect_reason = i.defect_reason.join(",");
            }
            if(i.category === 30 || i.processing_category === 20 || i.processing_category === 30)  {
              i.estimated_time = "-";
              i.overtime = "-";
              i.lack_of_time = "-";
              i.duration = "-";
              i.work_duration = "-"
            }else {
              i.estimated_time > 0 ? i.estimated_time = shiftTimeStamp(i.estimated_time) : i.estimated_time = "-";
              i.overtime > 0 ? i.overtime =shiftTimeStamp(i.overtime): i.overtime ="-";
              i.lack_of_time > 0 ? i.lack_of_time =shiftTimeStamp(i.lack_of_time): i.lack_of_time ="-";
              i.duration =  i.duration && `${i.duration}秒`;
              i.work_duration = i.work_duration && `${i.work_duration}秒`
            }
            i.real_time > 0 ?  i.real_time =shiftTimeStamp(i.real_time) :  i.real_time ="-";
            if(i.category === 30) {
              i.defective = "-";
              i.work_process_name = "-"
            }
            if (i.category) {
              i.category = obj[i.category.toString()];
            }
          });
        }
     
        res.data.data && setModalDataSource(res.data.data);
      }
    }).catch(err => {
      message.error(err)
    })
    setExportColumns(columns);
  },[])
  const getListAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
        getWorkReportDetailForOperator(
        Object.assign(queryCondition, { 
          object_id: object_id.toString(),
          page_num:Number(queryCondition.page_num),
          page_size:Number(queryCondition.page_size),
          start_date:selectDate.start_date,
          end_date:selectDate.end_date, })
      ).then((res) => {
          if (res.code === 200) {
            res.data.data?.forEach((i,index)=>{
              i.key = uuid();
              i.index = index + 1;
            });
         
            resolve(res.data);
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);

  return (
    <div className="report_info_modal">
      <PaganationTable
        columns={columns}
        getListAsync={getListAsync}
        scrollValue={{x:3100}}
      ></PaganationTable>
    </div>
  );
};
export default ReportInfo;


import React, {useEffect, useState} from "react";
import type {ColumnsType} from 'antd/es/table';
import TableBar from '../../../components/GeneralManagement/TableBar';
import ModalContainer from "../../../components/Modal";
import {Form, message, Input, Select, TimePicker} from "antd";
import {filterData, getFormData, indexType, showError, booleanType} from "../../../utils";
import {createOrUpdateUser} from "../../../service";
import moment from "moment";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {modifySystemParams, systemInfo} from "../../../service";


interface EditParamProps {
    form: FormInstance
}

const Param = (): React.ReactElement => {
    const typeList: indexType = {
        10: '小数',
        20: '整数',
        30: '时间段',
        40: '布尔值',
    }
    const columns: ColumnsType<any> = [
        {
            title: 'No',
            dataIndex: 'No',
            key: 'No',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '参数名称',
            dataIndex: 'param_name',
            key: 'name',
            align: 'center',
            width: 300,
            ellipsis: true,
            render: (text: string) =>
                <span>{text}</span>
        },
        {
            title: '参数说明',
            dataIndex: 'param_explain',
            key: 'describe',
            className: 'column-min-width',
            width: 400,
            ellipsis: true,
            render: (text: string) =>
                <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '参数类型',
            dataIndex: 'param_type',
            key: 'type',
            align: 'center',
            width: 200,
            ellipsis: true,
            render: (text: number) =>
                <span>{typeList[text]}</span>
        },
        {
            title: '值',
            dataIndex: 'value',
            key: 'value',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (text: string, item: any) => {
                if (item.param_type === 40) {
                    return <span>{text === '1' ? '是' : '否'}</span>
                } else {
                    return <span>{text}</span>
                }
            }
        },
        {
            title: '开始',
            dataIndex: 'start_time',
            key: 'start',
            align: 'center',
            width: 150,
            ellipsis: true,
        },
        {
            title: '结束',
            dataIndex: 'end_time',
            key: 'end',
            align: 'center',
            width: 150,
            ellipsis: true,
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            ellipsis: true,
            render: (_: any, record: any) =>
                (<span className={'mes-user-options'} onClick={() => {
                    openModal(record)
                }}>编辑</span>)
        },

    ]
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();

    const openModal = (item: any) => {
        setSelectedItem(item);
        setOpen(true);
        form.setFieldsValue({
            param_name: item.param_name,
            param_explain: item.param_explain,
            param_type: item.param_type,
            value: item.value,
            time: [moment(item.start_time, 'HH:mm'), moment(item.end_time, 'HH:mm')],
        })
        setInnerComponent(<EditParam form={form}/>)
    }

    const onOK = () => {
        form.validateFields().then(values => {
            let realParams = {
                object_id: selectedItem.object_id,
                param_name: values.param_name,
                param_explain: values.param_explain,
                value: values.value,
                start_time: values.time ? values.time[0].format('HH:mm') : undefined,
                end_time: values.time ? values.time[1].format('HH:mm') : undefined,
            }
            const formData = getFormData(filterData(realParams))
            modifySystemParams(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('编辑成功！');
                } else {
                    showError(res.message);
                }
            })
        }).catch(e => {
            console.log(e)
        })
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        getList(pageSize, currentPage)
        setOpen(false);
    }

    const onCancel = () => {
        form.resetFields();
        setInnerComponent(<div/>);
        setOpen(false);
    }

    const onChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        getList(pageSize, page);
    }

    const getList = (pageSize: number, page: number) => {
        setLoading(true);
        systemInfo({page_size: pageSize, page_num: page}).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setDataSource(res.data.data ? res.data.data : []);
                setTotal(res.data.total);
                setTotalPage(res.data.pages)
            }
        })
    }

    const EditParam = React.memo((props: EditParamProps): React.ReactElement => {
            const {form} = props;
            const [type, setType] = useState<number>();
            const changeType = (value: number) => {
                form.resetFields(['value', 'time'])
                setType(value)
            }

            useEffect(() => {
                setType(form.getFieldValue("param_type"))
            }, [])

            return (
                <div className={'form-margin'}>
                    <Form form={form} colon={false}>
                        <Form.Item label={'参数名称'} name={'param_name'} rules={[{required: true, message: '请输入参数名称！'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'参数说明'} name={'param_explain'} rules={[{max: 200, message: '参数说明不得大于200位！'}]}>
                            <Input.TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item label={'参数类型'} name={'param_type'} rules={[{required: true, message: '请选择参数类型！'}]}>
                            <Select onChange={changeType} disabled>
                                {
                                    Object.entries(typeList).map(e =>
                                        <Select.Option key={e[0]}
                                                       value={Number(e[0])}>{e[1]}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        {type === 10 && <Form.Item label={'值'} name={'value'} rules={[{
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject(new Error('请输入小数!'))
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]}>
                            <Input/>
                        </Form.Item>}
                        {type === 20 && <Form.Item label={'值'} name={'value'} rules={[{
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject(new Error('请输入整数!'))
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]}>
                            <Input/>
                        </Form.Item>}
                        {type === 40 && <Form.Item label={'值'} name={'value'} rules={[{
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject(new Error('请选择布尔值!'))
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]}>
                            <Select>
                                {Object.entries(booleanType).map(e => <Select.Option key={e[0]}
                                                                                     value={e[0]}>{e[1]}</Select.Option>)}
                            </Select>
                        </Form.Item>}
                        {
                            type === 30 && <Form.Item label={'时间段'} name={'time'} rules={[{
                                validator: (_, value) => {
                                    if (!value && type === 30) {
                                        return Promise.reject(new Error('请选择时间段!'))
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            }]}>
                                <TimePicker.RangePicker format={'HH:mm'} style={{width: '100%'}} order={false}/>
                            </Form.Item>
                        }
                    </Form>
                </div>
            )
        }
    )

    useEffect(() => {
        getList(pageSize, currentPage)
    }, [])

    return (
        <div className={'mes-param'}>
            <TableBar columns={columns} dataSource={dataSource} onChange={onChange} total={total}
                      currentPage={currentPage} pageSize={pageSize} loading={loading} scrollValue={{x: 1500}}
                      totalPage={totalPage}/>
            <ModalContainer title={'编辑参数'} open={open} onOk={() => onOK()}
                            onCancel={() => onCancel()} okText={'保存'} width={'40%'}
                            innerComponent={innerComponent}/>
        </div>
    )
}

export default Param;

import React, { useCallback, useRef, useState, useEffect } from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { message } from "antd";
import "../index.less";
import ModalContainer from "../../../components/Modal";
import { formatNumber,shiftTimeStamp,moneyFormat } from "../../../utils/index";
import ExportExcel from "../../../components/ExportTable/exportTable";
import moment from "moment";
import { getCostList } from "../../../service";
import Button from "../../../components/MyButton";
import Repair from "./components/Repair";
import WorkProcess from "./components/WorkProcess";
import {uuid} from "../../../utils/index";
import workReportDetails from "../../../assets/work-report-details.png";
import MyImage from "../../../components/MyImage";

enum ModalTitle {
  REPAIR = "返修",
  WORKPROCESS = "工序",
}

const Cost = (): React.ReactElement => {
  const [exportColumns, setExportColumns] = useState<any[]>([]);
  const [modalDataSource, setModalDataSource] = useState<any[]>([]);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  const gRef = useRef<GRef>(null);
  const formList: QueryListItem[] = [
    {
      key: "code",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      key: "task_number",
      name: "任务单号",
      placeholder: "请输入任务单号",
    },
  ];
  const columns: any[] = [
    {
      title: "订单信息",
      key: "1",
      className: "orderInfo",
      align: "center",
      fixed:true,
      children: [
        {
          title: "No",
          className:"orderInfo",
          dataIndex: "index",
          key: "index",
          align: "center",
          width:60,
          ellipsis: true,
          fixed:true,
        },
        {
          title: "订单号",
          className: "orderInfo",
          dataIndex: "order_number",
          key: "order_number",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },
        {
          title: "零件名称",
          className: "orderInfo",
          dataIndex: "part_name",
          key: "part_name",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },

        {
          title: "零件图号",
          className: "orderInfo",
          dataIndex: "part_number",
          key: "part_number",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },
      ],
    },
    {
      title: "",
      key: "3",
      align: "center",
      className: "orderInfo",
      children: [
        {
          title: "订单数量",
          className: "orderInfo",
          dataIndex: "order_part_number",
          key: "order_part_number",
          align: "right",
          ellipsis: true,
          width: 90,
          render: (text: string) => (
            <div>{text && formatNumber(text.toString())}</div>
          ),
        },
      ],
    },
    {
      title: "报工信息",
      key: "2",
      align: "center",
      className: "reportInfo",
      children: [
        {
          title: "任务单号",
          className: "reportInfo",
          dataIndex: "task_number", 
          key: "task_number",
          align: "center",
          ellipsis: true,
          width: 160,
        },
        {
          title: "投产数量",
          className: "reportInfo",
          dataIndex: "task_quantity",
          key: "task_quantity",
          align: "right",
          ellipsis: true,
          width: 90,
          render: (text: string) => (
            <div>{text && formatNumber(text.toString())}</div>
          ),
        },
        {
          title: "最小完成数量",
          className: "reportInfo",
          dataIndex: "task_min_quantity",
          key: "task_min_quantity",
          align: "right",
          ellipsis: true,
          width: 120,
          render: (text: string) => (
            <div>{text && formatNumber(text.toString())}</div>
          ),
        },
        {
          title: "工序",
          className: "reportInfo",
          dataIndex: "work_process_name",
          key: "work_process_name",
          align: "center",
          ellipsis: true,
          width: 160,
        },
        {
          title: "预计总工时",
          className: "reportInfo",
          dataIndex: "estimated_time",
          key: "estimated_time",
          align: "right",
          ellipsis: true,
          width: 160,
          render:(text:number,record:any) => {
            return (
              <span>{record.work_process_name === "返修" ? 0 : text && shiftTimeStamp(text)}</span>
            )
          }
        },
        {
          title: "预计费用(元)",
          className: "reportInfo",
          dataIndex: "estimated_price",
          key: "estimated_price",
          align: "right",
          ellipsis: true,
          width: 120,
          render:(text:number,record:any) => {
            return (
              <span>{record.work_process_name === "返修" ? 0 : text && moneyFormat(text)}</span>
            )
          }
        },
        {
          title: "预计单价(元)",
          className: "reportInfo",
          dataIndex: "estimated_unit_price",
          key: "estimated_unit_price",
          align: "right",
          ellipsis: true,
          width: 120,
          render:(text:number,record:any) => {
            return (
              <span>{text && moneyFormat(text)}</span>
            )
          }
        },
        {
          title: "实际总工时",
          className: "reportInfo",
          dataIndex: "real_time",
          key: "real_time",
          align: "right",
          ellipsis: true,
          width: 160,
          render:(text:number,record:any) => {
            return (
              <span>{text && shiftTimeStamp(text)}</span>
            )
          }
        },
        {
          title: "实际费用(元)",
          className: "reportInfo",
          dataIndex: "real_price",
          key: "real_price",
          align: "right",
          ellipsis: true,
          width: 120,
          render:(text:any) => {
            return <span>{text && moneyFormat(text)}</span>
          }
        },
        {
          title: "实际单价(元)",
          className: "reportInfo",
          dataIndex: "real_unit_price",
          key: "real_unit_price",
          align: "right",
          ellipsis: true,
          width: 120,
          render:(text:any) => {
            return <span>{text && moneyFormat(text)}</span>
          }
        },
      ],
    },
    {
      title: "",
      key: "4",
      align: "center",
      className: "reportInfo",
      children: [
        {
          title: "报工明细",
          className: "reportInfo",
          dataIndex: "options",
          key: "options",
          align: "center",
          // width: 100,
          fixed:"right",
          render: (_: any, record: any) => {
      
            return (
              <span
                className="table_btn_type"
              >
                <MyImage src={workReportDetails} title="报工明细" onClick={
                  () => {
                    if (record.work_process_name === "返修") {
                      openModal(ModalTitle.REPAIR, record);
                    } else {
                      openModal(ModalTitle.WORKPROCESS, record);
                    }
                  }
                }></MyImage>
              </span>
            );
          },
        },
      ],
    },
  ];
  const openModal = (title: ModalTitle, record: any) => {
    setModalTitle(title);
    setTimeout(() => {
      setOpen(true);
      if (title === ModalTitle.REPAIR) {
        setInnerComponent(
          <Repair
            record={record}
            setExportColumns={setExportColumns}
            setModalDataSource={setModalDataSource}
          ></Repair>
        );
      } else if (title === ModalTitle.WORKPROCESS) {
        setInnerComponent(
          <WorkProcess record={record} setExportColumns={setExportColumns} setModalDataSource={setModalDataSource}></WorkProcess>
        );
      }
    }, 0);
  };
  const ExtraComponent = React.memo((): React.ReactElement => {
    const tabName = "成本核算" + moment(new Date()).format("YYMMDDHHmmss");
    const exportExcel = async(callback:any) => {
      if (gRef.current) {
          if (gRef.current.exportData) 
          {
              gRef.current.exportData().then(res => {
             
                  res.map((a:any) => {
                    if(a.estimated_time) {
                      a.estimated_time = shiftTimeStamp(a.estimated_time)
                    }
                    if(a.real_time) {
                      a.real_time = shiftTimeStamp(a.real_time)
                    }
                  })
                  let length  = res.length + 2
                  callback(res,length)
              }).catch(err => {
                console.log("导出全部数据遇到错误",err)
              })
          }
      }
  }
    let newColumns: any[] = JSON.parse(JSON.stringify(columns));
      // 过滤报工明细列
      newColumns.map((c, index) => {
        if (index === 2) {
          let newChildren = c.children?.filter((i: any) => {
            return i.dataIndex !== "options";
          });
          c.children = newChildren;
        }
      });
  
    return (
      <div className="btn_box">
        <ExportExcel
          onClick={exportExcel}
          columns={newColumns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={true}
        ></ExportExcel>
      </div>
    );
  });

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    // setQueryCondition1(queryCondition);
    return new Promise((resolve) => {
      getCostList(queryCondition)
        .then((res) => {
          if (res.code === 200) {
            res.data.data?.map( (i,index) => {
            
              // i.key = index
              i.index = index + 1;
              i.key = uuid()
            })
            resolve(res.data);
         
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);

  return (
    <div className="cost">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        scroll={true}
        scrollValue={{x:2000}}
        ExtraComponent={<ExtraComponent />}
      />
      {modalTitle && (
        <ModalContainer
          title={"报工明细"}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <div className="modal_btn">
              <Button
                title="关闭"
                onClick={() => {
                  setOpen(false)
                }}
                buttonStyle={{
                  backgroundColor: "#B8B8B8",
                }}
              ></Button>
              <ExportExcel
                dataSource={modalDataSource}
                columns={exportColumns}
                tableName={
                  "报工明细" + moment(new Date()).format("YYMMDDHHmmss")
                }
                length={modalDataSource.length + 2}
                btnName="导出"
                btnStyle={{
                  width: "130px",
                  height: "48px",
                  background: "#3E7BFA",
                  borderRadius: "5px 5px 5px 5px",
                }}
                isTwoHeader={true}
              ></ExportExcel>
            </div>,
          ]}
          width={"70%"}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default Cost;

// 查看外协返厂转序单
import React, { forwardRef,useEffect,useState } from "react";
import PrintExternalTable from "./PrintExternalTable";
interface IProps {
  is_return: boolean;
  printData?: any;
  is_show?: boolean;
  is_show_print?:boolean,
}
const ShowExternal = forwardRef((props: IProps, ref: any) => {
  const { is_return, is_show, printData,is_show_print } = props;
  return (
    <div>
      <div
        style={{
          height: "43px",
          backgroundColor: "#F8F8F8",
          borderRadius: "0px 0px 6px 6px",
        }}
      ></div>
      <div ref={ref}>
        <div
          style={{
            color: "#333333",
            fontSize: "16px",
            fontWeight: "bold",
            height: "80px",
            lineHeight: "80px",
            marginLeft: "24px",
          }}
        >
          转序单号:{printData.number}
        </div>
        <PrintExternalTable
          is_return={is_return}
          printData={printData}
          is_show={is_show}
          is_show_print={is_show_print}
        ></PrintExternalTable>
      </div>
    </div>
  );
});
export default ShowExternal;

import React, {useEffect, useState} from "react";
import {Form, Button, Input, Select, DatePicker, Checkbox} from 'antd';
import {QueryListItem} from "./index";
import {indexType} from "../../utils";
import './index.less';

interface QueryBarProps {
    queryForm: any,
    formList: QueryListItem[],
    onFinish: () => void,
    ExtraComponent?: JSX.Element,
    btnStyle?:React.CSSProperties,
    initialValues: () => Object,
}

/**
 * 查询表单组件
 */
const QueryBar = (props: QueryBarProps) => {
    const {queryForm, formList, ExtraComponent, onFinish,btnStyle,initialValues} = props;
    const {RangePicker} = DatePicker;
    //收起展开，默认是收起状态
    const [isOpen, setIsOpen] = useState<boolean>(false);
    //显示的表单
    const [showFormList, setShowFormList] = useState<QueryListItem[]>();

    const reset = () => {
        queryForm.resetFields();
        onFinish();
    }

    useEffect(() => {
        if (formList.length > 3) {
            setShowFormList(formList.slice(0, 3))
        } else {
            setShowFormList(formList)
        }
    }, [])

    const changeStatus = (value: boolean) => {
        setIsOpen(value);
        if (value) {
            setShowFormList(formList)
        } else {
            setShowFormList(formList.slice(0, 3))
        }
    }


    return (
        <div className={'mes-queryBar'}>
            <Form className={'mes-form'} form={queryForm} onFinish={() => onFinish()} layout={'inline'} colon={false}
                  initialValues={initialValues()}>
                {
                    showFormList && showFormList.map((e, i) => {
                            if (e.options) {
                                return (
                                    <Form.Item
                                        label={<span style={e.width ? {width: e.width} : {width: 40}}>{e.name}</span>}
                                        name={e.key} key={i}>
                                        <Select placeholder={e.placeholder}
                                        mode={e.multiple ? 'multiple' : undefined}
                                        onClear={e.onClear? e.onClear : undefined}
                                                allowClear>
                                            {
                                                e.options.map((oe: any, oi: number) =>
                                                    <Select.Option key={oi} value={oe.id}>{oe.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                )
                            } else if (e.date) {
                                return (
                                    <Form.Item
                                        label={<span style={e.width ? {width: e.width} : {width: 40}}>{e.name}</span>}
                                        name={e.key} key={i}>
                                        <DatePicker/>
                                    </Form.Item>
                                )
                            } else if (e.rangePick) {
                                return (
                                    <Form.Item
                                        label={<span style={e.width ? {width: e.width} : {width: 40}}>{e.name}</span>}
                                        name={e.key} key={i}>
                                        <RangePicker  format="YYYY/MM/DD" />
                                    </Form.Item>
                                )
                            } else if (e.checkbox) {
                                return (
                                    <Form.Item
                                        label={<span style={e.width ? {width: e.width} : {width: 40}}>{e.name}</span>}
                                        name={e.key} key={i} className="query-checkgroup">
                                        <Checkbox.Group options={e.checkbox} className="checkbox"/>
                                    </Form.Item>
                                )
                            } else if (e.check) {
                                return (
                                    <Form.Item name={e.key} key={i} className={'query-check'} valuePropName="checked">
                                        <Checkbox>{e.name}</Checkbox>
                                    </Form.Item>
                                )
                            } else {
                                return (
                                    <Form.Item
                                        label={<span style={e.width ? {width: e.width} : {width: 40}}>{e.name}</span>}
                                        name={e.key} key={i}>
                                        <Input placeholder={e.placeholder} allowClear/>
                                    </Form.Item>

                                )
                            }
                        }
                    )
                }
                <Form.Item>
                    <Button htmlType={'submit'} className={'mes-queryBar-submit'} style={btnStyle}>查询</Button>
                    <Button htmlType={'reset'} className={'mes-queryBar-reset'} style={btnStyle} onClick={() => reset()}>重置</Button>
                    {
                        formList.length > 3 ?
                            <div style={ExtraComponent ? {display: 'inline-flex'} : {display: 'inline-block'}}>
                                <div style={ExtraComponent ? {marginRight: 0} : {}}>{ExtraComponent}</div>
                                <span onClick={() => changeStatus(!isOpen)}
                                      className={'isOpen-icon'}>{isOpen ?
                                    <span><img src={require('../../assets/close-icon.png')} alt={''}
                                               className={'isOpen-icon-img'}/>收起</span> :
                                    <span><img src={require('../../assets/open-icon.png')} alt={''}
                                               className={'isOpen-icon-img'}/>展开</span>}
                                </span>
                            </div> : null
                    }
                </Form.Item>
            </Form>
            {
                formList.length <= 3 ? <div style={{whiteSpace: 'nowrap'}}>
                    {ExtraComponent}
                </div> : null
            }
        </div>
    )
}

export default React.memo(QueryBar);

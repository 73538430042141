// 创建或者编辑的弹窗
import React, { useContext,useEffect } from "react";
import "../index.less";
import DeliveryTable from "./deliveryTable";
import ModalForm,{FormItem} from "../../../components/ModalForm";
import { UserContext } from "../../../contexts/userContext";
import moment from "moment";

interface IProps {
  // ModalForm FormInstance
  modalForm:any;
  // TableForm FormInstance
  tableForm?:any;
  // 创建时传
  createDeliverData?:any;
  // 编辑时传
  detailDelivery?:any;
  // 当前是编辑页面
  isEdit:boolean;
  // 创建时传
  setCreateDeliverData?:any
}

const CreateOrEditDelivery: React.FC<IProps> = (props) => {
  const { useInfo } = useContext(UserContext);
  const {createDeliverData,tableForm,modalForm,detailDelivery,isEdit,setCreateDeliverData} = props;
 
  useEffect(()=>{
    modalForm.setFieldsValue({
      fahuo_number:isEdit? detailDelivery.code : "系统自动生成",
      fahuo_date:isEdit ? detailDelivery.create_time && moment(detailDelivery.create_time).format("YYYY/MM/DD"): moment().format("YYYY/MM/DD"),
      fahuo_man:isEdit ?detailDelivery.user_name:useInfo.name,
      trader_name:isEdit ? detailDelivery.trader_name && detailDelivery.trader_name: createDeliverData.trader_name,
      contract_code:isEdit ?  detailDelivery.contract_code : createDeliverData.part_data[0].contract_code,
      contract_date:isEdit ?  detailDelivery.contract_date : createDeliverData.part_data[0].contract_date,
      title:isEdit ?  detailDelivery.title : createDeliverData.title,
    })
  },[])
  const formItem:FormItem[]=[
    {
      label: "发货单号",
      name: "fahuo_number",
      is_disable:true
    },
    {
      label: "发货日期",
      name: "fahuo_date", 
      is_disable:true
    },
    {
      label: "发货人",
      name: "fahuo_man",
      is_disable:true
    },
    {
      label: "客户",
      name: "trader_name",
      is_disable:true
    },
    {
      label: "合同编号",
      name: "contract_code",
      is_edit:true,
    },
    {
      label: "合同日期",
      name: "contract_date",
      // is_datePicker: true,
    },
    {
      label: "单据标题",
      name: "title",
      is_row: true,
      is_edit:true,
      rules:[{ required: true, message: '请输入单据标题!' },
        { max:32, message: '内容长度不能超过32' }
      ]
    },
  ];

  return (
    <div className="create_edit_delivery">
        <ModalForm form={modalForm} formItem={formItem}
        form_name="create_edit_delivery_form"/>
        <DeliveryTable is_Edit={isEdit} detailDelivery={detailDelivery} setCreateDeliverData={setCreateDeliverData} tableForm={tableForm} createDeliverData={createDeliverData}/>
    </div>
  );
};
export default CreateOrEditDelivery;

import {extend} from 'umi-request';
import type {ResponseInterceptor} from 'umi-request';
import {message} from 'antd';
import {createHashHistory} from "history";

const errorInterceptors: ResponseInterceptor = async (response) => {
    if (response.status === 401) {
        message.error('登录已过期！').then(()=>{
            let history = createHashHistory()
            history.push('/')
            window.sessionStorage.setItem('isLogin', 'null')
            window.location.reload()
        })
        return response
    }
    return response
};

/** 配置request请求时的参数 */
export const request = extend({
    responseType: 'json',
    requestType: "form",
    // timeout: 20000,
    credentials: "include",
});
request.interceptors.response.use(errorInterceptors);

// ==========================工艺管理=======================
// 请求工艺管理列表
export async function getCraftList(
    params?: API.getCraftListParams & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicData>>(`/api/production/get_production_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 请求工艺详情
export async function getCraftInfo(
    params?: API.getCraftInfoParams,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>(`/api/production/get_production_detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 新增或者编辑工艺信息
export async function addOrEditCraft(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>('/api/production/update_production', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 复制工艺
export async function copyCraft(
    params?: API.copyCraftParams & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>(`/api/production/copy_production`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 同步工艺
export async function synchronousCraft(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>('/api/production/synchronization', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}



// ===========================发货管理========================
// 发货查询
export async function getDeliverList(
    params?: API.getDeliverList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/deliver/get_deliver_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 发货单管理
export async function getDeliverManagement(
    params?: API.getDeliverList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/deliver/deliver_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 订单零件信息，创建任务单前调用
export async function getOrderPartInfo(
    params?: API.orderPartInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>(`/api/deliver/get_orderpart_info`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 新建或者编辑发货单
export async function createOrEditDeliver(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/deliver/update_deliver', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 发货单详情
export async function getdeliverDetail(
    params?: API.deliverDetail,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>(`/api/deliver/get_deliver_detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 删除发货单
export async function deleteDeliver(
    body?:API.deleteDeliver,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/deliver/delete_deliver', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 已交货
export async function submitDeliver(
    body?: API.submitDelivery,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/deliver/deliver_yet', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 新增发货管理接口，发货管理页面的列表获取接口
export async function getOrderPartList(
    params?: API.getOrderPartList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/deliver/get_orderpart_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}



// ====================发票管理===================
// 获取发票列表
export async function getInvoiceList(
    params?: API.getInvoiceListParams,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/invoice/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 发票信息
export async function getInvoiceDetail(
    params?: API.getInvoiceDetail,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicData>>(`/api/invoice/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 编辑发票信息
export async function editInvoice(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/invoice/edit', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}


// =============================综合查询/报工=============
// 报工列表
export async function getReportList(
    params?: API.getReportList,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/common/report/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 报工详情
export async function getReportDetail(
    params?: API.getReportDetail,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicData>>(`/api/common/report/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 强制报工
export async function forceReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/common/compulsory/report', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 强制修改
export async function forceModify(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/common/compulsory/report/modify', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}




  // ================综合查询/返修===============
    export async function getRepairList(
        params?: API.getRepairList,
        options?: { [key: string]: any }
    ) {
        return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/common/repair/list`, {
            method: 'GET',
            params,
            ...(options || {}),
        })
    }


// ================综合查询/外协，返厂===============
// 外协、返厂监控列表
export async function getWorkList(
    params?: API.getWorkList,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/common/work/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 创建外协、返厂转序单
export async function createRecord(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/common/create/record', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 外协、返厂转序单列表
export async function getRecordList(
    params?: API.recordList,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr>>(`/api/common/record/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
//  外协、返厂转序单详情
export async function getRecordDetail(
    params?: API.recordDetail,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>(`/api/common/record/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// ================综合查询/入库查询===============
export async function getStorageList(
    params?: API.getStorageList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr& API.sameData>>(`/api/storage/get_storage_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


// ================综合查询/质检监控===============
// 质检监控
export async function getCheckList(
    params?: API.getCheckList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr& API.sameData>>(`/api/storage/get_check_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 完成质检
export async function endCheck(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr& API.sameData>>('/api/wechat/end_check', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
// 获取质检详情
export async function getCheckInfo(
    params?: API.getCheckInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any>>(`/api/storage/get_check_detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


// ================综合查询/成本核算===============
// 成本核算列表
export async function getCostList(
    params?: API.getCostList,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr& API.sameData>>(`/api/cost_accounting/list_cost_accounting`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 报工明细
export async function getWorkReportDetail(
    params?: API.workReportDetail,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr& API.sameData>>(`/api/cost_accounting/work_report_details`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


// ================综合查询/操作员统计===============
// 操作员统计
export async function getOperatorList(
    params?: API.getOperatorList,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<{data:API.getOperatorListResponse[]}>>(`/api/operator_statistics/list_operator_statistics`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 报工明细
export async function getWorkReportDetailForOperator(
    params?: API.workReportDetailForOperator,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr& API.sameData>>(`/api/operator_statistics/work_report_detailed`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// ================综合查询/设备使用率===============
// 获取设备使用率列表
export async function getCapacityUtilizationList(
    params?: API.getCapacityUtilizationList,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.getCapacityUtilizationListResponse[]>>(`/api/capacity_utilization/list_capacity_utilization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 获取设备使用率详情
export async function getCapacityUtilizationDetail(
    params?: API.getCapacityUtilizationDetail,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.getCapacityUtilizationDetailResponse[]>>(`/api/capacity_utilization/get_capacity_utilization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
// 设备使用率导出
export async function getExportCapacityUtilization(
    params?: API.exportCapacityUtilization,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<any[]>>(`/api/capacity_utilization/export_capacity_utilization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}



// =============================系统管理============================
// 获取系统参数列表
export async function systemInfo(
    params?:  API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr & API.sameData>>(`/api/system_parameter/list_system_parameter`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 修改系统参数
export async function modifySystemParams(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/system_parameter/update_system_parameter', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// 获取系统日志列表
export async function systemLog(
    params?:  API.systemLog & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr & API.sameData>>(`/api/system_logs/list_system_logs`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取用户列表
export async function getUserList(
    params?: API.userListParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/user/list_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新建或修改用户
export async function createOrUpdateUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/create_or_update_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取获取用户角色
export async function getUserRole(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getUserRoleResponse>(`/api/user/get_user_role`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//用户授权
export async function roleUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/role_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//重置密码
export async function resetPassword(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/reset_password', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//启用/禁用用户
export async function invokeDisableUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/invoke_disable_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除用户
export async function deleteUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/delete_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取用户信息
export async function getUser(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/user/get_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取文件上传url
export async function getUploadUrl(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/file/get_upload_url', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//上传头像
export async function updateProfilePhoto(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/update_profile_photo', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取角色列表
export async function getRoleList(
    params: API.roleListParams,
    options?: { [key: string]: any }
) {
    return request<API.roleListResponse>(`/api/role/list_role`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改角色
export async function createOrUpdateRole(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/create_or_update_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除角色
export async function deleteRole(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/delete_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取角色信息
export async function getRole(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getRoleResponse>(`/api/role/get_role`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//角色授权
export async function authorizationRole(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/authorization_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//APP角色授权
export async function authorizationRoleApp(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/app_authorization_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取角色权限
export async function getRoleAuthority(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getUserRoleResponse>(`/api/role/get_role_authority`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据ID获取APP授权
export async function getRoleAuthorityApp(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getUserRoleResponse>(`/api/role/get_role_app_authority`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取组织机构
export async function getOrganizationList(
    params: API.organizationListParams,
    options?: { [key: string]: any }
) {
    return request<API.organizationListResponse>(`/api/organization/list_organization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新建或修改组织机构
export async function createOrUpdateOrganization(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/organization/create_or_update_organization', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除组织机构
export async function deleteOrganization(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/organization/delete_organization', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取组织结构树
export async function getOrganizationTree(
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/organization/get_organization_tree`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据ID获取组织机构信息
export async function getOrganization(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getOrganizationResponse>(`/api/organization/get_organization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取岗位列表
export async function getStationList(
    params: API.jobListParams,
    options?: { [key: string]: any }
) {
    return request<API.jobListResponse>(`/api/station/list_station`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改岗位
export async function createOrUpdateStation(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/station/create_or_update_station', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除岗位
export async function deleteStation(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/station/delete_station', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据id获取岗位
export async function getStation(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getJobResponse>(`/api/station/get_station`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// =============================基础信息============================
//工艺路线列表
export async function getWorkmanshipInfo(
    params?: API.WorkmanshipRequest,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function WorkmanshipCreate(
    body: any,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/workcraft/create`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function WorkmanshipUpdate(
    body: any,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft/update`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// 工艺路线详情
export async function getWorkmanshipDetail(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/work_craft/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function WorkmanshipDelete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
//设备信息列表
export async function deviceInfo(
    params?:   API.deviceinfo & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr & API.sameData>>(`/api/deviceinfo`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//设备信息创建
export async function deviceInfoCreate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/deviceinfo/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//设备信息更新
export async function deviceInfoUpdate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/deviceinfo/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// 设备信息详情
export async function deviceInfoDetail(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.deviceInfoDetailResponse>>(`/api/deviceinfo/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//删除设备信息
export async function deviceInfoDelete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/deviceinfo/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//新增客商信息
export async function traderInfoCreate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/traderinfo/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//客商信息更新
export async function traderInfoUpdate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/traderinfo/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除客商信息
export async function traderInfoDelete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/traderinfo/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//客商信息列表
export async function traderInfo(
    params?:   API.traderInfo & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr & API.sameData>>(`/api/traderinfo`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//客商信息详情
export async function traderInfoDetail(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/traderinfo/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//创建不良原因
export async function defectReasonCreate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/defectreason/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//更新不良信息
export async function defectReasonUpdate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/defectreason/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除不良信息
export async function defectReasonDelete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/defectreason/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//不良信息列表
export async function defectReason(
    params?:   API.defectReasonParams & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr & API.sameData>>(`/api/defectreason`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取不良信息详情
export async function defectReasonDetail(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/defectreason/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//创建工序
export async function workProcessCreate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/workprocess/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//更新工序
export async function workProcessUpdate(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/workprocess/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//工序删除
export async function workProcessDelete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<null>>('/api/workprocess/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//工序列表
export async function workProcess(
    params?:   API.workProcessParams & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.basicDataArr & API.sameData>>(`/api/workprocess`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//工序详情
export async function workProcessDetail(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.ApiResponseBasic<API.workProcessDetailResponse>>(`/api/workprocess/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// =============================生产管理============================
//订单列表
export async function getOrderList(
    params: API.apiOrderList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/order/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//修改加工分类
export async function processingCategory(
    body?: any,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/processing/category', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//创建投产任务
export async function createTask(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/create/task', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//查看投产记录
export async function orderTaskList(
    params?: API.orderTaskList,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/task/list', {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//查看零件工艺信息
export async function partPrecesses(
    params?: API.orderTaskList,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/part/precesses', {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//任务列表
export async function getTaskList(
    params?: API.getTaskList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/task/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//任务详情
export async function getTaskDetail(
    params?: API.taskProcess,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/task/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//编辑任务
export async function taskEdit(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/task/edit', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除任务
export async function taskDelete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/task/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//下达任务
export async function taskIssue(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/task/issue', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//过程卡、任务进度
export async function taskProcess(
    params: API.taskProcess,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/task/process`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//返修记录
export async function taskRepairList(
    params: API.taskProcess,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/task/repair/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//任务进度
export async function taskProgress(
    params: API.taskProcess,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/task/progress`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//数量调整
export async function taskModifyQuantity(
    body?: any,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/task/modify/quantity', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//关闭任务单
export async function taskClose(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/task/close', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//撤回任务
export async function taskRecall(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/task/recall', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// =============================订单管理============================

//新增或编辑订单
export async function orderUpdateOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/update_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
//下发订单
export async function orderSendOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/send_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}
//删除订单
export async function orderDeleteOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/delete_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//关闭订单
export async function orderCloseOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/close_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取订单列表
export async function orderGetOrderList(
    params: API.orderGetOrderList & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/order/get_order_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取零件列表
export async function partInfo(
    params?: API.partInfo & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/partinfo`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//撤回订单
export async function orderRecall(
    body?: FormData,
    params?:{},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/order/reback_order`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取订单详情
export async function orderGetOrderDetail(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/order/get_order_detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}
//综合管理
export async function orderGetCompositeList(
    params: API.orderGetComposite & API.pageInfo,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/order/get_composite_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// =============================登录注册============================
//登录
export async function login(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/login', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取验证码
export async function getCaptcha(
    params?: API.listParams,
    options?: { [key: string]: any }
) {
    return request<string>(`/api/user/get_captcha`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取当前用户信息
export async function getCurrentUser(
    params?: API.listParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/user/current_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//退出登录
export async function logout(
    body?: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/logout', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//修改密码
export async function changePassword(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/change_password', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// =============================主画面============================
//主页面获取实时任务统计
export async function getTaskStatistics(
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.DashboardStatisticsResponse>(`/api/index/get_task_statistics`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//主页面获取实时质检统计
export async function getCheckStatistics(
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.DashboardStatisticsResponse>(`/api/index/get_check_statistics`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


// =======================获取下拉框专用用户列表=======================
export async function getUserListForSelect(
    params?: API.userListParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/user/list_user_select`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//综合管理修改零件数量
export async function changePartNumber(
    body: API.changePartNumber,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/order/update_part_number', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

import React, {useRef, useState} from "react";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {Button, Form, Input, message, Space} from "antd";
import ModalContainer from "../../../components/Modal";
import DeleteItem from "../../../components/DeleteItem";
import {createOrUpdateStation, deleteStation, getStation, getStationList} from "../../../service";
import {filterData, getFormData, showError} from "../../../utils";
import '../index.less';
import {ColumnsType} from "antd/es/table";

enum ModalTitle {
    ADD = '新增岗位',
    EDIT = '编辑岗位',
    DELETE = '系统确认',
}

const Job = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement>(<div/>);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'station_name',
            name: '名称',
            placeholder: '请输入岗位名称'
        }
    ]
    const columns: ColumnsType<any> = [
        {
            title: '岗位名称',
            dataIndex: 'station_name',
            key: 'station_name',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            width: 400,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-user-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-user-options'} style={{marginRight: 0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },

    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-user-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const AddOrEdit = React.memo((): React.ReactElement => {

        return (
            <Form colon={false} form={form} className={'form-margin'}>
                <Form.Item label={'岗位名称'} name={'station_name'} rules={[
                    {required: true, message: '请输入岗位名称!'},
                    {max: 32, message: '岗位名称不得大于32位!'}]}>
                    <Input placeholder={'请输入岗位名称'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remarks'} rules={[{max: 200, message: '备注不得大于200位！'}]}
                >
                    <Input placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEdit/>)
        } else if (title === ModalTitle.EDIT) {
            getStation({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        station_name: res.data.station_name,
                        remarks: res.data.remarks
                    })
                    setInnerComponent(<AddOrEdit/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createOrUpdateStation(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                createOrUpdateStation(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteStation(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null)
        refreshData()
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(<div/>)
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getStationList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const setWidth = () => {
        if (modalTitle !== ModalTitle.DELETE) {
            return '40%'
        }
    }

    return (
        <div className={'mes-job'}>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open} width={setWidth()}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}
export default Job

import React, {useRef, useState} from "react";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {Table} from "antd";
import {

    orderGetOrderList
} from "../../../service";
import {
    filterQueryDataOrder, fontColor,
    formatNumber, orderStatusList,
    showError,
    wayList
} from "../../../utils";
import {ColumnsType} from "antd/es/table";
import moment from "moment";


const OrderView = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'trader_name',
            name: '客户名称',
            width: 60,
            placeholder: '请输入客户名称'
        },
        {
            key: 'code',
            name: '订单号',
            width: 60,
            placeholder: '请输入订单号'
        },
        {
            key: 'status',
            name: '订单状态',
            width: 60,
            placeholder: '请选择订单状态',
            options: orderStatusList
        },
        {
            key: 'deliveryDate',
            name: '交货日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'createDate',
            name: '创建日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'issueDate',
            name: '下发日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            align: "center",
            width: 60,
            ellipsis: true,
            fixed: "left",
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        Table.EXPAND_COLUMN,
        {
            title: '订单号',
            dataIndex: 'code',
            key: 'orderNumber',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '客户',
            dataIndex: ['trader', 'name'],
            key: 'customer',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '交货日期',
            dataIndex: 'finish_date',
            key: 'deliveryDate',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 90,
            render: (value: string) => {
                return (orderStatusList.map(e => {
                    if (e.id === Number(value)) {
                        return <img key={e.id} src={e.src} alt={''} style={{width: 60}}/>
                    }
                }))
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 300,
            ellipsis: true
        },
        {
            title: '创建者',
            dataIndex: ['user', 'name'],
            key: 'creator',
            align: 'center',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'createTime',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '下发时间',
            dataIndex: 'issue_time',
            key: 'issueTime',
            align: 'center',
            width: 200,
            ellipsis: true
        },
    ]

    const getDataAsync = (queryCondition: API.orderGetOrderList & API.pageInfo) => {
        return new Promise((resolve) => {
            orderGetOrderList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const expandedRowRender = (record: any): React.ReactElement => {
        const columns: ColumnsType<any> = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                align: "center",
                width: 60,
                ellipsis: true,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '零件名称',
                dataIndex: ['part', 'name'],
                key: 'partName',
                align: 'center',
                ellipsis: true
            },
            {
                title: '零件图号',
                dataIndex: ['part', 'number'],
                key: 'partNumber',
                align: 'center',
                ellipsis: true
            },
            {
                title: '供料方式',
                dataIndex: ['part', 'material_type'],
                key: 'way',
                align: 'center',
                ellipsis: true,
                render: (value: number) => {
                    return wayList.map(e => {
                        if (e.id === value) {
                            return <span key={e.id}>{e.name}</span>
                        } else {
                            return null
                        }
                    })
                }
            },
            {
                title: '材料',
                dataIndex: ['part', 'material'],
                key: 'material',
                align: 'center',
                ellipsis: true
            },
            {
                title: '订单数量',
                dataIndex: 'number',
                key: 'orderAccount',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '加工内容',
                dataIndex: 'content',
                key: 'content',
                align: 'center',
                ellipsis: true
            },
            {
                title: '交货日期',
                dataIndex: 'finish_date',
                key: 'deliveryDate',
                align: 'center',
                ellipsis: true,
                width: 200,
                render: (value: string) => {
                    if (value) {
                        return <span>{moment(value).format('YYYY/MM/DD')}</span>
                    }
                }
            },
            {
                title: '合同数量',
                dataIndex: 'contract_number',
                key: 'contractQuantity',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                ellipsis: true
            },
        ]
        return <Table columns={columns} dataSource={record.order_part} pagination={false} className={'expand-table'}/>
    }

    return (
        <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync} ref={gRef}
                           scrollValue={{x:1390}} expandedRowRender={expandedRowRender} filterQueryData={filterQueryDataOrder}/>
    )
}
export default OrderView

import React, {useContext, useEffect, useState} from "react";
import {DatePicker, Form, FormInstance, Input, Select, Table, Row, Col, InputNumber} from "antd";
import {formatNumber, showError, wayList} from "../../../utils";
import {traderInfo} from "../../../service";
import {UserContext} from "../../../contexts/userContext";
import {useNavigate} from "react-router-dom";
import {ModalTitle} from "./index";

interface ViewProps {
    title?: string,
    form: FormInstance,
    disabled: boolean,
    dataSource: any[],
}

const View = React.memo((props: ViewProps): React.ReactElement => {
    const {title, form, disabled, dataSource} = props;
    const {useInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const defaultColumns = [
        {
            title: "No",
            dataIndex: "No",
            key: "No",
            width: 60,
            ellipsis: true,
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        {
            title: "零件名称",
            dataIndex: ['part', 'name'],
            key: 'part_name',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: "零件图号",
            dataIndex: ['part', 'number'],
            key: 'part_number',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: "供料方式",
            dataIndex: ['part', 'material_type'],
            key: 'part_material_type',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: number) => {
                return wayList.map(e => {
                    if (e.id === value) {
                        return <span key={e.id}>{e.name}</span>
                    }
                })
            }
        },
        {
            title: "材料",
            dataIndex: ['part', 'material'],
            key: 'part_material',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: "订单数量",
            dataIndex: 'number',
            key: 'number',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: string) => <div
                style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
        },
        {
            title: "加工内容",
            dataIndex: 'content',
            key: 'content',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: "交货日期",
            dataIndex: 'finish_date',
            key: 'finish_date',
            width: 200,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 400,
            ellipsis: true,
            align: 'center',
        },
    ];
    const [columns, setColumns] = useState<any[]>(defaultColumns);
    const [customerList, setCustomerList] = useState<any[]>([]);
    const skipPage = (router: string) => {
        if (useInfo.authRoutes?.includes(router)) {
            navigate(router)
        }
    }

    useEffect(() => {
        if (title === ModalTitle.VIEW) {
            const tempColumns: any[] = [
                {
                    title: "No",
                    dataIndex: "No",
                    key: "No",
                    width: 60,
                    ellipsis: true,
                    fixed: "left",
                    align: 'center',
                    render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
                },
                {
                    title: "零件名称",
                    dataIndex: ['part', 'name'],
                    key: 'part_name',
                    width: 150,
                    ellipsis: true,
                    fixed: "left",
                    align: 'center',
                },
                {
                    title:"零件图号",
                    dataIndex: ['part', 'number'],
                    key: 'part_number',
                    width: 150,
                    ellipsis: true,
                    fixed: "left",
                    align: 'center',
                },
                {
                    title: "订单数量",
                    dataIndex: 'number',
                    key: 'number',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: "供料方式",
                    dataIndex: ['part', 'material_type'],
                    key: 'part_material_type',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                    render: (value: number) => {
                        return wayList.map(e => {
                            if (e.id === value) {
                                return <span key={e.id}>{e.name}</span>
                            }
                        })
                    }
                },
                {
                    title: "材料",
                    dataIndex: ['part', 'material'],
                    key: 'part_material',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: "加工内容",
                    dataIndex: 'content',
                    key: 'content',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: "交货日期",
                    dataIndex: 'finish_date',
                    key: 'finish_date',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '报价单价',
                    dataIndex: 'quot_single_price',
                    key: 'quot_single_price',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '报价金额',
                    dataIndex: 'quot_price',
                    key: 'quotationAmount',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同数量',
                    dataIndex: 'contract_number',
                    key: 'contract_number',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同单价',
                    dataIndex: 'contract_single_price',
                    key: 'contract_single_price',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同金额',
                    dataIndex: 'contract_price',
                    key: 'contractAmount',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合同号',
                    dataIndex: 'contract_code',
                    key: 'contract_code',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '合同日期',
                    dataIndex: 'contract_date',
                    key: 'contract_date',
                    ellipsis: true,
                    width: 150,
                    align: 'center',
                },
                {
                    title: '外协单价',
                    dataIndex: 'out_single_price',
                    key: 'out_single_price',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '外协说明',
                    dataIndex: 'out_remark',
                    key: 'out_remark',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    key: 'remark',
                    width: 400,
                    ellipsis: true,
                    align: 'center',
                },
            ]
            setColumns(tempColumns)
        } else {
            setColumns(defaultColumns)
        }
    }, [title])

    useEffect(() => {
        //获取客商信息中类别为客户、客商的客户信息
        traderInfo().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    setCustomerList(res.data.data)
                }
            } else {
                showError(res.message);
            }
        })
    }, [])


    return (
        <div className={'form-margin'}>
            <Form form={form} colon={false} disabled={disabled}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'订单号'} key={'code'} name={'code'}>
                            <Input placeholder={'请输入订单号'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={useInfo.authRoutes?.includes('/basic/businessman') ?
                            <span className={'mes-user-options'}
                                  onClick={() => {
                                      skipPage('/basic/businessman')
                                  }}
                            >客户</span> : <span>客户</span>
                        } key={'trader_id'} name={'trader_id'}>
                            <Select placeholder={'请选择客户'}>
                                {
                                    customerList.map((oe: any, oi: number) =>
                                        <Select.Option key={oi} value={oe.object_id}>{oe.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'交货日期'} key={'finish_date'} name={'finish_date'}>
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    title === ModalTitle.VIEW &&
                    <Row>
                        <Col span={8}>
                            <Form.Item label={'报价金额'} key={'offer_price'} name={'offer_price'}>
                                <InputNumber placeholder={'自动计算'} disabled step={0.01}
                                             formatter={value => value ? formatNumber(value.toString()) : ''}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'合同金额'} key={'contract_price'} name={'contract_price'}>
                                <InputNumber placeholder={'自动计算'} disabled step={0.01}
                                             formatter={value => value ? formatNumber(value.toString()) : ''}/>
                            </Form.Item>
                        </Col>

                    </Row>
                }
                <Row>
                    <Col span={24}>
                        <Form.Item label={'备注'} key={'remark'} name={'remark'}
                                   rules={[{max: 200, message: '备注不得大于200位！'}]}>
                            <Input.TextArea placeholder={'请输入备注'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'创建者'} key={'creator'} name={'creator'}>
                            <Input placeholder={'请输入创建者'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'创建时间'} key={'createTime'} name={'createTime'}>
                            <DatePicker format={'YYYY/MM/DD HH:mm'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'下发时间'} key={'issueTime'} name={'issueTime'}>
                            <DatePicker format={'YYYY/MM/DD HH:mm'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className={'edit-table-title'} style={{marginBottom: 20}}>订单零件信息</div>
            <Table columns={columns} dataSource={dataSource} pagination={false}
                   scroll={title === ModalTitle.VIEW ? {x: 2000} : {x:1600}}
                   rowKey={'object_id'}/>
        </div>
    )
})


export default View;

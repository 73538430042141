import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { Form, Tooltip, message } from "antd";
import ModalContainer from "../../../components/Modal";
import "../index.less";
import {
  formatTime,
  formatNumber,
  getUsersForSelect,
  getWorkprocesss,
  filterDateParam,
  indexType,
  InspectionCategory,
  InspectionStatus,
  getFormData,
  filterData,
} from "../../../utils/index";
import { ColumnsType } from "antd/es/table";
import MButton from "../../../components/MyButton";
import FinishCheck from "./components/FinishCheck";
import ExternalCheck from "./components/ExternalCheck";
import FirstCheck from "./components/FirstCheck";
import ExportExcel from "../../../components/ExportTable/exportTable";
import moment from "moment";
import { IOptions } from "../ReportMonitor";
import { getCheckList, endCheck,getCheckInfo } from "../../../service";
import MyImage from "../../../components/MyImage";
import noStart from "../../../assets/status/notStart.png";
import processing from "../../../assets/status/processing.png";
import completed from "../../../assets/status/completed.png";
import complete from "../../../assets/complete.png";

enum ModalTitle {
  FIRST_CHECK = "首件检验",
  EXTERNAL_CHECK = "外协检验",
  LAST_CHECK = "最终检验",
}

const QualityMonitor = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  const [selectData, setSelectData] = useState<{ [key: string]: IOptions[] }>({});
  const [objectId,setObjectId]= useState<number>();
  const gRef = useRef<GRef>(null);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [formList, setFormList] = useState<QueryListItem[]>([
    {
      key: "category",
      name: "检验类别",
      placeholder: "请选择检验类别",
      options: InspectionCategory,
    },
    {
      key: "status",
      name: "检验状态",
      placeholder: "请选择检验状态",
      multiple: true,
      options: InspectionStatus,
      defaultValue: [20],
    },
    {
      key: "code",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      key: "task_number",
      name: "任务单号",
      placeholder: "请输入任务单号",
    },
    {
      key: "send_user_id",
      name: "送检人",
      placeholder: "请选择送检人",
      options: selectData.users,
    },
    {
      key: "work_id",
      name: "质检工序",
      placeholder: "请选择质检工序",
      options: selectData.processes,
    },
    {
      key: "date",
      name: "送检日期",
      placeholder: "请选择送检日期",
      rangePick: true,
    },
    {
      key: "check_user_id",
      name: "检验员",
      placeholder: "请选择检验员",
      options: selectData.users,
    },
    {
      key: "check_date",
      name: "检验日期",
      placeholder: "请选择检验日期",
      rangePick: true,
    },
  ]);
  useEffect(() => {
    getWorkprocesss((processes: IOptions[]) => {
      
      setSelectData({
        ...selectData,
        processes: processes,
      });
      let newFormList = formList;
      newFormList[6].options = processes;
      setFormList(newFormList);
    });
    getUsersForSelect((users: IOptions[]) => {
      setSelectData({
        ...selectData,
        users: users,
      });
      let newFormList = formList;
      newFormList[5].options = users;
      newFormList[8].options = users;
      setFormList(newFormList);
    });
  }, []);
  
  const openModal = (modalTitle: ModalTitle | null, item: any) => {
    // item其实是请求质检详情的数据
    setModalTitle(modalTitle);
    setOpen(true);
    if (modalTitle === "首件检验") {
      setInnerComponent(
        <FirstCheck
          modalForm={form}
          selectedItem={item}
          modalForm1={form1}
        />
      );
    } else if (modalTitle === "外协检验") {
      setInnerComponent(
        <ExternalCheck
          modalForm={form}
          selectedItem={item}
          modalForm1={form1}
        />
      );
    } else {
      setInnerComponent(
        <FinishCheck
          modalForm={form}
          selectedItem={item}
          modalForm1={form1}
          // modalForm2={form2}
        />
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      ellipsis: true,
      fixed:true,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 90,
      fixed:true,
      render: (status: number) => {
        return status === 10 ? (
          <MyImage src={noStart} width={60}></MyImage>
        ) : status === 20 ? (
          <MyImage src={processing} width={60}></MyImage>
        ) : (
          <MyImage src={completed} width={60}></MyImage>
        );
      },
    },
    {
      title: "质检类别",
      dataIndex: "category",
      key: "category",
      align: "center",
      ellipsis: true,
      width: 90,
      fixed:"left",
      render: (kind: number) => {
        const obj: indexType = {
          10: "首件自检",
          20: "定时自检",
          30: "首件检验",
          40: "外协检验",
          60: "巡检",
          50: "终检",
        };
        // 断言kind是obj的key的类型
        // console.log("kind", obj[kind as keyof typeof obj]);
        return <div>{kind && obj[kind]}</div>;
      },
    },
    {
      title: "订单号",
      dataIndex: "order_code",
      key: "order_code",
      align: "center",
      width: 140,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "零件名称",
      dataIndex: "part_name",
      key: "part_name",
      align: "center",
      ellipsis: true,
      width: 140,
      fixed: "left",
    },
    {
      title: "零件图号",
      dataIndex: "part_number",
      key: "part_number",
      align: "center",
      ellipsis: true,
      width: 140,
      fixed: "left",
    },
    {
      title: "订单数量",
      dataIndex: "number",
      key: "number",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(item)=> <div>{item && formatNumber(item.toString())}</div>
    },
    {
      title: "任务单号",
      dataIndex: "task_number",
      key: "task_number",
      align: "center",
      ellipsis: true,
      width: 160,
    },
    {
      title: "质检工序",
      dataIndex: "work_process_name",
      key: "work_process_name",
      align: "center",
      ellipsis: true,
      width: 140,
      render:(item,record)=> {
        if(record.category === 50){
          return <div>-</div>
        } else {
          return  <div>{item}</div>
        }
      }
    },
    {
      title: "送检数量",
      dataIndex: "send_quantity",
      key: "send_quantity",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(item,record)=> {
        if(record.category === 10 || record.category === 20 || record.category === 60){
          return <div>-</div>
        } else {
          return  <div>{item && formatNumber(item.toString())}</div>
        }
      }
    },
    {
      title: "送检人",
      dataIndex: "send_user", 
      key: "send_user",
      align: "center",
      ellipsis: true,
      width: 100,
      render:(item,record)=> {
        if(record.category === 10 || record.category === 20 || record.category === 60){
          return <div>-</div>
        } else {
          return  <div>{item}</div>
        }
      }
    },
    {
      title: "送检时间",
      dataIndex: "send_time",
      key: "send_time",
      align: "center",
      ellipsis: true,
      width: 130,
      render:(item,record)=> {
        if(record.category === 10 || record.category === 20  || record.category === 60){
          return <div>-</div>
        } else {
          return  <div>{item && formatTime(item, true)}</div>
        }
      }
    },
    {
      title: "检验员",
      dataIndex: "user", 
      key: "user",
      align: "center",
      ellipsis: true,
      width: 100,
    },
    {
      title: "开始时间",
      dataIndex: "create_time", 
      key: "create_time",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
      ),
    },
    {
      title: "完成时间",
      dataIndex: "end_time",
      key: "end_time",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
      ),
    },
    {
      title: "质检结果",
      dataIndex: "is_qualified",
      key: "is_qualified",
      align: "center",
      ellipsis: true,
      width: 90,
      render:(item,record)=> {
        if(record.category === 10 || record.category === 30){
          if(record.status === 10 || record.status === 20) {
            return <span></span>
          }else {
            return <div>{item === 0 ?"不良":"合格"}</div>
          }
        } else {
          return  <div>-</div>
        }
      }
    },
    {
      title: "合格数量",
      dataIndex: "qualified",
      key: "qualified",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(item,record)=> {
        if(record.category === 10 ||  record.category === 30){
          return <div>-</div>
        } else {
          return  <div>{item && formatNumber(item.toString())}</div>
        }
      }
    },
    {
      title: "不良数量",
      dataIndex: "defective",
      key: "defective",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(item,record)=> {
        if(record.category === 10 ||  record.category === 30){
          return <div>-</div>
        } else {
          return  <div>{item && formatNumber(item.toString())}</div>
        }
      }
    },
    {
      title: "不良原因",
      dataIndex: "defect_reason_id",
      key: "defect_reason_id",
      align: "center",
      ellipsis: true,
      width: 140,
    },
    {
      title: "让步放行数量",
      dataIndex: "pass_quantity",
      key: "pass_quantity",
      align: "right",
      ellipsis: true,
      width: 130,
      render:(item,record)=> {
        if(record.category === 50){
          return <div>{item && formatNumber(item.toString())}</div>
        } else {
          return  <div>-</div>
        }
      }
    },
    {
      title: "返修数量",
      dataIndex: "repair_quantity",
      key: "repair_quantity",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(item,record)=> {
        if(record.category === 50){
          return <div>{item && formatNumber(item.toString())}</div>
        } else {
          return  <div>-</div>
        }
      }
    },
    {
      title: "报废数量",
      dataIndex: "useless_quantity",
      key: "useless_quantity",
      align: "right",
      ellipsis: true,
      width: 90,
      render:(item,record)=> {
        if(record.category === 50){
          return <div>{item && formatNumber(item.toString())}</div>
        } else {
          return  <div>-</div>
        }
      }
    },
    {
      title: "更新人",
      dataIndex: "update_user",
      key: "update_user",
      align: "center",
      ellipsis: true,
      width: 100,
    },
    {
      title: "更新时间",
      dataIndex: "update_time", 
      key: "update_time",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
      ),
    },
    {
      title: "操作",
      dataIndex: "options",
      key: "options",
      align: "center",
      // width: 90,
      fixed:"right",
      render: (_: any, record: any) => {
        
        return record.status === 20 &&
          (record.category === 30 ||
            record.category === 40 ||
            // 终检为50
            record.category === 50) ? (
          <div
            style={{
              color: "#3E7BFA",
              cursor: "pointer",
            }}
          >
            <MyImage src={complete} title="完成" onClick={
              () => {
                setObjectId(record.object_id);
                getCheckInfo({
                  object_id:record.object_id
                }).then(res => {
                 
                  record.category === 30 &&
                  openModal(ModalTitle.FIRST_CHECK, res.data);
                 record.category === 40 &&
                  openModal(ModalTitle.EXTERNAL_CHECK, res.data);
                 record.category === 50 &&
                  openModal(ModalTitle.LAST_CHECK, res.data);
                })
               
              }
            }></MyImage>
          </div>
        ) : (
          <div></div>
        );
      },
    },
  ];
  const ExtraComponent = React.memo((): React.ReactElement => {
    const exportExcel = async(callback:any) => {
      if (gRef.current) {
          if (gRef.current.exportData) 
          {
              gRef.current.exportData().then(res => {
               
                  const obj: indexType = {
                    10: "首件自检",
                    20: "定时自检",
                    30: "首件检验",
                    40: "外协检验",
                    60: "巡检",
                    50: "终检",
                  };
                  const statusObj: indexType = {
                    10: "未开始",
                    20: "处理中",
                    30: "已完成",
                  }
                  res.map((a:any,i:any) => {
                    a.create_time ? a.create_time = formatTime(a.create_time,true) : a.create_time = a.create_time;
                    a.end_time ? a.end_time = formatTime(a.end_time,true) : a.end_time = a.end_time;
                    a.update_time ? a.update_time = formatTime(a.update_time,true) : a.update_time = a.update_time;
                      a.category === 50 ? a.work_process_name = "-":  a.work_process_name = a.work_process_name
                    
                    
                      if(a.category === 10 || a.category === 20 || a.category === 60 ){              
                        a.send_quantity = "-"
                      }else {
                        a.send_quantity = a.send_quantity
                      }
                  
                    
                      if(a.category === 10 || a.category === 20 || a.category === 60 ){
                        a.send_user = "-" 
                      }else {
                        a.send_user = a.send_user
                     
                    }
                  
                      if(a.category === 10 || a.category === 20 || a.category === 60 ){
                        a.send_time = "-"
                      }else {
                        a.send_time = a.send_time
                      }
                    
                
                      if(a.category === 10 || a.category === 30){
                        if(a.status === 10 || a.status === 20) {
                          a.is_qualified = ""
                         }else {
                          if(a.is_qualified === 0 || a.is_qualified === 1) {
                            a.is_qualified === 0 ?  a.is_qualified = "不良": a.is_qualified = "合格"
                         }
                        }
                      }else {
                        a.is_qualified = "-"
                      }
                    
                   
                      if(a.category === 10 || a.category === 30){
                        a.qualified ="-"
                      }else {
                        a.qualified =  a.qualified
                    }

                    
                      if(a.category === 10 || a.category === 30){
                        a.defective = "-" 
                      }else {
                        a.defective =  a.defective
                      }
                   
                
                      if(a.category === 50){
                        a.pass_quantity =a.pass_quantity
                      }else {
                        a.pass_quantity =  "-"
                      }
                    
                
                      if(a.category === 50){
                        a.repair_quantity = a.repair_quantity
                      }else {
                        a.repair_quantity =  "-"
                      }
          
                      if(a.category === 50){
                        a.useless_quantity = a.useless_quantity
                      }else {
                        a.useless_quantity =  "-"
                      }
                    
                    if(a.category) {
                      a.category = obj[a.category]
                    }
                    if(a.status) {
                      a.status = statusObj[a.status]
                    }
                  })
                  let length  = res.length + 1
                  callback(res,length)
              }).catch(err => {
                console.log("导出全部数据遇到错误",err)
              })
          }
      }
  }
    const tabName =
      "质检监控" + moment(new Date()).format("YYMMDDhhmmss");

    return (
      <div className="btn_box">
        <ExportExcel
          onClick={exportExcel}
          columns={columns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={false}
        ></ExportExcel>
      </div>
    );
  });

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getCheckList(Object.assign({status:JSON.stringify([20])},queryCondition)).then((res) => {
          if (res.code === 200) {
            if(res.data.data) {
              res.data.data.map((item,index) => {
                item.index  = index + 1;
              })
            }
            resolve(res.data);
            // 实际的数据得加工使用user_id作为object_id,否则没有key
           
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);
  const filterQueryData = (queryData: any) => {
    
    if(queryData.check_date){
      queryData = filterDateParam(queryData,"check_date","check_start_time","check_end_time")
    }
    if(queryData.date) {
      queryData = filterDateParam(queryData,"date","send_start_time","send_end_time")
    }
    if(queryData.status){
      if(JSON.stringify(queryData.status) === "[]"){
        queryData.status = JSON.stringify([10,20,30]);
      }else {
        queryData.status = JSON.stringify(queryData.status)
      }
    }
    return queryData
  }
  return (
    <div className="quality_monitor">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        scroll={true}
        scrollValue={{x:2830}}
        ExtraComponent={<ExtraComponent />}
        filterQueryData={filterQueryData}
      />
      {modalTitle && (
        <ModalContainer
          width={"50%"}
          footer={[
            <div className="modal_btn">
              <MButton
                title="关闭"
                onClick={() => {
                  setOpen(false)
                }}
                buttonStyle={{
                  backgroundColor: "#B8B8B8",
                }}
              ></MButton>
              <MButton
                title="完成"
                onClick={() => {
                  if (modalTitle === "首件检验") {
                    form1.validateFields(["radio",'defect_reason_id']).then(res=>{
                      // 获取表单的值
                      const form1Values = form1.getFieldsValue(['radio','defect_reason_id']);
                     
                      const obj = {
                        object_id:objectId,
                        radio:form1Values.radio,
                        defect_reason_id:JSON.stringify(form1Values.defect_reason_id)
                      }
                      // 把udefined和null的字段去掉
                      const obj1 = filterData(obj);
                      const formData = getFormData(obj1)
                      endCheck(formData).then(res =>{
                
                        if(res.code === 200){
                          message.success("操作完成");
                          setOpen(false);
                          if(gRef.current){
                            gRef.current.onFinish()
                          }
                        }else {
                          message.error(res.message)
                        }
                      }).catch(err=>{
                        message.error(err)
                      })
                    }).catch(err =>{
                      console.log("首件检验校验错误",err)
                    })
                  } else if (modalTitle === "外协检验") {
                    form1.validateFields(["defective",'defect_reason_id']).then(res=>{
                      const form1Values = form1.getFieldsValue(['defective','defect_reason_id',"qualified"]);
                      let obj ={
                        object_id:objectId as number,
                        defective:form1Values.defective && Number(form1Values.defective),
                        defect_reason_id:form1Values.defect_reason_id && JSON.stringify(form1Values.defect_reason_id),
                        qualified: form1Values.qualified && form1Values.qualified,
                      }
                      // 把udefined和null的字段去掉
                      const obj1 = filterData(obj);
                      const formData = getFormData(obj1)
                      endCheck(formData).then(res =>{
                        if(res.code === 200){
                          message.success("操作完成");
                          setOpen(false);
                          if(gRef.current){
                            gRef.current.onFinish()
                          }
                        }else {
                          message.error(res.message)
                        }
                      }).catch(err=>{
                        message.error(err)
                      })
                    }).catch(err =>{
                      console.log("外协检验校验错误",err)
                    })
                  } else if (modalTitle === "最终检验") {
                 
                      form1.validateFields(["defective",'repair_quantity','useless_quantity','pass_quantity','defect_reason_id']).then(res1=>{
                      const form1Values = form1.getFieldsValue(['defective','defect_reason_id',"qualified",'repair_quantity','useless_quantity','pass_quantity']);
                      let obj = {
                        defective: form1Values.defective && Number(form1Values.defective),
                        defect_reason_id:form1Values.defect_reason_id &&JSON.stringify(form1Values.defect_reason_id),
                        qualified:form1Values.qualified && Number(form1Values.qualified),
                        repair_quantity: form1Values.repair_quantity && Number(form1Values.repair_quantity),
                        useless_quantity: form1Values.useless_quantity && Number(form1Values.useless_quantity),
                        pass_quantity: form1Values.pass_quantity && Number(form1Values.pass_quantity),
                        object_id:objectId as number,
                      }; 
                      // 把udefined和null的字段去掉
                      const obj1 = filterData(obj);
                      const formData = getFormData(obj1)
                      endCheck(formData).then(res =>{
                        if(res.code === 200){
                          message.success("操作完成");
                          setOpen(false);
                          if(gRef.current){
                            gRef.current.onFinish()
                          }
                        }else {
                          message.error(res.message)
                        }
                      }).catch(err=>{
                        message.error(err)
                      })
                      }).catch(err1=>{
                        console.log("最终检验校验失败",err1)
                      })
                  }
                }}
                buttonStyle={{
                  backgroundColor: "#3E7BFA",
                }}
              ></MButton>
            </div>,
          ]}
          title={modalTitle}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default QualityMonitor;

import { useState, useEffect } from "react";
import ModalForm, { FormItem } from "../../../../components/ModalForm";
import { getErrorList, formatTime,formatNumber} from "../../../../utils/index";
import { ISelectOption } from "../../ReportMonitor/components/WorkReportOrModify";
import { Form, Input,  Select, Row } from "antd";
interface IProps {
  modalForm: any;
  selectedItem: any;
  modalForm1: any;
}

const ExternalCheck = (props: IProps) => {
  const [errorList, setErrorList] = useState<ISelectOption[]>([]);
  const { selectedItem, modalForm, modalForm1 } = props;
  const [checkStatusDisabled, setCheckStatusDisabled] = useState<boolean>(true);
  const formItem: FormItem[] = [
    {
      label: "任务单号",
      name: "task_number",
      is_disable: true,
      is_row_but_noGrow: true,
      is_row: true,
    },
    {
      label: "订单号",
      name: "order_code",
      is_disable: true,
    },
    {
      label: "零件名称",
      name: "part_name",
      is_disable: true,
    },
    {
      label: "零件图号",
      name: "part_number",
      is_disable: true,
    },
    {
      label: "加工内容",
      name: "content",
      is_disable: true,
      is_row: true,
    },
    {
      label: "投产日期",
      name: "start_date",
      is_disable: true,
    },
    {
      label: "投产数量",
      name: "touchan_number",
      is_disable: true,
    },
    {
      label: "最小完成数量",
      name: "min_quantity",
      is_disable: true,
    },
    {
      label: "工序名称",
      name: "work_process_name",
      is_disable: true,
    },
    {
      label: "送检人",
      name: "send_user",
      is_disable: true,
    },
    {
      label: "送检时间",
      name: "send_time",
      is_disable: true,
    },
    {
      label: "送检数量",
      name: "send_quantity",
      is_disable: true,
    },
  ];

  useEffect(() => {
    getErrorList((errors: ISelectOption[]) => {
   
      setErrorList(errors);
    });
    modalForm.setFieldsValue({
      task_number: selectedItem.task_number,
      order_code: selectedItem.order_code,
      part_name: selectedItem.part_name,
      part_number: selectedItem.part_number,
      content: selectedItem.content,
      start_date:selectedItem.start_date && formatTime(selectedItem.start_date, true),
      touchan_number: selectedItem.touchan_number,
      min_quantity: selectedItem.min_quantity,
      work_process_name: selectedItem.work_process_name,
      send_user: selectedItem.send_user,
      send_time:selectedItem.send_time && formatTime(selectedItem.send_time, true),
      send_quantity: selectedItem.send_quantity && formatNumber(selectedItem.send_quantity.toString()),
    }); 
    modalForm1.setFieldsValue({
      user: selectedItem.user,
      create_time: selectedItem.create_time,
    });
    modalForm1.resetFields(["qualified", "defective", "defect_reason_id"]);
  }, []);

  const handleInputChange = (e: any) => {
    if (parseInt(e.target.value) > 0) {
      modalForm1.setFieldsValue({
        qualified: selectedItem.send_quantity - parseInt(e.target.value),
      });
      setCheckStatusDisabled(false);
      modalForm1
        .validateFields(["defect_reason_id"])
        .then((res: any) => {
          return Promise.resolve();
        })
        .catch((err: any) => {
          return Promise.reject("不良原因校验错误");
        });
    }
    if (parseInt(e.target.value) === 0) {
      modalForm1.setFieldsValue({
        qualified: selectedItem.send_quantity - parseInt(e.target.value),
      });
      modalForm1.setFieldsValue({ defect_reason_id: undefined });
      setCheckStatusDisabled(true);
      modalForm1
        .validateFields(["defect_reason_id"])
        .then((res: any) => {
          return Promise.resolve();
        })
        .catch((err: any) => {
          return Promise.reject("不良原因校验错误");
        });
    }
  };
  return (
    <div className="check">
      <ModalForm
        form={modalForm}
        form_name="external_check_form"
        formItem={formItem}
        selectedItem={selectedItem}
      ></ModalForm>
      <div className="border"></div>
      <Form
        name="first_check_form1"
        form={modalForm1}
        className="form1"
        autoComplete="off"
      >
        <Row>
          <Form.Item label="质检员" name="user" className="wrap">
            <Input disabled />
          </Form.Item>
          <Form.Item label="开始时间" name="create_time" className="wrap">
            <Input disabled />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="合格数量" name="qualified" className="wrap">
            <Input disabled placeholder="自动计算"/>
          </Form.Item>
          <Form.Item
            label="不良数量"
            name="defective"
            className="wrap"
            rules={[
              { required: true, message: "请输入不良数量" },
              () => {
                let reg = /^[0-9]*[1-9][0-9]*$/;
                return {
                  validator(_: any, value: any) {
                  
                    // Number(0) = true
                    // 只有空字符串和数字0使用Boolean转化时返回false
                    if (value && value.trim().length > 0) {
                      if (!reg.test(value) && Number(value) !== 0) {
                        return Promise.reject(new Error("请输入整数"));
                      }
                      if (Number(value) > selectedItem.send_quantity) {
                        return Promise.reject(
                          new Error("不良数量应该小于等于送检数量")
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                };
              },
            ]}
          >
            <Input placeholder="请输入不良数量" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            className="wrap"
            label="不良原因"
            name="defect_reason_id"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (parseInt(getFieldValue("defective")) > 0 && value) {
                    return Promise.resolve();
                  }
                  if (parseInt(getFieldValue("defective")) === 0 && !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("请选择不良原因");
                },
              }),
            ]}
          >
            <Select
              mode="multiple"
              disabled={checkStatusDisabled}
              placeholder="请选择不良原因"
            >
              {errorList.map((oe: any, oi: number) => {
                return (
                  <Select.Option key={oi} value={oe.value}>
                    {oe.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default ExternalCheck;

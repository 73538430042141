import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { saveAs } from "file-saver";
interface IProps {
  dataSource?: any[]; // 数据源
  tableName: string; // 表格的名字
  btnName: string; // 导出按钮的名字
  columns: any[]; // Table的columns
  isTwoHeader: boolean; // 是否有两个表头
  btnStyle?: React.CSSProperties; // 按钮的样式
  /* 表头部标题和底部日期等信息,格式如下
  const otherData :any[]= [
    {
    rows1:[ "合同日期"],        表格的第一行
    rows2:[ "合同编号", "日期"],    表格的第二行
    rows3:["日期","合同编号", "日期"]  表格的第三行
    lastRow:["交货人","收货人"]     表格的尾部
    },
    { title: ["中贸公司"]}  表格的标题
  ];
  const lastRow = ["交货人","收货人"]; */
  otherData?: any[];
  length?:number, // excel表格的行数
  lastRowLength?:number; // lastRow应该在第几行插入
  is_bg_null?: boolean, // 是否不加表头底色
  is_external_table?:boolean, // 是转序单打印么
  is_delivery_table?:boolean, // 是发货单打印么
  is_order_table?:boolean, // 是否是订单查看导出
  onClick?:(onExport:any) => void, // 点击导出组件的事件
  totalNumber?:number, // 合计列的值
}
interface IChildren {
  key: string;
  header: string;
  parentKey: string;
  dataIndex: string[] |string
}
interface IHeader {
  header: string;
  key: string;
  children?: IChildren[];
  dataIndex?:string[] | string
}
interface IColumns {
  title: string;
  key: string;
  dataIndex: string;
  children?: any[];
}


const ExportExcel = (props: IProps) => {
  const {totalNumber,onClick,isTwoHeader,lastRowLength,btnStyle,is_external_table,is_delivery_table,is_order_table,tableName,btnName,otherData,length,is_bg_null} = props;
  const [dataSource, setDataSource] = useState(props.dataSource ? props.dataSource : []);
  const [columns, setColumns] = useState(props.columns);
  useEffect(() => {
    props.dataSource && setDataSource(props.dataSource);
    setColumns(props.columns);
  }, [props.columns,props.dataSource]);

  // 导出的入口函数
  const onExport = (importData?:any[],len?:number) => {
    importData && setDataSource(importData);
    const ExcelJs = require("exceljs");
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet("tab1");
    worksheet.properties.defaultRowHeight = 20;
    const headers: IHeader[] = generateHeaders(columns) ;
    const names1: string[] = [];
    const names2: string[] = [];
    const headerKeys: string[] = [];
    const childKeys: any[] = [];

    headers.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          names1.push(item.header);
          names2.push(child.header);
          headerKeys.push(child.parentKey);
          childKeys.push(child.dataIndex);

        });
      } else {
        names1.push(item.header);
        names2.push(item.header);
        childKeys.push(item.dataIndex);
      }
    });

    handleHeader(worksheet, headers, names1, names2, headerKeys);
    addData2Table(worksheet, childKeys, importData);

    // 根据列内容最大长度自适应列宽
    worksheet.columns = worksheet.columns?.map((col: any) => {
      const lengths = col.values.map((v:any) => v.toString().length*2);
      const maxLength = Math.max(...lengths.filter((v:any) => typeof v === 'number'));
      return {
        ...col,
        width: maxLength,
      }
    });

    // 通过 cell 设置背景色和边框，更精准，多行表头添加颜色会填满空单元格,可以使用eachCell避免
    let headerRow = worksheet.getRow(otherData ? Object.keys(otherData[0]).length+1: 1);
      if (!is_bg_null) {
        // 如果不要求背景颜色为空，则直接设置第一行背景颜色
        headerRow.eachCell((cell: any) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC0C0C0" },
          };
        });
      }
      if (isTwoHeader) {
        // 如果有两个表头，则设置第二行背景颜色
        let headerRow2 = worksheet.getRow(otherData ? Object.keys(otherData[0]).length + 2 : 2);
        headerRow2.eachCell((cell: any) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC0C0C0" },
          };
        });
      }

    // 计算需要渲染的行数和从哪行开始渲染
    let rowlength = (otherData ? isTwoHeader ? Object.keys(otherData[0]).length + 2
          : Object.keys(otherData[0]).length + 1 : 0) + (len ? len : length ? length : 19);
    for ( let num = otherData ? is_external_table ? 0 : is_delivery_table ? Object.keys(otherData[0]).length - 1 : 0 : 0;
      num < rowlength;
      num++
    ) {
      for (let index = 0; index < names2.length; index++) {
        // 根据行列得到每一个单元格
        let cell = worksheet.getRow(num + 1).getCell(index + 1);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      }
    }
    saveWorkbook(workbook, `${tableName}.xlsx`);
  };

  //生成表头
  const handleHeader = (
    worksheet: any,
    headers: IHeader[],
    names1: string[],
    names2: string[],
    headerKeys: string[]
  ) => {
    if (otherData) {
      if (is_external_table) {
        otherData.forEach((item, index) => {
          if (index === 1) {
            let title = [item.title];
            worksheet.insertRows(1, title);
            const firstRow = worksheet.getRow(1);
            firstRow.alignment = { vertical: "middle", horizontal: "center" };
            firstRow.height = 50;
            firstRow.font = {
              size: 24,
              bold: true,
            };
            worksheet.mergeCells(`A1:${String.fromCharCode(64 + names2.length)}1`);
          } else if (index === 0) {
            if (item.rows2) {
              const length = item.rows2.length;
              const rows2: string[] = [];
              if (length === 6) {
                rows2[0] = item.rows2[0];
                rows2[1] = item.rows2[1];
                rows2[2] = item.rows2[2];
                rows2[3] = item.rows2[3];
                rows2[4] = item.rows2[4];
                rows2[5] = item.rows2[5];
              }
              const row = worksheet.insertRow(1, rows2);
              row.height = 25;
            }
            if (item.rows1) {
              const length = item.rows1?.length;
              const rows1: string[] = [];
              if (length === 6) {
                rows1[0] = item.rows1[0];
                rows1[1] = item.rows1[1];
                rows1[2] = item.rows1[2];
                rows1[3] = item.rows1[3];
                rows1[4] = item.rows1[4];
                rows1[5] = item.rows1[5];
              }
              const row = worksheet.insertRow(1, rows1);
              row.height = 25;
            }
          }
        });
      }
      if (is_delivery_table) {
        otherData.forEach((item, index) => {
          if (index === 1) {
            let title = [item.title];
            worksheet.insertRows(1, title);
            const firstRow = worksheet.getRow(1);
            firstRow.alignment = { vertical: "middle", horizontal: "center" };
            firstRow.height = 50;
            firstRow.font = {
              size: 24,
              bold: true,
            };
            worksheet.mergeCells(`A1:${String.fromCharCode(64 + names2.length)}1`);
          } else if (index === 0) {
            if (item.rows3) {
              const length = item.rows3?.length;
              const rows3: string[] = [];
              if (length === 4) {
                rows3[0] = item.rows3[0];
                rows3[1] = item.rows3[1];
                rows3[3] = item.rows3[2];
                rows3[4] = item.rows3[3];
              }
              const row = worksheet.insertRow(1, rows3);
              row.height = 25;
            }
            if (item.rows2) {
              const length = item.rows2.length;
              const rows2: string[] = [];
              if (length === 4) {
                rows2[0] = item.rows2[0];
                rows2[1] = item.rows2[1];
                rows2[names2.length - 2] = item.rows2[2];
                rows2[names2.length - 1] = item.rows2[3];
              }
              const row = worksheet.insertRow(1, rows2);
              row.height = 25;
            }
            if (item.rows1) {
              const length = item.rows1?.length;
              const rows1: string[] = [];
              if (length === 2) {
                rows1[0] = item.rows1[0];
                rows1[1] = item.rows1[1];
              }
              const row = worksheet.insertRow(1, rows1);
              row.height = 25;
            }
          }
        });
      }
      if (is_order_table) {
        otherData.forEach((item, index) => {
          if (index === 1) {
            let title = [item.title];
            worksheet.insertRows(1, title);
            const firstRow = worksheet.getRow(1);
            firstRow.alignment = { vertical: "middle", horizontal: "center" };
            firstRow.height = 50;
            firstRow.font = {
              size: 24,
              bold: true,
            };
            worksheet.mergeCells(`A1:${String.fromCharCode(64 + names2.length)}1`);
          } else if (index === 0) {
            if (item.rows1) {
              const length = item.rows1?.length;
              const rows1: string[] = [];
              if (length === 6) {
                rows1[0] = item.rows1[0];
                rows1[1] = item.rows1[1];
                rows1[2] = item.rows1[2];
                rows1[3] = item.rows1[3];
                rows1[4] = item.rows1[4];
                rows1[5] = item.rows1[5];
              }
              const row = worksheet.insertRow(1, rows1);
              row.height = 25;
            }
          }
        });
      }
    }
     const isChildren = headers?.some((item) => item.children);
     // 两个表头
     if (isChildren) {
       const rowHeader1 = worksheet.addRow(names1);
       const rowHeader2 = worksheet.addRow(names2);
       if (otherData) {
         rowHeader1.height = 25;
         rowHeader2.height = 25;
       }
       mergeColumnCell(
         rowHeader1,
         worksheet,
         headerKeys
       );
       return;
     }
     // 单个表头
     const rowHeader = worksheet.addRow(names1);
     if(otherData){
      rowHeader.height = 25;
     }
  };

  // 添加数据
  const addData2Table = (
    worksheet: any,
    childKeys: string[],
    importData?:any[]
  ) => {
    (importData ? importData : dataSource)?.forEach((item) => {
      const rowData = childKeys?.map((key: any) => {
        if (typeof key === "string") {
          // Boolean(0)为false
          if (item[key] || item[key] === 0) {
            return item[key];
          }
        } else if (Array.isArray(key)) {
          if (key.length === 5) {
            return item[key[0]][key[1]][key[2]][key[3]][key[4]];
          } else if (key.length === 4) {
            return item[key[0]][key[1]][key[2]][key[3]];
          } else if (key.length === 3) {
            if (item[key[0]] && item[key[0]][key[1]] && (item[key[0]][key[1]][key[2]] || item[key[0]][key[1]][key[2]] === 0)) {
              return item[key[0]][key[1]][key[2]];
            }
          } else if (key.length === 2) {
            if (item[key[0]] &&(item[key[0]][key[1]] || item[key[0]][key[1]] === 0)) {
              return item[key[0]][key[1]];
            }
          }
        } else {
          return null;
        }
      });
      const row = worksheet.addRow(rowData);
      row.height = otherData ? 25 : 20;
      row.alignment = {
        vertical: "middle",
        wrapText: false, // 自动换行
        shrinkToFit: false,
      };
      row.font = { size: 11, name: "微软雅黑" };
    });
  if(otherData && otherData[0].lastRow){
    const lastRow = otherData[0].lastRow;
    if(lastRow.length === 2 && lastRowLength){
      worksheet.getCell(`A${lastRowLength+1}`).value = lastRow[0];
      worksheet.getCell(`D${lastRowLength+1}`).value = lastRow[1];
    } else if(lastRow.length === 7 && lastRowLength){
      worksheet.getCell(`B${lastRowLength+2}`).value = lastRow[0];
      worksheet.getCell(`B${lastRowLength+4}`).value = lastRow[1];
      worksheet.getCell(`B${lastRowLength+6}`).value = lastRow[2];
      worksheet.getCell(`E${lastRowLength+2}`).value = lastRow[3];
      worksheet.getCell(`E${lastRowLength+4}`).value = lastRow[4];
      worksheet.getCell(`E${lastRowLength+6}`).value = lastRow[5];
      worksheet.getCell(`A${lastRowLength}`).value = lastRow[6];
      if(lastRow[6] === "合计" && totalNumber) {
        worksheet.getCell(`D${lastRowLength}`).value = totalNumber;
      }
      const row = worksheet.getRow(lastRowLength);
      row.height = 25;
    } else {
      console.log("传入数据格式有误")
    }
  }
};

  // table的columns转换为exceljs库的表头格式的方法
  const generateHeaders = (
    columns: IColumns[]
  ) => {
    // 过滤操作选项
    let newColumns:IColumns[];
    if(!isTwoHeader){
      newColumns = columns.filter( item =>{
        return item.title !== "操作"
      });
    } else {
      const deleteTargetCodeArr= ["操作"];
      newColumns = columns.map((el) => {
        const filteredChildren = el.children?.filter(
          (child) => !deleteTargetCodeArr.includes(child.title)
        );
        return {
            key: el.key,
            title: el.title,
            children: filteredChildren,
            dataIndex: el.dataIndex
        }
      });
    }
    return newColumns?.map((col) => {
      const obj: {
        header: string;
        key: string;
        children?: any[];
        dataIndex?: string[] | string
      } = {
        header: col.title,
        key: col.key,
        dataIndex: col.dataIndex ? col.dataIndex :col.key
      };
      if (col.children) {
        obj.children = col.children?.map((item) => {
          return {
            key: item.key, 
            parentKey: col.key,
            header: item.title,
            // 头部最多嵌套两层，所以第二层的dataIndex一定存在
            dataIndex: item.dataIndex
          }

        });
      }
      return obj;
    });
  };

  // 合并第一行表头
  const mergeColumnCell = (
    rowHeader1: any,
    worksheet: any,
    headerKeys: string[]
  ) => {
    let pointer = -1;
    headerKeys.forEach((parentKey, index) => {
      if (index <= pointer) return;
      // 因为头尾都是订单信息，所以不能根据index !== names1.lastIndexOf(name)进行合并
      // 当前parentKey出现的index不等于parentKey这个值在headerKeys中出现的最后一次的index,表明需要被水平合并
      const shouldHorizontalMerge = index !== headerKeys.lastIndexOf(parentKey);
      pointer = headerKeys.lastIndexOf(parentKey);
      if (shouldHorizontalMerge) {
        worksheet.mergeCells(
          // 按开始行、开始列、结束行、结束列合并
          Number(rowHeader1.number), // 1
          index + 1,
          Number(rowHeader1.number),
          headerKeys.lastIndexOf(parentKey) + 1
        );
      }
      const cell = rowHeader1.getCell(index + 1);
      cell.alignment = { vertical: "middle", horizontal: "center"};
    });
  };

  // 使用file-saver库接收workbook和文件名来下载excel到本地
  const saveWorkbook = (workbook: any, fileName: string) => {
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: "" });
      saveAs(blob, fileName);
    });
  };

  return (
    <div>
      <Button
        style={
          btnStyle
            ? btnStyle
            : {
                width: "48px",
                height: "28px",
                backgroundColor: "#3E7BFA",
                borderRadius: "5px 5px 5px 5px",
                opacity: 1,
                color: "#fff"
              }
        }
        onClick={onClick ? ()=>{onClick((importData:any[],len:number)=>{onExport(importData,len)})} : ()=>{onExport()}}
      >
        {btnName}
      </Button>
    </div>
  );
};

export default ExportExcel;

import React, {useContext, useEffect, useState} from "react";
import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import {classificationList, showError} from "../../../utils";
import {ModalTitle} from "../ProductionOrderManagement";
import {ModalTitle as TaskModalComponentProps} from "../TaskManagement";
import {Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Select, Row, Col} from "antd";
import {getWorkmanshipInfo, workProcess} from "../../../service";
import {UserContext} from "../../../contexts/userContext";
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import {useWatch} from "antd/es/form/Form";


interface CreateOrEditTaskProps {
    //弹窗标题
    title: ModalTitle.CREATE | TaskModalComponentProps.EDIT | TaskModalComponentProps.EDIT2,
    //form FormInstance
    form: FormInstance,
    //tableForm FormInstance
    tableForm: FormInstance,
    //tableForm tableDisabled
    tableDisabled?: boolean
    //显示的表格数据
    initialDataSource?: any[]

}

const CreateOrEditTask = React.memo((props: CreateOrEditTaskProps) => {
    const {form, title, tableForm, tableDisabled, initialDataSource} = props;
    const {useInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const defaultColumns: any[] = [
        {
            title: "No",
            dataIndex: "No",
            key: "No",
            width: 60,
            ellipsis: true,
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        {
            title: <span>
                    <span className={'requiredIcon'}>*</span>
                {useInfo.authRoutes?.includes('/basic/procedure') ?
                    <span className={'mes-user-options'}
                          onClick={() => {
                              skipPage('/basic/procedure')
                          }}>工序</span> : <span>工序</span>}
                </span>,
            dataIndex: 'process_id',
            key: 'process_id',
            align: 'center',
            width: 200,
            inputType: 'text',
            ellipsis: true,
            render: (value: any, item: any, index: number) => {
                return <Form.Item name={[`${index}`, 'process_id']} style={{textAlign: 'left'}}
                                  rules={[{required: true, message: '请选择工序！'}]}>
                    <Select placeholder={'请选择工序'} disabled={title === TaskModalComponentProps.EDIT2 && item.flag}
                            onSelect={(value: any, option: any) => {
                                tableForm.setFieldValue([`${index}`, 'is_epiboly'], option.title1.out_help)
                                // tableForm.setFieldValue([`${index}`, 'process_explain'], option.title1.remark)
                                setFlag(value => !value)
                            }}>
                        {workProcessList.map(e => <Select.Option key={e.object_id} title1={e}
                                                                 value={e.object_id}>{e.work_process_name}</Select.Option>)}
                    </Select>
                </Form.Item>
            }
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>是否外协</span>,
            dataIndex: 'is_epiboly',
            key: 'is_epiboly',
            align: 'center',
            width: 150,
            inputType: 'text',
            ellipsis: true,
            render: (value: any, item: any, index: number) => {
                return <Form.Item name={[`${index}`, 'is_epiboly']} valuePropName="checked" initialValue={false}>
                    <Checkbox
                        onChange={(e) => {
                            setFlag(value => !value)
                        }}
                        disabled={title === TaskModalComponentProps.EDIT2 ? item.flag : tableForm.getFieldValue([`${index}`, 'is_factory'])}
                    />
                </Form.Item>
            }
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>是否返厂</span>,
            dataIndex: 'is_factory',
            key: 'is_factory',
            align: 'center',
            width: 150,
            inputType: 'text',
            ellipsis: true,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'is_factory']} valuePropName="checked" initialValue={false}>
                    <Checkbox
                        onChange={(e) => {
                            setFlag(value => !value)
                        }}
                        disabled={title === TaskModalComponentProps.EDIT2 ? item.flag : tableForm.getFieldValue([`${index}`, 'is_epiboly'])}
                    />
                </Form.Item>
        },
        {
            title: '工艺说明',
            dataIndex: 'process_explain',
            key: 'process_explain',
            align: 'center',
            width: 450,
            inputType: 'text',
            ellipsis: true,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'process_explain']}
                           rules={[{max: 200, message: '工艺说明不得大于200位!'}]}
                           style={{textAlign: 'left'}}>
                    <Input.TextArea placeholder={'请输入工艺说明'}
                                    disabled={title === TaskModalComponentProps.EDIT2 && item.flag}/>
                </Form.Item>
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 100,
            align: 'center',
            render: (_: any, item: any, index: number) => {
                if (title === TaskModalComponentProps.EDIT2) {
                    if (item.process_id === undefined) {
                        return (
                            <span className={'mes-user-options'} onClick={() => {
                                if (tRef.current) {
                                    tRef.current.handleDelete(index)
                                }
                            }}>删除</span>
                        )
                    } else {
                        return null
                    }
                } else {
                    return (
                        <span className={'mes-user-options'} onClick={() => {
                            if (tRef.current) {
                                tRef.current.handleDelete(index)
                            }
                        }}>删除</span>
                    )
                }
            }
        },
        // 任何情况不显示该项，修改前的数据不可修改的标志
        {
            title: 'flag',
            dataIndex: 'flag',
            key: 'flag',
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'flag']}>
                </Form.Item>
        },
        //任何情况不显示该项，修改前的数据有值，新增加的没有值
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'id']}>
                </Form.Item>
        },
    ]
    const newItem = {
        process_id: undefined,
        is_epiboly: '',
        is_factory: '',
        process_explain: undefined,
        flag: false,
        id:0
    }
    const tRef = React.useRef<TRef>(null);
    const [workProcessList, setWorkProcess] = useState<any[]>([]);
    const [workShipList, setWorkShipList] = useState<any[]>([]);
    const [detailDataSource, setDetailDataSource] = useState<any[]>([]);
    const skipPage = (router: string) => {
        if (useInfo.authRoutes?.includes(router)) {
            navigate(router)
        }
    }
    const [showWorkShip, setShowWorkShip] = useState<boolean>();
    const [showTable, setShowTable] = useState<boolean>();
    const [flag, setFlag] = useState<boolean>();
    const category = useWatch('category', form);
    const quantity = useWatch('quantity', form);
    const [editOrDragTableLoading,setEditOrDragTableLoading] = useState<boolean>(true);

    useEffect(() => {
        if (category === 10) {
            setShowWorkShip(false);
            setShowTable(false);
        } else if (category === 20) {
            setShowWorkShip(true);
            setShowTable(false);
            if (!form.getFieldValue('work_craft_id')) {
                getWorkmanshipInfo().then(res => {
                    if (res.code === 200) {
                        if (res.data.data) {
                            if (res.data.data.length) {
                                form.setFieldValue('work_craft_id', res.data.data[0].object_id)
                            }
                        }
                    } else {
                        showError(res.message)
                    }
                })
            }
        } else if (category === 30) {
            setShowWorkShip(false);
            setShowTable(true);
        }
    }, [category])
    useEffect(() => {
        //设置detailDataSource
        if (title === ModalTitle.CREATE) {
            setDetailDataSource([])
            setEditOrDragTableLoading(false)
        } else if (title === TaskModalComponentProps.EDIT) {
            if (initialDataSource) {
                setDetailDataSource(initialDataSource)
                setEditOrDragTableLoading(false)
            }
        } else if (title === TaskModalComponentProps.EDIT2) {
            if (initialDataSource) {
                setDetailDataSource(initialDataSource)
                setEditOrDragTableLoading(false)
            }
        }
    }, [title, initialDataSource])
    useEffect(() => {
        //获取工序列表
        workProcess().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    setWorkProcess(res.data.data)
                }
            } else {
                showError(res.message);
            }
        })
        //获取工艺路线
        getWorkmanshipInfo().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    setWorkShipList(res.data.data)
                }
            } else {
                showError(res.message)
            }
        })
    }, [])
    useEffect(() => {
        if (quantity) {
            form.validateFields(['min_quantity'])
        }
    }, [quantity])

    return (<div className={'form-margin label-width-120'}>
        <Form form={form} colon={false}>
            <Row>
                <Col span={12}>
                    <Form.Item label={'任务单号'} name={'name'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'订单号'} name={'orderNumber'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={'零件名称'} name={'partName'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'零件图号'} name={'partNumber'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={'订单数量'} name={'partAccount'}>
                        <InputNumber disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'总投产数量'} name={'allQuantity'}>
                        <InputNumber disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={'加工内容'} name={'content'}>
                        <Input.TextArea disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={'投产日期'} name={'start_date'} rules={[
                        {
                            required: true, message: '请选择投产日期！'
                        },
                        () => ({
                            validator(_, value) {
                                //value有值并且后面的验证通过
                                if (!value || value.isSameOrAfter(moment(), 'day')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('投产日期应该大于等于当前日期!'));
                            },
                        }),
                    ]}>
                        <DatePicker/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={'本次投产数量'} name={'quantity'} rules={[
                        {
                            required: true, message: '请输入本次投产数量！'
                        },
                        {
                            pattern: /^[0-9]*[1-9][0-9]*$/,
                            message: '本次投产数量只能输入正整数!'
                        }
                    ]}>
                        <InputNumber min={0} placeholder={'请输入本次投产数量'}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'最小完成数量'} name={'min_quantity'} rules={[
                        {
                            required: true, message: '请输入最小完成数量！'
                        },
                        {
                            pattern: /^[0-9]*[1-9][0-9]*$/,
                            message: '最小完成数量只能输入正整数!'
                        },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || value <= quantity) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject(new Error('最小完成数量应该小于等于本次投产数量！'))
                                }
                            }
                        })
                    ]}>
                        <InputNumber min={0} placeholder={'请输入最小完成数量'}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={'加工类别'} name={'category'} rules={[{
                        required: true, message: '请选择加工类别！'
                    }]}>
                        <Select placeholder={'请选择加工类别'}>
                            {classificationList.map(e => <Select.Option key={e.id}
                                                                        value={e.id}>{e.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {
                        showWorkShip && <Form.Item label={'工艺路线'} name={'work_craft_id'} rules={[{
                            required: true, message: '请选择工艺路线！'
                        }]}>
                            <Select placeholder={'请选择工艺路线'}>
                                {workShipList.map(e => <Select.Option key={e.object_id}
                                                                      value={e.object_id}>{e.work_craft_name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    }
                </Col>
            </Row>
        </Form>
        {
            showTable &&
            <EditOrDragTable editOrDragTableLoading={editOrDragTableLoading} tableForm={tableForm} detailColumns={defaultColumns} detailDataSource={detailDataSource}
                             title={'工艺流程'} newItem={newItem} ref={tRef} tableDisabled={tableDisabled} isDrag/>
        }
    </div>)
})

export default CreateOrEditTask;

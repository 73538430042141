// 封装弹出框的Form表单
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  DatePicker,
  Radio,
  Select,
} from "antd";
import "./index.less";
import { indexType } from "../../utils";
const { TextArea } = Input;
interface IProps {
  formItem: FormItem[];
  selectedItem?: any;
  // 是否禁用当前项
  is_show?: boolean;
  // 表单的name
  form_name: string;
  // form表单的实例
  form: any;
  onFinish?: (values: any) => void;
  // onFinish:() =>void;
  onFinishFailed?: (errorInfo: any) => void;
  // 对显示到的时间格式做要求
  dateFormat?: string;
  showTime?:boolean
}
interface IOption {
  label: string;
  value: number;
}
export interface FormItem {
  label: any;
  name: string;
  is_datePicker?: boolean;
  is_rangePicker?: boolean;
  is_row?: boolean;
  is_require?: boolean;
  is_disable?: boolean;
  is_edit?: boolean;
  value?: string;
  radio?: IOption[];
  placeholder?: string;
  selectoptions?: IOption[]; 
  is_textArea?:boolean
  // 是否多选
  multiple?: boolean;
  // 独占一行，但是长度不填满一行
  is_row_but_noGrow?: boolean;
  // 是否是两个元素占一行
  is_two?: boolean;
  // 是否两个元素，一个元素正常，另一个元素占满剩余空间
  is_row_grow_two?:boolean
  // 初始的默认值
  defaultValue?: any;
  // 校验规则函数
  rules?: any[];
  // check框改变的函数
  checkOnChange?:(e: any) => void;
  
}

const ModalForm: React.FC<IProps> = (props) => {
  const {
    form,
    selectedItem,
    is_show,
    formItem,
    form_name,
    onFinish,
    onFinishFailed,
    dateFormat,
    showTime
  } = props;
  // useEffect(() => {
  //   console.log("selectedItem", selectedItem);
  //   form.setFieldsValue(selectedItem);
  // });
  const reset = () => {
    form.resetFields();
    // 清空表单之后应该重新查询
    // onFinish();
  };

  const initialValues = (): Object => {
    let object: indexType = {};
  
    formItem.map((e: any) => {
      if (e.defaultValue) {
        return (object[e.name] = e.defaultValue);
      }
    });
    return object;
  };
  return (
    <div className="modal_form">
      <div className="modal_form_box">
        <Form
          form={form}
          className="form"
          name={form_name}
          onFinish={onFinish}
          initialValues={initialValues()}
          onFinishFailed={onFinishFailed}
          disabled={is_show ? true : false}
        >
          {formItem && formItem.map((item: FormItem) => {
            if (item.selectoptions) {
              
              return (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  key={item.name}
                  className={item.is_two ? "two_a_row" : "wrap"}
                  rules={
                    item.rules
                      ? item.rules
                      : [
                          item.is_require
                            ? { required: true, message: `请选择${item.label}` }
                            : {},
                        ]
                  }
                  
                >
                  <Select
                    disabled={item.is_disable ? true : false}
                    placeholder={item.placeholder && item.placeholder}
                    mode={item.multiple ? "multiple" : undefined}
                    allowClear>
                    { item.selectoptions.map((oe: any, oi: number) => {
                      return (
                        <Select.Option key={oi} value={oe.value}>
                          {oe.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else if (item.is_datePicker) {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  className={item.is_two ? "two_a_row" : "wrap"}
                  rules={
                    item.rules
                      ? item.rules
                      : [
                          item.is_require
                            ? { required: true, message: `请选择${item.label}` }
                            : { required: false},
                        ]
                  }
                >
                  <DatePicker
                    showTime={showTime ? showTime : false}
                    className="grow"
                    format={dateFormat ? dateFormat : "YYYY/MM/DD"}
                    disabled={item.is_disable ? true : false}
                  />
                </Form.Item>
              );
            } else if (item.radio) {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={
                    item.rules
                      ? item.rules
                      : [
                          item.is_require
                            ? { required: true, message: `请选择${item.label}` }
                            : {},
                        ]
                  }
                  className={item.is_two ? "two_a_row" : "wrap"}
                >
                  <Radio.Group onChange={item.checkOnChange ? item.checkOnChange: undefined}>
                    {item.radio.map((item) => (
                      <Radio value={item.value}>{item.label}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              );
            }else if(item.is_textArea) {
              return  <Form.Item
              key={item.name}
              label={item.label}
              name={item.name}
              style={{height:"60px"}}
              className={
                item.is_row
                  ? item.is_row_but_noGrow
                    ? "row_no_grow"
                    : "row"
                  : item.is_two
                  ? "two_a_row"
                  : "wrap"
              }
              rules={
                item.rules
                  ? item.rules
                  : [
                      item.is_require
                        ? { required: true, message: `请输入${item.label}` }
                        : {},
                    ]
              }
            >
              <TextArea
                disabled={item.is_disable ? true : false}
                 placeholder={item.is_edit ? `请输入${item.label}` : ""}
              />
            </Form.Item>
            } else {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  className={
                    item.is_row
                      ? item.is_row_but_noGrow?
                         "row_no_grow"
                        : "row"
                      : item.is_two
                      ? "two_a_row"
                      
                      : "wrap"
                  }
                  rules={
                    item.rules
                      ? item.rules
                      : [
                          item.is_require
                            ? { required: true, message: `请输入${item.label}` }
                            : {},
                        ]
                  }
                >
                  <Input
                    disabled={item.is_disable ? true : false}
                    placeholder={item.is_edit ? `请输入${item.label}` : ""}
                  />
                </Form.Item>
              );
            }
          })}
        </Form>
      </div>
    </div>
  );
};
export default ModalForm;

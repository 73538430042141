import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    Form,
    Input,
    message,
    Space,
    Row,
    Col,
    Table,
    Select,
    Checkbox, FormInstance
} from "antd";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {filterData, formatNumber, getFormData, showError, taskList, toastMessage, wayList} from "../../../utils";
import DeleteItem from "../../../components/DeleteItem";
import ModalContainer from "../../../components/Modal";
import '../index.less';
import {ColumnsType} from "antd/es/table";
import {
    getWorkmanshipDetail,
    getWorkmanshipInfo,
    WorkmanshipCreate,
    WorkmanshipDelete, WorkmanshipUpdate, workProcess
} from "../../../service";
import moment from "moment";
import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import {UserContext} from "../../../contexts/userContext";
import {useNavigate} from "react-router-dom";

enum ModalTitle {
    ADD = '新增工艺路线',
    EDIT = '编辑工艺路线',
    VIEW = '查看工艺路线',
    DELETE = '系统确认',
}


interface AddOrEditProps {
    //弹窗标题
    title: ModalTitle,
    //form FormInstance
    form: FormInstance,
    //form disabled
    disabled?: boolean
    //tableForm FormInstance
    tableForm: FormInstance,
    //tableForm tableDisabled
    tableDisabled?: boolean
    //显示的表格数据
    initialDataSource?: any[]
}

interface ViewProps {
    form: FormInstance,
    disabled: boolean
    dataSource: any[]
}

const Workmanship = (): React.ReactElement => {
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement>(<div/>);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '代码',
            placeholder: '请输入工艺路线代码'
        },
        {
            key: 'work_craft_name',
            name: '名称',
            placeholder: '请输入工艺路线名称'
        },
    ]

    const columns: ColumnsType<any> = [
        Table.SELECTION_COLUMN,
        Table.EXPAND_COLUMN,
        {
            title: '工艺路线代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '工艺路线名称',
            dataIndex: 'work_craft_name',
            key: 'work_craft_name',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 400,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            ellipsis: true,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.VIEW, item)
                        }}>查看</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, item)
                        }}>编辑</span>
                        <span className={'mes-user-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, item)
                        }}>删除</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEditCategory = React.memo((props: AddOrEditProps): React.ReactElement => {
        const {form, title, tableForm, tableDisabled, disabled, initialDataSource} = props;
        const {useInfo} = useContext(UserContext);
        const [editOrDragTableLoading,setEditOrDragTableLoading] = useState<boolean>(true);
        const navigate = useNavigate();
        const [flag, setFlag] = useState<boolean>();
        const [workProcessList, setWorkProcess] = useState<any[]>([]);
        const skipPage = (router: string) => {
            if (useInfo.authRoutes?.includes(router)) {
                navigate(router)
            }
        }

        useEffect(() => {
            //获取工序列表
            workProcess().then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        setWorkProcess(res.data.data)
                    }
                } else {
                    showError(res.message);
                }
            })
        }, [])
        const defaultColumns: any[] = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                width: 80,
                ellipsis: true,
                align: 'center',
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: <span>
                    <span className={'requiredIcon'}>*</span>
                    {useInfo.authRoutes?.includes('/basic/procedure') ?
                        <span className={'mes-user-options'}
                              onClick={() => {
                                  skipPage('/basic/procedure')
                              }}>工序</span> : <span>工序</span>}
                </span>,
                dataIndex: 'work_process_id',
                key: 'work_process_id',
                width: 200,
                ellipsis: true,
                align: 'center',
                render: (value: any, item: any, index: number) => {
                    return <Form.Item name={[`${index}`, 'work_process_id']} style={{textAlign: 'left'}}
                                      rules={[{required: true, message: '请选择工序！'}]}
                    >
                        <Select placeholder={'请选择工序'} onSelect={(value: any, option: any) => {
                            tableForm.setFieldValue([`${index}`, 'out_help'], option.title1.out_help)
                            setFlag(value => !value)
                        }}>
                            {workProcessList.map(e => <Select.Option key={e.object_id} title1={e}
                                                                     value={e.object_id}>{e.work_process_name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                }
            },
            {
                title: <span><span className={'requiredIcon'}>*</span>是否外协</span>,
                dataIndex: 'out_help',
                key: 'out_help',
                width: 100,
                ellipsis: true,
                align: 'center',
                render: (value: any, item: any, index: number) =>
                    <Form.Item name={[`${index}`, 'out_help']} valuePropName="checked">
                        <Checkbox/>
                    </Form.Item>
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                ellipsis: true,
                align: 'center',
                render: (value: any, item: any, index: number) =>
                    <Form.Item name={[`${index}`, 'remark']}
                               rules={[{max: 200, message: '备注不得大于200位!'}]}
                               style={{textAlign: 'left'}}>
                        <Input.TextArea placeholder={'请输入备注'}/>
                    </Form.Item>
            },
            {
                title: '操作',
                dataIndex: 'options',
                key: 'options',
                width: 100,
                align: 'center',
                render: (_: any, item: any, index: number) => (
                    <span className={'mes-user-options'} onClick={() => {
                        if (tRef.current) {
                            tRef.current.handleDelete(index)
                        }
                    }}>删除</span>
                )
            }
        ];
        const newItem = {
            work_process_id: undefined,
            out_help: false,
            remark: undefined,
        }
        const tRef = useRef<TRef>(null);
        const [detailDataSource, setDetailDataSource] = useState<any[]>([]);

        useEffect(() => {
            //设置detailDataSource
            if (title === ModalTitle.ADD) {
                setDetailDataSource([])
                setEditOrDragTableLoading(false)
            } else if (title === ModalTitle.EDIT) {
                if (initialDataSource) {
                    setDetailDataSource(initialDataSource)
                    setEditOrDragTableLoading(false)
                }
            }
        }, [title])

        return (
            <div className={'form-margin'}>
                <Form colon={false} form={form} className={'basic-form'} disabled={disabled}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={'代码'} name={'code'} rules={[
                                {
                                    type: "string",
                                    max: 32,
                                    pattern: /^[A-Za-z0-9]+$/,
                                    message: '代码由不大于32位的字母、数字组成!'
                                }
                            ]}>
                                <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'名称'} name={'work_craft_name'} rules={[
                                {required: true, message: '请输入工艺路线名称!'},
                                {
                                    type: "string",
                                    max: 32,
                                    message: '名称为不得大于32位！'
                                }
                            ]}>
                                <Input placeholder={'请输入名称'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={'备注'} name={'remark'} rules={[
                                {max: 200, message: '备注不得大于200位！'}
                            ]}>
                                <Input.TextArea placeholder={'请输入备注'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <EditOrDragTable editOrDragTableLoading={editOrDragTableLoading}  tableForm={tableForm} detailColumns={defaultColumns}
                                 detailDataSource={detailDataSource}
                                 title={'工序列表'} newItem={newItem} ref={tRef} tableDisabled={tableDisabled} isDrag/>
            </div>
        )
    })

    const ViewCategory = React.memo((props: ViewProps): React.ReactElement => {
        const {form, disabled, dataSource} = props;
        const {useInfo} = useContext(UserContext);
        const navigate = useNavigate();
        const [workProcessList, setWorkProcess] = useState<any[]>([]);
        const skipPage = (router: string) => {
            if (useInfo.authRoutes?.includes(router)) {
                navigate(router)
            }
        }
        const defaultColumns: any[] = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                width: 80,
                ellipsis: true,
                align: 'center',
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: <span>
                    {useInfo.authRoutes?.includes('/basic/procedure') ?
                        <span className={'mes-user-options'}
                              onClick={() => {
                                  skipPage('/basic/procedure')
                              }}>工序</span> : <span>工序</span>}
                </span>,
                dataIndex: 'work_process_id',
                key: 'work_process_id',
                width: 200,
                ellipsis: true,
                align: 'center',
                render: (value: number) => {
                    const item = workProcessList.find(e => e.object_id === value);
                    return <span>{item ? item.work_process_name : undefined}</span>
                }
            },
            {
                title: "是否外协",
                dataIndex: 'out_help',
                key: 'out_help',
                width: 100,
                ellipsis: true,
                align: 'center',
                render: (value: boolean) => <span>{value ? '是' : '否'}</span>
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                width: 300,
                ellipsis: true,
                align: 'center',
            },
        ];

        useEffect(() => {
            //获取工序列表
            workProcess().then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        setWorkProcess(res.data.data)
                    }
                } else {
                    showError(res.message);
                }
            })
        }, [])

        return (
            <div className={'form-margin'}>
                <Form colon={false} form={form} className={'basic-form'} disabled={disabled}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={'代码'} name={'code'} rules={[
                                {
                                    type: "string",
                                    max: 32,
                                    pattern: /^[A-Za-z0-9]+$/,
                                    message: '代码由不大于32位的字母、数字组成!'
                                }
                            ]}>
                                <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'名称'} name={'work_craft_name'}>
                                <Input placeholder={'请输入名称'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={'备注'} name={'remark'} rules={[
                                {max: 200, message: '备注不得大于200位！'}
                            ]}>
                                <Input.TextArea placeholder={'请输入备注'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className={'edit-table-title'} style={{marginBottom: 20}}>工序列表</div>
                <Table columns={defaultColumns} dataSource={dataSource} pagination={false}/>
            </div>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditCategory form={form} tableForm={tableForm} title={title}/>)
        } else if (title === ModalTitle.VIEW) {
            getWorkmanshipDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        work_craft_name: res.data.work_craft_name,
                        remark: res.data.remark,
                    })
                    setInnerComponent(<ViewCategory form={form} disabled={true} dataSource={res.data.process}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.EDIT) {
            getWorkmanshipDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        work_craft_name: res.data.work_craft_name,
                        remark: res.data.remark,
                    })
                    let initialDataSource: any = []
                    if (res.data.process) {
                        res.data.process.map((e: any, i: number) => {
                            initialDataSource.push({
                                index: i,
                                ...e,
                            })
                        })
                    }
                    tableForm.setFieldsValue({...initialDataSource})
                    setInnerComponent(<AddOrEditCategory form={form} tableForm={tableForm} title={title}
                                                         initialDataSource={initialDataSource}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                tableForm.validateFields().then(values2 => {
                    //form数据转datasource数据
                    const tempTable = Object.values(values2)
                    tempTable.map((e: any) => {
                        e.remark = e.remark ? e.remark : ''
                        e.out_help = e.out_help ? 1 : 0
                    })
                    if (tempTable.length === 0) {
                        message.warning('必须添加一条工序！')
                    } else {
                        let realParams = {
                            ...values,
                            process_info: tempTable
                        }
                        const formData = filterData(realParams)
                        WorkmanshipCreate({work_craft_info: JSON.stringify(formData)}).then(res => {
                            if (res.code === 200) {
                                afterOnOK('新增成功！');
                            } else {
                                showError(res.message)
                            }
                        })
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                tableForm.validateFields().then(values2 => {
                    //form数据转datasource数据
                    const tempTable = Object.values(values2)
                    tempTable.map((e: any) => {
                        e.remark = e.remark ? e.remark : ''
                        e.out_help = e.out_help ? 1 : 0
                    })
                    if (tempTable.length === 0) {
                        message.warning('必须添加一条工序！')
                    } else {
                        let realParams = {
                            ...values,
                            craft_id: selectedItem.object_id,
                            process_info: tempTable
                        }
                        const formData = filterData(realParams)
                        WorkmanshipUpdate({work_craft_info: JSON.stringify(formData)}).then(res => {
                            if (res.code === 200) {
                                afterOnOK('编辑成功！');
                            } else {
                                showError(res.message)
                            }
                        })
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            if (selectedRowKeys.length) {
                //批量删除
                const formData = getFormData(filterData({ids: `[${selectedRowKeys}]`}))
                WorkmanshipDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                        setSelectedRowKeys([])
                    } else {
                        showError(res.message);
                    }
                })
            } else {
                //单个删除
                const formData = getFormData(filterData({ids: `[${selectedItem.object_id}]`}))
                WorkmanshipDelete(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                    } else {
                        showError(res.message);
                    }
                })
            }
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title !== ModalTitle.DELETE) {
            form.resetFields();
            tableForm.resetFields();
        }
        //去除勾选的数据
        if (title === ModalTitle.DELETE && selectedRowKeys.length > 0) {
            setSelectedRowKeys([])
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(<div/>);
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        if (modalTitle !== ModalTitle.DELETE) {
            form.resetFields();
            tableForm.resetFields();
        }
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => {
        const ExportJsonExcel = require("js-export-excel");

        const exportExcel = () => {
            if (gRef.current) {
                if (gRef.current.exportData) {
                    gRef.current.exportData().then(res => {
                        const option: any = {};
                        option.fileName = '工艺路线' + moment(new Date()).format('YYYYMMDD');
                        option.datas = [
                            {
                                sheetData: res,
                                sheetName: "sheet",
                                sheetFilter: ['code', 'work_craft_name', 'remark'],
                                sheetHeader: ['工艺路线代码', '工艺路线名称', '备注'],
                                columnWidths: [20, 20, 20],
                            }
                        ];
                        const toExcel = new ExportJsonExcel(option);
                        toExcel.saveExcel();
                    })
                }
            }
        }

        return (
            <div>
                <Button className={'mes-user-add'} style={{marginRight: 20}} onClick={() => {
                    openModal(ModalTitle.ADD, null)
                }}>新增</Button>
                <Button className={'mes-batch-deletion'} style={{marginRight: 20}} onClick={() => {
                    if (selectedRowKeys.length) {
                        openModal(ModalTitle.DELETE, null)
                    } else {
                        toastMessage('请选择要删除的工艺路线')
                    }
                }}>批量删除</Button>
                <Button className={'mes-export'} style={{marginRight: 20}} onClick={exportExcel}>导出</Button>
            </div>
        )
    })

    const expandedRowRender = (record: any): React.ReactElement => {
        const columns: ColumnsType<any> = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                align: "center",
                width: 100,
                ellipsis: true,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '工序代码',
                dataIndex: 'code',
                key: 'code',
                align: 'center',
                ellipsis: true
            },
            {
                title: '工序名称',
                dataIndex: 'work_process_name',
                key: 'work_process_name',
                align: 'center',
                ellipsis: true
            },
            {
                title: '是否外协',
                dataIndex: 'out_help',
                key: 'out_help',
                align: 'center',
                ellipsis: true,
                render: (value) => {
                    return <span>{value ? '是' : '否'}</span>
                }
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                ellipsis: true
            },
        ]
        return <Table columns={columns} dataSource={record.process} pagination={false} className={'expand-table'}/>
    }

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.VIEW) {
            return (<div>
                <Button onClick={() => onCancel(ModalTitle.VIEW)}>关闭</Button>
            </div>)
        }
    }

    const getDataAsync = useCallback((queryCondition: API.WorkmanshipRequest): Promise<any> => {
        return new Promise(resolve => {
            getWorkmanshipInfo(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const setWidth = () => {
        if (modalTitle !== ModalTitle.DELETE) {
            return '50%'
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<ExtraComponent/>}
                               getDataAsync={getDataAsync} ref={gRef} expandedRowRender={expandedRowRender} scrollValue={{x: 1200}}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open} width={setWidth()}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default Workmanship;

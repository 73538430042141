import React, {useContext, useEffect} from "react";
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import AllComponents from '../pages';
import {Result403, Result404} from '../pages/ErrorPage'
import Dashboard from '../pages/Dashboard';
import Settings from "../pages/Settings";
import {UserContext} from "../contexts/userContext";

export interface IFMenuBase {
    key: string;
    label?: any;
    icon?: any;
    title: any;
    component?: string;
    route?: string;
}

export interface IFMenu extends IFMenuBase {
    children?: IFMenu[];
}

const Router = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {useInfo} = useContext(UserContext);

    const getRoutes = (r: IFMenu) => {
        const route = (r: IFMenuBase) => {
            const Component = r.component && AllComponents[r.component];
            if (Component) {
                return (
                    <Route
                        key={r.key}
                        path={r.route}
                        element={<Component/>}
                    />
                );
            }
        };

        const subRoute = (r: IFMenu): any =>
            r.children && r.children.map((subR: any) => (subR.children ? subRoute(subR) : route(subR)));

        return r.component ? route(r) : subRoute(r);
    }

    useEffect(() => {
        if (useInfo.allRoutes && useInfo.authRoutes) {
            const allRoutes = useInfo.allRoutes.concat(['/', '/settings'])
            const authRoutes = useInfo.authRoutes.concat(['/', '/settings'])
            if (!allRoutes.includes(location.pathname)) {
                navigate('/404')
            } else {
                if (!authRoutes.includes(location.pathname)) {
                    navigate('/403')
                }
            }
        }
    }, [useInfo.allRoutes])

    return (
        <>
            <Routes>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/403" element={<Result403/>}/>
                <Route path="/404" element={<Result404/>}/>
                {useInfo.menus && useInfo.menus.map(getRoutes)}
            </Routes>
        </>
    )
}

export default Router;

import React,{useState, useCallback,useRef } from "react";
import { Tooltip,message} from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatTime } from "../../../utils/index";
import {copyCraft} from "../../../service";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import PaganationTable from "../../ComprehensiveQuery/components/PaganationTable";
import '../index.less';
interface IProps {
  setSelectedRows: (selectRows:any[])=>void,
}
const formItem: QueryListItem[] = [
  {
    key: "order_code",
    name: "订单号",
    placeholder: "请输入订单号",
  },
  {
    key: "part_name",
    name: "零件名称",
    placeholder: "请输入零件名称",
  },
  {
    key: "part_number",
    name: "零件图号",
    placeholder: "请输入零件图号",
  },
];
interface DataType {}

const CopyCraft = (props: IProps) => {
  const {setSelectedRows} = props;
  const [selectedRowKeys,setSelectedRowKeys] = useState<any[]>([]);
  const gRef = useRef<GRef>(null);
  const columns: ColumnsType<DataType> = [
    {
      title: "订单号",
      dataIndex: ["order","code"],
      key: "code",
      width:170,
      ellipsis:true,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "零件名称",
      dataIndex: ["part", "name"],
      key: "name",
      width:170,
      ellipsis:true,
    },
    {
      title: "零件图号",
      dataIndex: ["part", "number"],
      key: "number",
      width:170,
      ellipsis:true,
    },
    {
      title: "加工内容",
      dataIndex: "content",
      key: "content",
      width:170,
      ellipsis:true,
    },
    {
      title: "交货日期",
      dataIndex: ["order","finish_date"],
      key: "finish_date",
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, true)}</Tooltip>
      ),
    },
  ];
  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      copyCraft(queryCondition)
        .then((res) => {
          if (res.code === 200) {
            resolve(res.data);
          
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          message.error(err)
        });
    });
  }, []);
  return (
    <div className="copy_craft">
        <GeneralManagement
        formList={formItem}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        setSelectedRows={setSelectedRows}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        type="radio"
      />
    </div>
  );

};
export default CopyCraft;

import React, { useCallback, useRef, useState, useEffect } from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { Form,message } from "antd";
import ModalContainer from "../../../components/Modal";
import "../index.less";
import {
  formatTime,
  getUsersForSelect,
  getWorkprocesss,
  getSystemInfoItem,
  transformObject,
  filterDateParam,
  formatNumber,
  transformPrint,
  getFormData,
  indexType
} from "../../../utils/index";
import moment from "moment";
import MyButton from "../../../components/MyButton";
import { ColumnsType } from "antd/es/table";
import MButton from "../../../components/MyButton";
import CreateExternal from "../components/CreateExternal";
import PrintExternal from "../components/PrintExternal";
import { getWorkList, createRecord } from "../../../service";
import ExportExcel from "../../../components/ExportTable/exportTable";
import { IOptions } from "../ReportMonitor/index";
import Print from "react-print-html";
import {uuid} from "../../../utils";

enum ModalTitle {
  PRINT = "打印返厂转序单",
  CREATE = "创建返厂转序单",
}

const ReturnMonitor = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>();
  const [selectData, setSelectData] = useState<{ [key: string]: IOptions[] }>({});
  const gRef = useRef<GRef>(null);
  const [maxRow, setMaxRow] = useState<number>(0);
  const [printData, setPrintData] = useState<any>({});
  const [form] = Form.useForm();
  const printRef = useRef();
  const [formList, setFormList] = useState<QueryListItem[]>([
    {
      key: "order_number",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件名称",
    },
    {
      key: "report_status",
      name: "状态",
      placeholder: "请选择状态",
      defaultSelect: [20],
      multiple: true,
      options: [
        { id: 20, name: "处理中" },
        { id: 30, name: "已完成" },
      ],
    },
    {
      key: "task_number",
      name: "任务单号",
      placeholder: "请输入任务单号",
    },
    {
      key: "record_number",
      name: "转序单号",
      placeholder: "请输入转序单号",
    },
    {
      key: "user_id",
      name: "转序人",
      placeholder: "请选择转序人",
      options: selectData.users,
    },
    {
      key: "date",
      name: "转序日期",
      placeholder: "请选择转序日期",
      rangePick: true,
    },
    {
      key: "trader",
      name: "客户名称",
      placeholder: "请输入客户名称",
    },
    {
      key: "process_id",
      name: "转序工序",
      placeholder: "请选择转序工序",
      options: selectData.processes,
    },
  ]);

  useEffect(() => {
    getWorkprocesss((processes: IOptions[]) => {
   
      setSelectData({
        ...selectData,
        processes: processes,
      });
      let newFormList = formList;
      newFormList[8].options = processes;
      setFormList(newFormList);
    });
    getUsersForSelect((users: IOptions[]) => {
     
      setSelectData({
        ...selectData,
        users: users,
      });
      let newFormList = formList;
      newFormList[5].options = users;
      setFormList(newFormList);
    });
    getSystemInfoItem("转序单最大行数", (selectedItem) => {
      let max_num = Number(selectedItem[0].value);
      
      setMaxRow(max_num);   
    });  
  }, []);
  const ExtraComponent = React.memo((): React.ReactElement => {
    const tabName = "返厂监控" + moment(new Date()).format("YYMMDDhhmmss");
    const exportExcel = async(callback:any) => {
      if (gRef.current) {
          if (gRef.current.exportData) 
          {
              gRef.current.exportData().then(res => {
               
                  const statusObj: indexType = {
                    10: "未开始",
                    20: "处理中",
                    30: "已完成",
                  }
                  res.map( (a:any) => {
                    if(a.status) {
                      a.status = statusObj[a.status]
                    }
                    if(a.start_time) {
                      a.start_time = formatTime(a.start_time,true)
                    }
                    if(a.receipt.create_time) {
                      a.receipt.create_time = formatTime(a.receipt.create_time,false)
                    }
                    if(a.update_time) {
                      a.update_time = formatTime(a.update_time,true)
                    }
                  })
                  let length  = res.length + 2
                  callback(res,length)
              }).catch(err => {
                console.log("导出全部数据遇到错误",err)
              })
          }
      }
  }

    return (
      <div className="btn_box">
        <MyButton
          title="转序"
          onClick={() => {
            if (selectedRowKeys.length === 0) {
              message.warning("请选择要返厂的订单零件");
              return;
            }
            const newArrLength = new Set(
              selectedRows.map((item) => item.trader_id)
            ).size;
            if (newArrLength !== 1) {
              message.warning("只能对相同客户的订单零件创建返厂转序单");
              return;
            }
            const recordIds = Array.from(new Set(
              selectedRows.map((item) => item.record_id)
            ));
            const recordIdsLength = new Set(
              selectedRows.map((item) => item.record_id)
            ).size;
            if (recordIdsLength !== 1 || (recordIdsLength == 1 && recordIds[0] !== null)) {
              message.warning("已经生成外协转序单的记录，不能重复创建。");
              return;
            }
            if (selectedRowKeys.length > maxRow) {
              message.warning(`转序单最大行数为${maxRow}条,所选任务数不能超过该值`);
              return;
            }
            openModal(ModalTitle.CREATE);   
          }}
          buttonStyle={{
            width: "48px",
            height: "28px",
            marginLeft: "0px",
            marginRight: "1px",
            background: "#3E7BFA",
            borderRadius: "5px"
          }}
        ></MyButton>
        <ExportExcel
          onClick={exportExcel}
          columns={columns}
          tableName={tabName}
          btnName="导出"
          isTwoHeader={true}
        ></ExportExcel>
      </div>
    );
  });
  const openModal = (modalTitle: ModalTitle | null, printData?: any) => {
    setModalTitle(modalTitle);
    setOpen(true);
    if (modalTitle === "打印返厂转序单") {
      setInnerComponent(
        <PrintExternal setCols={setCols} printData={printData} ref={printRef} />
      );
    } else if (modalTitle === "创建返厂转序单") {
      setInnerComponent(
        <CreateExternal form={form} selectedRows={selectedRows} />
      );
    }
  };
  const handleCell:any = (record:any, index:number) => {
    return { rowSpan: record.count };
  }
  const handleCell1:any = (record:any,index:number) => {
    return {
      rowSpan: record.count,
      className:
        record.status === 20
          ? record.plan_date
            ? Number(moment(record.plan_date).valueOf()) <
              Number(moment().valueOf())
              ? "bgColor"
              : "bgWhite"
            : "bgWhite"
          : "bgWhite",
    };
  }
  const onCancel1 = (title: any) => {
    //去除勾选的数据
    if (title === ModalTitle.PRINT && selectedRowKeys.length > 0) {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }
    setModalTitle(ModalTitle.CREATE);
    setInnerComponent(<div/>);
    setOpen(false);
}
  const columns: ColumnsType<any> = [
    {
      title: "订单信息",
      className: "orderInfo",
      key: "1",
      align: "center",
      fixed: "left",
      children: [
        {
          title: "No",
          className: "orderInfo",
          dataIndex: "index",
          key: "index",
          align: "center",
          width:60,
          ellipsis: true,
          fixed:true,
        },
        {
          title: "订单号",
          className: "orderInfo",
          dataIndex: "order_number",
          key: "order_number",
          align: "center",
          width: 140,
          ellipsis: true,
          fixed: "left",
          onCell:handleCell
        },
        {
          title: "零件名称",
          className: "orderInfo",
          dataIndex: "part_name",
          key: "part_name",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
          onCell:handleCell
        },
        {
          title: "零件图号",
          className: "orderInfo",
          dataIndex: "part_number",
          key: "part_number",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
          onCell:handleCell
        },
      ],
    },
    {
      title: "",
      className: "orderInfo",
      key: "11111",
      align: "center",
      fixed: "left",
      children: [
        {
          title: "订单数量",
          className: "orderInfo",
          dataIndex: "order_quantity",
          key: "order_quantity",
          align: "right",
          ellipsis: true,
          width: 90,
          onCell:handleCell,
          render: (item: Number) => (
            <div>{item && formatNumber(item.toString())}</div>
          ),
        },
      ],
    },
    {
      title: "任务单信息",
      className: "productionInfo",
      key: "2",
      align: "center",
      children: [
        {
          title: "任务单号",
          className: "productionInfo",
          dataIndex: "task_number",
          key: "task_number",
          align: "center",
          ellipsis: true,
          width: 160,
          onCell:handleCell
        },
        {
          title: "投产数量",
          className: "productionInfo",
          dataIndex: "task_quantity",
          key: "task_quantity",
          align: "right",
          ellipsis: true,
          width: 90,
          onCell:handleCell,
          render: (item: Number) => (
            <div>{item && formatNumber(item.toString())}</div>
          ),
        },
        {
          title: "最小完成数量",
          className: "productionInfo",
          dataIndex: "task_min_quantity",
          key: "task_min_quantity",
          align: "right",
          ellipsis: true,
          width: 120,
          onCell:handleCell,
          render: (item: Number) => (
            <div>{item && formatNumber(item.toString())}</div>
          ),
        },

        {
          title: "不良数量",
          className: "productionInfo",
          dataIndex: "task_defective",
          key: "task_defective",
          align: "right",
          ellipsis: true,
          width: 90,
          onCell:handleCell,
          render: (item: Number) => (
            <div>{item && formatNumber(item.toString())}</div>
          ),
        },
      ],
    },
    {
      title: "发货信息",
      className: "externalAssistanceInfo",
      key: "3",
      align: "center",
      children: [
        {
          title: "返厂转序单",
          className: "externalAssistanceInfo",
          dataIndex: "record_number",
          key: "record_number",
          align: "center",
          ellipsis: true,
          width: 140,
          onCell:handleCell
        },
        {
          title: "返厂工序",
          className: "externalAssistanceInfo",
          dataIndex: "record_process",
          key: "record_process",
          align: "center",
          ellipsis: true,
          width: 140,
          onCell:handleCell
        },
        {
          title: "客户",
          className: "externalAssistanceInfo",
          dataIndex: "trader",
          key: "trader",
          align: "center",
          ellipsis: true,
          width: 160,
          onCell:handleCell
        },
        {
          title: "转序人",
          className: "externalAssistanceInfo",
          dataIndex: "user",
          key: "user",
          align: "center",
          ellipsis: true,
          width: 100,
          onCell:handleCell
        },
        {
          title: "转序时间",
          className: "externalAssistanceInfo",
          dataIndex: "start_time",
          key: "start_time",
          align: "center",
          ellipsis: true,
          width: 130,
          onCell:handleCell,
          render: (item: any) => (
            <div>
              {item && formatTime(item, true)}
            </div>
          ),
        },
        {
          title: "发货数量",
          className: "externalAssistanceInfo",
          dataIndex: "quantity",
          key: "quantity",
          align: "right",
          ellipsis: true,
          width: 90,
          onCell:handleCell,
          render:(i)=><span>{i && formatNumber(i.toString())}</span>
        },
        {
          title: "发货记录状态",
          className: "externalAssistanceInfo",
          dataIndex: "status",
          key: "status",
          align: "center",
          ellipsis: true,
          width: 120,
          onCell:handleCell,
          render: (item) => (
            <div
              style={{
                color: item === 20 ? "#2B8743" : "#3E7BFA",
              }}
            >
              {item && item === 10
                ? "未开始"
                : item === 20
                ? "处理中"
                : "已完成"}
            </div>
          ),
        },
        {
          title: "预计收货日期",
          className: "externalAssistanceInfo",
          dataIndex: "plan_date",
          key: "plan_date",
          align: "center",
          ellipsis: true,
          width: 120,
          render: (item: String) => (
            <div>{item && formatTime(item, false)}</div>
          ),
          onCell:handleCell1
        },
      ],
    },
    {
      title: "收货信息",
      className: "reportInfo",
      key: "4",
      align: "center",
      children: [
        {
          title: "实际收货日期",
          className: "reportInfo",
          dataIndex: ["receipt", "create_time"],
          key: "create_time",
          align: "center",
          ellipsis: true,
          width: 120,
          render: (item: String) => (
            <div>{item && formatTime(item, false)}</div>
          ),
        },
        {
          title: "实际收货数量",
          className: "reportInfo",
          dataIndex: ["receipt", "qualified"],
          key: "qualified",
          align: "right",
          ellipsis: true,
          width: 120,
          render:(r) => <span>{r && formatNumber(r.toString())}</span>
        },
        {
          title: "收货备注",
          className: "reportInfo",
          dataIndex: ["receipt","remark"],
          key: "remark",
          align: "center",
          ellipsis: true,
          // width: 200,
        },
      ],
    },
  ];

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getWorkList(
        Object.assign(
         { flag: 1, report_status: JSON.stringify([20]) },
          queryCondition 
        )
      )
        .then((res) => {
          if (res.code === 200) {
            res.data.data && handleSpan(res.data.data)
            resolve(res.data);
          
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  }, []);
  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    if(queryData.report_status){
      
      if(JSON.stringify(queryData.report_status) === "[]"){
        queryData.report_status = JSON.stringify([20,30])
      }else {
        queryData.report_status = JSON.stringify(queryData.report_status)
      }
    }
    return queryData;
  };
  const getCheckboxProps = (record:any) => ({
    disabled: JSON.stringify(record.receipt) === "{}"? false:true //禁用的条件
  })
   // 处理数据，以便解决航合并中从第几行开始合并以及合并多少行的问题
   const handleSpan = (result:any[]) => {
    result.forEach((ele,i) => {
      ele.index = i + 1;
      ele.count = 1;
      ele.key = uuid();
    });
    //关键==>遍历
    for (let i = 0; i < result.length; i++) {
      const element = result[i];
      for (let j = i + 1; j < result.length; j++) {
        if (element.object_id=== result[j].object_id) {
          element.count++;
          result[j].count = 0;
          if(j==result.length-1){
            return result;
          }
        } else {
          i = j - 1;
          break;
        }
      }
    }
   
    return result;
  }
  const getTotalNumber = (data:any) => {
    let totalNumber:number = 0;
    data.forEach((item:any) => {
      if(item.number) {
        totalNumber+=item.number
      }
    })
    return totalNumber;
  }
  return (
    <div className="return_monitor">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        ExtraComponent={<ExtraComponent />}
        scroll={true}
        scrollValue={{x:2540}}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedRows={setSelectedRows}
        btnStyle={{ marginRight: "1px" }}
        filterQueryData={filterQueryData}
        getCheckboxProps={getCheckboxProps}
        paginationFalse={true}
      />
      {modalTitle && (
        <ModalContainer
          width={modalTitle === "创建返厂转序单" ? "50%" : "794px"}
          footer={[
            modalTitle === "创建返厂转序单" ? (
              <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false);
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="创建"
                  onClick={() => {
                    form
                      .validateFields()
                      .then((res) => {
                        let obj: any = transformObject(form.getFieldsValue());
                        let obj1: any = transformPrint(form.getFieldsValue());
                        obj.flag = 1;
                        obj.record_date = moment(obj.record_date).format("YYYY-MM-DD");
                        // obj.finish_date = obj.finish_date ? moment(obj.finish_date).format("YYYY-MM-DD") : undefined;
                        if(obj.finish_date) {
                          obj.finish_date = moment(obj.finish_date).format("YYYY-MM-DD");
                         } else {
                          // 传undefined不符合Date格式，直接删除，改成该项为不必传
                          delete obj.finish_date
                         }
                         if(!obj.name) {
                          delete obj.name
                         }
                         if(!obj.project) {
                          delete obj.project
                         }
                        obj.work = JSON.stringify(obj.work);
                      
                        const formData = getFormData(obj);
                        createRecord(formData)
                          .then((res: any) => {
                         
                            if (res.code === 200) {
                              obj1.work &&
                                obj1.work.map((item: any, index: number) => {
                                  item.index = index + 1;
                                });
                              if (gRef.current) {
                                gRef.current.onFinish();
                              }
                              // 收集打印需要的数据
                              selectedRows.map((item) => {
                                obj1.work &&
                                  obj1.work.map((w: any) => {
                                    if (item.object_id === w.id) {
                                      w.partName = item.part_name;
                                      w.partNumber = item.part_number;
                                      w.number = item.quantity;
                                    }
                                  });
                              });
                      
                              setPrintData(obj1);
                              openModal(ModalTitle.PRINT, obj1);
                              message.success("创建成功");
                              if(gRef.current){
                                gRef.current.onFinish()
                              }
                            } else {
                              message.error(res.message);
                            }
                          })
                          .catch((err: any) => {
                            message.error(err);
                          });
                      })
                      .catch((err) => {
                        console.log("校验错误", err);
                      });
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            ) : (
              <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false);
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <ExportExcel
                  columns={cols as any}
                  dataSource={printData.work}
                  btnName="导出"
                  tableName="返厂转序单"
                  isTwoHeader={false}
                  length={maxRow+1}
                  lastRowLength={maxRow + 5}
                  is_bg_null={true}
                  btnStyle={{
                    width: "130px",
                    height: "48px",
                    backgroundColor: "#3E7BFA",
                  }}
                  is_external_table={true}
                  totalNumber={getTotalNumber(printData.work)}
                  otherData={[
                    {
                      rows1: [
                        "生产制号",
                        printData.batch_production,
                        "任务名称",
                        printData.name,
                        "配套项目",
                        printData.project,
                      ],
                      rows2: [
                        "转序日期",
                        moment(printData.record_date).format("YYYY-MM-DD"),
                        "要求完成日期",
                        printData.finish_date ? moment(printData.finish_date).format("YYYY-MM-DD"):'',
                        "承制单位",
                        printData.trader,
                      ],
                      lastRow: [
                        "外协单位人员签字：",
                        "外协配套科人员签字：",
                        "特种工艺车间人员签字：",
                        "日期：",
                        "日期：",
                        "日期:",
                        "合计"
                      ],
                    },
                    { title: ["外协零件转序清单"] },
                  ]}
                ></ExportExcel>
                <MButton
                  title="打印"
                  onClick={() => {
                    if (printRef.current) {
                      Print(printRef.current);
                      onCancel1(modalTitle)
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            ),
          ]}
          open={open}
          onCancel={() => {
            setOpen(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
          }}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default ReturnMonitor;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import GeneralManagement, {GRef} from "../../../components/GeneralManagement";
import {Button, Form, List, message, Space, Table, Row, Col, Input, DatePicker, Select} from 'antd';
import {
    classificationList,
    filterData, filterDateParam, fontColor,
    formatNumber,
    getFormData, shiftTimeStamp,
    showError,
    statusList,
    wayList
} from "../../../utils";
import moment from 'moment';
import ModalContainer from "../../../components/Modal";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {ColumnsType} from "antd/es/table";
import '../index.less';
import CreateOrEditTask from "../components/CreateOrEditTask";
import {
    createTask,
    getOrderList,
    orderGetOrderDetail,
    partPrecesses,
    processingCategory
} from "../../../service";
import View from "../../Order/OrderManagement/View";
import MyImage from "../../../components/MyImage";

export enum ModalTitle {
    CATEGORY = '设置加工类别',
    CREATE = '创建投产任务',
    ORDER = '查看订单信息',
    PART = '零件工艺信息',
}

interface PartComponentProps {
    data: any[],
    dataSource: any[],
    type: number
}

export interface ModalComponentProps {
    form: FormInstance,
    item?: any
}

const ProductionOrderManagement = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();

    const gRef = useRef<GRef>(null);

    const formList = [
        {
            key: 'trader_name',
            name: '客户名称',
            width: 60,
            placeholder: '请输入客户名称',
        },
        {
            key: 'order_number',
            name: '订单号',
            width: 60,
            placeholder: '请输入订单号',
        },
        {
            key: 'part_status',
            name: '零件状态',
            width: 60,
            placeholder: '请选择零件状态',
            options: statusList,
            defaultValue: 20
        },
        {
            key: 'part_number',
            name: '零件图号',
            width: 60,
            placeholder: '请输入零件图号',
        },
        {
            key: 'part_material_type',
            name: '供料方式',
            width: 60,
            placeholder: '请选择供料方式',
            options: wayList
        },
        {
            key: 'deliveryDate',
            name: '交货日期',
            width: 60,
            placeholder: '请选择交货日期',
            rangePick: true
        },
    ]

    const columns: ColumnsType<any> = [
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            className: 'orderInfo',
            key: 'orderInfo',
            align: 'center',
            children: [
                {
                    title: "No",
                    dataIndex: "index",
                    className: 'orderInfo',
                    key: "index",
                    align: "center",
                    width: 60,
                    ellipsis: true,
                    fixed: "left",
                    render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
                },
                {
                    title: '零件状态',
                    dataIndex: 'status',
                    className: 'orderInfo',
                    key: 'status',
                    width: 90,
                    align: 'center',
                    fixed: "left",
                    render: (value: number) => {
                        return statusList.map(e => {
                            if (e.id === value) {
                                return <img key={e.id} src={e.src} alt={''} style={{width:60}}/>
                            }
                        })
                    }
                },

                {
                    title: '订单号',
                    dataIndex: ['order', 'code'],
                    className: 'orderInfo',
                    key: 'orderNumber',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string, item: any) => <span className={'pointer-item'}
                                                                onClick={() => openModal(ModalTitle.ORDER, item)}>{value}</span>
                },
                {
                    title: '零件名称',
                    dataIndex: ['part', 'name'],
                    className: 'orderInfo',
                    key: 'partName',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string, item: any) => <span className={'pointer-item'}
                                                                onClick={() => openModal(ModalTitle.PART, item)}>{value}</span>
                },
                {
                    title: '零件图号',
                    dataIndex: ['part', 'number'],
                    className: 'orderInfo',
                    key: 'partNumber',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string, item: any) => <span className={'pointer-item'}
                                                                onClick={() => openModal(ModalTitle.PART, item)}>{value}</span>
                },
                {
                    title: '订单数量',
                    dataIndex: 'number',
                    className: 'orderInfo',
                    key: 'orderAccount',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            className: 'orderInfo',
            key: 'orderInfo',
            align: 'center',
            children: [
                {
                    title: '客户',
                    dataIndex: ['order', 'trader', 'name'],
                    className: 'orderInfo',
                    key: 'customer',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                },
                {
                    title: '供料方式',
                    dataIndex: ['part', 'material_type'],
                    className: 'orderInfo',
                    key: 'way',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    render: (value: number) => {
                        return wayList.map(e => {
                            if (e.id === value) {
                                return <span key={e.id}>{e.name}</span>
                            }
                        })
                    }
                },
                {
                    title: '材料',
                    dataIndex: ['part', 'material'],
                    className: 'orderInfo',
                    key: 'material',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                },
                {
                    title: '加工内容',
                    dataIndex: 'content',
                    className: 'orderInfo',
                    key: 'content',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '交货日期',
                    dataIndex: 'finish_date',
                    className: 'orderInfo',
                    key: 'deliveryDate',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD')}</span>
                        }
                    }
                },
                {
                    title: '加工分类',
                    dataIndex: 'processing_category',
                    className: 'orderInfo',
                    key: 'classification',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                    render: (value: number) => {
                        return classificationList.map(e => {
                            if (e.id === value) {
                                return <span key={e.id}>{e.name}</span>
                            }
                        })
                    }
                },
                {
                    title: '备注',
                    dataIndex: ['order', 'remark'],
                    className: 'orderInfo',
                    key: 'remark',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '工艺信息',
            dataIndex: 'processInfo',
            className: 'processInfo',
            key: 'processInfo',
            align: "center",
            children: [
                {
                    title: '总投产数量',
                    dataIndex: ['production', 'number'],
                    className: 'processInfo',
                    key: 'quantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : ''}</div>
                },
                {
                    title: '工艺完成日期',
                    dataIndex: 'production_time',
                    className: 'processInfo',
                    key: 'completeDate',
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD')}</span>
                        } else {
                            return null
                        }
                    }
                },
                {
                    title: '工艺员',
                    dataIndex: ['production', 'user'],
                    className: 'processInfo',
                    key: 'person',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                },
            ]
        },
        {
            title: '投产信息',
            dataIndex: 'productionInfo',
            className: 'productionInfo',
            key: 'productionInfo',
            align: "center",
            children: [
                {
                    title: '累计投产数量',
                    dataIndex: ['task', 'produce_quantity'],
                    className: 'productionInfo',
                    key: 'productionQuantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '累计入库数量',
                    dataIndex: ['task', 'warehouse_quantity'],
                    className: 'productionInfo',
                    key: 'receiptQuantity',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '合格率',
                    dataIndex: ['task', 'rate'],
                    className: 'productionInfo',
                    key: 'rate',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => <div style={{textAlign: 'right'}}>{value}</div>
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo2',
            className: 'orderInfo',
            key: 'orderInfo2',
            align: "center",
            children: [

                {
                    title: '操作',
                    className: 'orderInfo',
                    align: 'center',
                    width: 150,
                    fixed: "right",
                    render: (_: any, item: any) => {
                        if (item.status === 20) {
                            return (
                                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                    <MyImage title={'加工分类'} src={require('../../../assets/process-classification.png')} onClick={() => {
                                        openModal(ModalTitle.CATEGORY, item)
                                    }}></MyImage>
                                    <MyImage title={'投产'} src={require('../../../assets/go-to-operation.png')} onClick={() => {
                                        openModal(ModalTitle.CREATE, item)
                                    }}></MyImage>
                                </Space>
                            )
                        }
                    }
                },
            ]
        },
    ]

    //设置加工类别
    const CategoryComponent = React.memo((props: ModalComponentProps) => {
        const {form, item} = props

        useEffect(() => {
            form.setFieldsValue(
                {
                    name: item.order.trader.name,
                    orderNumber: item.order.code,
                    partName: item.part.name,
                    partNumber: item.part.number,
                    partAccount: item.number,
                    deliveryDate: item.finish_date ? moment(item.finish_date) : '',
                    way: item.part.material_type,
                    material: item.part.material,
                    content: item.content,
                    classification: item.processing_category,
                }
            )
        }, [item])


        return (
            <div className={'form-margin'}>
                <Form form={form}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label={'客户'} key={'name'} name={'name'}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label={'订单号'} key={'orderNumber'} name={'orderNumber'}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'零件名称'} key={'partName'} name={'partName'}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'零件图号'} key={'partNumber'} name={'partNumber'}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label={'零件数量'} key={'partAccount'} name={'partAccount'}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'交货日期'} key={'deliveryDate'} name={'deliveryDate'}>
                                <DatePicker disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'供料方式'} key={'way'} name={'way'}>
                                <Select placeholder={'请选择供料方式'} disabled>
                                    {wayList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={'材料'} key={'material'} name={'material'}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={'加工内容'} key={'content'} name={'content'}>
                                <Input.TextArea disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label={'加工类别'} key={'classification'} name={'classification'}>
                                <Select placeholder={'请选择加工类别'}>
                                    {
                                        classificationList.map((oe: any, oi: number) =>
                                            <Select.Option key={oi} value={oe.id}>{oe.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>)
    })

    //零件工艺信息
    const PartComponent = React.memo((props: PartComponentProps) => {
        const {data, dataSource, type} = props
        const columns: ColumnsType<any> = [
            {
                title: "序号",
                dataIndex: "No",
                key: "No",
                align: "center",
                width: 100,
                ellipsis: true,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '工序名称',
                dataIndex: ['process', 'work_process_name'],
                key: 'name',
                width: 300,
                align: 'center',
                ellipsis: true
            },
            {
                title: '工序内容',
                dataIndex: 'remark',
                key: 'partNumber',
                align: 'center',
                ellipsis: true
            },
            {
                title: '首件工时',
                dataIndex: 'duration',
                key: 'workHour',
                align: 'center',
                ellipsis: true,
                render: (value) => <div style={{textAlign: 'right'}}>{value + '秒'}</div>
            },
            {
                title: '单件工时',
                dataIndex: 'work_duration',
                key: 'singleWorkHour',
                align: 'center',
                ellipsis: true,
                render: (value) => <div style={{textAlign: 'right'}}>{value + '秒'}</div>
            },
            {
                title: '预计总工时',
                dataIndex: 'predict_detail',
                key: 'allWorkHour',
                align: 'center',
                ellipsis: true,
                render: (value) => <div style={{textAlign: 'right'}}>{shiftTimeStamp(value)}</div>
            },
            {
                title: '备注',
                dataIndex: ['process', 'remark'],
                key: 'remark',
                align: 'center',
                ellipsis: true
            },
        ]

        return (<div className={'card-table form-margin'}>
            <List
                header={null}
                footer={
                    type === 10 ?
                        <Table className={'card-footer'} columns={columns} dataSource={dataSource}
                               pagination={false}></Table> : null
                }
                bordered
                dataSource={data}
                renderItem={item => {
                    return (
                        <List.Item>
                            {
                                Object.entries(item).map(e => <div className={'card-data-item'}>
                                    <span className={'card-data-item-key'}>{e[0]}</span>
                                    <span className={'card-data-item-value'}
                                          title={e[1] as any}>{e[1] as React.ReactNode}</span>
                                </div>)
                            }
                        </List.Item>
                    )
                }}
            />
        </div>)
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.CATEGORY) {
            setInnerComponent(<CategoryComponent form={form} item={item}/>)
        } else if (title === ModalTitle.CREATE) {
            form.setFieldsValue({
                name: '自动生成',
                orderNumber: item.order.code,
                partName: item.part.name,
                partNumber: item.part.number,
                partAccount: item.number,
                allQuantity: item.production.number,
                content: item.content,
                start_date: moment(),
                quantity:
                    item.processing_category === 10 ?
                        ((item.production.number ? item.production.number : 0) - (item.task.produce_quantity ? item.task.produce_quantity : 0)) :
                        ((item.number ? item.number : 0) - (item.task.produce_quantity ? item.task.produce_quantity : 0)),
                min_quantity: item.processing_category === 10 ?
                    ((item.production.number ? item.production.number : 0) - (item.task.produce_quantity ? item.task.produce_quantity : 0)) :
                    ((item.number ? item.number : 0) - (item.task.produce_quantity ? item.task.produce_quantity : 0)),
                category: item.processing_category
            })
            if (item.processing_category === 10) {
                if (JSON.stringify(item.production) === '{}') {
                    message.warn('工艺信息为空，无法投产')
                    return
                } else {
                    setInnerComponent(<CreateOrEditTask form={form} tableForm={tableForm} title={title}/>)
                }
            } else {
                setInnerComponent(<CreateOrEditTask form={form} tableForm={tableForm} title={title}/>)
            }
        } else if (title === ModalTitle.ORDER) {
            orderGetOrderDetail({object_id: item.order_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        trader_id: res.data.trader.name,
                        finish_date: res.data.finish_date ? moment(res.data.finish_date) : '',
                        offer_price: res.data.offer_price,
                        contract_price: res.data.contract_price,
                        remark: res.data.remark,
                        creator: res.data.user.name,
                        createTime: res.data.create_time ? moment(res.data.create_time) : '',
                        issueTime: res.data.issue_time ? moment(res.data.issue_time) : '',
                    })
                    setInnerComponent(<View form={form} disabled dataSource={res.data.order_part}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.PART) {
            partPrecesses({part_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    const data: any[] = [
                        {
                            '订单号': res.data.order.code,
                            '零件名称': res.data.part.name,
                            '零件图号': res.data.part.number,
                            '交货日期': res.data.finish_date,
                        },
                        {
                            '零件数量': res.data.number,
                            '材料': res.data.part.material,
                            '毛坯外形尺寸': res.data.production.blank_size,
                            '零件净尺寸': res.data.production.part_size,
                        },
                        {
                            '总投产数量': res.data.production.number,
                            '毛坯数量': res.data.production.blank_number,
                        },
                    ]
                    const dataSource: any[] = res.data.production.precesses
                    setInnerComponent(<PartComponent data={data} dataSource={dataSource}
                                                     type={item.processing_category}/>)
                }
            })
        }
        setOpen(true)
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.CATEGORY) {
            form.validateFields().then(values => {
                let realParams = {
                    part_id: selectedItem.object_id,
                    category: values.classification
                }
                processingCategory(realParams).then(res => {
                    if (res.code === 200) {
                        afterOnOK('修改成功！');
                    }
                })
            })
        } else if (title === ModalTitle.CREATE) {
            form.validateFields().then(values => {
                let tempForm = {
                    part_id: selectedItem.object_id,
                    start_date: moment(values.start_date).format('YYYY-MM-DD'),
                    quantity: values.quantity,
                    min_quantity: values.min_quantity,
                    category: values.category,
                    //只有20 整件外协 提交工艺线路
                    work_craft_id: values.category === 20 ? values.work_craft_id : undefined,
                }
                //只有30 小批量加工，提交工艺流程
                if (
                    values.category === 30
                ) {
                    tableForm.validateFields().then(values2 => {
                        //表格长度校验
                        //form数据转datasource数据
                        const tempTable = Object.values(values2)
                        tempTable.map((e: any) => {
                            e.is_epiboly = e.is_epiboly ? 1 : 0;
                            e.is_factory = e.is_factory ? 1 : 0;
                        })
                        if (tempTable.length === 0) {
                            message.warning('必须添加一条工艺流程！')
                        } else {
                            let realParams = {
                                ...tempForm,
                                work: JSON.stringify(tempTable)
                            }
                            const formData = getFormData(filterData(realParams))
                            createTask(formData).then(res => {
                                if (res.code === 200) {
                                    afterOnOK('新增成功！');
                                } else {
                                    showError(res.message)
                                }
                            })
                        }
                    })
                } else {
                    //    调用接口
                    const formData = getFormData(filterData(tempForm))
                    createTask(formData).then(res => {
                        if (res.code === 200) {
                            afterOnOK('新增成功！');
                        } else {
                            showError(res.message)
                        }
                    })
                }

            })
        }
    }

    const afterOnOK = (text: string) => {
        if (modalTitle === ModalTitle.CATEGORY || modalTitle === ModalTitle.ORDER) {
            form.resetFields();
        } else if (modalTitle === ModalTitle.CREATE) {
            form.resetFields();
            tableForm.resetFields();
        }
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish(true)
        }
    }

    const onCancel = () => {
        if (modalTitle === ModalTitle.CATEGORY || modalTitle === ModalTitle.ORDER) {
            form.resetFields();
        }
        if (modalTitle === ModalTitle.CREATE) {
            form.resetFields();
            tableForm.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(null);
        setOpen(false);
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise((resolve) => {
            getOrderList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        });
    }, []);

    const rowClassName = (record: any, index: number): string => {
        if (record.warning.delivery) {
            //    未交付预警
            return 'orange'

        } else if (record.warning.production) {
            //    未投产预警
            return 'purple'

        } else {
            return 'white'
        }
    }

    const filterQueryDataProduction = (queryData: any) => {
        if (queryData.deliveryDate) {
            queryData = filterDateParam(queryData, 'deliveryDate', 'start_date', 'end_date')
        }
        return queryData
    }

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.ORDER || modalTitle === ModalTitle.PART) {
            return (<div>
                <Button onClick={() => onCancel()}>关闭</Button>
            </div>)
        }
    }


    return (
        <div className={'production-orderManagement'}>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync} ref={gRef}
                               filterQueryData={filterQueryDataProduction}
                               scroll rowClassName={rowClassName}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel()}
                                okText={(modalTitle === ModalTitle.CATEGORY || modalTitle === ModalTitle.CREATE) ? '保存' : '确认'}
                                width={'50%'} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
            <div style={{display: 'flex'}}>
                <div className={'legend-row row-purple'} style={{marginRight: 38}}>未投产预警</div>
                <div className={'legend-row row-orange'}>未完成预警</div>
            </div>
        </div>
    )
}
export default ProductionOrderManagement;

import React, {useContext, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, QueryListItem} from "../../../components/GeneralManagement";
import {Button, Form, message, Space, Table} from "antd";
import ModalContainer from "../../../components/Modal";
import DeleteItem from "../../../components/DeleteItem";
import {
    orderDeleteOrder, orderGetOrderDetail,
    orderGetOrderList, orderRecall, orderSendOrder, orderUpdateOrder, partInfo, traderInfo,
} from "../../../service";
import {
    filterData, filterQueryDataOrder, fontColor,
    formatNumber,
    getFormData, getSystemInfoItem, orderStatusList,
    showError,
    wayList
} from "../../../utils";
import moment from "moment";
import {ColumnsType} from "antd/es/table";
import AddOrEdit, {PartNumberOptions} from "./AddOrEdit";
import View from "./View";
import {UserContext} from "../../../contexts/userContext";
import MyImage from "../../../components/MyImage";
import ExportExcel from "../../../components/ExportTable/exportTable";

export enum ModalTitle {
    VIEW = '查看订单',
    ADD = '新增订单',
    //编辑未下发的订单
    EDIT = '编辑订单',
    //编辑已下发的订单
    EDIT2 = '编辑订单 ',
    DELETE = '系统确认',
}


const OrderManagement = (): React.ReactElement => {
    const {useInfo} = useContext(UserContext);
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [partNumberOptions, setPartNumberOptions] = useState<PartNumberOptions[]>([]);
    const [code, setCode] = useState<string>();
    const [issueTime, setIssueTime] = useState<string>();
    const [trader, setTrader] = useState<string>();
    const [tempDatasource, setTempDatasource] = useState<any[]>([]);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const [maxRow, setMaxRow] = useState<number>(tempDatasource.length);
    const formList: QueryListItem[] = [
        {
            key: 'trader_name',
            name: '客户名称',
            width: 60,
            placeholder: '请输入客户名称'
        },
        {
            key: 'code',
            name: '订单号',
            width: 60,
            placeholder: '请输入订单号'
        },
        {
            key: 'status',
            name: '订单状态',
            width: 60,
            placeholder: '请选择订单状态',
            options: orderStatusList
        },
        {
            key: 'deliveryDate',
            name: '交货日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'createDate',
            name: '创建日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
        {
            key: 'issueDate',
            name: '下发日期',
            width: 60,
            placeholder: '请选择日期范围',
            rangePick: true
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: "No",
            dataIndex: "No",
            key: "No",
            align: "center",
            width: 60,
            ellipsis: true,
            fixed: "left",
            render: (_: any, __: any, index: number) => {
                return <span>{index + 1}</span>
            },
        },
        Table.EXPAND_COLUMN,
        {
            title: '订单号',
            dataIndex: 'code',
            key: 'orderNumber',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '客户',
            dataIndex: ['trader', 'name'],
            key: 'customer',
            align: 'center',
            width: 200,
            ellipsis: true
        },
        {
            title: '交货日期',
            dataIndex: 'finish_date',
            key: 'deliveryDate',
            align: 'center',
            ellipsis: true,
            width: 150,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD')}</span>
                }
            }
        },
        {
            title: '报价金额',
            dataIndex: 'offer_price',
            key: 'quotationAmount',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (value: string) => <div
                style={{textAlign: 'right'}}>{value ? formatNumber(value) : 0}</div>
        },
        {
            title: '合同金额',
            dataIndex: 'contract_price',
            key: 'contractAmount',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (value: string) => <div
                style={{textAlign: 'right'}}>{value ? formatNumber(value) : 0}</div>
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 90,
            render: (value: number) => {
                return (
                    orderStatusList.map(e => {
                        if (e.id === value) {
                            return <img key={e.id} src={e.src} alt={''} style={{width: 60}}/>
                        }
                    })
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            ellipsis: true,
            width: 200,
        },
        {
            title: '创建者',
            dataIndex: ['user', 'name'],
            key: 'creator',
            align: 'center',
            ellipsis: true,
            width: 100,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'createTime',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                } else {
                    return null
                }
            }
        },
        {
            title: '下发时间',
            dataIndex: 'issue_time',
            key: 'issueTime',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'left',
            fixed: "right",
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    {/*10 查看 编辑 删除 下发*/}
                    {/*20 查看 编辑 撤回*/}
                    {
                        item.status === 10 &&
                        <Space size={'large'}>
                            <MyImage title={'查看'} src={require('../../../assets/show.png')} onClick={() => {
                                openModal(ModalTitle.VIEW, item)
                            }}></MyImage>
                            <MyImage title={'编辑'} src={require('../../../assets/edit.png')} onClick={() => {
                                openModal(ModalTitle.EDIT, item)
                            }}></MyImage>
                            <MyImage title={'删除'} src={require('../../../assets/delete.png')} onClick={() => {
                                openModal(ModalTitle.DELETE, item)
                            }}></MyImage>
                            <MyImage title={'下发'} src={require('../../../assets/distribute.png')} onClick={() => {
                                handleIssue(item)
                            }}></MyImage>
                        </Space>
                    }
                    {
                        item.status === 20 &&
                        <Space size={'large'}>
                            <MyImage title={'查看'} src={require('../../../assets/show.png')} onClick={() => {
                                openModal(ModalTitle.VIEW, item)
                            }}></MyImage>
                            <MyImage title={'编辑'} src={require('../../../assets/edit.png')} onClick={() => {
                                openModal(ModalTitle.EDIT2, item)
                            }}></MyImage>
                            <MyImage title={'撤回'} src={require('../../../assets/withdraw.png')} onClick={() => {
                                handleRecall(item)
                            }}></MyImage>
                        </Space>
                    }
                </Space>
            )
        },
    ]

    const handleRecall = (item: any) => {
        const formData = filterData({object_id: item.object_id})
        orderRecall(formData).then(res => {
            if (res.code === 200) {
                refreshData();
                message.success('操作成功！');
            } else {
                showError(res.message);
            }
        })
    }

    const handleIssue = (item: any) => {
        const formData = getFormData(filterData({object_id: item.object_id}))
        orderSendOrder(formData).then(res => {
            if (res.code === 200) {
                refreshData();
                message.success('操作成功！');
            } else {
                showError(res.message);
            }
        })
    }

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-user-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    //获取订单零件表
    const getPartInfo = (values?: Object) => {
        return new Promise((resolve) => {
            partInfo(values).then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        const temp: PartNumberOptions[] = []
                        res.data.data.map((e: any) => {
                            temp.push({
                                key: e.object_id,
                                value: e.number,
                                name: e.name,
                                material: e.material,
                                material_type: e.material_type,
                            })
                        })
                        resolve(temp)
                        setPartNumberOptions(temp)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            getPartInfo().then((list: any) => {
                setInnerComponent(<AddOrEdit form={form} tableForm={tableForm} title={title} partNumberDefault={list}/>)
            })
        } else if (title === ModalTitle.VIEW) {
            orderGetOrderDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    //获取订单号、客户、下发时间
                    setCode(res.data.code);
                    setIssueTime(res.data.issue_time ? moment(res.data.issue_time).format('YYYY/MM/DD HH:mm') : '')
                    setTrader(res.data.trader.name)
                    //获取表格数据
                    if (res.data.order_part.length) {
                        let temp = res.data.order_part
                        temp.map((e: any, i: number) => {
                            e.No = i + 1;
                        })
                        setTempDatasource(temp)
                        setMaxRow(temp.length)
                    }
                    form.setFieldsValue({
                        code: res.data.code,
                        trader_id: res.data.trader.name,
                        finish_date: res.data.finish_date ? moment(res.data.finish_date) : '',
                        offer_price: res.data.offer_price,
                        contract_price: res.data.contract_price,
                        remark: res.data.remark,
                        creator: res.data.user.name,
                        createTime: res.data.create_time ? moment(res.data.create_time) : '',
                        issueTime: res.data.issue_time ? moment(res.data.issue_time) : '',
                    })
                    setInnerComponent(<View form={form} disabled dataSource={res.data.order_part} title={title}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.EDIT || title === ModalTitle.EDIT2) {
            orderGetOrderDetail({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    //获取客商信息中类别为客户、客商的客户信息
                    traderInfo().then(rest => {
                        if (rest.code === 200) {
                            if (rest.data.data) {
                                //判断用户是否被删除
                                const hasTrader = rest.data.data.find(item => item.object_id === res.data.trader_id)
                                form.setFieldsValue({
                                    code: res.data.code,
                                    trader_id: hasTrader ? res.data.trader_id : '',
                                    finish_date: res.data.finish_date ? moment(res.data.finish_date) : '',
                                    offer_price: res.data.offer_price,
                                    contract_price: res.data.contract_price,
                                    remark: res.data.remark,
                                })
                                let initialDataSource: any = []
                                if (res.data.order_part) {
                                    res.data.order_part.map((e: any, i: number) => {
                                        initialDataSource.push({
                                            ...e,
                                            index: i,
                                            finish_date: moment(e.finish_date),
                                            part_name: e.part.name,
                                            part_number: e.part.number,
                                            part_material_type: e.part.material_type,
                                            part_material: e.part.material,
                                        })
                                    })
                                }
                                tableForm.setFieldsValue({...initialDataSource})
                                getPartInfo().then((list: any) => {
                                    setInnerComponent(<AddOrEdit form={form} tableForm={tableForm} title={title}
                                                                 initialDataSource={initialDataSource}
                                                                 partNumberDefault={list}/>)
                                })
                            }
                        } else {
                            showError(res.message);
                        }
                    })
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                let tempForm = {
                    code: values.code,
                    trader_id: values.trader_id,
                    finish_date: values.finish_date ? moment(values.finish_date).format('YYYY-MM-DD') : undefined,
                    offer_price: values.offer_price,
                    contract_price: values.contract_price,
                    remark: values.remark,
                }
                tableForm.validateFields().then(values2 => {
                    //    表格长度校验
                    //form数据转datasource数据
                    const tempTable = Object.values(values2)
                    tempTable.map((e: any) => {
                        e.finish_date = moment(e.finish_date).format('YYYY-MM-DD')
                        filterData(e)
                    })
                    if (tempTable.length === 0) {
                        message.warning('必须添加一条订单零件！')
                    } else {
                        let realParams = {
                            ...tempForm,
                            orderpart_info: JSON.stringify(tempTable)
                        }
                        const formData = getFormData(filterData(realParams))
                        orderUpdateOrder(formData).then(res => {
                            if (res.code === 200) {
                                afterOnOK('新增成功！');
                            } else {
                                showError(res.message)
                            }
                        })
                    }
                })
            })
        } else if (title === ModalTitle.EDIT || title === ModalTitle.EDIT2) {
            form.validateFields().then(values => {
                let tempForm = {
                    object_id: selectedItem.object_id,
                    code: values.code,
                    trader_id: values.trader_id,
                    finish_date: values.finish_date ? moment(values.finish_date).format('YYYY-MM-DD') : undefined,
                    offer_price: values.offer_price,
                    contract_price: values.contract_price,
                    remark: values.remark,
                }
                tableForm.validateFields().then(values2 => {
                    //    表格长度校验
                    //form数据转datasource数据
                    const tempTable = Object.values(values2)
                    tempTable.map((e: any) => {
                        e.finish_date = moment(e.finish_date).format('YYYY-MM-DD')
                        filterData(e)
                    })
                    if (tempTable.length === 0) {
                        message.warning('必须添加一条订单零件！')
                    } else {
                        let realParams = {
                            ...tempForm,
                            orderpart_info: JSON.stringify(tempTable)
                        }
                        const formData = getFormData(filterData(realParams))
                        orderUpdateOrder(formData).then(res => {
                            if (res.code === 200) {
                                afterOnOK('编辑成功！');
                            } else {
                                showError(res.message)
                            }
                        })
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            orderDeleteOrder(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        if (modalTitle !== ModalTitle.DELETE) {
            form.resetFields();
            tableForm.resetFields();
        }
        message.success(text);
        setOpen(false);
        setSelectedItem(null)
        refreshData()
    }

    const onCancel = (title: ModalTitle) => {
        if (title !== ModalTitle.DELETE) {
            form.resetFields();
            tableForm.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined)
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.orderGetOrderList & API.pageInfo) => {
        return new Promise((resolve) => {
            orderGetOrderList({...queryCondition, user_id: useInfo.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const expandedRowRender = (record: any): React.ReactElement => {
        const columns: ColumnsType<any> = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                align: "center",
                width: 60,
                ellipsis: true,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '零件名称',
                dataIndex: ['part', 'name'],
                key: 'partName',
                align: 'center',
                ellipsis: true
            },
            {
                title: '零件图号',
                dataIndex: ['part', 'number'],
                key: 'partNumber',
                align: 'center',
                ellipsis: true
            },
            {
                title: '供料方式',
                dataIndex: ['part', 'material_type'],
                key: 'way',
                align: 'center',
                ellipsis: true,
                render: (value: number) => {
                    return wayList.map(e => {
                        if (e.id === value) {
                            return <span key={e.id}>{e.name}</span>
                        } else {
                            return null
                        }
                    })
                }
            },
            {
                title: '材料',
                dataIndex: ['part', 'material'],
                key: 'material',
                align: 'center',
                ellipsis: true
            },
            {
                title: '订单数量',
                dataIndex: 'number',
                key: 'orderAccount',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '加工内容',
                dataIndex: 'content',
                key: 'content',
                align: 'center',
                ellipsis: true
            },
            {
                title: '交货日期',
                dataIndex: 'finish_date',
                key: 'deliveryDate',
                align: 'center',
                ellipsis: true,
                width: 120,
                render: (value: string) => {
                    if (value) {
                        return <span>{moment(value).format('YYYY/MM/DD')}</span>
                    }
                }
            },
            {
                title: '报价单价',
                dataIndex: 'quot_single_price',
                key: 'quotedPrice',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '合同单价',
                dataIndex: 'contract_single_price',
                key: 'contractInfoPrice',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '合同数量',
                dataIndex: 'contract_number',
                key: 'contractQuantity',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '合同编号',
                dataIndex: 'contract_code',
                key: 'contractNumber',
                align: 'center',
                ellipsis: true,
            },
            {
                title: '合同日期',
                dataIndex: 'contract_date',
                key: 'contractDate',
                align: 'center',
                ellipsis: true,
                width: 200,
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                ellipsis: true
            },
        ]
        return <Table columns={columns} dataSource={record.order_part} pagination={false} className={'expand-table'}/>
    }

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.VIEW) {
            const tabName = "销售订单" + moment(new Date()).format('YYYYMMDD');
            const tempColumns: any[] = [
                {
                    title: "序号",
                    dataIndex: "No",
                    key: "No",
                    width: 80,
                    ellipsis: true,
                    fixed: "left",
                    align: 'center',
                },
                {
                    title: '图号',
                    dataIndex: ['part', 'number'],
                    key: 'part_number',
                    width: 150,
                    ellipsis: true,
                    fixed: "left",
                    align: 'center',
                },
                {
                    title: '名称',
                    dataIndex: ['part', 'name'],
                    key: 'part_name',
                    width: 150,
                    ellipsis: true,
                    fixed: "left",
                    align: 'center',
                },
                {
                    title: '数量',
                    dataIndex: 'number',
                    key: 'number',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '材质',
                    dataIndex: ['part', 'material'],
                    key: 'part_material',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '加工内容',
                    dataIndex: 'content',
                    key: 'content',
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '交货日期',
                    dataIndex: 'finish_date',
                    key: 'finish_date',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    key: 'remark',
                    width: 400,
                    ellipsis: true,
                    align: 'center',
                },
            ]

            return (<div style={{display: 'flex', justifyContent: 'center'}}>
                <ExportExcel
                    columns={tempColumns}
                    dataSource={tempDatasource}
                    btnName="导出"
                    tableName={tabName}
                    isTwoHeader={false}
                    is_bg_null={true}
                    is_order_table={true}
                    length={maxRow+1}
                    // lastRowLength={maxRow + 3}
                    otherData={[
                        {
                            rows1: ["下单日期", issueTime, "订单号", code, "客户简称", trader],
                            // lastRow: ["交货人：", "收货人：",],
                        },
                        {title: ["销售订单"]},
                    ]}
                    btnStyle={{
                        width: "130px",
                        height: "48px",
                        backgroundColor: "#3E7BFA",
                    }}/>
                <Button onClick={() => onCancel(ModalTitle.VIEW)}>关闭</Button>
            </div>)
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} scroll
                               filterQueryData={filterQueryDataOrder} expandedRowRender={expandedRowRender}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                width={modalTitle !== ModalTitle.DELETE ? '1700px' : '30%'}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT || modalTitle === ModalTitle.EDIT2) ? '保存' : '确认'}
                                footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}
export default OrderManagement


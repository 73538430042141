import React, {useCallback, useEffect, useRef, useState} from 'react';
import GeneralManagement, {GRef} from "../../../components/GeneralManagement";
import {Button, Form, Space, Table, List, message, Modal, Row, Col, Input, DatePicker, InputNumber} from 'antd';
import {
    classificationList,
    convertSecToHHmmss,
    filterData, filterDateParam, fontColor,
    formatNumber, getFormData,
    InspectionStatus, shiftTimeStamp,
    showError,
    taskList,
} from "../../../utils";
import moment from 'moment';
import ModalContainer from "../../../components/Modal";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {ColumnsType} from "antd/es/table";
import '../index.less';
import CreateOrEditTask from "../components/CreateOrEditTask";
import DeleteItem from "../../../components/DeleteItem";
import {ModalComponentProps} from "../ProductionOrderManagement";
import {Column} from '@ant-design/plots';
import {
    getTaskDetail,
    getTaskList,
    orderGetOrderDetail, taskClose, taskDelete, taskEdit, taskIssue, taskModifyQuantity,
    taskProcess, taskProgress, taskRecall, taskRepairList
} from "../../../service";
import ExportExcel from "../../../components/ExportTable/exportTable";
import QRCode from "qrcode.react";
import View from "../../Order/OrderManagement/View";
import Print from 'react-print-html'
import {ExclamationCircleOutlined} from "@ant-design/icons";
import MyImage from "../../../components/MyImage";

export enum ModalTitle {
    ORDER = '查看订单信息',
    CARD = '工艺过程卡',
    //任务状态为10未下达时的编辑
    EDIT = '编辑投产任务',
    //加工类别为30小批量加工、并且任务状态为20已下达/30处理中时的编辑页面
    EDIT2 = '编辑任务',
    DELETE = '系统确认',
    REPAIR = '查询返修记录',
    PROCESS = '任务进度',
    NUMBER = '投产数量调整'
}

interface CardComponentProps {
    id: number,
    data: any[],
    dataSource: any[]
}

interface RepairComponentProps {
    dataSource: any[]
}

interface ProcessComponentProps {
    form: FormInstance,
    dataSource: any[]
}

interface DataType {
    name: string,
    value: number,
    type: string,
}

const TaskManagement = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const printRef = useRef(null);

    const formList = [
        {
            key: 'order_number',
            name: '订单号',
            width: 60,
            placeholder: '请输入订单号',
        },
        {
            key: 'part_number',
            name: '零件图号',
            width: 60,
            placeholder: '请输入零件图号',
        },
        {
            key: 'task_number',
            name: '任务单号',
            width: 60,
            placeholder: '请输入任务单号',
        },
        {
            key: 'status',
            name: '任务状态',
            width: 60,
            placeholder: '请选择任务状态',
            options: taskList
        },
        {
            key: 'deliveryDate',
            name: '交货日期',
            width: 60,
            placeholder: '请选择交货日期',
            rangePick: true
        },
        {
            key: 'productionDate',
            name: '投产日期',
            width: 60,
            placeholder: '请选择投产日期',
            rangePick: true
        },
    ]

    const columns: ColumnsType<any> = [
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            className: 'orderInfo',
            key: 'orderInfo',
            align: 'center',
            children: [
                {
                    title: "No",
                    dataIndex: "index",
                    className: 'orderInfo',
                    key: "index",
                    align: "center",
                    width: 60,
                    ellipsis: true,
                    fixed: "left",
                    render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    className: 'orderInfo',
                    key: 'status',
                    align: 'center',
                    width: 90,
                    fixed: "left",
                    render: (value: number) => {
                        return taskList.map(e => {
                            if (e.id === value) {
                                return <img key={e.id} src={e.src} alt={''} style={{width: 60}}/>
                            }
                        })
                    }
                },
                {
                    title: '订单号',
                    dataIndex: ['order', 'order', 'code'],
                    className: 'orderInfo',
                    key: 'orderNumber',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string, item: any) => <span className={'pointer-item'}
                                                                onClick={() => openModal(ModalTitle.ORDER, item)}>{value}</span>
                },
                {
                    title: '零件名称',
                    dataIndex: ['order', 'part', 'name'],
                    className: 'orderInfo',
                    key: 'partName',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string, item: any) => <span className={'pointer-item'}
                                                                onClick={() => openModal(ModalTitle.CARD, item)}>{value}</span>
                },
                {
                    title: '零件图号',
                    dataIndex: ['order', 'part', 'number'],
                    className: 'orderInfo',
                    key: 'partNumber',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string, item: any) => <span className={'pointer-item'}
                                                                onClick={() => openModal(ModalTitle.CARD, item)}>{value}</span>
                },
                {
                    title: '订单数量',
                    dataIndex: ['order', 'number'],
                    className: 'orderInfo',
                    key: 'orderAccount',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    fixed: "left",
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },

            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo',
            className: 'orderInfo',
            key: 'orderInfo',
            align: 'center',
            children: [
                {
                    title: '交货日期',
                    dataIndex: ['order', 'finish_date'],
                    className: 'orderInfo',
                    key: 'deliveryDate',
                    width: 120,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD')}</span>
                        }
                    }
                },
            ]
        },
        {
            title: '投产信息',
            dataIndex: 'productionInfo',
            className: 'productionInfo',
            key: 'productionInfo',
            align: "center",
            children: [
                {
                    title: '任务单号',
                    dataIndex: 'number',
                    className: 'productionInfo',
                    key: 'taskNumber',
                    align: 'center',
                    width: 160,
                    ellipsis: true,
                },
                {
                    title: '投产日期',
                    dataIndex: 'start_date',
                    className: 'productionInfo',
                    key: 'productionDate',
                    align: 'center',
                    width: 120,
                    ellipsis: true,
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD')}</span>
                        }
                    }
                },
                {
                    title: '投产数量',
                    dataIndex: 'quantity',
                    className: 'productionInfo',
                    key: 'productionNumber',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '最小完成数量',
                    dataIndex: 'min_quantity',
                    className: 'productionInfo',
                    key: 'completeNumber',
                    align: 'center',
                    width: 120,
                    ellipsis: true,
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
            ]
        },
        {
            title: '报工信息',
            dataIndex: 'reportInfo',
            className: 'reportInfo',
            key: 'reportInfo',
            align: "center",
            children: [
                {
                    title: '不良数量',
                    dataIndex: 'defective_quantity',
                    className: 'reportInfo',
                    key: 'badNumber',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    onCell: (record, _) => {
                        return {
                            className: record.warning.defective ? "cell-yellow" : ""
                        };
                    },
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
                {
                    title: '入库数量',
                    dataIndex: 'warehouse_quantity',
                    className: 'reportInfo',
                    key: 'receiptNumber',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                    render: (value: string) => <div
                        style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo2',
            className: 'orderInfo',
            key: 'orderInfo2',
            align: "center",
            children: [

                {
                    title: '合格率',
                    dataIndex: 'rate',
                    className: 'orderInfo',
                    key: 'rate',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '创建者',
                    dataIndex: ['user', 'name'],
                    className: 'orderInfo',
                    key: 'creator',
                    align: 'center',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    className: 'orderInfo',
                    key: 'createTime',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                        }
                    }
                },
                {
                    title: '下达时间',
                    dataIndex: 'issue_time',
                    className: 'orderInfo',
                    key: 'issueTime',
                    width: 200,
                    ellipsis: true,
                    align: 'center',
                    render: (value: string) => {
                        if (value) {
                            return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                        }
                    }
                },
                {
                    title: '加工类别',
                    dataIndex: 'processing_category',
                    className: 'orderInfo',
                    key: 'processing_category',
                    align: 'center',
                    width: 140,
                    ellipsis: true,
                    render: (value: number) => {
                        return classificationList.map(e => {
                            if (e.id === value) {
                                return <span key={e.id}>{e.name}</span>
                            }
                        })
                    }
                },
            ]
        },
        {
            title: '订单信息',
            dataIndex: 'orderInfo2',
            className: 'orderInfo',
            key: 'orderInfo2',
            align: "center",
            children: [
                {
                    title: '操作',
                    dataIndex: 'options',
                    className: 'orderInfo',
                    key: 'options',
                    align: 'left',
                    width: 350,
                    fixed: "right",
                    render: (_: any, item: any) => {
                        //任务单状态
                        if (item.status === 10) {
                            return <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                <MyImage title={'编辑'} src={require('../../../assets/edit.png')} onClick={() => {
                                    openModal(ModalTitle.EDIT, item)
                                }}></MyImage>
                                <MyImage title={'删除'} src={require('../../../assets/delete.png')} onClick={() => {
                                    openModal(ModalTitle.DELETE, item)
                                }}></MyImage>
                                <MyImage title={'下达'} src={require('../../../assets/distribute.png')} onClick={() => {
                                    handleIssue(item)
                                }}></MyImage>
                                <MyImage title={'打印过程卡'} src={require('../../../assets/print.png')} onClick={() => {
                                    openModal(ModalTitle.CARD, item)
                                }}></MyImage>
                            </Space>
                        } else if (item.status === 20) {
                            return <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                {
                                    item.processing_category === 30 &&
                                    <MyImage title={'编辑'} src={require('../../../assets/edit.png')} onClick={() => {
                                        openModal(ModalTitle.EDIT2, item)
                                    }}></MyImage>
                                }
                                <MyImage title={'打印过程卡'} src={require('../../../assets/print.png')} onClick={() => {
                                    openModal(ModalTitle.CARD, item)
                                }}></MyImage>
                                <MyImage title={'返修记录'} src={require('../../../assets/repair-record.png')}
                                         onClick={() => {
                                             openModal(ModalTitle.REPAIR, item)
                                         }}></MyImage>
                                <MyImage title={'任务进度'} src={require('../../../assets/task.png')} onClick={() => {
                                    openModal(ModalTitle.PROCESS, item)
                                }}></MyImage>
                                <MyImage title={'撤回'} src={require('../../../assets/withdraw.png')} onClick={() => {
                                    handleRecall(item)
                                }}></MyImage>
                            </Space>
                        } else if (item.status === 30) {
                            return <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                {
                                    item.processing_category === 30 &&
                                    <MyImage title={'编辑'} src={require('../../../assets/edit.png')} onClick={() => {
                                        openModal(ModalTitle.EDIT2, item)
                                    }}></MyImage>
                                }
                                <MyImage title={'打印过程卡'} src={require('../../../assets/print.png')} onClick={() => {
                                    openModal(ModalTitle.CARD, item)
                                }}></MyImage>
                                <MyImage title={'返修记录'} src={require('../../../assets/repair-record.png')}
                                         onClick={() => {
                                             openModal(ModalTitle.REPAIR, item)
                                         }}></MyImage>
                                <MyImage title={'任务进度'} src={require('../../../assets/task.png')} onClick={() => {
                                    openModal(ModalTitle.PROCESS, item)
                                }}></MyImage>
                                <MyImage title={'数量调整'} src={require('../../../assets/quantity-adjustment.png')}
                                         onClick={() => {
                                             openModal(ModalTitle.NUMBER, item)
                                         }}></MyImage>
                                <MyImage title={'关闭任务单'} src={require('../../../assets/close.png')} onClick={() => {
                                    closeTask(item)
                                }}></MyImage>
                            </Space>
                        } else if (item.status === 40) {
                            return <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                                <MyImage title={'打印过程卡'} src={require('../../../assets/print.png')} onClick={() => {
                                    openModal(ModalTitle.CARD, item)
                                }}></MyImage>
                                <MyImage title={'返修记录'} src={require('../../../assets/repair-record.png')}
                                         onClick={() => {
                                             openModal(ModalTitle.REPAIR, item)
                                         }}></MyImage>
                                <MyImage title={'任务进度'} src={require('../../../assets/task.png')} onClick={() => {
                                    openModal(ModalTitle.PROCESS, item)
                                }}></MyImage>
                            </Space>
                        }
                    }
                },
            ]
        },
    ]

    const handleIssue = (item: any) => {
        //校验任务单所在的订单状态
        if (item.order.order.status === 30 || item.order.order.status === 40) {
            showError('所在订单已交货或已关闭，无法对任务单执行下达操作！')
        } else {
            const formData = getFormData(filterData({task_id: item.object_id}))
            taskIssue(formData).then(res => {
                if (res.code === 200) {
                    refreshData(true);
                    message.success('操作成功！');
                } else {
                    showError(res.message);
                }
            })
        }
    }

    const handleRecall = (item: any) => {
        const formData = getFormData(filterData({task_id: item.object_id}))
        taskRecall(formData).then(res => {
            if (res.code === 200) {
                refreshData(true);
                message.success('操作成功！');
            } else {
                showError(res.message);
            }
        })
    }

    const closeTask = (item: any) => {
        const formData = getFormData(filterData({task_id: item.object_id, flag: 1}))
        taskClose(formData).then(res => {
            //根据返回状态判断是否存在未完成的工序，若存在未完成的则弹出确认框：尚有未完工的工序，确定要关闭任务单？选择是关闭任务单
            if (res.code === -1) {
                //存在未完成的数据
                Modal.confirm({
                    title: '尚有未完工的工序，确定要关闭任务单？',
                    icon: <ExclamationCircleOutlined/>,
                    onOk() {
                        return new Promise((resolve, reject) => {
                            const formData = getFormData(filterData({task_id: item.object_id, flag: 0}))
                            taskClose(formData).then(res => {
                                if (res.code === 200) {
                                    refreshData(true);
                                    message.success('操作成功！');
                                } else {
                                    showError(res.message)
                                }
                            })
                            resolve('success')
                        }).catch(() => console.log('Oops errors!'));
                    },
                    onCancel() {
                    },
                })
            } else if (res.code === 200) {
                refreshData(true);
                message.success('操作成功！');
            } else {
                showError(res.message);
            }
        })
    }

    //加工工艺过程卡
    const CardComponent = React.memo((props: CardComponentProps) => {
        const {data, dataSource, id} = props;
        const [firstLine, ...restLine] = data

        const columns: ColumnsType<any> = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                align: "center",
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '工序名称',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            },
            {
                title: '工序内容',
                dataIndex: 'process_explain',
                key: 'partNumber',
                align: 'center',
            },
            {
                title: '首件工时',
                dataIndex: 'first_duration',
                key: 'workHour',
                align: 'center',
                render: (value) => <div style={{textAlign: 'right'}}>{value + '秒'}</div>
            },
            {
                title: '单件工时',
                dataIndex: 'work_duration',
                key: 'singleWorkHour',
                align: 'center',
                render: (value) => <div style={{textAlign: 'right'}}>{value + '秒'}</div>
            },
            {
                title: '预计总工时',
                dataIndex: 'total_duration',
                key: 'allWorkHour',
                align: 'center',
                render: (value) => <div style={{textAlign: 'right'}}>{shiftTimeStamp(value)}</div>
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                render: () => <span>{null}</span>
            },
        ]

        useEffect(() => {
            const canvasToImage = (canvas: HTMLCanvasElement) => {
                const image = new Image();
                image.src = canvas.toDataURL("image/png");
                image.width = 100;
                return image;
            }
            const canvasContainer = document.getElementsByTagName('canvas')[0];
            const img = canvasToImage(canvasContainer);
            const ewmImg: HTMLElement | null = document.getElementById('ewm');
            if (ewmImg) {
                ewmImg.append(img);
            }
        }, [])

        return (<div className={'form-margin'}>
            <div className={'card-table'} ref={printRef}>
                <List bordered
                      header={
                          <div className={'card-header'}>
                              <span className={'card-table-title'}>加工工艺过程卡</span>
                              <QRCode
                                  className={'card-table-qr'}
                                  id="qrCode"
                                  value={`PRO_${id}`}
                                  size={60}
                                  fgColor="#000000"
                                  style={{display: 'none'}}
                              />
                              <div className="card-table-qr" id="ewm"></div>
                          </div>
                      }
                      footer={<Table className={'card-footer table-bordered-black'} columns={columns}
                                     dataSource={dataSource}
                                     pagination={false} bordered></Table>}>
                    <List.Item className={'process-card-first'}>
                        {
                            Object.entries(firstLine).map(e => <div className={'card-data-item'}>
                                <div className={'card-data-item-key'}>{e[0]}</div>
                                <div className={'card-data-item-value'}
                                     title={e[1] as any}>{e[1] as React.ReactNode}</div>
                            </div>)
                        }
                    </List.Item>
                    {
                        restLine.map((item: any) => {
                            return <List.Item className={'process-card'}>
                                {
                                    Object.entries(item).map(e => <div className={'card-data-item'}>
                                        <div className={'card-data-item-key'}>{e[0]}</div>
                                        <div className={'card-data-item-value'}
                                             title={e[1] as any}>{e[1] as React.ReactNode}</div>
                                    </div>)
                                }
                            </List.Item>
                        })
                    }
                </List>
            </div>
        </div>)
    })

    //查询返修记录
    const RepairComponent = React.memo((props: RepairComponentProps) => {
        const {dataSource} = props
        const columns: ColumnsType<any> = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                align: "center",
                width: 60,
                ellipsis: true,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '订单号',
                dataIndex: 'order_number',
                key: 'orderNumber',
                align: 'center',
                ellipsis: true,
            },
            {
                title: '零件名称',
                dataIndex: 'part_name',
                key: 'partName',
                align: 'center',
                ellipsis: true
            },
            {
                title: '零件图号',
                dataIndex: 'part_number',
                key: 'partNumber',
                align: 'center',
                ellipsis: true
            },
            {
                title: '订单数量',
                dataIndex: 'order_quantity',
                key: 'orderAccount',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '任务单号',
                dataIndex: 'task_number',
                key: 'taskNumber',
                align: 'center',
                width: 160,
                ellipsis: true,
            },
            {
                title: '投产数量',
                dataIndex: 'task_quantity',
                key: 'productionNumber',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '返修员',
                dataIndex: 'user',
                key: 'person',
                align: 'center',
                ellipsis: true
            },
            {
                title: '返修数量',
                dataIndex: 'quantity',
                key: 'repairNumber',
                align: 'center',
                ellipsis: true,
                render: (value: string, item: any) => {
                    if (item.status === 30) {
                        return <div
                            style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
                    } else {
                        return null
                    }
                }
            },
            {
                title: '开始日期',
                dataIndex: 'start_time',
                key: 'startDate',
                align: 'center',
                ellipsis: true,
                width: 200,
                render: (value: string) => {
                    if (value) {
                        return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                    }
                }
            },
            {
                title: '结束日期',
                dataIndex: 'end_time',
                key: 'endDate',
                align: 'center',
                ellipsis: true,
                width: 200,
                render: (value: string) => {
                    if (value) {
                        return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                ellipsis: true,
                render: (value: number) => {
                    return InspectionStatus.map(e => {
                        if (e.id === value) {
                            return <span key={e.id}>{e.name}</span>
                        }
                    })
                }
            },
        ]
        return <div className={'form-margin'}><Table columns={columns} dataSource={dataSource}
                                                     pagination={false}></Table></div>
    })

    //任务进度
    const ProcessComponent = React.memo((props: ProcessComponentProps) => {
        const {form, dataSource} = props;
        const columns: ColumnsType<any> = [
            {
                title: "No",
                dataIndex: "No",
                key: "No",
                align: "center",
                width: 60,
                ellipsis: true,
                render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
            },
            {
                title: '工序',
                dataIndex: 'order_number',
                key: 'name',
                align: 'center',
                ellipsis: true
            },
            {
                title: '工序说明',
                dataIndex: 'process_explain',
                key: 'content',
                align: 'center',
                ellipsis: true
            },
            {
                title: '合格',
                dataIndex: 'qualified',
                key: 'qualified',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '不良',
                dataIndex: 'defective',
                key: 'bad',
                align: 'center',
                ellipsis: true,
                render: (value: string) => <div
                    style={{textAlign: 'right'}}>{value ? formatNumber(value.toString()) : 0}</div>
            },
            {
                title: '未完成',
                dataIndex: 'undone',
                key: 'incomplete',
                align: 'center',
                ellipsis: true,
                render: (value: number) => <div
                    style={{textAlign: 'right'}}>{handleFormat(value)}</div>
            },
        ]

        const handleFormat = (value: number) => {
            if (value) {
                if (String(value).includes('.')) {
                    //    有小数
                    return formatNumber(value.toFixed(1).toString())
                } else {
                    //    没有小数
                    return formatNumber(value.toString())
                }
            } else {
                return 0
            }

        }

        const [data, setData] = useState<DataType[]>([])

        useEffect(() => {
            let tempData: DataType[] = []
            dataSource.map(e => {
                //或order_number相同，则进行处理
                tempData.map(t => {
                    if (t.name === e.order_number) {
                        e.order_number = e.order_number + " "
                    }
                })
                tempData.push(
                    {name: e.order_number, value: e.undone, type: '未完成'},
                    {name: e.order_number, value: e.defective, type: '不良'},
                    {name: e.order_number, value: e.qualified, type: '合格'},
                )
            })
            setData(tempData)
        }, [dataSource])

        const config = {
            data: data,
            isStack: true,
            xField: 'name',
            yField: 'value',
            seriesField: 'type',
            columnWidthRatio: 0.3,
            maxColumnWidth: 20,
            minColumnWidth: 5,
            theme: {
                colors10: ['#C2D1FF', '#EB4420', '#708AFF',]
            },
            xAxis: {
                tickLine: null
            },
        };

        return <div className={'form-margin label-width-120'}>
            <Form form={form}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'任务单号'} key={'name'} name={'name'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'订单号'} key={'orderNumber'} name={'orderNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'零件名称'} key={'partName'} name={'partName'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'零件图号'} key={'partNumber'} name={'partNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'加工内容'} key={'content'} name={'content'}>
                            <Input.TextArea disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'投产日期'} key={'productionDate'} name={'productionDate'}>
                            <DatePicker disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'投产数量'} key={'productionNumber'} name={'productionNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'最小完成数量'} key={'completeNumber'} name={'completeNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className={'column-stack'}>
                <Column {...config} legend={{layout: 'horizontal', position: 'top-left', offsetX: 80}}/>
            </div>
            <Table columns={columns} dataSource={dataSource} pagination={false}></Table>
        </div>
    })

    //投产数量调整
    const NumberComponent = React.memo((props: ModalComponentProps) => {
        const {form} = props;

        return <div className={'form-margin label-width-150'}>
            <Form form={form}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'任务单号'} key={'name'} name={'name'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'订单号'} key={'orderNumber'} name={'orderNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'零件名称'} key={'partName'} name={'partName'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'零件图号'} key={'partNumber'} name={'partNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'加工内容'} key={'content'} name={'content'}>
                            <Input.TextArea disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'投产日期'} key={'productionDate'} name={'productionDate'}>
                            <DatePicker disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'投产数量'} key={'productionNumber'} name={'productionNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'最小完成数量'} key={'completeNumber'} name={'completeNumber'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'调整后投产数量'} key={'quantity'} name={'quantity'}
                                   rules={[
                                       ({getFieldValue, validateFields}) => ({
                                           validator(rule, value) {
                                               if (!value || value > getFieldValue('min_quantity')) {
                                                   return Promise.resolve()
                                               } else {
                                                   validateFields(['min_quantity'])
                                               }
                                           }
                                       })
                                   ]}>
                            <InputNumber min={0} placeholder={'请输入投产数量'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'调整后最小完成数量'} key={'min_quantity'} name={'min_quantity'}
                                   rules={[
                                       ({getFieldValue}) => ({
                                           validator(rule, value) {
                                               if (!value || value <= getFieldValue('quantity')) {
                                                   return Promise.resolve()
                                               } else {
                                                   return Promise.reject(new Error('调整后最小完成数量应该小于等于调整后投产数量！'))
                                               }
                                           }
                                       })
                                   ]}>
                            <InputNumber min={0} placeholder={'请输入最小完成数量'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ORDER) {
            orderGetOrderDetail({object_id: item.order.order_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        trader_id: res.data.trader.name,
                        finish_date: res.data.finish_date ? moment(res.data.finish_date) : '',
                        offer_price: res.data.offer_price,
                        contract_price: res.data.contract_price,
                        remark: res.data.remark,
                        creator: res.data.user.name,
                        createTime: res.data.create_time ? moment(res.data.create_time) : '',
                        issueTime: res.data.issue_time ? moment(res.data.issue_time) : '',
                    })
                    setInnerComponent(<View form={form} disabled dataSource={res.data.order_part}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.CARD) {
            taskProcess({task_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    const data: any[] = [
                        {
                            '订单号': res.data.order_part.order.code,
                            '零件名称': res.data.order_part.part.name,
                            '零件图号': res.data.order_part.part.number,
                            '交货日期': res.data.order_part.order.finish_date,
                        },
                        {
                            '订单数量': res.data.order_part.number ? formatNumber(res.data.order_part.number.toString()) : 0,
                            '材料': res.data.order_part.part.material,
                            '毛坯外形尺寸': res.data.order_part.production.blank_size,
                            '零件净尺寸': res.data.order_part.production.part_size,
                        },
                        {
                            '总投产数量': res.data.order_part.production.number ? formatNumber(res.data.order_part.production.number.toString()) : 0,
                            '毛坯数量': res.data.order_part.production.blank_number ? formatNumber(res.data.order_part.production.blank_number.toString()) : 0,
                        },
                        {
                            '任务单号': res.data.number,
                            '投产数量': res.data.quantity ? formatNumber(res.data.quantity.toString()) : 0,
                            '最小完成数量': res.data.min_quantity ? formatNumber(res.data.min_quantity.toString()) : 0,
                            '投产日期': res.data.start_date,
                        },
                    ]
                    setInnerComponent(<CardComponent data={data} dataSource={res.data.craft_list}
                                                     id={res.data.object_id}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.EDIT) {
            form.setFieldsValue({
                name: item.number,
                orderNumber: item.order.order.code,
                partName: item.order.part.name,
                partNumber: item.order.part.number,
                partAccount: item.order.number,
                allQuantity: item.quantity,
                content: item.order.content,
                start_date: item.start_date ? moment(item.start_date) : '',
                quantity: item.quantity,
                min_quantity: item.min_quantity,
                category: item.processing_category,
                work_craft_id: item.work_craft_id
            })
            getTaskDetail({task_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    let initialDataSource: any = [];
                    if (res.data.work) {
                        res.data.work.map((e: any, i: number) => {
                            initialDataSource.push({
                                index: i,
                                ...e,
                            })
                        })
                    }
                    tableForm.setFieldsValue({...initialDataSource})
                    setInnerComponent(<CreateOrEditTask form={form} tableForm={tableForm} title={title}
                                                        initialDataSource={initialDataSource}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.EDIT2) {
            form.setFieldsValue({
                name: item.number,
                orderNumber: item.order.order.code,
                partName: item.order.part.name,
                partNumber: item.order.part.number,
                partAccount: item.order.number,
                allQuantity: item.quantity,
                content: item.order.content,
                start_date: item.start_date ? moment(item.start_date) : '',
                quantity: item.quantity,
                min_quantity: item.min_quantity,
                category: item.processing_category,
                work_craft_id: item.work_craft_id
            })
            getTaskDetail({task_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    let initialDataSource: any = [];
                    if (res.data.work) {
                        res.data.work.map((e: any, i: number) => {
                            initialDataSource.push({
                                flag: true,
                                id: e.object_id,
                                ...e,
                                index: i,
                            })
                        })
                    }
                    tableForm.setFieldsValue({...initialDataSource})
                    setInnerComponent(<CreateOrEditTask form={form} tableForm={tableForm} title={title}
                                                        initialDataSource={initialDataSource}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.REPAIR) {
            taskRepairList({task_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<RepairComponent dataSource={res.data.data}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.PROCESS) {
            taskProgress({task_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue(
                        {
                            name: res.data.number,
                            orderNumber: res.data.order_part.order.code,
                            partName: res.data.order_part.part.name,
                            partNumber: res.data.order_part.part.number,
                            content: res.data.order_part.content,
                            productionDate: moment(res.data.start_date),
                            productionNumber: res.data.quantity,
                            completeNumber: res.data.min_quantity,
                        }
                    )
                    setInnerComponent(<ProcessComponent form={form} dataSource={res.data.work}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.NUMBER) {
            taskProgress({task_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue(
                        {
                            name: res.data.number,
                            orderNumber: res.data.order_part.order.code,
                            partName: res.data.order_part.part.name,
                            partNumber: res.data.order_part.part.number,
                            content: res.data.order_part.content,
                            productionDate: moment(res.data.start_date),
                            productionNumber: res.data.quantity,
                            completeNumber: res.data.min_quantity,
                            quantity: res.data.quantity,
                            min_quantity: res.data.min_quantity
                        }
                    )
                    setInnerComponent(<NumberComponent form={form}/>)
                } else {
                    showError(res.message)
                }
            })

        }
        setOpen(true)
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.CARD) {
            //打印工艺过程卡
            if (printRef.current) {
                Print(printRef.current)
                onCancel()
            }
        } else if (title === ModalTitle.EDIT || title === ModalTitle.EDIT2) {
            //保存
            form.validateFields().then(values => {
                let tempForm = {
                    task_id: selectedItem.object_id,
                    start_date: moment(values.start_date).format('YYYY-MM-DD'),
                    quantity: values.quantity,
                    min_quantity: values.min_quantity,
                    category: values.category,
                    //只有20 整件外协 提交工艺线路
                    work_craft_id: values.category === 20 ? values.work_craft_id : undefined,
                }
                //只有30 小批量加工，提交工艺流程
                if (
                    values.category === 30
                ) {
                    tableForm.validateFields().then(values2 => {
                        //表格长度校验
                        //form数据转datasource数据
                        const tempTable = Object.values(values2)
                        tempTable.map((e: any) => {
                            e.is_epiboly = e.is_epiboly ? 1 : 0;
                            e.is_factory = e.is_factory ? 1 : 0;
                        })
                        if (tempTable.length === 0) {
                            message.warning('必须添加一条工艺流程！')
                        } else {
                            let realParams = {
                                ...tempForm,
                                work: JSON.stringify(tempTable)
                            }
                            const formData = getFormData(filterData(realParams))
                            taskEdit(formData).then(res => {
                                if (res.code === 200) {
                                    afterOnOK('新增成功！');
                                } else {
                                    showError(res.message)
                                }
                            })
                        }
                    })
                } else {
                    //    调用接口
                    const formData = getFormData(filterData(tempForm))
                    taskEdit(formData).then(res => {
                        if (res.code === 200) {
                            afterOnOK('编辑成功！');
                        } else {
                            showError(res.message)
                        }
                    })
                }

            })
        } else if (title === ModalTitle.NUMBER) {
            //调整
            let realParams = {
                task_id: selectedItem.object_id,
                quantity: form.getFieldValue('quantity'),
                min_quantity: form.getFieldValue('min_quantity')
            }
            taskModifyQuantity(realParams).then(res => {
                if (res.code === 200) {
                    afterOnOK('调整成功！');
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData({task_id: selectedItem.object_id})
            taskDelete(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        if (modalTitle === ModalTitle.ORDER || modalTitle === ModalTitle.PROCESS || modalTitle === ModalTitle.NUMBER) {
            form.resetFields();
        }
        if (modalTitle === ModalTitle.EDIT) {
            form.resetFields();
            tableForm.resetFields();
        }
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = (flag?: boolean) => {
        if (gRef.current) {
            if (modalTitle === ModalTitle.EDIT || modalTitle === ModalTitle.NUMBER || modalTitle === ModalTitle.DELETE || flag) {
                gRef.current.onFinish(true)
            } else {
                gRef.current.onFinish()
            }
        }
    }

    const onCancel = () => {
        if (modalTitle === ModalTitle.ORDER || modalTitle === ModalTitle.PROCESS || modalTitle === ModalTitle.NUMBER) {
            form.resetFields();
        }
        if (modalTitle === ModalTitle.EDIT) {
            form.resetFields();
            tableForm.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(null);
        setOpen(false);
    }

    const ExtraComponent = React.memo((): React.ReactElement => {
        const exportExcel = async (callback: any) => {
            if (gRef.current) {
                if (gRef.current.exportData) {
                    gRef.current.exportData(true).then(res => {
                        res.map((item: any, index: number) => {
                            if (item.status) {
                                taskList.map(t => {
                                    if (t.id === item.status) {
                                        item.status = t.name
                                    }
                                })
                            }
                            item.index = index + 1
                        })
                        let length = res.length + 2
                        callback(res, length)
                    }).catch(err => {
                        console.log("导出全部数据遇到错误", err)
                    })
                }
            }
        }
        const tabName =
            "任务管理" + moment(new Date()).format('YYYYMMDD');

        return (
            <div className="Invoice_div" style={{marginRight: 20}}>
                <ExportExcel
                    onClick={exportExcel}
                    columns={columns}
                    tableName={tabName}
                    btnName="导出"
                    isTwoHeader={true}
                />
            </div>
        );
    });

    const rowClassName = (record: any, index: number): string => {
        if (record.warning.report) {
            return 'pink'

        } else {
            return 'white'
        }
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise((resolve) => {
            getTaskList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        });
    }, []);

    const filterQueryDataProduction = (queryData: any) => {
        if (queryData.deliveryDate) {
            queryData = filterDateParam(queryData, 'deliveryDate', 'delivery_start_date', 'delivery_end_date')
        }
        if (queryData.productionDate) {
            queryData = filterDateParam(queryData, 'productionDate', 'start_date', 'end_date')
        }
        return queryData
    }

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.ORDER || modalTitle === ModalTitle.REPAIR || modalTitle === ModalTitle.PROCESS) {
            return (<div>
                <Button onClick={() => onCancel()}>关闭</Button>
            </div>)
        }
    }

    const onText = () => {
        if (modalTitle === ModalTitle.CARD) {
            return '打印'
        } else if (modalTitle === ModalTitle.EDIT) {
            return '保存'
        } else if (modalTitle === ModalTitle.NUMBER) {
            return '调整'
        }
    }

    const setWidth = () => {
        if (modalTitle === ModalTitle.REPAIR) {
            return '1470px'
        } else if (modalTitle === ModalTitle.CARD) {
            return '80%'
        } else if (modalTitle !== ModalTitle.DELETE) {
            return '50%'
        }
    }

    return (
        <div className={'production-orderManagement'}>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync} ref={gRef}
                               filterQueryData={filterQueryDataProduction}
                               scroll={true} ExtraComponent={<ExtraComponent/>} rowClassName={rowClassName}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                width={setWidth()}
                    // width={modalTitle !== ModalTitle.DELETE ? ((modalTitle === ModalTitle.NUMBER || modalTitle === ModalTitle.REPAIR) ? '1470px' : '1360px') : '30%'}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel()}
                                okText={onText()} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
            <div className={'legend-row row-pink'}>报工异常</div>
        </div>
    )
}
export default TaskManagement;

import React, { useCallback, useRef, useState, useEffect } from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../components/GeneralManagement";
import { Form, message } from "antd";
import ModalContainer from "../../components/Modal";
import "./index.less";
import {
  filterDateParam,
  formatTime,
  formatNumber,
  filterData,
  getFormData,
  getUsers,

} from "../../utils/index";
import EditOrShowCraft from "./components/EditOrShowCraft";
import Button from "../../components/MyButton";
import { ColumnsType } from "antd/es/table";
import { getCraftList, orderGetOrderDetail, synchronousCraft } from "../../service";
import { addOrEditCraft } from "../../service";
import OrderComponent from "../Order/OrderManagement/View";
import moment from "moment";
import show from "../../assets/show.png";
import organization from "../../assets/organization.png";
import synchronous from "../../assets/synchronous.png";
import MyImage from "../../components/MyImage";
import Synchronous from "./components/Synchronous";
import no from "../../assets/status/no.png";
import yes from "../../assets/status/yes.png";
import { ISelectValue } from "./components/MyTimePicker";

enum ModalTitle {
  SHOW = "查看工艺信息",
  ORGANIZTION = "编制工艺信息",
  SHOWORDER = "查看订单信息",
  SYNCHRONOUS = "同步工艺信息",
}

function toSecondsInMyPicker(value:ISelectValue) {
  const { day, hour, minute, seconds } = value;
  return Number(day)*24*60*60 + Number(hour)*60*60 + Number(minute)*60 + Number(seconds);
}

const CraftManagement = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] =
    useState<React.ReactElement | null>(null);
  const [craft, setCraft] = useState<{ id: number; name: string }[]>([]);
  const [selectItem, setSelectItem] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
  const gRef = useRef<GRef>(null);
  const [form] = Form.useForm();
  const [orderForm] = Form.useForm();
  const [tableForm] = Form.useForm();
  useEffect(() => {
    getUsers((users: { id: number; name: string }[]) => {
      setCraft(users);
      let newFormList = formList;
      newFormList[2].options = users;
      setFormList(newFormList);
    });
  }, []);
  const [formList, setFormList] = useState<QueryListItem[]>([
    {
      key: "status",
      // width:1,
      checkbox: [
        // 编制状态0未完成 1已完成,值为1代表当前是未编制，当值为1时，把1转化成authorized_status：0
        { label: "未编制", value: 1 },
        {
          // 完成状态0未完成 1已完成，值为2代表当前是未完成，当为2时，把2转化成complete_status：0
          // 当数组中有两个值时，代表当前是未编制也未完成complete_status：0，authorized_status：0
          label: "未完成",
          value: 2,
        },
      ],
      name: "",
      placeholder: "",
    },
    {
      key: "part_number",
      name: "零件图号",
      placeholder: "请输入零件图号",
    },
    {
      key: "operator_id",
      options: craft,
      name: "工艺员",
      placeholder: "请选择工艺员",
    },
    {
      key: "order_code",
      name: "订单号",
      placeholder: "请输入订单号",
    },
    {
      key: "part_name",
      name: "零件名称",
      placeholder: "请输入零件名称",
    },
    {
      key: "content",
      name: "加工内容",
      placeholder: "请输入加工内容",
    },
    {
      key: "date",
      rangePick: true,
      name: "交货日期",
      placeholder: "请选择交货日期",
    },
  ]);
  const columns: ColumnsType<any> = [
    {
      title: "订单信息",
      className: "orderInfo",
      key: "1",
      align: "center",
      children: [
        {
          title: "No",
          className: "orderInfo",
          dataIndex: "index",
          key: "index",
          align: "center",
          width: 60,
          ellipsis: true,
          fixed: true,
          render: (_: any, __: any, index: any) => <span>{index + 1}</span>,
        },
        {
          title: "工艺完成",
          className: "orderInfo",
          dataIndex: "production_status",
          key: "production_status",
          align: "center",
          fixed: true,
          width: 90,
          render: (status: number) => {
            return (
              <span>
                {status === 0 ? (
                  // <span style={{ color:"#EB4420"}}>否</span>
                  <MyImage src={no} width={60}></MyImage>
                ) : (
                  <MyImage src={yes} width={60}></MyImage>
                )}
                {/* <span style={{ color:"#3E7BFA "}}>是</span> */}
              </span>
            );
          },
        },
        {
          title: "订单号",
          className: "orderInfo",
          dataIndex: ["order", "code"],
          key: "code",
          align: "center",
          width: 140,
          ellipsis: true,
          fixed: "left",
          render: (text: string, record: any, _: number) => (
            <span
              style={{ borderBottom: "1px solid #ccc", cursor: "pointer" }}
              onClick={() => {
                openModal(ModalTitle.SHOWORDER, record.object_id, record);
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: "零件名称",
          className: "orderInfo",
          dataIndex: ["part", "name"],
          key: "name",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },
        {
          title: "零件图号",
          className: "orderInfo",
          dataIndex: ["part", "number"],
          key: "number",
          align: "center",
          ellipsis: true,
          width: 140,
          fixed: "left",
        },
      ],
    },
    {
      title: "订单信息",
      className: "orderInfo",
      key: "5",
      align: "center",
      children: [
        {
          title: "订单数量",
          className: "orderInfo",
          dataIndex: "number",
          key: "order_number",
          align: "right",
          ellipsis: true,
          width: 90,
          render: (item: Number) => <div>{formatNumber(item.toString())}</div>,
        },
        {
          title: "加工内容",
          className: "orderInfo",
          dataIndex: "content",
          key: "content",
          align: "center",
          ellipsis: true,
          width: 140,
        },
        {
          title: "交货日期",
          className: "orderInfo",
          dataIndex: "finish_date",
          key: "finish_date",
          align: "center",
          ellipsis: true,
          width: 90,
          render: (item: String) => (
            <div>{item && formatTime(item, false)}</div>
          ),
        },
      ],
    },
    {
      title: "投产信息",
      className: "productionInfo",
      align: "center",
      key: "2",
      children: [
        {
          title: "总投产数量",
          className: "productionInfo",
          dataIndex: ["production", "number"],
          key: "quantity_number",
          align: "right",
          ellipsis: true,
          width: 120,
          render: (item: Number) => (
            <div>{item && formatNumber(item.toString())}</div>
          ),
        },
      ],
    },
    {
      title: "工艺信息",
      className: "processInfo",
      key: "3",
      children: [
        {
          title: "工艺员",
          className: "processInfo",
          dataIndex: ["production", "operator"],
          key: "operator",
          align: "center",
          ellipsis: true,
          width: 100,
        },
        {
          title: "工艺完成时间",
          className: "processInfo",
          dataIndex: "production_time",
          key: "production_time",
          align: "center",
          ellipsis: true,
          width: 130,
          render: (item: String) => <div>{item && formatTime(item, true)}</div>,
        },
      ],
    },

    {
      title: "订单信息",
      className: "orderInfo",
      key: "4",
      align: "center",
      children: [
        {
          title: "修改人",
          className: "orderInfo",
          dataIndex: "update_user",
          key: "update_user",
          align: "center",
          ellipsis: true,
          width: 100,
        },
        {
          title: "修改时间",
          className: "orderInfo",
          dataIndex: "update_time",
          key: "update_time",
          align: "center",
          ellipsis: true,
          width: 130,
          render: (item: String) => <div>{item && formatTime(item, true)}</div>,
        },
      ],
    },
    {
      title: "",
      className: "orderInfo",
      key: "6",
      align: "center",
      children: [
        {
          title: "操作",
          className: "orderInfo",
          dataIndex: "options",
          key: "options",
          align: "center",
          fixed: "right",
          // width: 100,
          render: (text: any, record: any) => {
            return (
              <div
                style={{ color: "#3E7BFA", cursor: "pointer", display:"flex",
                justifyContent:"space-around" }}
              >
                <MyImage
                  src={show}
                  title="查看"
                  onClick={() => {
                    setSelectItem(record);

                    openModal(ModalTitle.SHOW, record.object_id, record);
                  }}
                ></MyImage>
                <MyImage
                  src={organization}
                  title="编制"
                  onClick={() => {
                    setSelectItem(record);
                    openModal(ModalTitle.ORGANIZTION, record.object_id, record);
                  }}
                ></MyImage>
                <MyImage
                  src={synchronous}
                  title="同步"
                  onClick={() => {
                    setSelectItem(record);
                    openModal(ModalTitle.SYNCHRONOUS, record.object_id, record);
                  }}
                ></MyImage>
              </div>
            );
          },
        },
      ],
    },
  ];

  const openModal = (title: ModalTitle, object_id: number, record: any) => {
    setModalTitle(title);
    setTimeout(() => {
      setOpen(true);
      if (title === ModalTitle.SHOW) {
        setInnerComponent(
          <EditOrShowCraft
            modalForm={form}
            object_id={object_id}
            selectedItem={record}
            is_show={true}
            tableForm={tableForm}
            is_disable={true}
          />
        );
      } else if (title === ModalTitle.ORGANIZTION) {
        setInnerComponent(
          <EditOrShowCraft
            modalForm={form}
            object_id={object_id}
            selectedItem={record}
            tableForm={tableForm}
            is_disable={false}
            is_show={false}
          />
        );
      } else if (title === ModalTitle.SHOWORDER) {
        if (record.order.object_id) {
          orderGetOrderDetail({ object_id: record.order.object_id }).then(
            (res) => {
              if (res.code === 200) {
                orderForm.setFieldsValue({
                  code: res.data.code,
                  trader_id: res.data.trader_id,
                  // finish_date: res.data.finish_date ? moment(res.data.finish_date) : undefined,
                  offer_price: res.data.offer_price,
                  contract_price: res.data.contract_price,
                  remark: res.data.remark,
                  creator: res.data.user.name,
                  createTime: res.data.create_time
                    ? moment(res.data.create_time)
                    : "",
                  issueTime: res.data.issue_time
                    ? moment(res.data.issue_time)
                    : "",
                });
                if (res.data.finish_date) {
                  orderForm.setFieldValue(
                    "finish_date",
                    moment(res.data.finish_date)
                  );
                }
                setInnerComponent(
                  <OrderComponent
                    form={orderForm}
                    disabled
                    dataSource={res.data.order_part}
                  />
                );
              } else {
                message.error(res.message);
              }
            }
          );
        }
      } else {
        setInnerComponent(
          <Synchronous
            selectedItem={record}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
          />
        );
      }
    }, 0);
  };

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getCraftList(
        Object.assign(
          {
            authorized_status: 1,
            complete_status: 1,
          },
          queryCondition
        )
      )
        .then((res) => {
          if (res.code === 200) {
            resolve(res.data);
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    });
  }, []);
  // 表格行属性
  const rowClassName = (record: any, index: number) => {
    if (record.is_alarm === 1) {
      return "color";
    }
    return "white";
  };

  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "start_date", "end_date");
    }
    if (queryData.operator_id) {
      queryData.operator_id = Number(queryData.operator_id);
    }
    if (queryData.status) {
      if (queryData.status.length === 2) {
        queryData.authorized_status = 0;
        queryData.complete_status = 0;
      }
      if (queryData.status.length === 1) {
        queryData.status[0] === 1
          ? (queryData.authorized_status = 0)
          : (queryData.complete_status = 0);
      }
      delete queryData.status;
      if (queryData.date) {
        queryData = filterDateParam(
          queryData,
          "date",
          "start_date",
          "end_date"
        );
      }
    }
    return queryData;
  };

  return (
    <div className="craft_management">
      <GeneralManagement
        formList={formList}
        columns={columns}
        rowClassName={rowClassName}
        getDataAsync={getDataAsync}
        ref={gRef}
        scroll={true}
        scrollValue={{x:1620}}
        filterQueryData={filterQueryData}
      />
      {modalTitle && (
        <ModalContainer
          title={modalTitle}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={
            modalTitle === "编制工艺信息"
              ? [
                  <div className="modal_btn">
                    <Button
                      title="工艺完成"
                      onClick={() => {
                        // 工艺完成首先进行校验
                        form
                          .validateFields(["number", "blank_number"])
                          .then((res) => {
                            tableForm.validateFields().then((res) => {
                              // 去除为undefined,null,""的属性
                              let formValues = filterData(
                                form.getFieldsValue([
                                  "blank_size",
                                  "part_size",
                                  "blank_number",
                                  "number",
                                ])
                              );
                              let tableValues = tableForm.getFieldsValue();

                              const tableArr = Object.values(tableValues).map(
                                (item: any) => {
                                  // 否为0 是为1
                                  item.out_status = !Boolean(item.out_status)
                                    ? 0
                                    : 1;
                                  item.repair_status = !Boolean(
                                    item.repair_status
                                  )
                                    ? 0
                                    : 1;
                                  // 校验：每一工序的“是否外协工序”、“是否返厂工序”不能够都被选中,外协返厂只能二选一或二选0
                                  if (
                                    item.out_status === 1 &&
                                    item.repair_status === 1
                                  ) {
                                    return "[]";
                                  }
                                  return item;
                                }
                              );
                              if (tableArr.indexOf("[]") !== -1) {
                                message.warning(
                                  "每一工序的是否外协工序、是否返厂工序不能够都被选中"
                                );
                                return;
                              }

                              if (JSON.stringify(tableArr) === "[]") {
                                message.warning("工艺流程不能为空");
                                return;
                              }

                              tableArr.forEach((i) => {
                                // i.duration = toSeconds(i.duration);
                                // i.work_duration = toSeconds(i.work_duration);
                                i.duration = toSecondsInMyPicker(i.duration);
                                i.work_duration = toSecondsInMyPicker(i.work_duration);
                              });
                              let obj = Object.assign(formValues, {
                                object_id: selectItem.object_id,
                                status: 2,
                                production_precesses: JSON.stringify(tableArr),
                                production_id:
                                  selectItem.production.object_id &&
                                  selectItem.production.object_id,
                              });
                              // 过滤undefined 和null的选项并且组织成formData形式
                              let obj1 = getFormData(filterData(obj));
                              addOrEditCraft(obj1)
                                .then((res) => {
                                  if (res.code === 200) {
                                    message.success("工艺编制完成");
                                    if (gRef.current) {
                                      gRef.current.onFinish();
                                    }
                                    setOpen(false);
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            });
                          });
                      }}
                      buttonStyle={{
                        backgroundColor: "#8DA0FF",
                      }}
                    ></Button>
                    <Button
                      title="关闭"
                      onClick={() => {
                        setOpen(false);
                      }}
                      buttonStyle={{
                        backgroundColor: "#B8B8B8",
                      }}
                    ></Button>
                    <Button
                      title="保存"
                      onClick={() => {
                        form
                          .validateFields(["number", "blank_number"])
                          .then((res) => {
                            tableForm.validateFields().then((res) => {
                              // 去除为undefined,null,""的属性
                              let formValues = filterData(
                                form.getFieldsValue([
                                  "blank_size",
                                  "part_size",
                                  "blank_number",
                                  "number",
                                ])
                              );
                              let tableValues = tableForm.getFieldsValue();
                              const tableArr = Object.values(tableValues).map(
                                (item: any) => {
                                  item.out_status = !Boolean(item.out_status)
                                    ? 0
                                    : 1;
                                  item.repair_status = !Boolean(
                                    item.repair_status
                                  )
                                    ? 0
                                    : 1;
                                  return item;
                                }
                              );

                              tableArr.map((i) => {
                                // i.duration = toSeconds(i.duration);
                                // i.work_duration = toSeconds(i.work_duration);
                                i.duration = toSecondsInMyPicker(i.duration);
                                i.work_duration = toSecondsInMyPicker(i.work_duration);
                              });
                              let obj = Object.assign(formValues, {
                                object_id: selectItem.object_id,
                                status: 1,
                                production_precesses: JSON.stringify(tableArr),
                                production_id:
                                  selectItem.production.object_id &&
                                  selectItem.production.object_id,
                              });
                              // 过滤undefined 和null的选项并且组织成formData形式
                              let obj1 = getFormData(filterData(obj));
                              addOrEditCraft(obj1)
                                .then((res) => {
                                  if (res.code === 200) {
                                    message.success("保存成功");
                                    if (gRef.current) {
                                      gRef.current.onFinish();
                                    }
                                    setOpen(false);
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            });
                          });
                      }}
                      buttonStyle={{
                        backgroundColor: "#3E7BFA",
                      }}
                    ></Button>
                  </div>,
                ]
              : modalTitle === "同步工艺信息"
              ? [
                  <div className="modal_btn">
                    <Button
                      title="同步"
                      onClick={() => {
                        if(!selectedRowKeys){
                          message.warn("请选择需要同步工艺流程的任务单");
                          return;
                        }
                        synchronousCraft(getFormData({
                          task_ids: JSON.stringify(selectedRowKeys)
                        })).then(res=> {
                          setSelectedRowKeys(undefined);
                          if(res.code === 200) {
                            message.success("同步成功")
                          }else {
                            message.warn(res.message); 
                          }
                          setOpen(false);
                        }).catch(err => {
                          console.log('err',err)
                        })
                      }}
                      buttonStyle={{
                        backgroundColor: "#3E7BFA",
                      }}
                    ></Button>
                    <Button
                      title="取消"
                      onClick={() => {
                        setOpen(false);
                      }}
                      buttonStyle={{
                        backgroundColor: "#B8B8B8",
                      }}
                    ></Button>
                  </div>,
                ]
              : [
                  <div className="modal_btn">
                    <Button
                      title="关闭"
                      onClick={() => {
                        setOpen(false);
                      }}
                      buttonStyle={{
                        backgroundColor: "#B8B8B8",
                      }}
                    ></Button>
                  </div>,
                ]
          }
          width={modalTitle === "同步工艺信息" ? "50%" : "80%"}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default CraftManagement;

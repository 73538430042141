import React, {
  useCallback,
  useRef,
  useState,
  useEffect
} from "react";
import GeneralManagement, {
  GRef,
  QueryListItem,
} from "../../../components/GeneralManagement";
import { Form,Space, Tooltip,message } from "antd";
import ModalContainer from "../../../components/Modal";
import "../index.less";
import { formatTime,filterDateParam,getSystemInfoItem,transform,getFormData,filterData,transformObjectDelivery } from "../../../utils/index";
import CreateOrEditDelivery from "../components/createOrEditDelivery";
import PrintDelivery from "../components/printDelivery";
import DeleteItem from "../../../components/DeleteItem";
import {ColumnsType} from "antd/es/table";
import {getDeliverManagement, getdeliverDetail,createOrEditDeliver,deleteDeliver} from "../../../service";
import MButton from "../../../components/MyButton";
import moment from "moment";
import ExportExcel from "../../../components/ExportTable/exportTable";
import Print from "react-print-html";
import MyImage from "../../../components/MyImage";
import edit from "../../../assets/edit.png";
import deletePng from "../../../assets/delete.png";
import show from "../../../assets/show.png";
enum ModalTitle {
  SHOW = "查看发货单",
  EDIT = "编辑发货单",
  DELETE = "删除",
}

const DeliveryDocManagement = (): React.ReactElement => {
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerComponent, setInnerComponent] = useState<React.ReactElement | null>(null);
  const [deliveryDetail,setDeliverDetail] = useState<any>({});
  const [objectId,setObjectId] = useState<number>();
  const [maxRow,setMaxRow]= useState<number>(0);
  const [cols,setCols] = useState<any[]>([]);
  const gRef = useRef<GRef>(null);
  const printRef = useRef();
  const [modalForm] = Form.useForm();
  const [tableForm] = Form.useForm();
  useEffect(()=>{
    getSystemInfoItem("发货单最大行数",(selectItem:any[])=>{
      
      setMaxRow(Number(selectItem[0].value))
    })
  },[])
  const formList: QueryListItem[] = [
    {
      key: "deliver_code",
      name: "发货单号",
      placeholder: "请输入发货单号",
    },
    {
      key: "trader_name",
      name: "客户名称",
      placeholder: "请输入客户名称",
    },
    {
      key: "code",
      name: "任务制号",
      placeholder: "请输入任务制号",
    },
    {
      key: "date",
      name: "发货日期",
      placeholder: "请输入发货日期",
      rangePick: true,
    },
  ];
  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      align: "center",
      width:60,
      fixed:true,
      ellipsis: true,
      render: (_: any, __: any, index: any) => <span>{index+1}</span>,
    },
    {
      title: "发货单号", 
      dataIndex: "code",
      key: "code",
      align: "center",
      ellipsis: true,
      width: 160,

    },
    {
      title: "客户",
      dataIndex: "trader_name",
      key: "trader_name",
      align: "center",
      ellipsis: true,
      width: 160,

    },
    {
      title: "任务制号",
      dataIndex: "order_code",
      key: "order_code",
      align: "center",
      ellipsis: true,
      width: 160,

    },
    {
      title: "合同编号",
      dataIndex: "contract_code",
      key: "contract_code",
      align: "center",
      ellipsis: true,
      width: 180,

    },
    {
      title: "合同日期",
      dataIndex: "contract_date",
      key: "contract_date",
      align: "center",
      ellipsis: true,
      width: 200,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item}</Tooltip>
      ),
    },
    {
      title: "发货人",
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      ellipsis: true,
      width: 100,
    },
    {
      title: "发货日期", 
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (item: String) => (
        <Tooltip placement="topLeft">{item && formatTime(item, false)}</Tooltip>
      ),
    },

    {
      title: "操作",
      dataIndex: "options",
      key: "options",
      align: "center",
      fixed:"right",
      // width:120,
      render: (_: any, record: any) => {
        return (
          <Space size={'middle'} style={{ color: "#3E7BFA", cursor: "pointer", 
          // display:"flex", justifyContent:"center"
         }}>
            <MyImage src={edit} title="编辑" onClick={
              ()=>{getDeliverDetail(record.object_id,true)}
            }></MyImage>
             <MyImage src={deletePng} title="删除" onClick={
              ()=>{
                setObjectId(record.object_id);
                openModal(ModalTitle.DELETE,record)
              }
            }></MyImage>
             <MyImage src={show} title="查看" onClick={
              ()=>{getDeliverDetail(record.object_id,false)}
            }></MyImage>
          </Space>
        );
      },
    },
  ];
 const getDeliverDetail = (object_id:number,is_edit?:boolean) => {
  setObjectId(object_id);
  getdeliverDetail({
    object_id:object_id
  }).then(res=>{
    if(res.code === 200){
      if(res.data){
        if(res.data.deliver_part && Array.isArray(res.data.deliver_part)){
          const emptyLine = maxRow - res.data.deliver_part.length;
          if (emptyLine > 0 && !is_edit) {
            for (let i = 1; i <= emptyLine; i++) {
              res.data.deliver_part.push({ index: (res.data.deliver_part.length + 1).toString() });
            }
          }
          res.data.deliver_part.map((item:any,index:number)=> {
            item.index = index + 1
          })
        }
      
        setDeliverDetail(res.data); 
      }
        openModal(is_edit ? ModalTitle.EDIT : ModalTitle.SHOW, res.data); 
    }
  }).catch(err=>{
    message.error(err)
  })
}
const onCancel1 = (title: any) => {
  //去除勾选的数据
  setModalTitle(ModalTitle.EDIT);
  setInnerComponent(<div/>);
  setOpen(false);
}

  const openModal = (title: ModalTitle, detailDelivery?:any) => {
    setModalTitle(title);
    setTimeout(() => {
      setOpen(true);
      if (title === ModalTitle.EDIT) {
        setInnerComponent(<CreateOrEditDelivery modalForm={modalForm} tableForm={tableForm} detailDelivery={detailDelivery} isEdit={true}/>);
      } else if(title === ModalTitle.SHOW){
        setInnerComponent(<PrintDelivery ref={printRef} setCols={setCols}   
        is_show={true}
        showDetailDelivery={detailDelivery}/>);
      } else {
        setInnerComponent(<DeleteItem text="确定要删除该发货单"/>);
      }
    }, 0);
  };

  const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
    return new Promise((resolve) => {
      getDeliverManagement(queryCondition)
        .then((res) => {
          if (res.code === 200) {
            resolve(res.data);
         
          } else {
            message.error(res.message);
          }
        })
        .catch((err) => {
          message.error(err)
        });
    });
  }, []);
  const filterQueryData = (queryData: any) => {
    if (queryData.date) {
      queryData = filterDateParam(queryData, "date", "deliver_start_date", "deliver_end_date");
    }
    return queryData;
  };

  return (
    <div className="delivery_doc_management">
      <GeneralManagement
        formList={formList}
        columns={columns}
        getDataAsync={getDataAsync}
        ref={gRef}
        scrollValue={{x:1300}}
        filterQueryData={filterQueryData}
      />
      {modalTitle && (
        <ModalContainer
          title={modalTitle === "查看发货单"? "": modalTitle === "删除" ? "系统确认": modalTitle}
          open={open}
          footer={[
            modalTitle === "编辑发货单" ? (
              <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false)
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="保存"
                  onClick={() => {
                    modalForm
                      .validateFields()
                      .then((res) => {
                        tableForm
                          .validateFields()
                          .then((res) => {
                            let obj:any = transformObjectDelivery(tableForm.getFieldsValue());
                            obj.trader_id = deliveryDetail.trader_id;
                            obj.contract_code =modalForm.getFieldsValue().contract_code;
                            obj.contract_date = modalForm.getFieldsValue().contract_date;
                            obj.title = modalForm.getFieldsValue().title;
                            obj.content = tableForm.getFieldsValue().content;
                            obj.order_code = tableForm.getFieldsValue().order_code;
                          
                            obj.part_data = JSON.stringify(obj.work);
                            // 编辑发货单时加上发货单的object_id
                            obj.object_id = objectId;
                            delete obj.work;
                            if(JSON.parse(obj.part_data).length === 0){
                              message.warning("必须添加一条订单零件")
                              return;
                            }
                          
                            const formData = getFormData(filterData(obj))
                            createOrEditDeliver(formData).then(res=>{
                            
                              if(res.code === 200){
                                message.success("操作成功");
                                if(gRef.current){
                                  gRef.current.onFinish(true)
                                }
                                setOpen(false)
                              }else {
                                message.error(res.message)
                              }
                            }).then((err:any)=>{
                              console.log("err",err)
                            })   
                          }).catch((err) => {
                            console.log("发货表格校验失败", err);
                          });
                      })
                      .catch((err) => {
                        console.log("发货表单校验失败", err);
                      });
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            ): modalTitle === "查看发货单" ? (
              <div className="modal_btn">
                <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false)
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="打印"
                  onClick={() => {
                    if(printRef.current){
                      Print(printRef.current);
                      onCancel1(modalTitle);
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
                  <ExportExcel
                    columns={cols as any}
                    dataSource={deliveryDetail.deliver_part}
                    btnName="导出"
                    tableName="发货单"
                    isTwoHeader={false}
                    length={deliveryDetail.deliver_part.length>maxRow?deliveryDetail.deliver_part.length :maxRow}
                    lastRowLength={deliveryDetail.deliver_part.length>maxRow?deliveryDetail.deliver_part.length+5 : maxRow+5}
                    is_bg_null={true}
                    btnStyle={{
                      width: "130px",
                      height: "48px",
                      backgroundColor: "#3E7BFA",
                    }}
                    is_delivery_table={true}
                    otherData={[
                      {
                        rows1: ["合同日期：",deliveryDetail.contract_date?deliveryDetail.contract_date:""],
                        rows2: ["合同编号：", deliveryDetail.contract_code?deliveryDetail.contract_code:"","日期：",moment(deliveryDetail.create_time).format("YYYY/MM/DD")],
                        rows3: ["加工项目",deliveryDetail.content,"任务制号",deliveryDetail.order_code],
                        lastRow: [
                          "交货人：",
                          "收货人：",
                        ],
                      },
                      { title: [deliveryDetail.title] },
                    ]}
                  ></ExportExcel>
              </div>
            ):(
              <div className="modal_btn">
                  <MButton
                  title="关闭"
                  onClick={() => {
                    setOpen(false)
                  }}
                  buttonStyle={{
                    backgroundColor: "#B8B8B8",
                  }}
                ></MButton>
                <MButton
                  title="确认"
                  onClick={() => {
                    deleteDeliver({
                      object_id:objectId as number
                    }).then(res => {
                      if(res.code === 200){
                        message.success("删除成功");
                        if(gRef.current){
                          gRef.current.onFinish(true)
                        }
                        setOpen(false);
                      } else {
                        message.error(res.message)
                      }
                    }).catch(err => {
                      message.error(err)
                    })
                  }}
                  buttonStyle={{
                    backgroundColor: "#3E7BFA",
                  }}
                ></MButton>
              </div>
            ),
          ]}
          onCancel={() => {
            setOpen(false);
          }}
          width={modalTitle === "删除" ? "30%" :modalTitle ==="查看发货单" ? "794px" :"60%"}
          innerComponent={innerComponent}
        />
      )}
    </div>
  );
};
export default DeliveryDocManagement;

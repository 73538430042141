import React from 'react';
import {Modal,Spin} from 'antd';
import './index.less'

interface ModalContainerProps {
    //弹窗标题
    title?: string,
    //控制弹窗显示与关闭
    open: boolean,
    //点击确认按钮
    onOk?: (e: React.MouseEvent<HTMLElement>) => void;
    //点击取消按钮
    onCancel: (e: React.MouseEvent<HTMLElement>) => void;
    //弹窗内组件
    innerComponent?: React.ReactNode;
    //取消的文字
    cancelText?: string,
    //确认的文字
    okText?: string,
    //弹窗的宽度
    width?: string,
    //自定义页脚内容
    footer?:React.ReactNode
}

/**
 * 通用弹窗组件
 */
const ModalContainer = (props: ModalContainerProps) => {
    const {title, open, onOk, onCancel, innerComponent, cancelText = '关闭', okText = '确认', width = '30%',footer} = props

    return (
        <Modal title={title} open={open} onOk={onOk} onCancel={onCancel} centered width={width} cancelText={cancelText}
               okText={okText} maskClosable={false} destroyOnClose children={innerComponent?innerComponent:<div className={'mes-modal-loading'}><Spin delay={200} spinning={!innerComponent}/></div>} footer={footer}
               wrapClassName={'mes-modal'}/>
    )
}

export default React.memo(ModalContainer);

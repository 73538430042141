import { useState, useEffect } from "react";
import ModalForm, { FormItem } from "../../../../components/ModalForm";
import { getErrorList, formatTime } from "../../../../utils/index";
import { Form, Input, Radio, Select,Row } from "antd";
interface IProps {
  modalForm: any;
  selectedItem: any;
  modalForm1: any;
}
const formItem: FormItem[] = [
  {
    label: "任务单号",
    name: "task_number",
    is_disable: true,
    is_row_but_noGrow: true,
    is_row: true,
  },
  {
    label: "订单号",
    name: "order_code",
    is_disable: true,
  },
  {
    label: "零件名称",
    name: "part_name",
    is_disable: true,
  },
  {
    label: "零件图号",
    name: "part_number",
    is_disable: true,
  },
  {
    label: "加工内容",
    name: "content",
    is_disable: true,
    is_row: true,
  },
  {
    label: "投产日期",
    name: "start_date",
    is_disable: true,
  },
  {
    label: "投产数量",
    name: "touchan_number",
    is_disable: true,
  },
  {
    label: "最小完成数量",
    name: "min_quantity",
    is_disable: true,
  },
  {
    label: "工序名称",
    name: "work_process_name",
    is_disable: true,
  },
  {
    label: "送检人",
    name: "send_user",
    is_disable: true,
  },
  {
    label: "送检时间",
    name: "send_time",
    is_disable: true,
  },
];
const FirstCheck1 = (props: IProps) => {
  const {selectedItem, modalForm, modalForm1 } = props;
  const [errorList, setErrorList] = useState<any[]>([]);
  const [checkStatusDisabled, setCheckStatusDisabled] = useState<boolean>(true);

  useEffect(() => {
    modalForm.setFieldsValue({
      task_number: selectedItem.task_number,
      order_code: selectedItem.order_code,
      part_name: selectedItem.part_name,
      part_number: selectedItem.part_number,
      content: selectedItem.content,
      start_date: selectedItem.start_date && formatTime(selectedItem.start_date, true),
      touchan_number: selectedItem.touchan_number,
      min_quantity: selectedItem.min_quantity,
      work_process_name: selectedItem.work_process_name,
      send_user: selectedItem.send_user,
      send_time:
        selectedItem.send_time && formatTime(selectedItem.send_time, true),
    });
    modalForm1.setFieldsValue({
      user: selectedItem.user,
      create_time: selectedItem.create_time,
    });
    modalForm1.resetFields(["radio", "defect_reason_id"]);
    getErrorList((errors: any[]) => {
  
      setErrorList(errors);
    });
  }, []);
  const handleRadioChange = (e: any) => {
    
    if (e.target.value === 0) {
      setCheckStatusDisabled(false);
    } else {
      modalForm1.setFieldsValue({ defect_reason_id: undefined });
      setCheckStatusDisabled(true);
    }
    modalForm1
      .validateFields(["defect_reason_id"])
      .then((res: any) => {
        return Promise.resolve();
      })
      .catch((err: any) => {
        return Promise.reject("不良原因校验错误");
      });
  };
  return (
    <div className="check">
      <ModalForm
        form={modalForm}
        form_name="first_check_form"
        formItem={formItem}
        selectedItem={selectedItem}
      ></ModalForm>
      <div className="border"></div>
      <Form
        name="first_check_form1"
        form={modalForm1}
        className="form1"
        autoComplete="off"
      >
      <Row>
      <Form.Item label="质检员" name="user" className="wrap">
          <Input disabled />
        </Form.Item>
        <Form.Item label="开始时间" name="create_time" className="wrap">
          <Input disabled />
        </Form.Item>
      </Row>
       <Row>
       <Form.Item
          label="检验结果"
          name="radio"
          className="wrap"
          rules={[{ required: true, message: "请选择检验结果" }]}
        >
          <Radio.Group onChange={handleRadioChange}>
            <Radio value={1}> 合格 </Radio>
            <Radio value={0}> 不良 </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          dependencies={["radio"]}
          className="bad"
          label="不良原因"
          name="defect_reason_id"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
             
                // 两种情况下能通过，一个是合格且不存在不良原因，另一个是不合格且存在不良原因
                if (getFieldValue("radio") === 0 && value) {
                  return Promise.resolve();
                }
                if (getFieldValue("radio") === 1 && !value) {
                  return Promise.resolve();
                }
                return Promise.reject("请选择不良原因");
              },
            }),
          ]}
        >
          <Select
            mode="multiple"
            disabled={checkStatusDisabled}
            placeholder="请选择不良原因"
          >
            {errorList.map((oe: any, oi: number) => {
              return (
                <Select.Option key={oi} value={oe.value}>
                  {oe.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
       </Row>
      </Form>
    </div>
  );
};
export default FirstCheck1;

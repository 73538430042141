import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom'
import './index.less';
import {getTaskStatistics, getCheckStatistics} from "../../service";
import {formatNumber, showError} from "../../utils";
import {UserContext} from "../../contexts/userContext";

interface TaskData {
    id: number,
    name: string,
    image: string,
    text: string,
    number: number | null,
    color: string,
    router: string
}

interface StatisticsData {
    id: number,
    name: string,
    number: number,
}

const Dashboard = (): React.ReactElement => {
    const {useInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const [taskData, setTaskData] = useState<TaskData[]>([
        {
            id: 10,
            name: 'total',
            image: require('../../assets/dashboard-task-total.png'),
            text: '机加任务',
            number: null,
            color: '#3E7BFA',
            router: '/comprehensiveQuery/reportMonitor'
        },
        {
            id: 40,
            name: 'not_started',
            image: require('../../assets/dashboard-task-notStart.png'),
            text: '外协加工',
            number: null,
            color: '#FF8800',
            router: '/comprehensiveQuery/externalAssistanceMonitor'
        },
        {
            id: 50,
            name: 'processing',
            image: require('../../assets/dashboard-task-processing.png'),
            text: '返厂处理',
            number: null,
            color: '#06C270',
            router: '/comprehensiveQuery/returnMonitor'
        },
        {
            id: 30,
            name: 'overdue',
            image: require('../../assets/dashboard-task-undone.png'),
            text: '返修任务',
            number: null,
            color: '#FF3B3B',
            router: '/comprehensiveQuery/repairMonitor'
        },
    ])
    const [statisticsData, setStatisticsData] = useState<StatisticsData[]>([
        {
            id: 30,
            name: '首件检验',
            number: 0
        },
        {
            id: 40,
            name: '外协检验',
            number: 0
        },
        {
            id: 60,
            name: '巡检',
            number: 0
        },
        {
            id: 50,
            name: '终检',
            number: 0
        },
    ])
    useEffect(() => {
        getTaskStatistics().then(res => {
            if (res.code === 200) {
                if (res.data.length) {
                    let tempTask = [...taskData];
                    res.data.map(e => {
                        tempTask.map(t => {
                            if (t.id === 10) {
                                let temp1 = res.data.find(a => a.category === 10);
                                let temp2 = res.data.find(a => a.category === 20);
                                t.number = (temp1 ? temp1.total : 0) + (temp2 ? temp2.total : 0)
                            } else {
                                if (t.id == e.category) {
                                    t.number = e.total
                                }
                            }

                        })
                    })
                    setTaskData(tempTask);
                }
            } else {
                showError(res.message)
            }
        })
        getCheckStatistics().then(res => {
            if (res.code === 200) {
                if (res.data.length) {
                    let tempCheck = [...statisticsData];
                    res.data.map(e => {
                        tempCheck.map(t => {
                            if (t.id === 20) {
                                let temp1 = 0;
                                let temp2 = 0;
                                let temp3 = 0;
                                if (e.category === 20) {
                                    temp1 = e.total
                                }
                                if (e.category === 30) {
                                    temp2 = e.total
                                }
                                if (e.category === 40) {
                                    temp3 = e.total
                                }
                                t.number = temp1 + temp2 + temp3
                            } else {
                                if (t.id == e.category) {
                                    t.number = e.total
                                }
                            }
                        })
                    })
                    setStatisticsData(tempCheck);
                }
            } else {
                showError(res.message)
            }
        })
    }, [])

    const skipPage = (router: string) => {
        if (useInfo.authRoutes?.includes(router)) {
            navigate(router)
        }
    }

    return (
        <div className={'mes-dashboard'}>
            <div className={'dashboard-task'}>
                <div className={'dashboard-title'}>任务统计</div>
                <div style={{display: 'flex'}}>
                    {
                        taskData.map((e, i) => {
                            return (
                                <div className={'task-item'} key={i}>
                                    <div style={{marginRight: 10}}><img src={e.image} alt=""/></div>
                                    <div>
                                        <div className={'item-title'}>{e.text}</div>
                                        <div
                                            className={useInfo.authRoutes?.includes(e.router) ? 'item-number' : 'item-number-no-cursor'}
                                            onClick={() => skipPage(e.router)}
                                            style={{color: e.color}}>{e.number ? formatNumber(e.number.toString()) : 0}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={'dashboard-title'} style={{marginTop: 34}}>实时质检统计</div>
                <div style={{display: 'flex'}}>
                    {
                        statisticsData.map((e, i) => {
                            return (
                                <div className={'task-item'} key={i}>
                                    <div className={'item-dot'}></div>
                                    <div className={'item-title'} style={{marginRight: 22}}>{e.name}</div>
                                    <div
                                        className={useInfo.authRoutes?.includes('/comprehensiveQuery/qualityMonitor') ? 'item-statistics-number' : 'item-statistics-number-no-cursor'}
                                        onClick={() => skipPage('/comprehensiveQuery/qualityMonitor')}>{e.number ? formatNumber(e.number.toString()) : 0}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default Dashboard

import {Breadcrumb} from 'antd';
import screenfull from "screenfull";
import {useLocation} from "react-router-dom";
import React, {useContext, useState} from "react";
import {UserContext} from "../../contexts/userContext";

/**
 * 面包屑导航
 */
const BreadcrumbBar = () => {
    const location = useLocation();
    const {useInfo} = useContext(UserContext);
    const [isFull, setIsFull] = useState(false);
    const openKeysItem = useInfo.menus?.find(e => e.key === location.pathname.split('/')[1]);
    const getSelectedKeys = () => {
        if (openKeysItem) {
            if (openKeysItem.children?.length) {
                return openKeysItem.children.find((e: { key: string; }) => e.key === location.pathname);
            }
        }
    }
    const selectedKeysItem = getSelectedKeys();
    const screenFull = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
            setIsFull(value => !value);
        }
    };
    const refresh = () => {
        window.location.reload();
    }

    return (
        <div className={'mes-bread'}>
            <div>
                {location.pathname === '/settings' && (
                    <Breadcrumb className={'lineHeight35'}>
                        <Breadcrumb.Item>
                            <span>账户设置</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                )}
                {location.pathname === '/' && (
                    <Breadcrumb className={'lineHeight35'}>
                        <Breadcrumb.Item>
                            <img src={require('../../assets/homeBreadLogo.png')} alt={''}
                                 className={'mes-bread-home'}/>
                            <span>首页</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                )}
                {
                    openKeysItem && selectedKeysItem && (
                        <Breadcrumb style={{lineHeight: '35px', color: '#666666'}}>
                            <Breadcrumb.Item>
                                <img src={require('../../assets/homeBreadLogo.png')} alt={''}
                                     className={'mes-bread-home'}/>
                                {openKeysItem.title}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {selectedKeysItem.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    )
                }
            </div>
            <div>
                <span style={{marginRight: 17, cursor: 'pointer'}} onClick={refresh}>
                    <img src={require('../../assets/refresh.png')} alt={''}
                         style={{width: 30}}/>
                </span>
                <span style={{cursor: 'pointer'}} onClick={screenFull}>
                    <img src={isFull ? require('../../assets/screenExit.png') : require('../../assets/screenFull.png')}
                         alt={''}
                         style={{width: 30}}/>
                </span>
            </div>
        </div>
    )
}

export default React.memo(BreadcrumbBar);

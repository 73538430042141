import React from "react";
import GeneralManagement, {Options} from "../../../components/GeneralManagement";
import moment from "moment";
import {filterDateParam, showError} from "../../../utils";
import {ColumnsType} from "antd/es/table";
import {systemLog} from "../../../service";


const Log = (): React.ReactElement => {
    const funcList = [
        {
            id: 10,
            name: '任务管理'
        },
        {
            id: 20,
            name: '报工监控'
        },
        {
            id: 30,
            name: '发货单管理'
        },
        {
            id: 40,
            name: '综合管理'
        },
    ]
    const formList = [
        {
            key: 'system_function',
            name: '系统功能',
            width: 60,
            placeholder: '请选择系统功能',
            options: funcList
        },
        {
            key: 'date',
            name: '操作日期',
            width: 60,
            placeholder: '请选择操作日期',
            rangePick: true
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'No',
            dataIndex: 'No',
            key: 'No',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (_: string, item: any, index: number) => <span>{index + 1}</span>
        },
        {
            title: '系统功能',
            dataIndex: 'system_function',
            align: 'center',
            key: 'name',
            width: 200,
            ellipsis: true,
            render: (text: number) => {
                return (
                    funcList.map(e => {
                        if (e.id === text) {
                            return <span>{e.name}</span>
                        }
                    })
                )
            }
        },
        {
            title: '操作详情',
            dataIndex: 'operation_details',
            key: 'detail',
            width: 400,
            ellipsis: true,
            render: (text: string) =>
                <span>{text}</span>
        },
        {
            title: '操作人',
            dataIndex: 'operation_user',
            align: 'center',
            key: 'person',
            width: 200,
            ellipsis: true,
            render: (text: string) =>
                <span>{text}</span>
        },
        {
            title: '操作时间',
            dataIndex: 'create_time',
            align: 'center',
            key: 'time',
            ellipsis: true,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                } else {
                    return null
                }
            }
        },
    ]

    const getDataAsync = (queryCondition: any) => {
        return new Promise((resolve) => {
            systemLog(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               filterQueryData={(queryData) => filterDateParam(queryData, 'date', 'start_date', 'end_date')}/>
        </div>
    )
}
export default Log;

// 封装创建发货单和编辑发货单的表格
import React, { useRef,useEffect} from "react";
import { Input, Table, Form,InputNumber } from "antd";
import "../index.less";

interface IProps {
  // 创建时传的数据
  createDeliverData?: any;
  // TableForm FormInstance
  tableForm?: any;
  // 当前是创建还是编辑
  is_Edit: boolean;
  // 编辑时传的数据
  detailDelivery?: any;
  // 创建时传
  setCreateDeliverData?:any
}
const DeliveryTable = (props: IProps) => {
  const {
    tableForm,
    detailDelivery,
    is_Edit,
    createDeliverData,
    setCreateDeliverData
  } = props;
  const [dataSource, setDataSource] = React.useState<any[]>(
    is_Edit
      ? detailDelivery.deliver_part && detailDelivery.deliver_part
      : createDeliverData.part_data && createDeliverData.part_data
  );
  const dataSourceRef = useRef<any[]>();
  dataSourceRef.current = dataSource;

  let columns:any[]=[
    {
      title: "加工项目",
      key: "1",
      align: "center",
      children: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          align: "center",
          ellipsis: true,
          width:100
        },
      ],
    },
    {
      title: () => (
        <Form.Item
          name="content"
          rules={[
            { required: true, message: "请输入加工项目!" },
            { max: 32, message: "最多输入32个字符" },
          ]}
        >
          <Input placeholder="请输入加工项目"/>
        </Form.Item>
      ),
      key: "2",
      align: "center",
      className: "title_style",
      children: [
        {
          title: "零件名称",
          dataIndex: [ "part", "name"],
          key: "name",
          align: "center",
          ellipsis: true,
          fixed: "left",
        },
        {
          title: "零件图号",
          dataIndex: ["part", "number"],
          key: "number1",
          align: "center",
          ellipsis: true,
        },
      ],
    },
    {
      title: "任务制号",
      key: "3",
      align: "center",
      children: [
        {
          title: "数量",
          dataIndex: is_Edit ? "number" : "kucun_num", 
          key: "kucun_num",
          align: "center",
          ellipsis: true,
          width:260,
          render: (text: any, record: any) => {
            return (
              <Form.Item
                // initialValue={is_Edit ? text : createDeliverData.kucun_num}
                initialValue={text}
                // name={is_Edit?`${record.order_part_id}-${record.object_id}-number`:`${record.object_id}-number`}
                name={`${record.object_id}-number`}
                rules={[
                  {
                    required: true,
                    message: "请输入数量",
                  },
                  ({ getFieldValue }: any) => {
                    let reg = /^[0-9]*[1-9][0-9]*$/;
                    return {
                      validator(_: any, value: any) {
                        // Number(0) = true
                        // 只有空字符串和数字0使用Boolean转化时返回false
                        // console.log("Boolean(value)",Boolean(value))
                        
                        if (value || value === 0) {
                          if (!reg.test(value.toString()) && Number(value) !== 0) {
                            return Promise.reject(new Error("请输入整数"));
                            // 校验修改后的数量不能大于修改前的数量
                          } 
                          // else if (
                          //   is_Edit && text &&
                          //   Number(value) > text
                          // ){
                          //   return Promise.reject(
                          //     new Error("修改后的数量不能大于修改前的数量")
                          //   );
                          // } 
                          // else if(is_Edit && Number(value) === 0 ) {
                          //   return Promise.reject("数量必须大于0")
                          // }
                           else if(
                            (!is_Edit && Number(value)>text) ||  (!is_Edit && Number(value) <= 0)
                          ){
                            return Promise.reject("数量必须大于0,小于库存数量")
                          }
                        }
                        return Promise.resolve();
                      },
                    };
                  },
                ]}
              >
                <InputNumber
                controls={false}
                style={{width:"100%"}}
                placeholder="请输入数量" 
                formatter={(value) =>{
                  return value?.toString().replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") as string
                }}
                parser={(value) => value!.replace(/,/gi,'')} 
                />
              </Form.Item>
            );
          },
        },
      ],
    },
    {
      title: () => (
        <Form.Item
          name="order_code"
          rules={[
            { required: true, message: "请输入任务制号!" },
            { max: 32, message: "最多输入32个字符" },
          ]}
        >
          <Input placeholder="请输入任务制号"/>
        </Form.Item>
      ),
      className: "title_style",
      key: "4",
      align: "center",
      children: [
        {
          title: "工序",
          dataIndex: is_Edit?"precesses":"content",
          key: "precesses",
          align: "center",
          ellipsis: true,
          render: (text: any, record: any) => {
            return (
              <Form.Item
                initialValue={text}
                // name={is_Edit?`${record.order_part_id}-${record.object_id}-precesses`:`${record.object_id}-precesses`}
                name={`${record.object_id}-precesses`}
                rules={[
                  {
                    required: true,
                    message: "请输入工序",
                  },
                  { max: 200, message: "最多输入200个字符" },
                ]}
              >
                <Input />
              </Form.Item>
            );
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          align: "center",
          ellipsis: true,
          render: (text: any, record: any) => (
            <Form.Item
              // name={is_Edit?`${record.order_part_id}-${record.object_id}-remark`:`${record.object_id}-remark`}
              name={`${record.object_id}-remark`}
              initialValue={text}
              rules={[
                { max: 200, message: "最多输入200个字符" },
              ]}
            >
              <Input placeholder="请输入备注" />
            </Form.Item>
          ),
        },
        {
          title: "操作",
          dataIndex: "caozuo",
          key: "caozuo",
          align: "center",
          width:100,
          render: (_: any, record: any) => (
            <div
              className="delete"
              onClick={() => {
                if(dataSourceRef.current){
                let newDataSource1 = dataSourceRef.current?.filter((item) => item.index !== record.index);
              
                newDataSource1.map( (i,index) => {
                  i.index = index+1
                })
                setDataSource(newDataSource1 as any[]);
                setCreateDeliverData && setCreateDeliverData({
                  ...createDeliverData,
                  part_data:newDataSource1
                });
                }
              }}
            >
              删除
            </div>
          ),
        }
      ],
    },
  ];
  const filterOptions = (arr:any[]) =>{
    arr.map(i => {
      if(i.key && i.key === "4") {
        if(i.children) {
          // -1表示从数组尾部算删除的元素
          i.children = i.children.slice(0,i.children.length -1)
        }
      }
    });
    return arr
  }
  useEffect(()=>{
    tableForm.resetFields();
    tableForm.setFieldsValue({
      content:is_Edit ? detailDelivery.content : "零件加工",
      order_code:is_Edit  ? detailDelivery.order_code: createDeliverData.order_code && createDeliverData.order_code

    })
  },[])
  return (
    <div className="delivery_table">
      <Form form={tableForm}>
        <Table
          columns={is_Edit ? filterOptions(columns) : columns }
          dataSource={dataSource}
          className="table"
          bordered
          pagination={false}
        />
      </Form>
    </div>
  );
};
export default React.memo(DeliveryTable);
